import { Request } from "@/services/request";

export default {
  fetchCheckList(id) {
    return new Request().append(`api/v1/task_todo_checklist/${id}/by_task/`).method("get");
  },
  createCheckList(data) {
    return new Request()
      .append("api/v1/task_todo_checklist/")
      .method("post")
      .setData(data)
  }, 
  updateCheckList(id, data) {
    return new Request()
      .append(`api/v1/task_todo_checklist/${id}/`)
      .method("patch")
      .setData(data)
  },
  deleteCheckList(id) {
    return new Request()
      .append(`api/v1/task_todo_checklist/${id}/`)
      .method("delete")
  },

  fetchJobBidsAwarded(id) {
    return new Request().append(`api/v1/job/${id}/bids_awarded/`).method("get");
  },
  fetchToDoSetting() {
    return new Request()
      .append(`api/v1/todo/default_datas/`)
      .method("get");
  }, 
  fetchToDoStatus(id) {
    return new Request().append(`api/v1/task_status/${id}/by_job/`).method("get");
  },

  createToDoStatus(data) {
    return new Request()
      .append("api/v1/task_status/")
      .method("post")
      .setData(data)
  },
  updateToDoStatus(id, data) {
    return new Request()
      .append(`api/v1/task_status/${id}/`)
      .method("patch")
      .setData(data)
  },
  deleteToDoStatus(id) {
    return new Request()
      .append(`api/v1/task_status/${id}/`)
      .method("delete")
  },

  fetchToDo(id) {
    return new Request().append(`api/v1/task_todo/${id}/by_job/`).method("get");
  },
  createToDoTask(data) {
    return new Request()
      .append("api/v1/task_todo/")
      .method("post")
      .setData(data)
  },
  updateToDoTask(id, data) {
    return new Request()
      .append(`api/v1/task_todo/${id}/`)
      .method("patch")
      .setData(data)
  },
  deleteToDoTask(id) {
    return new Request()
      .append(`api/v1/task_todo/${id}/`)
      .method("delete")
  },

  fetchPriority(id) {
    return new Request().append(`api/v1/task_priority/${id}/by_job/`).method("get");
  },
  createPriority(data) {
    return new Request()
      .append("api/v1/task_priority/")
      .method("post")
      .setData(data)
  },
  updatePriority(id, data) {
    return new Request()
      .append(`api/v1/task_priority/${id}/`)
      .method("patch")
      .setData(data)
  },
  deletePriority(id) {
    return new Request()
      .append(`api/v1/task_priority/${id}/`)
      .method("delete")
  },
};