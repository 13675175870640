<template>
  <div v-if="options" class="dropdown d-block d-sm-inline-block">
    <a role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <img
        alt="Option"
        :src="white ? OptionWhite : Option"
        :class="{ '': status }"
        class="text-left p-2"
      />
    </a>
    <ul class="dropdown-menu">
      <li
        v-for="(item, index) in options.filter((el) => el.value !== status)"
        :key="index"
      >
        <div
          v-if="item.value"
          class="cursor-pointer"
          @click="update(item.value)"
        >
          <tool-tips :infos="item.title" :class-name="'icon'">
            <img
              :src="
                assignImage(
                  Object.keys(icon).filter((e) => e.includes(item.value))
                )
              "
              :alt="item.title"
            />
          </tool-tips>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ToolTips from "./ToolTips.vue";
import  "bootstrap/dist/js/bootstrap.esm";
import Option from "@/assets/img/icons/Option.svg";
import OptionWhite from "@/assets/img/icons/Option-white.svg";
import details from "@/assets/img/icons/infos.svg";
import deleteIcon from "@/assets/img/icons/delete.svg";
import canceled from "@/assets/img/icons/cancel.svg";
import edit from "@/assets/img/icons/edit.svg";
import send from "@/assets/img/icons/sendFile.svg";
import refused from "@/assets/img/icons/unauthorized.svg";
import active from "@/assets/img/icons/check.svg";
import addtask from "@/assets/img/icons/add-line.svg";

export default {
  name: "OptionAction",
  components: { ToolTips },

  props: {
    options: {
      type: Array,
      required: false,
      default: null,
    },

    status: {
      type: String,
      required: false,
      default: null,
    },

    displayTitle: {
      type: String,
      required: false,
      default: null,
    },
    white: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    Option,
    OptionWhite,
    icon: {
      details,
      deleteIcon,
      canceled,
      edit,
      send,
      refused,
      active,
      addtask,
    },
  }),
  methods: {
    update(select) {
      this.$emit("update:options", select);
    },

    assignImage(cal) {
      if (cal.lenght !== 0) {
        return this.icon[cal[0]];
      }
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
  text-align: center;
}
li {
  list-style: none;
  display: inline-block;
  padding: 0 0.25rem;
}
</style>
