import { Request } from "@/services/request";

export default {
  addPropriety(data) {
    return new Request()
      .append(`api/v1/customerproperties/`)
      .method("post")
      .setData(data);
  },
  fetchProprieties({ page = 1, search = null,}) {
    return new Request()
      .append(`api/v1/customerproperties/`)
      .method("get")
      .params({ page, search });
  },

  fetchPropertiesJobs({
    page = 1,
    id = null,
    provider_propertie_associate = null
  }) {
    return new Request().append(`api/v1/customerproperties/${id}/job_list/`)
    .method("get")
    .params({ page, provider_propertie_associate });
  },
  fetchPropertiesEstimates({
    page = 1,
    id = null,
    provider_propertie_associate = null
  }) {
    return new Request().append(`api/v1/estimation/${id}/by_customer_property/`)
    .method("get")
    .params({ page, provider_propertie_associate });
  },

  fetchAllProprieties() {
    return new Request()
      .append(`api/v1/customerproperties/customer_properties/`)
      .method("get")
  },
  fetchPropriety(id) {
    return new Request()
      .append(`api/v1/customerproperties/${id}/`)
      .method("get")
  },
  deletePropriety(id) {
    return new Request()
      .append(`api/v1/customerproperties/${id}/`)
      .method("delete")
  },
  updatePropriety(id, data) {
    return new Request()
      .append(`api/v1/customerproperties/${id}/`)
      .method("patch")
      .setData(data);
  },
}
