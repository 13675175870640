<template>
  <div class="provider-details ">
    <loader-spinner v-if="loading" />

    <div
      v-else
      class="row"
    >
      <div class="page-header">
        <nav aria-label="breadcrumb title-part">
          <ol class="breadcrumb">
            <li class="breadcrumb-item title">
              <router-link
                :to="{
                  name: 'CustomerSavedProviders',
                }"
              >
                Saved Providers
              </router-link>
            </li>

            <li
              v-if="savedProvider?.provider"
              class="breadcrumb-item active title-content"
              aria-current="page"
            >
              {{ savedProvider?.provider.user.full_name }}
            </li>
          </ol>
        </nav>
      </div>

      <div
        v-if="!loading"
        class="page-content px-md-3"
      >
        <template v-if="savedProvider?.provider?.user">
          <div class="proposals mt-4 px-md-4 pm">
            <div class="proposal">
              <div class="row position-relative">
                <div class="col-md-6 px-0">
                  <div class="d-flex">
                    <div class="ms-2">
                      <profile-avatar
                        :url="savedProvider.provider.user.photo?.url"
                        :fullname="savedProvider.provider.user.full_name"
                        :color="savedProvider.provider.user.profile_color"
                        :background="savedProvider.provider.user.profile_background"
                      />
                    </div>

                    <div>
                      <router-link
                        :to="{ 
                          name: 'ProviderPublicProfile',
                          params: {
                            username: `@${savedProvider.provider.user.username}`,
                          },
                        }"
                        class="provider-name"
                      >
                        <strong class="provider-name">
                          {{ savedProvider.provider.user.full_name }}
                        </strong>
                      </router-link>

                      <div class="heading">
                        <div class="rating">
                          <span>Rate:</span>
                          <rating-stars
                            :rating="savedProvider.provider.rating ?? 0"
                          />
                        </div>
                      </div>

                      <a
                        v-if="savedProvider.cv"
                        :href="savedProvider.cv?.url"
                        download
                        class="btn-secondary download-btn mt-1"
                      >
                        View CV
                        <img
                          :src="icons.downloadVectorIcon"
                          :alt="savedProvider.cv.original_name"
                          class="ms-2"
                        >
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 text-md-end favorite">
                  <button
                    class="btn-favorite added"
                    :disabled="removingFromFavorites"
                    @click="confirmClaim"
                  >
                    <submission-spinner
                      v-if="removingFromFavorites"
                      :color="'#209516'"
                    />
                    <img
                      v-else
                      :src="icons.heartVectorIcon"
                      alt="Favorite Icon"
                    >
                  </button>
                </div>
              </div>

              <p>
                {{ savedProvider.provider.user.profile_description }}
              </p>
            </div>
          </div>

          <div
            v-if="savedProvider.provider.user.skills?.length > 0"
            class="part px-md-4"
          >
            <p class="skill-title">
              Skills :
            </p>

            <div class="auto-flex">
              <span
                v-for="skill in savedProvider.provider.user.skills"
                :key="skill.id"
                class="type-job"
              >{{ skill.name }}</span>
            </div>
          </div>

          <div
            v-if="false"
            class="pb-2 px-md-4"
          >
            <p class="skill-title">
              Works experiences :
            </p>

            <div class="mt-4 mb-3">
              <div class="row">
                <div class="works">
                  <div class="col-md-12">
                    <p class="work">
                      Python API Developer
                    </p>
                    <p class="date">
                      7 Jan. 2022 - 15 Jan. 2022
                    </p>
                  </div>
                  <div class="d-md-flex">
                    <span class="d-flex me-4">
                      <p class="case">Level :</p>

                      <p class="number-case">Beginner</p>
                    </span>

                    <span class="d-flex me-4">
                      <p class="case">Level :</p>

                      <p class="number-case">Beginner</p>
                    </span>

                    <span class="d-flex me-4">
                      <p class="case">Level :</p>

                      <p class="number-case">Beginner</p>
                    </span>
                  </div>
                </div>

                <div class="p-3">
                  <div class="col-md-12">
                    <p class="work">
                      Python API Developer
                    </p>
                    <p class="date">
                      7 Jan. 2022 - 15 Jan. 2022
                    </p>
                  </div>
                  <div class="d-md-flex">
                    <span class="d-flex me-4">
                      <p class="case">Level :</p>

                      <p class="number-case">Beginner</p>
                    </span>

                    <span class="d-flex me-4">
                      <p class="case">Level :</p>

                      <p class="number-case">Beginner</p>
                    </span>

                    <span class="d-flex me-4">
                      <p class="case">Level :</p>

                      <p class="number-case">Beginner</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <confirm-modal
      ref="confirmModal"
      :ok-button-title="'Remove'"
      :message="`Are you sure you want to remove this provider's profile from favorites ?`"
      @onConfirm="removeProviderFromFavorites"
    />
  </div>
</template>

<script>
import RatingStars from "@/components/RatingStars.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import ProfileAvatar from "@/components/ProfileAvatar.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import heartVectorIcon from "@/assets/img/icons/heart.svg";
import downloadVectorIcon from "@/assets/img/icons/download.svg";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import api from "@/services/api";

export default {
  name: "CustomerSavedProvidersDetailsPage",

  components: {
    ProfileAvatar,
    RatingStars,
    ConfirmModal,
    SubmissionSpinner,
    LoaderSpinner,
  },

  data: () => ({
    icons: {
      heartVectorIcon,
      downloadVectorIcon,
    },
    loading: false,
    savedProvider: null,
    removingFromFavorites: false,
  }),

  mounted() {
    this.fetchSavedProvider();
  },

  methods: {
    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },

    async fetchSavedProvider() {
      try {
        this.loading = true;
        const response = await api.fetchSavedProvider(this.$route.params.id);
        this.savedProvider = response.data;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async removeProviderFromFavorites() {
      try {
        this.removingFromFavorites = true;
        this.clearErrorsMessages();

        await api.removeProviderFromFavorites(this.savedProvider.id);
        this.$router.push({ name: "CustomerSavedProviders" });
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.removingFromFavorites = false;
      }
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (
        err.response &&
        err.response.status !== 400 &&
        err.response.status !== 404
      ) {
        this.errors.serverSideErrorMessage = err.response.message;
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
