<template>
  <div class="dashboard-provider">
    <list-header
      title="Estimates"
      :options="headerOptions"
      @update:options="onListHeaderUpdate"
    />

    <div
      class="row mb-3"
    >
      <div
        v-for="statistic in statistics"
        :key="statistic.value"
        class="col-md-6 col-lg-4"
      >
        <div class="statistic-item">
          <div class="card">
            <h3>{{ statistic.count }}</h3>

            <p>{{ statistic.title }}</p>

            <span class="linear-bar" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table
            class="table"
          >
            <head-table
              :list="thead"
            />

            <tbody v-if="!fetching">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td class="py-3">
                  <span
                    class="cursor-pointer text-primary"
                    @click="detail(item)"
                  >{{ item.title }}</span>
                </td>

                <td>
                  {{ formatDate(item.created_at) }}
                </td>

                <td>
                  {{ statusToUI(item.status) }}
                </td>

                <td>
                  <submission-spinner
                    v-if="updatingEstimate && selectedEstimateId === item.id"
                    :color="'#209516'"
                  />
                  <option-action
                    v-else
                    :status="item.status"
                    :options="getActions(item.status)"
                    @update:options="choose(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetching" />

          <app-pagination
            v-if="totalPages > 1"
            :align-end="true"
            :current-page="currentPage"
            :has-previous-page="hasPreviousPage"
            :previous-page="currentPage - 1 || 1"
            :has-next-page="hasNextPage"
            :next-page="currentPage + 1"
            :per-page="itemsPerPage"
            :total-elements="itemsCount"
            :total-pages="totalPages"
            @onGotoPage="fetchEstimates({ page: $event })"
            @onPrev="fetchEstimates({ page: currentPage - 1 || 1 })"
            @onNext="fetchEstimates({ page: currentPage + 1 })"
          />
        </div>
      </div>
    </div>

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </div>
</template>

<script>
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import OptionAction from "@/components/OptionAction.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import AppPagination from "@/components/AppPagination.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import api from "@/services/api";
import ListMixin from "@/mixins/list";

import { DateHelpers } from "@/utils/date-helpers";
import statusToUI from "@/utils/statusFormate";
import { EstimateStatus } from "@/configs/constants";

export default {
  name: "CustomerEstimatesPage",

  components: {
    ListHeader,
    HeadTable,
    OptionAction,
    ConfirmModal,
    LoaderSpinner,
    AppPagination,
    SubmissionSpinner,
  },

  mixins: [ListMixin(api.fetchEstimates, true)],

  data: () => ({
    options: [
      {
        title: "Details",
        value: "details",
      },
      {
        title: "Approve",
        value: "approved",
      },
      {
        title: "Decline",
        value: "denied",
      },
    ],
    headerOptions: {
      title: "Status",
      list: [
        {
          title: "Status",
        },
        {
          title: "Active",
          value: "active",
        },
        {
          title: "Unsent",
          value: "unsent",
        },
      ],
    },
    thead: ["Title", "Date sent", "Status", ""],
    listData: [],
    page: 1,
    status: null,
    search: null,
    fetching: false,
    statistics: {
      pending: {
        title: "Pending",
        value: "pending",
        count: 0,
      },
      approved: {
        title: "Approved",
        value: "approved",
        count: 0,
      },
      dinied: {
        title: "Denied",
        value: "denied",
        count: 0,
      },
    },
    actions: {
      DENIED: "denied",
      APPROVED: "approved",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    selectedEstimateId: null,
    updatingEstimate: false,
  }),

  watch: {
    listData(val) {
      this.fetching = false;
    },
  },

  mounted() {
      this.fetchEstimatesStatistics();
      this.fetchEstimates({ page: Number(this.$route.query.page) || 1 });
  },

  methods: {
    ...DateHelpers,
    ...statusToUI,

    getActions(status) {
      let items = [
        {
          title: "Details",
          value: "details",
        },
      ];
      if (this.canApproveEstimate(status)) {
        items.push({
          title: "Approve",
          value: "approved",
        });
      }
      if (this.canDeclineEstimate(status)) {
        items.push({
          title: "Decline",
          value: "denied",
        });
      }

      return items;
    },

    canApproveEstimate(status) {
      if (!status) return false;
      return status !== EstimateStatus.APPROVED;
    },

    canDeclineEstimate(status) {
      if (!status) return false;
      return ![EstimateStatus.APPROVED, EstimateStatus.DENIED].includes(status);
    },

    fetchEstimates(params) {
      this.fetching = true;
      this.updateListFilters({
        ...params,
      });
    },

    async fetchEstimatesStatistics() {
      try {
        const response = await api.fetchEstimatesSummary();
        const statistics = response.data;
        for (const key in this.statistics) {
          if (Object.hasOwnProperty.call(this.statistics, key)) {
            const element = this.statistics[key];
            this.statistics[key] = {
              ...element,
              count: statistics[element.value] ?? 0,
            };
          }
        }
      } catch (_) {}
    },

    onListHeaderUpdate(event) {
      this.fetchEstimates({ page: 1, status: event.value ?? undefined });
      this.headerOptions.title = event.title;
      this.status = event.value;
    },

    create() {
      this.$router.push({
        name: "CustomerEstimateCreate",
      });
    },

    detail(item) {
      this.$router.push({
        name: "CustomerEstimateDetails",
        params: { id: item.id },
      });
    },

    choose(item, event) {
      switch (event) {
        case "details":
          this.detail(item);
          break;

        case "approved":
          this.confirmClaim(item.id, this.actions.APPROVED);
          break;

        case "denied":
          this.confirmClaim(item.id, this.actions.DENIED);
          break;
      }
    },

    confirmClaim(id, action) {
      switch (action) {
        case this.actions.APPROVED:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to approve this estimate ?`;
          break;

        case this.actions.DENIED:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to deny this estimate ?`;
          break;
      }
      this.selectedEstimateId = id;
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.APPROVED:
          this.updateEstimatesStatus(
            EstimateStatus.APPROVED,
            `Estimate successfully approved.`
          );
          break;

        case this.actions.DENIED:
          this.updateEstimatesStatus(
            EstimateStatus.DENIED,
            `Estimate successfully denied.`
          );
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    updateEstimatesStatus(status, successMessage) {
      this.updatingEstimate = true;

      api
        .updateEstimatesStatus(this.selectedEstimateId, status)
        .then((_) => {
          const index = this.listData.findIndex(
            (x) => x.id === this.selectedEstimateId
          );
          if (index !== -1) {
            this.listData[index].status = status;
          }
          this.selectedEstimateId = null;
          toast.success(successMessage);
          this.fetchEstimatesStatistics();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.updatingEstimate = false;
        });
    },
  },
};
</script>
