<template>
  <div class="job-list">
    <notification-item
      v-for="notification in notifications"
      :id="notification.id"
      :key="notification.id"
      :title="notification.title"
      :content="notification.message"
      :date="notification.created_at"
      :read="notification.read"
      :type="notification.type"
      :data="notification.data || {}"
    />
  </div>
</template>

<script>
import NotificationItem from "@/components/notifications/NotificationItem.vue";

export default {
  name: "ProviderNotificationList",

  components: {
    NotificationItem,
  },

  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
};
</script>
