import { InvoiceStatus } from "@/configs/constants";

export default {
  formatInvoiceStatus(status) {
    let formatedStatus = "";

    switch (status) {
      case InvoiceStatus.PAID:
        formatedStatus = "Paid";
        break;

      case InvoiceStatus.UNSENT:
        formatedStatus = "Unsent";
        break;

      case InvoiceStatus.PENDING:
        formatedStatus = "Pending";
        break;

      case InvoiceStatus.PARTIAL:
        formatedStatus = "Partially Paid";
        break;

      case InvoiceStatus.REFUND:
        formatedStatus = "Refunded";
        break;

      case InvoiceStatus.EXTERNAL_PAY:
        formatedStatus = "External Pay";
        break;
    }
    return formatedStatus;
  },

  getInvoiceStatusStyle(status) {
    let styles = {
      color: null,
      class: null,
    };

    switch (status) {
      case InvoiceStatus.PAID:
        styles.color = "#209516";
        styles.class = "invoice-status-paid";
        break;

      case InvoiceStatus.EXTERNAL_PAY:
        styles.color = "#209516";
        styles.class = "invoice-status-paid";
        break;
      case InvoiceStatus.UNSENT:
        styles.color = "#737C84";
        styles.class = "invoice-status-unsent";
        break;

      case InvoiceStatus.PENDING:
        styles.color = "#ECBD01";
        styles.class = "invoice-status-pending";
        break;

      case InvoiceStatus.PARTIAL:
        styles.color = "#FFA500";
        styles.class = "invoice-status-partial";
        break;

      case InvoiceStatus.REFUND:
        styles.color = "#e0230b";
        styles.class = "invoice-status-reversed";
        break;
    }
    return styles;
  },
};
