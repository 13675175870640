<template>
  <div
    v-if="canApply"
    class="job-apply"
  >
    <section class="content col-lg-8 mx-auto">
      <div class="page-header">
        <h3 class="mt-3">
          Edit proposal
        </h3>
      </div>
      <loader-spinner v-if="loading" />
      <div
        v-else-if="errors.errorMessage"
        class="row mt-4"
      >
        <div class="col-lg-8 ">
          <alerte-danger
            :head-error-message="errors.errorMessage"
            @updateContent="errors.errorMessage = $event"
          />
        </div>
      </div>
      <div
        v-else-if="job"
        class="page-content"
      >
        <template v-if="loading" />

        <div
          v-else-if="errors.errorMessage"
          class="row mt-4"
        >
          <div class="col-md-8">
            <alerte-danger
              :head-error-message="errors.errorMessage"
              @updateContent="errors.errorMessage = $event"
            />
          </div>
        </div>

        <form
          v-else
          @submit.prevent="updateProposal"
        >
          <div class="row">
            <alerte-danger
              :head-error-message="errors.serverSideErrorMessage"
              @updateContent="errors.serverSideErrorMessage = $event"
            />
            <p class="title mt-3 mb-3">
              Proposal details
            </p>

            <div class="col-lg-6 mb-3">
              <label
                for="from"
                class="form-label"
              >Proposed bids</label>

              <div class="d-flex align-items-center mb-3">
                <money3
                  v-model="applydata.amount"
                  prefix="$"
                />
                <span
                  v-if="job?.budget_type !== jobBudgetTypes.FIXED"
                  class="ms-2"
                >
                  /
                  {{
                    formatJobPaymentFrequencies(job?.payment_frequency).short
                  }}
                </span>
              </div>

              <small
                v-if="errors.amountErrorMessage"
                class="invalid-feedback"
              >
                {{ errors.amountErrorMessage }}
              </small>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 mb-3">
              <label class="form-label">Work Start Date (optional)</label>

              <vue-date-picker
                v-model="applydata.work_start_date"
                placeholder="Choose a Start Date"
                class="datepicker form-control"
                auto-apply
                :enable-time-picker="false"
                :min-date="new Date()"
                :max-date="applydata.work_end_date"
                :format="'MM-dd-yyyy'"
              />

              <small
                v-if="errors.workStartDateErrorMessage"
                class="invalid-feedback"
              >
                {{ errors.workStartDateErrorMessage }}
              </small>
            </div>

            <div class="col-lg-6 mb-3">
              <label
                for="contract"
                class="form-label"
              >Work End Date (optional)</label>

              <vue-date-picker
                v-model="applydata.work_end_date"
                placeholder="Choose an End Date"
                class="datepicker form-control"
                auto-apply
                :enable-time-picker="false"
                :min-date="applydata.work_start_date || new Date()"
                :start-date="applydata.work_start_date || new Date()"
                :format="'MM-dd-yyyy'"
              />

              <small
                v-if="errors.workEndDateErrorMessage"
                class="invalid-feedback"
              >
                {{ errors.workEndDateErrorMessage }}
              </small>
            </div>

            <p class="title mb-3">
              More Informations
            </p>

            <div class="mb-4">
              <label
                for="contract"
                class="form-label"
              >
                Cover Letter  <span class="text-danger">*</span>
              </label>

              <textarea
                v-model="applydata.message"
                required
                placeholder="Write your answer here..."
                class="m-0"
                rows="4"
                :class="{
                  'form-control is-invalid':
                    (v$.applydata.message.$invalid &&
                      v$.applydata.message.$dirty) ||
                    errors.messageErrorMessage,
                }"
                @blur="v$.applydata.message.$touch"
              />
              <template
                v-if="
                  (v$.applydata.message.$invalid &&
                    v$.applydata.message.$dirty) ||
                    errors.messageErrorMessage
                "
              >
                <small
                  v-if="v$.applydata.message.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="errors.messageErrorMessage"
                  class="invalid-feedback"
                >
                  {{ errors.messageErrorMessage }}
                </small>
              </template>
            </div>

            <div class="mb-3">
              <label
                for="contract"
                class="form-label"
              >
                Curriculum Vitae
              </label>
              <select
                v-model="applydata.cv"
                class="form-select py-3 shadow-none"
                :class="{ 'is-invalid': errors.cvErrorMessage }"
                @change="changeSelect($event)"
                @blur="v$.applydata.cv.$touch"
              >
                <option
                  v-for="cv in myCvList"
                  :key="cv.id"
                  :value="cv.id"
                  :disabled="cv.id === proposal?.cv?.id"
                  class="select-option"
                >
                  {{ cv.original_name }}
                </option>
              </select>

              <small
                v-if="errors.cvErrorMessage"
                class="invalid-feedback"
              >
                {{ errors.cvErrorMessage }}
              </small>

              <div
                v-if="upload"
                class="upload-file-wrapper mt-3 d-none"
              >
                <input
                  ref="w9Document"
                  class="d-none shadow-none"
                  type="file"
                  @change="onCvFileChange"
                >
                <span v-if="cvName">
                  {{ cvName.filename }}
                </span>
                <span v-else> Upload your cv. </span>

                <button
                  type="button"
                  class="btn-upload"
                  @click="$refs.w9Document.click()"
                >
                  <img
                    :src="icons.download"
                    alt="icon Upload"
                  >
                  Upload
                </button>
              </div>
            </div>

            <div
              v-if="job?.screening_question?.length > 0"
              class="mb-3"
            >
              <p class="title">
                Screening Questions
              </p>

              <template v-if="errors.responseErrorMessage">
                <small
                  v-if="errors.responseErrorMessage"
                  class="invalid-feedback"
                >
                  {{ errors.responseErrorMessage }}
                </small>
              </template>
              <ol>
                <div
                  v-for="question in newTableQuestion"
                  :key="question.id"
                >
                  <li
                    v-if="question.type === jobScreeningQuestionTypes.LONG"
                    class="text-primary mb-4"
                  >
                    <p>
                      {{ question.name }}
                    </p>
                    <textarea
                      required
                      placeholder="Write your answer here..."
                      class="mb-4 m-0"
                      :value="question.answer"
                      @input="addResponse(question, '', $event)"
                    />
                  </li>
                  <li
                    v-if="question.type === jobScreeningQuestionTypes.MULTIPLE"
                  >
                    <p class="text-primary">
                      {{ question.name }}
                    </p>

                    <div class="check-list mb-4">
                      <div
                        v-for="(responseQuestion, index) in question?.response"
                        :key="index"
                        class="form-check"
                      >
                        <input
                          :id="`question-${index}`"
                          class="form-check-input shadow-none"
                          type="checkbox"
                          :checked="responseQuestion.isChecked"
                          @change="addResponse(question, responseQuestion, '')"
                        >
                        <label
                          class="form-check-label"
                          :for="`question-${index}`"
                        >
                          {{ responseQuestion.response }}
                        </label>
                      </div>
                    </div>
                  </li>
                  <li v-if="question.type === jobScreeningQuestionTypes.SINGLE">
                    <p class="text-primary">
                      {{ question.name }}
                    </p>
                    <div class="check-list mb-4">
                      <div
                        v-for="(responseQuestion, index) in question?.response"
                        :key="responseQuestion.id"
                        class="form-check"
                      >
                        <input
                          :id="`question-${index}`"
                          class="form-check-input shadow-none"
                          type="checkbox"
                          :checked="responseQuestion.isChecked"
                          @input="addResponse(question, responseQuestion, '')"
                        >
                        <label
                          class="form-check-label"
                          :for="`question-${index}`"
                        >
                          {{ responseQuestion.response }}
                        </label>
                      </div>
                    </div>
                  </li>
                  <li
                    v-if="question.type === jobScreeningQuestionTypes.SHORT"
                    class="text-primary mb-4"
                  >
                    <p>{{ question.name }}</p>
                    <input
                      type="text"
                      required
                      :value="question.answer"
                      class="form-control shadow-none"
                      placeholder="Write your answer here..."
                      @input="addResponse(question, '', $event)"
                    >
                  </li>
                </div>
              </ol>
            </div>
            <div class="row">
              <div class="col-6 mb-3">
                <router-link
                  :to="{
                    name: 'ProviderProposalDetails',
                    params: { id: proposal.job.id, proposalId: proposal.id },
                  }"
                  class="btn-outline"
                >
                  Back
                </router-link>
              </div>

              <div class="col-6 mb-3 text-end">
                <button class="btn-save btn-primary">
                  <submission-spinner v-if="submitting" /> Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import api from "@/services/api";
import errorMessages from "@/utils/error-messages";

import { Money3Component } from "v-money3";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import validationHelpers from "@/utils/validation";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import download from "@/assets/img/icons/downloads.svg";
import calendarVectorIcon from "@/assets/img/icons/calendar-icon.svg";

import {
  JobBudgetTypes,
  JobScreeningQuestionTypes,
} from "@/configs/constants/jobs";
import { ProviderStatus } from "@/configs/constants/users";

import JobHelper from "@/utils/job";
import { DateHelpers } from "@/utils/date-helpers";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderEditProposal",

  components: { money3: Money3Component, SubmissionSpinner, LoaderSpinner, AlerteDanger },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    applydata: {
      amount: 0,
      work_start_date: null,
      work_end_date: null,
      cv: null,
      message: null,
    },
    sqresponse: [],
    newTableQuestion: [],
    multipleQuestion: [],
    cvName: null,
    uploadingW9DocumentFile: false,
    upload: false,
    job: null,
    loading: false,
    submitting: false,
    icons: {
      download,
      calendarVectorIcon,
    },
    errors: {
      amountErrorMessage: null,
      workStartDateErrorMessage: null,
      workEndDateErrorMessage: null,
      cvErrorMessage: null,
      responseErrorMessage: null,
      serverSideErrorMessage: null,
      errorMessage: null,
      messageErrorMessage: null,
    },
  }),

  validations() {
    return {
      applydata: {
        amount: {
          required,
        },

        message: {
          required,
        },
      },
    };
  },

  computed: {
    currentProvider() {
      return this.$store.getters["user/user"];
    },

    jobScreeningQuestionTypes() {
      return JobScreeningQuestionTypes;
    },

    myCvList() {
      let items = [];
      if (this.currentProvider?.cv != null) {
        items = [
          {
            id: this.proposal?.cv?.id,
            original_name: this.proposal?.cv?.original_name,
          },
          ...this.currentProvider.cv?.map((x) => x.user_cv),
        ];
      }
      return items;
    },

    jobBudgetTypes() {
      return JobBudgetTypes;
    },

    canApply() {
      return [ProviderStatus.OK, ProviderStatus.ACTIVE].includes(
        this.currentProvider?.status
      );
    },
  },

  mounted() {
    if (!this.canApply) {
      this.$router.back();
      return;
    }

    this.fetchBid(this.$route.params.proposalId);
    this.applydata.job = this.$route.params.id;
  },

  methods: {
    ...JobHelper,
    ...DateHelpers,
    ...validationHelpers,

    addResponse(quiz, response, event) {
      const questionIndex = this.newTableQuestion.findIndex(
        (elmt) => elmt.id === quiz.id
      );
      if (questionIndex !== -1) {
        if (
          quiz.type === JobScreeningQuestionTypes.SHORT ||
          quiz.type === JobScreeningQuestionTypes.LONG
        ) {
          this.newTableQuestion[questionIndex].answer = event.target.value;
          this.newTableQuestion[questionIndex].isAnswered = true;
        } else {
          const index = this.newTableQuestion[
            questionIndex
          ].answer_selected.findIndex((responseId) => {
            return responseId == response.id;
          });
          if (index !== -1) {
            this.newTableQuestion[questionIndex].answer_selected.splice(
              index,
              1
            );
            if (
              this.newTableQuestion[questionIndex].answer_selected.length == 0
            ) {
              this.newTableQuestion[questionIndex].isAnswered = false;
            }
            response.isChecked = false;
          } else {
            this.newTableQuestion[questionIndex].answer_selected.push(
              response.id
            );
            response.isChecked = true;
            this.newTableQuestion[questionIndex].isAnswered = true;
          }
        }
      } else {
        return;
      }
    },

    onCvFileChange(event) {
      const uploadedFile = event.target.files[0];
      this.cvName = {
        filename: uploadedFile.name,
      };
      this.applydata.cv = uploadedFile;
    },

    checkReponses() {
      this.newTableQuestion.forEach((objet) => {
        objet.response?.forEach((reponse) => {
          if (objet.answer_selected.includes(reponse.id)) {
            reponse.isChecked = true;
          } else {
            reponse.isChecked = false;
          }
        });
      });
    },

    async fetchBid(id) {
      try {
        this.loading = true;
        const response = await api.fetchBid(id);
        this.proposal = response.data;
        this.job = response.data.job;
        this.sqresponse = this.proposal?.sqresponse;
        this.formatData(this.proposal);

        let responses = this.sqresponse;
        for (let i = 0; i < responses.length; i++) {
          responses[i].question.response = responses[i].response;
          responses[i].question.response_selected =
            responses[i].response_selected;
          responses[i].question.question = responses[i].question.id;
        }
        const responseQ = responses.map((res) => res.question);
        let sqresponses = this.newTable(
          responseQ,
          "question",
          "response",
          "response_selected"
        );
        this.newTableQuestion = this.job.screening_question.map((objet1) => {
          const objet2 = sqresponses.find(
            (objet2) => objet2.question === objet1.id
          );
          if (objet2) {
            return {
              ...objet1,
              answer: objet2.response,
              answer_selected: objet2.response_selected.map((res) => res.id),
            };
          } else {
            return objet1;
          }
        });
        this.checkReponses();
        for (let i = 0; i < this.newTableQuestion.length; i++) {
          if (
            this.newTableQuestion[i].answer_selected.length !== 0 ||
            this.newTableQuestion[i].answer
          ) {
            this.newTableQuestion[i].isAnswered = true;
          } else {
            this.newTableQuestion[i].isAnswered = false;
          }
        }
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async updateProposal() {
      const isgood = this.newTableQuestion.some(
        (elmt) => elmt.isAnswered == false
      );
      if (isgood) {
        this.errors.responseErrorMessage =
          errorMessages.AN_ERROR_HAS_SCRENNING_QUESTION;
        this.submitting = false;
      } else {
        let sqresponses = this.newTable(
          this.newTableQuestion,
          "id",
          "answer",
          "answer_selected"
        );
        sqresponses = sqresponses.map((item) => {
          return {
            question: item.id,
            response: item.answer,
            response_selected: item.answer_selected,
          };
        });
        try {
          this.errors.responseErrorMessage = null;
          if (this.applydata.work_start_date) {
            this.applydata.work_start_date = this.formatDate(
              this.applydata.work_start_date,
              "YYYY-MM-DD"
            );
          }
          if (this.applydata.work_end_date) {
            this.applydata.work_end_date = this.formatDate(
              this.applydata.work_end_date,
              "YYYY-MM-DD"
            );
          }

          const formData = {
            amount: this.applydata.amount,
            work_start_date: this.applydata.work_start_date,
            work_end_date: this.applydata.work_end_date,
            cv: this.applydata.cv,
            message: this.applydata.message,
            sqresponse: sqresponses,
            job: this.$route.params.id,
          };

          this.submitting = true;
          await api.updateProposal(this.$route.params.proposalId, formData);
        } catch (error) {
          this.submitting = false;
          this.errorsHandler(error);
        } finally {
          this.submitting = false;
        }
      }
      this.submitting = false;
    },
    formatData(data) {
      this.applydata.amount = data?.amount;
      this.applydata.work_end_date = data?.work_end_date;
      this.applydata.work_start_date = data?.work_start_date;
      this.applydata.message = data?.message;
      this.applydata.cv = data?.cv?.id;
    },
    newTable(table, ...variables) {
      const newTab = table.map((objet) => {
        let newObjet = {};
        variables.forEach((variable) => {
          newObjet[variable] = objet[variable];
        });
        return newObjet;
      });
      return newTab;
    },
    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    changeSelect(event) {
      if (event.target.value == "upload") {
        this.upload = true;
      }
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          /**
           * TODO
           * Hnadle logout
           */
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 404) {
          this.errors.errorMessage = errorMessages.PROPOSAL_NOT_FOUND;
        } else if (err.response.status === 400) {
          this.errors.serverSideErrorMessage = err.response.data.status;

          for (const error in err.response.data) {
            if (error == "cv") {
              this.errors.cvErrorMessage = error.cv;
            }
            this.errors[error + "ErrorMessage"] = err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.job-apply h3 {
  color: #209516;
  font-weight: 500;
  font-size: 1.625rem;
}
.job-apply .page-content .title {
  font-weight: 600;
  font-size: 1.5rem;
  color: #222a36;
  margin-top: 1.3125rem;
}
.job-apply .btn-save {
  padding: 0.6rem 0.85rem;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 4.9rem;
}
.job-apply .btn-back {
  padding: 0.6rem 0.85rem;
  background: #e7e7e7;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  font-weight: 500;
  font-size: 1rem;
  color: #222a36;
  margin-top: 4.9rem;
}

.label-upload {
  cursor: pointer;
  color: #209516;
  padding: 0.6rem 1.25rem;
  font-weight: 500;
  font-size: 1rem;
  background: #ffffff;
  border: 1.2px solid #209516;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}
#upload {
  opacity: 1;
  position: absolute;
  z-index: -1;
  left: 7%;
  top: 30%;
  border: none !important;
}
.uploads {
  padding: 0.6rem;
  margin-top: 0.6rem;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  background: #ffffff;
  border: 1px solid #e5e8eb;
  border-radius: 4px;
  position: relative;
}
</style>
