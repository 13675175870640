import { Request } from "@/services/request";

export default {
  createBid(data) {
    return new Request().append("api/v1/bid/").method("post").setData(data);
  },

  checkIfHaveBid(projectId) {
    return new Request()
      .append("api/v1/bid/have_bid/")
      .method("get")
      .params({ project: projectId });
  },

  fetchAllBids({
    page = 1,
    isActive = null,
    project = null,
    startDate = null,
    endDate = null,
  }) {
    return new Request()
      .append("api/v1/bid/")
      .params({
        page,
        is_active: isActive,
        project,
        start_date: startDate,
        end_date: endDate,
      })
      .method("get");
  },

  fetchBid(id) {
    return new Request().append(`api/v1/bid/${id}/`).method("get");
  },  
  fetchBids() {
    return new Request().append(`api/v1/bid/bid_awarded/`).method("get");
  },

  makeBidActive(id) {
    return new Request().append(`api/v1/bid/${id}/make_active/`).method("post");
  },

  makeBidInactive(id) {
    return new Request()
      .append(`api/v1/bid/${id}/make_inactive/`)
      .method("post");
  },
};
