<template>
  <section class="content proposal">
    <div class="page-header  pb-0 border-0">
      <h3 class="mt-3 mind">
        Active Jobs
      </h3>
    </div>

    <div
      class="page-content"
    >
      <div class="job-detail p-0">
        <div
          v-if="jobsInProgress.length > 0"
          class="proposals p-0 border-top border-0"
        >
          <jobs-list
            :jobs="jobsInProgress"
            :hide="(includeService('marketplace') && includePlan('can_access_marketplace'))"
            @fetchNextPage="onFetchNextJobsInProgressPage"
          />
        </div>

        <loader-spinner v-if="fetchingJobsInProgress" />
      </div>
    </div>
    <confirm-modal
      ref="confirmModal"
      title="Access restricted"
      message="You have a limited access to some features on this page. Please upgrade your account."
      ok-button-title="Upgrade"
      @onConfirm="$router.push({ name: 'ProviderSubscription' })"
    />
  </section>
</template>

<script>
import api from "@/services/api";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import { ProposalStatus } from "@/configs/constants/jobs";
import ConfirmModal from '@/components/ConfirmModal.vue';
import JobsList from '@/components/provider/jobs/JobsList.vue';
import { PaginationContants } from "@/configs/constants";

export default {
  name: "ProviderProposalsPage",

  components: {
    LoaderSpinner,
    ConfirmModal,
    JobsList,
  },

  data: () => ({
    status: ProposalStatus.ACCEPETED,
    pageTitle: "Won Proposals",
    fetching: false,
    proposals: [],
    maxPagesReached: false,
    jobsInProgress: [],
    jobsInProgressPagination: {
      currentPage: 1,
      itemsCount: 0,
      totalPages: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      itemsPerPage: PaginationContants.itemsPerPage,
      maxPagesReached: false,
    },
    fetchingJobsInProgress: false,
    isFilteringJobsInProgress: false,
  }),

  computed: {
    proposalStatus() {
      return ProposalStatus
    },
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },
  },

  mounted() {
    if ((this.includeService('marketplace') && this.includePlan('can_access_marketplace'))) {
      this.confirmClaim()
    } else {
      this.fetchJobsInProgress({page: 1});
    }
  },

  methods: {

    includeService(code) {
      let service = this.currentPlanServices?.find(el=> el.code == code);
      if (service == undefined) {
        return true;
      } else {
        return false
      }
    },
    includePlan(key) {
      if (this.currentPlan && this.currentPlan[key] == null) {
        return true;
      } else {
        return false
      }
    },
    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },
    async fetchJobsInProgress(params) {
      try {
        this.fetchingJobsInProgress = true;

        const response = await api.fetchJobInProgress(params);
        const jobs = response.data.data.map((x) => x.job);

        if (this.isFilteringJobsInProgress) {
          this.jobsInProgress = jobs;
        } else {
          this.jobsInProgress = this.jobsInProgress.concat(jobs);
        }

        this.jobsInProgressPagination = this.getPaginationData(
          params.page,
          response.data,
          this.jobsInProgressPagination.maxPagesReached
        );
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorHandler(error)
      } finally {
        this.fetchingJobsInProgress = false;
      }
    },

    async onFetchNextJobsInProgressPage() {
      if (
        this.jobsInProgressPagination.currentPage >=
        this.jobsInProgressPagination.totalPages
      ) {
        this.jobsInProgressPagination.maxPagesReached = true;
      }

      if (
        this.fetchingJobsInProgress ||
        this.jobsInProgressPagination.maxPagesReached
      ) {
        return;
      }

      await this.fetchJobsInProgress({
        ...this.filters,
        page: this.jobsInProgressPagination.currentPage + 1,
      });
    },
  },
};
</script>
