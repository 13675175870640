<template>
  <div
    v-if="jobs.length > 0"
    class="job-list"
  >
    <job-item
      v-for="(job, index) in jobs"
      :key="index"
      :job="job"
      :hide="hide"
      :is-favorite="job.provider_favorite || false"
      :is-last-item="index === jobs.length - 1"
      :proposal-status="getProposalData(index, 'status')"
      :proposal-id="getProposalData(index, 'id')"
      @fetchNextPage="onFetchNextPage"
    />
  </div>
</template>

<script>
import JobItem from "@/components/provider/jobs/JobItem.vue";

export default {
  name: "ProviderJobsList",

  components: {
    JobItem,
  },

  props: {
    jobs: {
      type: Array,
      required: true,
      default: () => [],
    },

    proposalsData: {
      type: Array,
      required: false,
      default: () => [],
    },
    hide: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    onFetchNextPage() {
      this.$emit('fetchNextPage')
    },

    getProposalData(index, key) {
      if (!this.proposalsData[index]) return ""
      return this.proposalsData[index][key]
    }
  }
};
</script>