<template>
  <div
    v-if="!loading"
    class="admin-dashboard"
  >
    <list-header
      title="Statistics"
      :options="headerOptions"
    />

    <div
      class="row mt-3"
    >
      <div class="col-md-6">
        <div class="col-md-12 text-start">
          <h4>Paid Invoices</h4>
          <bar-chart :data="invoicechartData.datasets.length > 0 ? invoicechartData : false" />
        </div>
      </div>

      <div class="col-md-6">
        <div class="col-md-12 text-start">
          <h4>Number of Jobs Awarded</h4>
          <bar-chart :data="jobchartData.datasets.length > 0 ? jobchartData : false" />
        </div>
      </div>

      <div class="col-12 col-md-6 row text-end align-items-center">
        <div class="col">
          <div class="dropdown">
            <div class="col-12 col-md-6 row text-end align-items-center">
              <div class="col">
                <div class="dropdown">
                  <a
                    class="btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Project Types
                  </a>
                  <div class="dropdown-menu">
                    content
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import ListHeader from "@/components/ListHeader.vue";
import RectangleMap from "@/assets/img/icons/Rectanglemap.svg";

import BarChart from "@/components/customer/BarChart.vue";

import InvoiceHelper from "@/utils/invoice";

export default {
  name: "SuperadminDashboardPage",

  components: {
    ListHeader,
    BarChart,
  },

  data: () => ({
    headerOptions: {
      title: "Select Date",
      list: ["content"],
    },
    RectangleMap,
    loading: false,
    invoicechartData: {
      labels: [],
      datasets: [],
    },
    jobchartData: {
      labels: [],
      datasets: [],
    },
  }),

  mounted() {
      this.fetchReport();
  },

  methods: {
    ...InvoiceHelper,
    async fetchReport() {
      try {
        this.loading = true;
        let response = await api.fetchCustomerReportData();

        response.data.invoicechartData.forEach((item) => {
          if (!this.invoicechartData.labels.includes("" + item.month + "")) {
            this.invoicechartData.labels.push(item.month);
          }
          this.invoicechartData.datasets.push({
            label: item.status,
            backgroundColor: this.getInvoiceStatusStyle(item.status).color,
            data: [item.total],
          });
        });

        response.data.jobchartData.forEach((item) => {
          if (!this.jobchartData.labels.includes("" + item.month + "")) {
            this.jobchartData.labels.push("" + item.month + "");
          }
          this.jobchartData.datasets.push({
            label: "total",
            backgroundColor: "#209516",
            data: [item.total],
          });
        });
      } catch (error) {
        console.log(error);
      }finally{
        this.loading = false;
      }
    },
  },
};
</script>
