<template>
  <div
    v-if="isdata"
    class="chart-container"
  >
    <Doughnut
      id="my-chart-id"
      :data="chartData"
    />
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Title,
  ArcElement,
  DoughnutController,
} from "chart.js";
ChartJS.register(
  BarElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  ArcElement,
  DoughnutController,
  Title
);

export default {
  name: "AppDoughnut",
  components: { Doughnut },
  props: {
    invoiceStat: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            borderColor: [],
            hoverOffset: 2,
            weight: 100,
          },
        ],
      },
      isdata: false,
    };
  },
  mounted() {
    if (this.invoiceStat) {
      this.chartData.labels = this.invoiceStat.labels;
      this.chartData.datasets[0].data = this.invoiceStat.datasets[0].data;
      this.chartData.datasets[0].backgroundColor =
        this.invoiceStat.datasets[0].backgroundColor;
      this.chartData.datasets[0].borderColor =
        this.invoiceStat.datasets[0].borderColor;
      this.isdata = true;
    }
  },
};
</script>

<style scoped>
.chart-container {
  width: 300px;
  display: flex;
  justify-content: flex-start;
  height: 300px;
}
</style>
