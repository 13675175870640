<template>
  <div class="dashboard-provider">
    <list-header
      title="Invoices"
      :options="headerOptions"
      :search="true"
      @update:options="
        fetchInvoices(
          $event.value ? { page: 1, status: $event.value } : { page: 1 }
        ),
        (headerOptions.title = $event.title),
        (status = $event.value),
        (page = 1)
      "
      @update:search="
        fetchInvoices($event ? { page: 1, search: $event } : { page: 1 }),
        (search = $event),
        (page = 1)
      "
    />

    <div class="row mb-3">
      <div
        v-for="statistic in statistics"
        :key="statistic.value"
        class="col-md-6 col-lg"
      >
        <div class="statistic-item">
          <div class="card">
            <h3>{{ statistic.count }}</h3>

            <p>{{ statistic.title }}</p>

            <span class="linear-bar" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="thead" />
            <tbody v-if="!loading">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td
                  class="py-3"
                >
                  <span
                    class="cursor-pointer text-primary"
                    @click="detail(item)"
                  >{{ item.invoice_no }}</span>
                </td>
                <td>
                  {{ item.customer_name }}
                </td>
                <td>
                  {{ item.job_name }}
                </td>
                <td>
                  {{ formatPrice(item.total) }}
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>
                <td>
                  <app-invoice-status
                    :status="item.status || ''"
                    :short="true"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <loader-spinner v-if="loading" />
        </div>
      </div>

      <page-action
        :index="page"
        :infos="pages"
        @update:index="
          fetchInvoices({ page: $event, status, search }), (page = $event)
        "
      />
    </div>
  </div>
</template>

<script>
import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import PageAction from "@/components/PageAction.vue";

import api from "@/services/api";
import statusToUI from "@/utils/statusFormate";
import PriceHelpers from "@/utils/price-format";
import AppInvoiceStatus from "@/components/InvoiceStatus.vue";
import { DateHelpers } from "@/utils/date-helpers";
import { InvoiceStatus, InvoiceStatusList } from "@/configs/constants";
import LoaderSpinner from '@/components/LoaderSpinner.vue';

export default {
  name: "SuperadminInvoicesPage",
  components: {
    ListHeader,
    HeadTable,
    PageAction,
    AppInvoiceStatus,
    LoaderSpinner
  },
  data: () => ({
    headerOptions: {
      title: InvoiceStatusList[0].title,
      list: InvoiceStatusList,
    },
    thead: [
      "Title",
      "Vendor name",
      "Project name",
      "Amount",
      "Date sent",
      "Status",
    ],
    listData: [],
    page: 1,
    status: null,
    search: null,
    pages: {
      count: null,
      max_page_size: null,
      total_pages: null,
    },
    loading: false,
    statistics: [
      {
        title: "Unsent",
        value: InvoiceStatus.UNSENT,
        count: 0,
      },
      {
        title: "Pending",
        value: InvoiceStatus.PENDING,
        count: 0,
      },
      {
        title: "Partial",
        value: InvoiceStatus.PARTIAL,
        count: 0,
      },
      {
        title: "Paid",
        value: InvoiceStatus.PAID,
        count: 0,
      },
      {
        title: "Reversed",
        value: InvoiceStatus.REFUND,
        count: 0,
      },
      {
        title: "External Pay",
        value: InvoiceStatus.EXTERNAL_PAY,
        count: 0,
      },
    ],
  }),
  mounted() {
    this.fetchInvoicesStatistics();
    this.fetchInvoices({ page: this.page });
  },
  methods: {
    ...DateHelpers,
    ...statusToUI,
    ...PriceHelpers,

    fetchInvoices(param) {
      this.loading = true;

      api
        .fetchInvoices(param)
        .then((response) => {
          this.listData = response.data.data;
          this.pages = {
            count: response.data.count,
            max_page_size: response.data.max_page_size,
            total_pages: response.data.total_pages,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },
    detail(item) {
      this.$router.push({
        name: "SuperAdminInvoiceDetails",
        params: { id: item.id },
      });
    },
     async fetchInvoicesStatistics() {
      try {
        const response = await api.fetchInvoicesStatistics();
        const statistics = response.data;
        this.statistics = (this.statistics ?? []).map((statistic) => {
          const matchingStatistic = statistics.find(
            (x) => x.status === statistic.value
          );
          return {
            ...statistic,
            count: matchingStatistic?.count ?? 0,
          };
        });
      } catch (_) {}
    },
  },
};
</script>
