<template>
  <div
    v-if="options"
    class="dropdown d-block d-sm-inline-block"
  >
    <div
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      class="px-2 py-0"
    >
      <img
        alt="Option"
        :src="white ? OptionWhite : Option"
        :class="{'': status}"
        class="text-left"
      >
    </div>

    <ul
      class="dropdown-menu"
    >
      <li
        v-for="(item, index) in options.filter(
          el => el.value !== status
        )"
        :key="index"
        class="text-capitalize"
      >
        <div
          v-if="item.value"
          class="dropdown-item cursor-pointer"
          @click="update(item.value)"
        >
          {{ item.title }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Option from "@/assets/img/icons/Option.svg";
import OptionWhite from "@/assets/img/icons/Option-white.svg";

export default {
  name: "OptionAction",
  
  props: {
    options: {
      type: Array,
      required: false,
      default: null,
    },

    status: {
      type: String,
      required: false,
      default: null,
    },

    displayTitle: {
      type: String,
      required: false,
      default: null,
    },
    white: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    Option,
    OptionWhite
  }),
 
  methods: {
    update(select) {
      this.$emit("update:options", select);
    },
  }
};
</script>