import * as getters from "@/store/modules/sDashState/getters";
import * as actions from "@/store/modules/sDashState/actions";
import * as mutations from "@/store/modules/sDashState/mutations";

const state = {
  approvalNumber: 0,
  customerNeededToCompleteProfile: 0,
  estimateNumber: 0,
  jobNumber: 0,
  invoiceNumber: 0
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};