import * as getters from "@/store/modules/token/getters";
import * as actions from "@/store/modules/token/actions";
import * as mutations from "@/store/modules/token/mutations";

const state = {
  token: null,
  deviceId: null,
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
