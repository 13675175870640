<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div
        class="modal-content"
        :style="backdground && { 'background': 'none' }"
      >
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },

    body: {
      type: HTMLElement,
      required: false,
      default: null,
    },
    
    backdground: {
      type: Boolean,
      required: false,
    },
  },

  data: () => ({
    showModal: false,
    fadeModal: false,
  }),

  methods: {
    openModal() {
      this.showModal = true;
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      this.fadeModal = false;
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },
  },
};
</script>

<style scoped>
.modal-body {
  border: none !important;
}
.modal-content {
  border: none;
  color: rgb(98, 125, 108);
}
</style>
