import { PaginationContants } from "@/configs/constants";

export default (
  fetchRequest,
  pageEnabled = false,
  updateRouteOnCurrentPageChange = true
) => ({
  data() {
    return {
      loading: false,
      listData: [],
      listFilters: {},
      ...(pageEnabled
        ? {
            currentPage: 1,
            itemsCount: 0,
            itemsPerPage: PaginationContants.itemsPerPage,
            totalPages: 1,
            hasNextPage: false,
            hasPreviousPage: false,
          }
        : {}),
      requestSource: null,
    };
  },

  watch: {
    listFilters() {
      if (this.listFilters.page !== this.currentPage) {
        this.currentPage = this.listFilters.page || 1;
      } else {
        this.fetchData();
      }
    },
    ...(pageEnabled
      ? {
          currentPage() {
            if (updateRouteOnCurrentPageChange) {
              this.$router.push({
                query: { ...this.$route.query, page: this.currentPage },
              });
            }
            this.listFilters.page = this.currentPage;
            this.fetchData();
          },
        }
      : {}),
  },

  async mounted() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        this.loading = true;

        let request = fetchRequest(this.listFilters);
        if (this.requestSource !== null) {
          this.requestSource.cancel();
          this.requestSource = null;
        }

        this.requestSource = request.config.source;
        let response = await request;

        if (pageEnabled) {
          this.listData = response.data.data;
          this.itemsCount = response.data.count;
          this.totalPages = response.data.total_pages;
          this.hasNextPage = response.data.links?.next ? true : false;
          this.hasPreviousPage = response.data.links?.previous ? true : false;
          this.itemsPerPage =
            response.data.max_page_size || PaginationContants.itemsPerPage;
        } else {
          this.listData = response.data.data;
        }
      } catch (_) {
        /**
         * TODO
         * Handle error
         */
        // this.errorHandler(e)
      } finally {
        this.loading = false;
      }
    },

    updateListFilters(overrideDict) {
      this.listFilters = {
        ...this.listFilters,
        ...overrideDict,
      };
    },
  },
});
