<template>
  <div>
    <list-header
      title="Coupons"
      :add="true"
      @create="create"
    />
    <loader-spinner v-if="loading" />

    <div
      v-else
      class="row"
    >
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="thead" />
            <tbody>
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td
                  class="py-3"
                >
                  <span
                    class="cursor-pointer text-primary"
                    @click="detail(item)"
                  >{{
                    item.code
                  }}</span>
                </td>
                <td>
                  {{ item.percent_off }}
                </td>
                <td>
                  {{ item.amount_off }}
                </td>
                <td>
                  {{ item.duration }}
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>
                <td>
                  <option-action
                    :status="null"
                    :options="options"
                    @update:options="choose(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <page-action
        :index="page"
        :infos="pages"
        @update:index="fetchCoupons({ page: $event, status }), (page = $event)"
      />
    </div>

    <confirm-modal
      ref="confirmModal"
      ok-button-title="Delete"
      :message="'Are you sure you want to delete ' +selectedCoupon?.code + ' ?'"
      @onConfirm="deleteCoupon"
    />
  </div>
</template>

<script>
import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import { toast } from "vue3-toastify";

import api from "@/services/api";
import PageAction from "@/components/PageAction.vue";
import { DateHelpers } from "@/utils/date-helpers";
import statusToUI from "@/utils/statusFormate";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import ConfirmModal from '@/components/ConfirmModal.vue';
import OptionAction from '@/components/OptionAction.vue';

export default {
  name: "ProviderWalletCouponsPage",

  components: {
    ListHeader,
    HeadTable,
    PageAction,
    LoaderSpinner,
    ConfirmModal,
    OptionAction,
  },

  data: () => ({
    thead: ["Code", "Percent Off", "Ammount Off", "Duration", "Created at", ""], 
    listData: [],
    page: 1,
    options: [
      {
        title: "Delete",
        value: "delete",
      },
      {
        title: "Edit",
        value: "edit",
      },
    ],
    pages: {
      count: null,
      max_page_size: null,
      total_pages: null,
    },
    loading: false,
    deletingCoupon: false,
    selectedCoupon: null
  }),

  mounted() {
    this.fetchCoupons({ page: this.page });
  },

  methods: {
    ...DateHelpers,
    ...statusToUI,

    fetchCoupons(param) {
      this.loading = true;

      api
        .fetchCoupons(param)
        .then((response) => {
          this.listData = response.data.data;
          this.pages = {
            count: response.data.count,
            max_page_size: response.data.max_page_size,
            total_pages: response.data.total_pages,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    create() {
      this.$router.push({
        name: "SuperAdminCouponCreate",
      });
    },

    detail(item) {
      this.$router.push({
        name: "SuperAdminCouponDetails",
        params: { id: item.id },
      });
    },
    confirmClaim(coupon) {
      this.selectedCoupon = coupon;

      this.$refs.confirmModal.openModal();
    },
    deleteCoupon() {
      this.deletingCoupon = true;
      api.deleteCoupon(this.selectedCoupon.id)
        .then((_) => {
          toast.success(`Coupon ${this.selectedCoupon.code} successfully deleted.`);
          this.selectedCoupon = null;
          this.fetchCoupons({ page: this.page });
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingCoupon = false;
        });
    },
    choose(item, event) {
      switch (event) {
        case "edit":
          this.edit(item);
          break;
        case "delete":
          this.confirmClaim(item)
          break;

        default:
          break;
      }
    },
    edit(item) {
      this.$router.push({
        name: "SuperAdminCouponEdit",
        params: { id: item.id },
      });
    },

  },
};
</script>