<template>
  <div class="dashboard-provider">
    <list-header
      title="Estimates"
      :options="headerOptions"
      :search="true"
      @update:options="onListHeaderUpdate"
      @update:search="onListHeaderUpdate"
    />

    <div class="row mb-3">
      <div
        v-for="statistic in statistics"
        :key="statistic.value"
        class="col-md-6 col-lg-4"
      >
        <div class="statistic-item">
          <div class="card">
            <h3>{{ statistic.count }}</h3>

            <p>{{ statistic.title }}</p>

            <span class="linear-bar" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="thead" />

            <tbody v-if="!fetching">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td class="py-3">
                  <span
                    class="cursor-pointer text-primary"
                    @click="detail(item)"
                  >{{ item.title }}</span>
                </td>

                <td>
                  {{ formatDate(item.created_at) }}
                </td>

                <td>
                  {{ statusToUI(item.status) }}
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetching" />

          <app-pagination
            v-if="totalPages > 1"
            :align-end="true"
            :current-page="currentPage"
            :has-previous-page="hasPreviousPage"
            :previous-page="currentPage - 1 || 1"
            :has-next-page="hasNextPage"
            :next-page="currentPage + 1"
            :per-page="itemsPerPage"
            :total-elements="itemsCount"
            :total-pages="totalPages"
            @onGotoPage="fetchEstimates({ page: $event })"
            @onPrev="fetchEstimates({ page: currentPage - 1 || 1 })"
            @onNext="fetchEstimates({ page: currentPage + 1 })"
          />
        </div>
      </div>
    </div>

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </div>
</template>

<script>
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import AppPagination from "@/components/AppPagination.vue";

import api from "@/services/api";
import ListMixin from "@/mixins/list";

import { DateHelpers } from "@/utils/date-helpers";
import statusToUI from "@/utils/statusFormate";

export default {
  name: "CustomerEstimatesPage",

  components: {
    ListHeader,
    HeadTable,
    ConfirmModal,
    LoaderSpinner,
    AppPagination,
  },

  mixins: [ListMixin(api.fetchEstimates, true)],

  data: () => ({
    options: [
      {
        title: "Won",
        value: "won",
      },
      {
        title: "Approve",
        value: "approved",
      },
      {
        title: "Deny",
        value: "denied",
      },
    ],
    headerOptions: {
      title: "Status",
      list: [
        {
          title: "Status",
        },
        {
          title: "Active",
          value: "active",
        },
        {
          title: "Unsent",
          value: "unsent",
        },
      ],
    },
    thead: ["Title", "Date sent", "Status"],
    listData: [],
    page: 1,
    status: null,
    search: null,
    fetching: false,
    statistics: {
      unsent: {
        title: "Unsent",
        value: "unsent",
        count: 0,
      },
      pending: {
        title: "Pending",
        value: "pending",
        count: 0,
      },
      approved: {
        title: "Approved",
        value: "approved",
        count: 0,
      },
      dinied: {
        title: "Denied",
        value: "denied",
        count: 0,
      },
      won: {
        title: "Won",
        value: "won",
        count: 0,
      },
      archived: {
        title: "Archived",
        value: "archived",
        count: 0,
      },
    },
    actions: {
      WON: "won",
      APPROVED: "approved",
      DENIED: "denied",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    selectedEstimateId: null,
    updatingEstimate: false,
  }),

  watch: {
    listData(val) {
      this.fetching = false;
    },
  },

  mounted() {
    this.fetchEstimatesStatistics();
    this.fetchEstimates({ page: Number(this.$route.query.page) || 1 });
  },

  methods: {
    ...DateHelpers,
    ...statusToUI,

    fetchEstimates(params) {
      this.fetching = true;
      this.updateListFilters({
        ...params,
      });
    },

    async fetchEstimatesStatistics() {
      try {
        const response = await api.fetchEstimatesSummary();
        const statistics = response.data;
        for (const key in this.statistics) {
          if (Object.hasOwnProperty.call(this.statistics, key)) {
            const element = this.statistics[key];
            this.statistics[key] = {
              ...element,
              count: statistics[element.value] ?? 0,
            };
          }
        }
      } catch (_) {}
    },

    onListHeaderUpdate(event) {
      this.fetchEstimates({ page: 1, status: event.value ?? undefined });
      this.headerOptions.title = event.title;
      this.status = event.value;
    },

    create() {
      this.$router.push({
        name: "CustomerEstimateCreate",
      });
    },

    detail(item) {
      this.$router.push({
        name: "SuperAdminEstimateDetails",
        params: { id: item.id },
      });
    },

  },
};
</script>
