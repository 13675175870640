<template>
  <div :class="`rating-stars small rating-stars-${stars}`" />
</template>

<script>
export default {
  name: "RatingStars",

  props: {
    rating: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  computed: {
    stars() {
      return (this.rating ?? 0).toString().replace(".", "-");
    },
  },
};
</script>

<style scoped>
.rating-stars {
  overflow: hidden;
  display: inline-block;
  width: 103px;
  height: 19px;
  margin: auto;
  background-image: url("@/assets/img/reviews-stars-large.png");
  filter: brightness(0) saturate(100%) invert(79%) sepia(28%) saturate(5463%)
    hue-rotate(8deg) brightness(103%) contrast(99%);
}

.rating-stars.rating-stars-0 {
  background-position: 0 0;
}
.rating-stars.rating-stars-0-5 {
  background-position: 0 -29px;
}
.rating-stars.rating-stars-1 {
  background-position: 0 -58px;
}
.rating-stars.rating-stars-1-5 {
  background-position: 0 -87px;
}
.rating-stars.rating-stars-2 {
  background-position: 0 -116px;
}
.rating-stars.rating-stars-2-5 {
  background-position: 0 -145px;
}
.rating-stars.rating-stars-3 {
  background-position: 0 -174px;
}
.rating-stars.rating-stars-3-5 {
  background-position: 0 -203px;
}
.rating-stars.rating-stars-4 {
  background-position: 0 -232px;
}
.rating-stars.rating-stars-4-5 {
  background-position: 0 -261px;
}
.rating-stars.rating-stars-5 {
  background-position: 0 -290px;
}

.rating-stars.small {
  background-size: 100%;
  width: 82px;
  height: 17px;
}
.rating-stars.small.rating-stars-0 {
  background-position: 0 0;
}
.rating-stars.small.rating-stars-0-5 {
  background-position: 0 -23px;
}
.rating-stars.small.rating-stars-1 {
  background-position: 0 -46px;
}
.rating-stars.small.rating-stars-1-5 {
  background-position: 0 -69px;
}
.rating-stars.small.rating-stars-2 {
  background-position: 0 -92px;
}
.rating-stars.small.rating-stars-2-5 {
  background-position: 0 -115px;
}
.rating-stars.small.rating-stars-3 {
  background-position: 0 -138px;
}
.rating-stars.small.rating-stars-3-5 {
  background-position: 0 -161px;
}
.rating-stars.small.rating-stars-4 {
  background-position: 0 -184px;
}
.rating-stars.small.rating-stars-4-5 {
  background-position: 0 -208px;
}
.rating-stars.small.rating-stars-5 {
  background-position: 0 -229px;
}

.rating-stars.x-large {
  width: 143px;
  height: 27px;
}
.rating-stars.x-large.in-viewport {
  background-image: url("@/assets/img/reviews-stars-x-large.png");
}
.rating-stars.x-large.rating-stars-0 {
  background-position: 0 0;
}
.rating-stars.x-large.rating-stars-0-5 {
  background-position: 0 -37px;
}
.rating-stars.x-large.rating-stars-1 {
  background-position: 0 -74px;
}
.rating-stars.x-large.rating-stars-1-5 {
  background-position: 0 -111px;
}
.rating-stars.x-large.rating-stars-2 {
  background-position: 0 -148px;
}
.rating-stars.x-large.rating-stars-2-5 {
  background-position: 0 -185px;
}
.rating-stars.x-large.rating-stars-3 {
  background-position: 0 -222px;
}
.rating-stars.x-large.rating-stars-3-5 {
  background-position: 0 -259px;
}
.rating-stars.x-large.rating-stars-4 {
  background-position: 0 -296px;
}
.rating-stars.x-large.rating-stars-4-5 {
  background-position: 0 -333px;
}
.rating-stars.x-large.rating-stars-5 {
  background-position: 0 -370px;
}
</style>
