<template>
  <div class="sidebar">
    <ul class="navbar-nav">
      <li class="font-menu">
        <router-link
          :to="{ name: 'CustomerDashboard' }"
          class="nav-link"
          aria-current="page"
          @click.prevent="goto('CustomerDashboard')"
        >
          <img :src="icons.superAdminDashboardIcon">
          <template v-if="!toggleMenu">
            Dashboard
          </template>
        </router-link>
      </li>

      <li
        id="accordionExample"
        class="accordion"
      >
        <div class="accordion-item">
          <a
            class="accordion-button font-menu"
            @click="(e) => accordionToggle(e)"
          >
            <img :src="icons.superAdminBagIcon">
            <template v-if="!toggleMenu">Jobs Board</template>
          </a>

          <div
            ref="collapse"
            class="accordion-collapse collapse"
          >
            <div class="accordion-body">
              <ul
                v-if="!toggleMenu"
                class="navbar-nav submenu"
              >
                <li class="font-menu">
                  <router-link
                    :to="{ name: 'CustomerJobCreate' }"
                    @click="goto('CustomerJobCreate')"
                  >
                    Post a Job
                  </router-link>
                </li>

                <li class="font-menu">
                  <router-link
                    :to="{
                      name: 'CustomerJobs',
                      query: { status: jobStatus.ACTIVE },
                    }"
                    @click="
                      goto('CustomerJobs', {
                        status: jobStatus.ACTIVE,
                      })
                    "
                  >
                    Active Jobs
                  </router-link>
                </li>

                <li class="font-menu">
                  <router-link
                    :to="{ name: 'CustomerSavedProviders' }"
                    @click="goto('CustomerSavedProviders')"
                  >
                    Saved Providers
                  </router-link>
                </li>

                <li class="font-menu">
                  <router-link
                    :to="{ name: 'CustomerSubcontract' }"
                    @click="goto('CustomerSubcontract')"
                  >
                    Subcontracts
                  </router-link>
                </li>

                <li class="font-menu">
                  <router-link
                    :to="{ name: 'CustomerReports' }"
                    @click="goto('CustomerReports', currentPlan?.project_reports == false)"
                  >
                    Reports
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>

      <li class="font-menu">
        <router-link
          :to="{ name: 'CustomerInvoices' }"
          class="nav-link"
          aria-current="page"
          @click="goto('CustomerInvoices')"
        >
          <img :src="icons.superAdminDocumentIcon">
          <template v-if="!toggleMenu">
            Invoices
          </template>
        </router-link>
      </li>

      <li class="font-menu">
        <router-link
          :to="{ name: 'CustomerCalendar' }"
          class="nav-link"
          aria-current="page"
          @click="goto('CustomerCalendar')"
        >
          <img :src="icons.calendarIcon">
          <template v-if="!toggleMenu">
            Calendar
          </template>
        </router-link>
      </li>

      <li
        v-if="false"
        class="font-menu"
      >
        <router-link
          :to="{ name: 'CustomerMessage' }"
          class="nav-link"
          aria-current="page"
          @click="goto('CustomerMessage')"
        >
          <img :src="icons.calendarIcon">
          Messages
        </router-link>
      </li>

      <li class="font-menu">
        <router-link
          :to="{ name: 'CustomerEstimates' }"
          class="nav-link"
          aria-current="page"
          @click="goto('CustomerEstimates')"
        >
          <img :src="icons.iconestimates">
          <template v-if="!toggleMenu">
            Estimates
          </template>
        </router-link>
      </li>

      <li class="font-menu">
        <router-link
          :to="{ name: 'CustomerProperties' }"
          class="nav-link"
          aria-current="page"
          @click="goto('CustomerProperties')"
        >
          <img :src="icons.iconproperty">
          <template v-if="!toggleMenu">
            Properties
          </template>
        </router-link>
      </li>

      <li class="font-menu">
        <router-link
          :to="{ name: 'CustomerBulkProperties' }"
          class="nav-link"
          aria-current="page"
          @click="goto('CustomerBulkProperties')"
        >
          <img :src="icons.iconbulk">
          <template v-if="!toggleMenu">
            Bulk Properties
          </template>
        </router-link>
      </li>

      <li class="font-menu">
        <router-link
          :to="{ name: 'CustomerCreditCardsList' }"
          class="nav-link"
          aria-current="page"
          @click="goto('CustomerCreditCardsList')"
        >
          <img :src="icons.walletIcon">
          <template v-if="!toggleMenu">
            Wallet
          </template>
        </router-link>
      </li>
    </ul>

    <confirm-modal
      ref="confirmModal"
      title="Access restricted"
      message="You have a limited access to some features on this page. Please upgrade your account."
      ok-button-title="Upgrade"
      @onConfirm="$router.push({ name: 'CustomerSubscription' })"
    />
  </div>
</template>

<script>
import superAdminDashboardIcon from "@/assets/img/icons/dashboard.svg";
import superAdminListIcon from "@/assets/img/icons/list.svg";
import superAdminBagIcon from "@/assets/img/icons/bag.svg";
import iconestimates from "@/assets/img/icons/iconestimates.svg";
import iconproperty from "@/assets/img/icons/property-icon.svg"
import iconbulk from "@/assets/img/icons/bulk-icon.svg"
import superAdminDocumentIcon from "@/assets/img/icons/document.svg";
import walletIcon from "@/assets/img/icons/wallet.svg";
import calendarIcon from "@/assets/img/icons/calendar.svg";
import { JobStatus } from "@/configs/constants/jobs";
import ConfirmModal from '../ConfirmModal.vue';

export default {
  name: "CustomerMenu",
  components: { ConfirmModal },
  props: {
      toggleMenu: {
      type: Boolean,
      required: false,
      default: false,
      },
  },

  data: () => ({
    icons: {
      superAdminDashboardIcon,
      superAdminListIcon,
      superAdminBagIcon,
      superAdminDocumentIcon,
      iconestimates,
      walletIcon,
      calendarIcon,
      iconproperty,
      iconbulk
    },
    selected: {}
  }),

  computed: {
    currentProvider() {
      return this.$store.getters["user/user"];
    },

    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },
    jobStatus() {
      return JobStatus;
    },
  },
  mounted() {
    // let planOff = {};
    // for (const key in this.currentPlan) {
    //   if (this.currentPlan[key] == false || this.currentPlan[key] == null) {
    //     planOff[key] = this.currentPlan[key];
    //   }
    // }
    // console.log(planOff);
  },
  methods: {
    goto(name, hide) {
      if (hide == true) {
        this.selected = name.replace('Customer', '')
        this.confirmClaim()
      }
      if (name === this.$route.name) {
        window.location.reload();
        return;
      }
      this.$router.push({ name });
      this.$emit("closeMobileMenu");
    },

    accordionToggle(e) {
      e.target.classList.toggle("collapsed");
      this.$refs.collapse.classList.add("collapsing");
      const time = setTimeout(() => {
        this.$refs.collapse.classList.remove("collapsing");
        this.$refs.collapse.classList.toggle("show");
        clearTimeout(time);
      }, 300);
    },

    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },

    logout() {
      this.$emitter.emit(GlobalEvents.LOGOUT);
    },
  },
};
</script>
