export const user = (state) => state.user;

export const status = (state) => {
  if (!state.user) {
    return null;
  }
  return state.user.status;
};

export const currentPlan = (state) => state.user?.my_current_plan;

export const currentPlanServices = (state) => state.user?.my_current_planservices || [];
