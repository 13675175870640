<template>  
  <div 
    v-if="service" 
    class="col-md-12"
  >
    <!-- Content Header (Page header) -->
    <section class="content">
      <h3>
        <router-link
          :to="{ name: 'SuperAdminServices' }"
        >
          Service  details 
        </router-link>
        <span class="fw-lighter"> / {{ service.code }}</span>
      </h3>
      
      <!-- Start content -->
      <div class="col-md-12 row p-4 page-content">
        <div class="col-md-12 row mb-3">
          <div class="col-md-6">
            <h4 class="text-primary">
              {{ service.code }}
            </h4>

            <p>{{ formatDate(service.created_at) }}</p>
          </div>

          <div class="col-md-6 text-end">
          <!-- <button
            class="btn d-block d-sm-inline-block ms-sm-3 mt-3 mt-sm-0 px-3 py-2"
            :class="plan.is_active == true ? 'btn-outline-primary' : 'btn-outline-danger'"
          >
            <span> 
              {{ statusToUI(plan.user.is_active) }}
            </span>
          </button> -->
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 my-3">
            <h4 class="border-bottom section-title">
              Basic Details
            </h4>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-6 mb-3">
            <label class="input-label fw-bold">Code</label>
            <p class="label-content">
              {{ service.code }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-6 mb-3">
            <label class="input-label fw-bold">Description</label>
            <p class="label-content">
              {{ service.description }}
            </p>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6 mb-3">
            <label class="input-label fw-bold">Recurrency type</label>
            <p class="label-content">
              {{ service.recurrency_type }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-6 mb-3">
            <label class="input-label fw-bold">Price</label>
            <p class="label-content">
              {{ formatPrice(service.value) }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

import api from "@/services/api";
import { DateHelpers } from "@/utils/date-helpers";
import PriceHelpers from "@/utils/price-format";

export default {
  name: "ProviderInvoiceDetails",
  components: {  },

  data: () => ({
    showModal: false,
    fadeModal: false,
    loading: false,
    listData: [],
    service: {},
  }),
  
  mounted() {
    this.fetchServiceById()
  },

  methods: {
    ...DateHelpers,
    ...PriceHelpers,
    
    back() {
      this.$router.back()
    },

    exportToPDF(invoice_no) {
      if (this.loading) { return }

      this.loading = true;
      html2pdf(document.getElementById("element-to-convert"), {
				margin: 10,
  			filename: invoice_no+".pdf",
			});
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },

    async fetchServiceById() {
      this.loading = true;

      try {
        let response = await api.fetchServiceById(this.$route.params.id);
        this.service = response.data;
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorHandler(e)
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>