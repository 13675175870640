<template>
  <footer class="mt-3">
    <p>&copy; {{ year }} ServiceLoop. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",

  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>