<template>
  <section class="content profile">
    <list-header
      title="Bulk Properties"
      :add="true"
      :search="true"
      @update:search="onListHeaderUpdate"
      @create="addBulkProperty"
    />
    <alerte-danger
      :head-error-message="error"
      @updateContent="error = $event"
    />

    <div>
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="theadBulk" />

            <tbody v-if="!fetchingBulkRequests">
              <tr
                v-for="(item, index) in listDataBulk"
                :key="index"
              >
                <td class="py-3 text-primary text-wrap">
                  <a :href="item.csv_url.url">
                    {{ item.csv_url.original_name }}
                  </a>
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>

                <td>
                  {{ formatDate(item.updated_at) }}
                </td>
                <td>
                  {{ statusToUI(item.status) }}
                </td>
                <td>
                  <option-action
                    :status="null"
                    :options="options"
                    @update:options="confirmClaim(item.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetchingBulkRequests" />

          <app-pagination
            v-if="dataBulkPage.totalPages > 1"
            :align-end="true"
            :current-page="dataBulkPage.currentPage"
            :has-previous-page="dataBulkPage.hasPreviousPage"
            :previous-page="dataBulkPage.currentPage - 1 || 1"
            :has-next-page="dataBulkPage.hasNextPage"
            :next-page="dataBulkPage.currentPage + 1"
            :per-page="dataBulkPage.itemsPerPage"
            :total-elements="dataBulkPage.itemsCount"
            :total-pages="dataBulkPage.totalPages"
            @onGotoPage="fetchBulkProprieties($event)"
            @onPrev="fetchBulkProprieties(dataBulkPage.currentPage - 1 || 1)"
            @onNext="fetchBulkProprieties(dataBulkPage.currentPage + 1)"
          />
        </div>
      </div>
    </div>
    <!-- End content -->

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </section>
</template>

<script>
import Avatar from "vue3-avatar";
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import documentIcon from "@/assets/img/icons/document-icon.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import api from "@/services/api";
import { MediaTypesConstants, PaginationContants } from "@/configs/constants";
import { DateHelpers } from "@/utils/date-helpers";

import { CustomerStatusList } from "@/configs/constants/users";
import statusToUI from "@/utils/statusFormate";
import HeadTable from "@/components/HeadTable.vue";
import OptionAction from"@/components/OptionAction.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import AppPagination from "@/components/AppPagination.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import ListHeader from '@/components/ListHeader.vue';
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "SuperadminCustomerDetailsPage",

  components: {
    HeadTable,
    AppPagination,
    LoaderSpinner,
    ConfirmModal,
    OptionAction,
    ListHeader,
    AlerteDanger,
  },

  data() {
    return {
      icons: {
        imageUploadedIcon,
        documentUploadedIcon,
        documentIcon,
      },
      propertiesOption: {},
      CustomerStatusList,
      theadBulk: ["CSV", "Created At", "Updated At", "Status", ""],
      listDataBulk: [],
      client: null,
      loading: true,
      exportloading: false,
      confirmationOkBtnText: null,
      confirmationMessage: null,
      selected: null,
      submenu: null,
      fetchingBulkRequests: true,
      dataBulkPage: {
        currentPage: 1,
        itemsCount: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        itemsPerPage: PaginationContants.itemsPerPage,
      },
      options: [
        {
          title: "Delete",
          value: "delete",
        },
      ],
      modalConfigs: {
        confirmTitle: null,
        currentAction: null,
        confirmMessage: null,
        showConfirmModal: false,
      },
      deletingClientProperty: false,
      selectedClientPropertyId: null,
      error: null
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    },
    mediaTypesConstants() {
      return MediaTypesConstants;
    },
  },

  async mounted() {
    // console.log(this.currentUser);
    this.fetchBulkProprieties({page: 1});
  },

  methods: {
    ...statusToUI,
    ...DateHelpers,

    onListHeaderUpdate(event) {
      this.fetchBulkProprieties({ page: 1, search: event });
    },
    fetchBulkProprieties(params) {
      this.fetchingBulkRequests = true;

      api
        .fetchBulkProprieties(params)
        .then((response) => {
          this.listDataBulk = response.data.data;
          this.dataBulkPage = {
            currentPage: page,
            itemsCount: response.data.count,
            totalPages: response.data.total_pages,
            hasNextPage: response.data.links?.next ? true : false,
            hasPreviousPage: response.data.links?.previous ? true : false,
            itemsPerPage:
              response.data.max_page_size || PaginationContants.itemsPerPage,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.fetchingBulkRequests = false;
        });
    },


    confirmClaim(id) {
      this.modalConfigs.confirmTitle = "Yes";
      this.modalConfigs.confirmMessage = `Are you sure you want to delete this Bulk property ?`;
      this.selectedClientPropertyId = id;
      this.modalConfigs.showConfirmModal = true;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      this.deleteBulkPropriety();
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.showConfirmModal = false;
    },
    downloadClientPropertiesCSV() {
      this.exportloading = true;

      api
        .downloadClientPropertiesCSV(this.$route.params.id)
        .then((response) => {
          // console.log(response);
          this.downloadFile(response);
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.exportloading = false;
        });
    },
    downloadFile(response) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] }); // Create a Blob from the data
      const url = window.URL.createObjectURL(blob); // Create a URL for the Blob

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'client-properties.csv'); // Set the download attribute with the filename

      // Append the link to the document and trigger the click
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link from the document
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    addBulkProperty() {
      this.$router.push({
        name: "CustomerBulkPropertiesCreate",
      });
    },

    deleteBulkPropriety() {
      this.deletingClientProperty = true
      api.deleteBulkPropriety(this.selectedClientPropertyId)
        .then((_) => {
          toast.success(`Bulk property successfully deleted.`);
          this.selectedClientPropertyId = null;
          this.fetchBulkProprieties({page: 1});
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingClientProperty = false;
        });
    },
    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.error =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.error = err.response.data[error]
          }
        } else {
          this.error =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.error = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

  },
};
</script>

<style>
.float-end {
  padding: 0.6rem;
}
</style>
