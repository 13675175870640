<template>
  <div
    id="auth-section"
    class="container"
  >
    <div class="text-center logo mb-4">
      <img
        :src="images.appLogo"
        alt="App Logo"
      >
    </div>

    <div class="row">
      <div
        class="col-12 col-lg-10 col-xl-7 mx-auto"
      >
        <div class="row">
          <div class=" login-section">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appLogo from "@/assets/img/ServiceLoop-logo.svg";

export default {
  name: "SuperAdminAuthLayout",
  
  data: () => ({
    images: {
      appLogo,
    },
  }),
};
</script>
