import { Request } from "@/services/request";

export default {
  createCreditCard(token, holderName) {
    return new Request()
      .append("api/v1/stripe/credit-card/")
      .method("post")
      .setData({ token, holder_name: holderName });
  },

  fetchCreditCards({ page = 1 }) {
    return new Request()
      .append("api/v1/stripe/credit-card/")
      .method("get")
      .params({
        page,
      });
  },

  makeCreditCardDefault(id) {
    return new Request()
      .append(`api/v1/stripe/credit-card/${id}/make_default/`)
      .method("post");
  },

  deleteCreditCard(id) {
    return new Request()
      .append(`api/v1/stripe/credit-card/${id}/`)
      .method("delete");
  },

  createBankAccount(data) {
    return new Request()
      .append("api/v1/stripe/bank-account/")
      .method("post")
      .setData(data);
  },

  fetchCurrentBankAccount() {
    return new Request()
      .append("api/v1/stripe/bank-account/current_account/")
      .method("get");
  },

  activateStripeAccount() {
    return new Request()
      .append(`api/v1/provider/active_stripe/`)
      .method("patch");
  },
};
