<template>
  <div>
    <list-header
      title="Jobs"
      :options="headerOptions"
      :search="true"
      @update:options="
        fetchAllJobs(
          $event.value ? { page: 1, status: $event.value } : { page: 1 }
        ),
        (headerOptions.title = $event.title),
        (status = $event.value),
        (page = 1)
      "
      @update:search="
        fetchAllJobs($event ? { page: 1, search: $event } : { page: 1 }),
        (search = $event),
        (page = 1)
      "
    />

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="thead" />
            <tbody v-if="!fetching">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td>
                  <router-link
                    :to="{
                      name: 'SuperAdminJobDetails',
                      params: { id: item?.id }
                    }"
                    class="text-primary position-relative"
                  >
                    {{ item?.title }} 
                    <span 
                      v-if="item?.owner_provider"
                      class="p-1 bg-danger border border-light rounded-circle d-inline-block"
                    />
                  </router-link>
                </td>
                <td
                  v-if="item?.owner_provider"
                >
                  {{ item?.owner_provider?.user.full_name }}
                </td>
                <td v-else>
                  {{ item?.owner?.user.full_name }}
                </td>
                <td>
                  <template v-if="item?.budget_type === jobBudgetTypes.FIXED">
                    <template
                      v-if="item?.budget !== null && item?.budget !== undefined"
                    >
                      {{ formatPrice(item?.budget) }}
                    </template>
                  </template>
                  <template v-else>
                    {{ formatPrice(item?.budget_from ?? 0) }}
                    -
                    {{ formatPrice(item?.budget_to ?? 0) }}
                  </template>
                </td>
                <td>
                  {{ item?.level.name }}
                </td>
                <td>
                  {{ formatDate(item?.created_at) }}
                </td>
                <td>
                  {{ statusToUI(item?.status) }}
                </td>
                <td>
                  <option-action
                    :status="item?.status"
                    :options="JobActionsList"
                    @update:options="confirmClaim(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <loader-spinner v-if="fetching" />
        </div>
      </div>

      <app-pagination
        v-if="totalPages > 1"
        :align-end="true"
        :current-page="currentPage"
        :has-previous-page="hasPreviousPage"
        :previous-page="currentPage - 1 || 1"
        :has-next-page="hasNextPage"
        :next-page="currentPage + 1"
        :per-page="itemsPerPage"
        :total-elements="itemsCount"
        :total-pages="totalPages"
        @onGotoPage="fetchAllJobs({ page: $event, search })"
        @onPrev="fetchAllJobs({ page: currentPage - 1 || 1, search })"
        @onNext="fetchAllJobs({ page: currentPage + 1, search })"
      />
    </div>

    <confirm-modal
      ref="confirmModal"
      :ok-button-title="confirmationOkBtnText"
      :message="confirmationMessage"
      @onConfirm="updateStatus"
    />
  </div>
</template>

<script>
import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import OptionAction from"@/components/OptionAction.vue";

import api from "@/services/api";
import ListMixin from "@/mixins/list";
import {
  JobStatusList,
  JobBudgetTypes,
  JobActionsList,
} from "@/configs/constants/jobs";
import statusToUI from "@/utils/statusFormate";
import { DateHelpers } from "@/utils/date-helpers";

import PriceHelpers from "@/utils/price-format";
import LoaderSpinner from '@/components/LoaderSpinner.vue';
import AppPagination from '@/components/AppPagination.vue';

export default {
  name: "SuperadminCustomersPage",

  components: {
    ListHeader,
    HeadTable,
    ConfirmModal,
    OptionAction,
    LoaderSpinner,
    AppPagination,
  },
  mixins: [ListMixin(api.fetchAllJobs, true)],

  data: () => ({
    JobStatusList,
    JobActionsList,
    headerOptions: {
      title: JobStatusList[0].title,
      list: JobStatusList,
    },
    thead: [
      "Title",
      "Owner",
      "Budget",
      "Level",
      "Creation date",
      "Status",
      "",
    ],
    confirmationOkBtnText: null,
    confirmationMessage: null,
    selected: null,
    listData: [],
    page: 1,
    pages: {
      count: null,
      max_page_size: null,
      total_pages: null,
    },
    loading: false,
    fetching: false,
    status: "",
    search: "",
  }),

  computed: {
    jobBudgetTypes() {
      return JobBudgetTypes;
    },
  },

  watch: {
    listData(val) {
      this.fetching = false;
    },
  },
  mounted() {
    this.fetchAllJobs({ page: this.page });
  },

  methods: {
    ...statusToUI,
    ...DateHelpers,
    ...PriceHelpers,

    confirmClaim(element, status) {
      this.selected = element;
      this.status = status;

      this.confirmationMessage = `Are you sure you want to make ${this.statusToUI(
        status
      )} ${this.selected.title} account ?`;
      this.confirmationOkBtnText = "Confirm";

      this.$refs.confirmModal.openModal();
    },

    updateStatus() {
      if (this.loading) return;

      this.loading = true;

      api
        .makeJob(this.selected.id, { status: this.status })
        .then((response) => {
          this.fetchAllJobs({ page: this.page });
          this.confirmationOkBtnText = null;
          this.confirmationMessage = null;
          this.selected = null;
          this.status = null;
          this.$refs.confirmModal.closeModal();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorsHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    fetchAllJobs(params) {
      this.fetching = true;
      this.updateListFilters({
        ...params,
      });
    },
  },
};
</script>