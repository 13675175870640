<template>
  <header>
    <div
      class="logo-section position-relative"
      :class="{'minwidth80px': toggleMenu}"
    >
      <router-link
        :to="{ name: 'CustomerDashboard' }"
        class="position-relative"
      >
        <img
          v-if="toggleMenu"
          :src="icons.logoS"
          alt="App Logo"
          class="logo"
        >
        <img
          v-else
          :src="icons.appLogo"
          alt="App Logo"
          class="logo"
        >
        <span class="position-absolute bottom-0 end-25 badge rounded-pill bg-transparent">
          {{ version[0]?.name }} 
        </span>
      </router-link>
      <img
        :src="toggleMenu ? icons.le_arrow : icons.ri_arrow"
        alt="ri arrow"
        class="position-absolute arrow"
        @click="$emit('toggleMenu')"
      >
    </div>

    <div class="header-section content">
      <div class="d-inline-flex align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 btn-toogle px-1 py-0 toggle"
          @click.prevent="toggleMobileMenu"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </div>

      <div class="profile-section">
        <notification-dropdown />

        <img
          v-if="false"
          :src="icons.commentIcon"
          alt="commentaire"
          class="me-3"
        >
        <div class="px-1">
          <a
            class="nav-link"
            href="#"
          >
            <i class="bi bi-chat-left-text" />
          </a>
        </div>

        <div
          v-if="currentCustomer"
          class="dropdown"
        >
          <div
            class="dropdown-toggle mb-0"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <profile-avatar
              :url="currentCustomer.photo?.url"
              :fullname="currentCustomer.full_name"
              :color="currentCustomer.profile_color"
              :background="currentCustomer.profile_background"
            />

            {{ currentCustomer.full_name }}
          </div>

          <ul class="dropdown-menu">
            <li>
              <router-link
                :to="{ name: 'CustomerProfile' }"
                class="dropdown-item"
              >
                Profile
              </router-link>
            </li>

            <li>
              <router-link
                :to="{ name: 'CustomerSubscription' }"
                class="dropdown-item"
              >
                Subscription
              </router-link>
            </li>

            <li>
              <a
                class="dropdown-item"
                @click.prevent="confirmClaim"
              >Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <confirm-modal
      ref="confirmModal"
      title="Logout"
      ok-button-title="Logout"
      :message="'Are you sure, you want to disconnect  your account ?'"
      @onConfirm="logout"
    />
  </header>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal.vue";
import ProfileAvatar from "@/components/ProfileAvatar.vue";
import NotificationDropdown from '@/components/notifications/NotificationDropdown.vue';

import {
  GlobalConstants,
  GlobalEvents,
  WebSocketEvents,
} from "@/configs/constants";

import appLogo from "@/assets/img/white_logo.svg";
import notification from "@/assets/img/icons/notification.svg";
import commentIcon from "@/assets/img/icons/insert-comment.svg";
import logoS from "@/assets/img/icons/logoS.svg";
import logoT from "@/assets/img/icons/logoT.svg";
import ri_arrow from "@/assets/img/icons/ri_arrow.svg";
import le_arrow from "@/assets/img/icons/le_arrow.svg";

import SocketService from "@/utils/socket-service";
import { CustomerStatus } from '@/configs/constants/users';
import api from '@/services/api';

export default {
  name: "CustomerHeader",

  components: {
    ConfirmModal,
    ProfileAvatar,
    NotificationDropdown,
  },
  props: {
    toggleMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    icons: {
      appLogo,
      notification,
      commentIcon,
      ri_arrow,
      le_arrow,
      logoS,
      logoT,
    },
    version: []
  }),

  computed: {
    currentCustomer() {
      return this.$store.getters["user/user"];
    },
  },

  mounted() {
    this.connectSocketAndListenEvents();
    this.fetchVersion()
  },

  methods: {
    toggleMobileMenu() {
      this.$emit("toggleMobileMenu");
    },

    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },

    logout() {
      this.$emitter.emit(GlobalEvents.LOGOUT);
    },

    connectSocketAndListenEvents() {
      this.socketService = new SocketService(
        `${GlobalConstants.WEB_SOCKET_SERVER_URL + "/ws/events/"}`
      );
      this.socket = this.socketService.connect();

      this.socket.addEventListener("close", (event) => {
        setTimeout(() => this.connectSocketAndListenEvents(), 1000)
      });

      this.socketService.on((event) => {
        const data = JSON.parse(event.data);
        console.log("Event received", data);
        this.processSocketEventsData(data);
      });

      this.$emitter.on(GlobalEvents.EMIT_WEBSOCKET_EVENT, (data) => {
        this.socketService.emit(data);
      });
    },

    processSocketEventsData(data) {
      switch (data.event_type) {
        case WebSocketEvents.CUSTOMER_STATUS_UPDATED:
          this.$store.dispatch("user/saveUser", {
            ...this.currentCustomer,
            status: data.new_status,
          });

          switch (data.new_status) {
            case CustomerStatus.REFUSED:
              this.logout();
              break;
          }
          break;

        case WebSocketEvents.chat.IS_TYPING:
          this.$emitter.emit(
            GlobalEvents.chat.IS_TYPING,
            data.payload
          );
          break;

        case WebSocketEvents.chat.MESSAGE_RECEIVED:
          this.$emitter.emit(
            GlobalEvents.chat.CHAT_MESSAGE_RECEIVED,
            data.message_sent
          );
          break;

        case WebSocketEvents.NOTIFICATION_RECEIVED:
          this.$emitter.emit(GlobalEvents.NEW_NOTIFICATION, {
            notification: data.new_notification,
            unreadNotifications: data.notification_count,
          });
          break;

        case WebSocketEvents.todo.TASK_CREATED:
          this.$emitter.emit(GlobalEvents.todo.TASK_CREATED, data.data);
          break;

        case WebSocketEvents.todo.TASK_UPDATED:
          this.$emitter.emit(GlobalEvents.todo.TASK_UPDATED, data.data);
          break;

        case WebSocketEvents.todo.TASK_DELETE:
          this.$emitter.emit(GlobalEvents.todo.TASK_DELETE, data.data);
          break;

        case WebSocketEvents.todo.TASK_CHECK_LIST_CREATED:
          this.$emitter.emit(GlobalEvents.todo.TASK_CHECK_LIST_CREATED, data.data);
          break;

        case WebSocketEvents.todo.TASK_CHECK_LIST_UPDATED:
          this.$emitter.emit(GlobalEvents.todo.TASK_CHECK_LIST_UPDATED, data.data);
          break;

        case WebSocketEvents.todo.TASK_CHECK_LIST_DELETE:
          this.$emitter.emit(GlobalEvents.todo.TASK_CHECK_LIST_DELETE, data.data);
          break;

        case WebSocketEvents.todo.STATUS_CREATE:
          this.$emitter.emit(GlobalEvents.todo.STATUS_CREATE, data.data);
          break;

        case WebSocketEvents.todo.STATUS_UPDATED:
          this.$emitter.emit(GlobalEvents.todo.STATUS_UPDATED, data.data);
          break;

        case WebSocketEvents.todo.STATUS_DELETED:
          this.$emitter.emit(GlobalEvents.todo.STATUS_DELETED, data.data);
          break;

        case WebSocketEvents.todo.PRIORITY_CREATE:
          this.$emitter.emit(GlobalEvents.todo.PRIORITY_CREATE, data.data);
          break;

        case WebSocketEvents.todo.PRIORITY_UPDATED:
          this.$emitter.emit(GlobalEvents.todo.PRIORITY_UPDATED, data.data);
          break;

        case WebSocketEvents.todo.PRIORITY_DELETE:
          this.$emitter.emit(GlobalEvents.todo.PRIORITY_DELETE, data.data);
          break;
      }
    },

    fetchVersion() {

      api.fetchVersion()
        .then((response) => {
          this.version = response.data.data;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
    },

  },
};
</script>
