<template>
  <div class="col-12 content-bundle py-4">
    <service-item
      v-for="service in planServices"
      :key="service.id"
      :service="service"
      @onAddService="handleServiceSelection(service.id)"
    />
  </div>
</template>

<script>
import ServiceItem from "@/components/subscription-plans/ServiceItem.vue";

export default {
  name: "ServiceList",

  components: {
    ServiceItem,
  },

  props: {
    services: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    planServices: [],
  }),

  watch: {
    services(val) {
      this.planServices = val;
    },
  },

  methods: {
    handleServiceSelection(id) {
      const index = this.planServices.findIndex((service) => service.id === id);
      if (index !== -1) {
        this.planServices[index].added = true;
        this.$emit("onAddService", this.planServices[index])
      }
    },
  },
};
</script>
