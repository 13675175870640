<template>
  <section
    id="notifications"
    class="content"
  >
    <div class="page-header pb-0 border-0">
      <h3 class="my-3 mind">
        Notifications
      </h3>

      <button
        class="btn-primary btn-next ms-3"
        :disabled="!hasUnreadNotifications || submitting"
        @click="markAllNotificationsAsRead"
      >
        <submission-spinner v-if="submitting" />
        Mark all as read
      </button>
    </div>

    <div class="page-content">
      <loader-spinner v-if="fetching" />

      <div
        v-else
        class="notifications-list"
      >
        <notification-list :notifications="listData" />
      </div>

      <app-pagination
        v-if="totalPages > 1"
        :align-end="true"
        :current-page="currentPage"
        :has-previous-page="hasPreviousPage"
        :previous-page="currentPage - 1 || 1"
        :has-next-page="hasNextPage"
        :next-page="currentPage + 1"
        :per-page="itemsPerPage"
        :total-elements="itemsCount"
        :total-pages="totalPages"
        @onGotoPage="getNotificaitons($event)"
        @onPrev="getNotificaitons(currentPage - 1 || 1)"
        @onNext="getNotificaitons(currentPage + 1)"
      />
    </div>
  </section>
</template>

<script>
import { toast } from "vue3-toastify";

import api from "@/services/api";
import ListMixin from "@/mixins/list";

import AppPagination from "@/components/AppPagination.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import NotificationList from "@/components/notifications/NotificationList.vue";

import { GlobalEvents } from "@/configs/constants";

export default {
  name: "ProviderNotificationsPage",

  components: {
    LoaderSpinner,
    AppPagination,
    NotificationList,
    SubmissionSpinner,
  },

  mixins: [ListMixin(api.fetchAllNotifications, true)],

  data: () => ({
    fetching: false,
    submitting: false,
    hasUnreadNotifications: false,
  }),

  watch: {
    listData() {
      this.fetching = false;
    },
  },

  mounted() {
    this.getNotificaitons(Number(this.$route.query.page) || 1);
    this.fetchLatestNotifications();
  },

  created() {
    this.$emitter.on(GlobalEvents.NEW_NOTIFICATION, ({ notification }) => {
      this.hasUnreadNotifications = true;
      this.listData.unshift(notification);
    });
  },

  methods: {
    getNotificaitons(page) {
      this.fetching = true;
      this.updateListFilters({
        page,
      });
    },

    async fetchLatestNotifications() {
      try {
        const response = await api.fetchLatestNotifications();
        const data = response.data;
        this.hasUnreadNotifications = data.unread_number > 0;
      } catch (_) {}
    },

    async markAllNotificationsAsRead() {
      try {
        this.submitting = true;

        await api.markAllNotificationsAsRead();
        this.hasUnreadNotifications = false;
        this.listData = this.listData.map((notification) => ({
          ...notification,
          read: true
        }))
        this.$emitter.emit(GlobalEvents.FETCH_LATEST_NOTIFICATIONS);
        toast.success(`All notifications successfully marked as read.`);
      } catch (_) {
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
