import { UserScopes } from "@/configs/constants/users";

// layouts

import CustomerLayout from "@/layouts/CustomerLayout.vue";

// view

import CustomerDashboard from "@/views/customer/Dashboard.vue";
import CustomerProfile from "@/views/customer/profile/Profile.vue";
import CustomerProfileEdit from "@/views/customer/profile/Edit.vue";
import CustomerChangePassword from "@/views/customer/profile/ChangePassword.vue";
import CustomerDeleteAccount from "@/views/customer/profile/DeleteAccount.vue";
import CustomerJobs from "@/views/customer/jobs/List.vue";
import CustomerJobCreate from "@/views/customer/jobs/Create.vue";
import CustomerJobDetails from "@/views/customer/jobs/Details.vue";
import CustomerJobEdit from "@/views/customer/jobs/Edit.vue";
import CustomerJobProposal from "@/views/customer/jobs/Proposal.vue";
import CustomerJobSommary from "@/views/customer/jobs/Sommary.vue";
import CustomerCalendar from "@/views/customer/calendar/Index.vue";
import CustomerMessage from "@/views/customer/message/Index.vue";
import CustomerJobDraft from "@/views/customer/jobs/DraftJob.vue";
import CustomerToDoList from "@/views/customer/jobs/ToDoList.vue";
import CustomerRating from "@/views/customer/jobs/Rating.vue";
import CustomerSendInvitation from "@/views/customer/subcontract/SendInvitation.vue";
import CustomerSubcontract from "@/views/customer/subcontract/List.vue";
import CustomerCreateSubcontractJob from "@/views/customer/subcontract/Create.vue";
import CustomerSubcontractDetails from "@/views/customer/subcontract/Detail.vue";
import CustomerInvoices from "@/views/customer/invoices/List.vue";
import CustomerInvoiceDetails from "@/views/customer/invoices/Detail.vue";
import CustomerSavedProviders from "@/views/customer/saved-providers/List.vue";
import CustomerSavedProvidersDetails from "@/views/customer/saved-providers/Details.vue";
import CustomerReports from "@/views/customer/Reports.vue";
import CustomerSubscription from "@/views/customer/Subscription.vue";
import CustomerEstimates from "@/views/customer/estimates/List.vue";
import CustomerEstimateDetails from "@/views/customer/estimates/Detail.vue";
import CustomerCreditCardsList from "@/views/share/wallet/List.vue";
import CustomerCreateCreditCard from "@/views/share/wallet/Create.vue";
import CustomerNotifications from "@/views/customer/Notifications.vue";
import CustomerPropertiesCreate from "@/views/customer/properties/Create.vue";
import CustomerPropertiesEdit from "@/views/customer/properties/Edit.vue";
import CustomerPropertiesList from "@/views/customer/properties/List.vue";
import CustomerPropertiesDetail from "@/views/customer/properties/Detail.vue";
import CustomerBulkPropertiesCreate from "@/views/customer/bulk/Create.vue";
import CustomerBulkPropertiesList from "@/views/customer/bulk/List.vue";

export default [
  {
    path: "/customer",
    redirect: "/customer/home",
    meta: {
      neededScope: [UserScopes.CUSTOMER],
      authRequired: true,
    },
    component: CustomerLayout,
    children: [
      {
        path: "home",
        name: "CustomerDashboard",
        component: CustomerDashboard,
      },

      {
        path: "profile",
        name: "CustomerProfile",
        component: CustomerProfile,
      },

      {
        path: "profile/edit",
        name: "CustomerProfileEdit",
        component: CustomerProfileEdit,
      },

      {
        path: "profile/change-password",
        name: "CustomerChangePassword",
        component: CustomerChangePassword,
      },

      {
        path: "profile/delete-account",
        name: "CustomerDeleteAccount",
        component: CustomerDeleteAccount,
      },

      {
        path: "jobs",
        name: "CustomerJobs",
        component: CustomerJobs,
      },

      {
        path: "jobs/create",
        name: "CustomerJobCreate",
        component: CustomerJobCreate,
      },

      {
        path: "jobs/:id/details",
        name: "CustomerJobDetails",
        component: CustomerJobDetails,
      },

      {
        path: "jobs/:id/edit",
        name: "CustomerJobEdit",
        component: CustomerJobEdit,
      },

      {
        path: "jobs/:id/proposals/:proposalId/details",
        name: "CustomerJobProposal",
        component: CustomerJobProposal,
      },
      {
        path: "jobs/:id/sommary",
        name: "CustomerJobSommary",
        component: CustomerJobSommary,
      },
      
      {
        path: "calendar",
        name: "CustomerCalendar",
        component: CustomerCalendar,
      },

      {
        path: "messages",
        name: "CustomerMessage",
        component: CustomerMessage,
      },

      {
        path: "jobs/draft",
        name: "CustomerJobDraft",
        component: CustomerJobDraft,
      },

      {
        path: "jobs/:id/to-do-list",
        name: "CustomerToDoList",
        component: CustomerToDoList,
      },
      {
        path: "jobs/:id/rating",
        name: "CustomerRating",
        component: CustomerRating,
      },

      
      {
        path: "jobs/:id/proposals/:proposalId/invitation",
        name: "CustomerSendInvitation",
        component: CustomerSendInvitation,
      },
      {
        path: "subcontract",
        name: "CustomerSubcontract",
        component: CustomerSubcontract,
      },
      {
        path: "subcontract/:id/detail/:proposalId/create",
        name: "CustomerCreateSubcontractJob",
        component: CustomerCreateSubcontractJob,
      },
      {
        path: "subcontract/:id/details",
        name: "CustomerSubcontractDetails",
        component: CustomerSubcontractDetails,
      },
      {
        path: "invoices",
        name: "CustomerInvoices",
        component: CustomerInvoices,
      },
      {
        path: "invoices/:id/detail",
        name: "CustomerInvoiceDetails",
        component: CustomerInvoiceDetails,
      },

      {
        path: "saved-providers",
        name: "CustomerSavedProviders",
        component: CustomerSavedProviders,
      },

      {
        path: "saved-providers/:id",
        name: "CustomerSavedProvidersDetails",
        component: CustomerSavedProvidersDetails,
      },

      {
        path: "reports",
        name: "CustomerReports",
        component: CustomerReports,
      },

      {
        path: "subscription",
        name: "CustomerSubscription",
        component: CustomerSubscription,
      },
      {
        path: "estimates",
        name: "CustomerEstimates",
        component: CustomerEstimates,
      },

      {
        path: "estimates/:id/detail",
        name: "CustomerEstimateDetails",
        component: CustomerEstimateDetails,
      },

      {
        path: 'wallet',
        name: 'CustomerCreditCardsList',
        component: CustomerCreditCardsList
      },

      {
        path: 'wallet/cerate-credit-card',
        name: 'CustomerCreateCreditCard',
        component: CustomerCreateCreditCard
      },

      {
        path: "notifications",
        name: "CustomerNotifications",
        component: CustomerNotifications,
      },

      {
        path: "properties",
        name: "CustomerProperties",
        component: CustomerPropertiesList,
      },

      {
        path: "properties/create",
        name: "CustomerPropertiesCreate",
        component: CustomerPropertiesCreate,
      },

      {
        path: "properties/:id/edit",
        name: "CustomerPropertiesEdit",
        component: CustomerPropertiesEdit,
      },

      {
        path: "properties/:id/detail/:tag",
        name: "CustomerPropertiesDetail",
        component: CustomerPropertiesDetail,
      },
      {
        path: "bulk-properties",
        name: "CustomerBulkProperties",
        component: CustomerBulkPropertiesList,
      },

      {
        path: "bulk-properties/create",
        name: "CustomerBulkPropertiesCreate",
        component: CustomerBulkPropertiesCreate,
      },
    ],
  },
];
