<template>
  <div class="post-job row">
    <section class="content">
      <div class="page-header">
        <h3>Add Property</h3>
      </div>

      <div class="page-content">
        <form @submit.prevent="save">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <div class="row">
            <div class="col-md-12">
              <p class="title mb-3">
                Property
              </p>

              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Company Name <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.company"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Enter company name..."
                    :class="{
                      'is-invalid':
                        v$.datasend.company.$invalid &&
                        v$.datasend.company.$dirty,
                    }"
                    @blur="v$.datasend.company.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.company.$invalid &&
                        v$.datasend.company.$dirty) ||
                        errors.company
                    "
                  >
                    <small
                      v-if="v$.datasend.company.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="v$.datasend.company.minLength.$invalid"
                      class="invalid-feedback"
                    >
                      The minimum size for this field is 3 characters
                    </small>

                    <small
                      v-else-if="errors.company"
                      class="invalid-feedback"
                    >
                      {{ errors.company }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Client Name <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.full_name"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Enter client name..."
                    :class="{
                      'is-invalid':
                        v$.datasend.full_name.$invalid &&
                        v$.datasend.full_name.$dirty,
                    }"
                    @blur="v$.datasend.full_name.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.full_name.$invalid &&
                        v$.datasend.full_name.$dirty) ||
                        errors.full_name
                    "
                  >
                    <small
                      v-if="v$.datasend.full_name.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="v$.datasend.full_name.minLength.$invalid"
                      class="invalid-feedback"
                    >
                      The minimum size for this field is 3 characters
                    </small>

                    <small
                      v-else-if="errors.full_name"
                      class="invalid-feedback"
                    >
                      {{ errors.full_name }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Phone No. <span class="text-danger">*</span>
                  </label>
                  <vue-tel-input
                    v-model="datasend.phone"
                    class="form-control pl-3 shadow-none"
                    :class="{
                      'is-invalid':
                        (v$.datasend.phone.$invalid &&
                          v$.datasend.phone.$dirty) ||
                        errors.phone,
                    }"
                    @blur="v$.datasend.phone.$touch"
                  />
                  <template
                    v-if="
                      (v$.datasend.phone.$invalid &&
                        v$.datasend.phone.$dirty) ||
                        errors.phone
                    "
                  >
                    <small
                      v-if="v$.datasend.phone.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.phone"
                      class="invalid-feedback"
                    >
                      {{ errors.phone }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Email <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.email"
                    type="email"
                    class="form-control pl-3 shadow-none"
                    placeholder="Enter email address..."
                    :class="{
                      'is-invalid':
                        v$.datasend.email.$invalid && v$.datasend.email.$dirty,
                    }"
                    @blur="v$.datasend.email.$touch"
                    @input="
                      datasend.email = formatEmailAddress($event.target.value)
                    "
                  >
                  <template
                    v-if="
                      (v$.datasend.email.$invalid &&
                        v$.datasend.email.$dirty) ||
                        errors.email
                    "
                  >
                    <small
                      v-if="v$.datasend.email.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.email"
                      class="invalid-feedback"
                    >
                      {{ errors.email }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Country <span class="text-danger">*</span>
                  </label>

                  <select
                    v-model="datasend.country"
                    class="form-select p-3 shadow-none"
                    :class="{
                      'is-invalid':
                        v$.datasend.country.$invalid &&
                        v$.datasend.country.$dirty,
                    }"
                    @blur="v$.datasend.country.$touch"
                  >
                    <option
                      v-for="country in allCountries"
                      :key="country.code"
                      :value="country.code"
                    >
                      {{ country.label }}
                    </option>
                  </select>
                  <template
                    v-if="
                      (v$.datasend.country.$invalid &&
                        v$.datasend.country.$dirty) ||
                        errors.country
                    "
                  >
                    <small
                      v-if="v$.datasend.country.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.country"
                      class="invalid-feedback"
                    >
                      {{ errors.country }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Address <span class="text-danger">*</span>
                  </label>
                  <GMapAutocomplete
                    :value="datasend.location_description"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Property address..."
                    :class="{
                      'is-invalid':
                        (v$.datasend.location_description.$invalid &&
                          v$.datasend.location_description.$dirty) ||
                        errors.location_description,
                    }"
                    @blur="validateAddress"
                    @change="handleAddressChange"
                    @place_changed="setAddress"
                  />
                  <template
                    v-if="
                      (v$.datasend.location_description.$invalid &&
                        v$.datasend.location_description.$dirty) ||
                        errors.location_description
                    "
                  >
                    <small
                      v-if="v$.datasend.location_description.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.location_description"
                      class="invalid-feedback"
                    >
                      {{ errors.location_description }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    City <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.city"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Property city..."
                    :class="{
                      'is-invalid':
                        v$.datasend.city.$invalid && v$.datasend.city.$dirty,
                    }"
                    @blur="v$.datasend.city.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.city.$invalid && v$.datasend.city.$dirty) ||
                        errors.city
                    "
                  >
                    <small
                      v-if="v$.datasend.city.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.city"
                      class="invalid-feedback"
                    >
                      {{ errors.city }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    State <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.state"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Property state..."
                    :class="{
                      'is-invalid':
                        v$.datasend.state.$invalid && v$.datasend.state.$dirty,
                    }"
                    @blur="v$.datasend.state.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.state.$invalid &&
                        v$.datasend.state.$dirty) ||
                        errors.state
                    "
                  >
                    <small
                      v-if="v$.datasend.state.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.state"
                      class="invalid-feedback"
                    >
                      {{ errors.state }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Zip code <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.zip"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Property zip code..."
                    :class="{
                      'is-invalid':
                        v$.datasend.zip.$invalid && v$.datasend.zip.$dirty,
                    }"
                    @blur="v$.datasend.zip.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.zip.$invalid && v$.datasend.zip.$dirty) ||
                        errors.zip
                    "
                  >
                    <small
                      v-if="v$.datasend.zip.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.zip"
                      class="invalid-feedback"
                    >
                      {{ errors.zip }}
                    </small>
                  </template>
                </div>
              </div>
            </div>

            <div class="row m-0 p-0">
              <div class="col-6 pb-3 text-start">
                <button
                  class="btn-outline"
                  @click="clientPortfolioDetail"
                >
                  Back
                </button>
              </div>

              <div class="col-6 pb-3 text-end">
                <button
                  class="btn-primary"
                  type="submit"
                  :disabled="loading"
                >
                  <submission-spinner
                    v-if="loading"
                    :color="'#209516'"
                  />
                  Add
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import croixVectorIcon from "@/assets/img/icons/poubell-red.svg";
import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

import LocationUtils from "@/utils/location";
import { GeneralHelpers } from "@/utils/general";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";
import { AllCountries } from "@/configs/countries";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderEstimateCreatePage",

  components: {
    SubmissionSpinner,
    AlerteDanger
  },

  beforeRouteLeave(to, from) {
    if (!this.allCountries[0].code) {
      this.allCountries.shift();
    }
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    listData: [],
    contact_type: [
      "Customer",
      "Employee",
      "Prospect",
      "Vendor",
      "Subcontractor",
      "Facility Manager",
      "Procurement",
      "AP",
      "AR",
    ],
    datasend: {
      full_name: null,
      company: null,
      country: null,
      phone: null, //+1 (982) 389 8989
      email: null,
      client: null,
      location_description: null,
      location: {
        type: "Point",
        coordinates: [0, 0],
      },
      zip: null,
      state: null,
      city: null,
    },
    errors: {
      full_name: null,
      company: null,
      country: null,
      phone: null,
      email: null,
      location_description: null,
      zip: null,
      state: null,
      city: null,
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    allCountries() {
      return AllCountries;
    },
  },

  validations() {
    return {
      datasend: {
        company: {
          required,
          minLength: minLength(3),
        },
        full_name: {
          required,
          minLength: minLength(3),
        },
        country: {
          required,
        },
        phone: {
          required,
        },
        email: {
          required,
        },
        location_description: {
          required,
        },
        city: {
          required,
        },
        state: {
          required,
        },
        zip: {
          required,
        },
      },
    };
  },

  mounted() {
    this.datasend.client = this.$route.params.id;
    this.allCountries.unshift({ label: "Select a country", code: null });
  },

  methods: {
    ...LocationUtils,
    ...GeneralHelpers,
    ...validationHelpers,

    back() {
      this.$router.back();
    },

    clientPortfolioDetail() {
      this.$router.push({
        name: "ClientPortfolioDetail",
        params: { id: this.$route.params.id, tag: "properties" },
      });
    },

    validateAddress() {
      setTimeout(() => {
        this.v$.datasend.location_description.$touch();
      }, 200);
    },

    handleAddressChange(value) {
      if (!value) {
        this.datasend.location_description = null;
      }
    },

    setAddress(values) {
      if (values) {
        const locationInfo = this.extractLocationInfo(
          values.address_components
        );
        if (locationInfo) {
          this.datasend.location_description = locationInfo.address;
          this.datasend.country = locationInfo.country.shortName;
          this.datasend.state = locationInfo.state;
          this.datasend.city = locationInfo.city;
          this.datasend.zip = locationInfo.pincode;
        }
        const lat = values.geometry?.location?.lat();
        const lng = values.geometry?.location?.lng();
        this.datasend.location = JSON.stringify({
          type: "Point",
          coordinates: [lng, lat],
        });
        this.v$.datasend.location_description.$touch();
      }
    },

    save() {
      if (this.loading) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.loading = true;
      this.clearErrorsMessages();

      api
        .addClientPropriety(this.datasend)
        .then((response) => {
          this.resetValidation();
          this.clientPortfolioDetail();
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.loading = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        full_name: null,
        company: null,
        country: null,
        phone: null,
        email: null,
        location_description: null,
        zip: null,
        state: null,
        city: null,
        serverSideErrorMessage: null,
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    formatNumber(value) {
      let number = value.replace(/\D/g, "");
      number = number.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      props.setPropertyValue(number);
    },

    formatNumberAdd$() {
      if (props.propertyValue != "") {
        let number = props.propertyValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        props.setPropertyValue(number + " $");
      }
    },

    formatNumberRem$() {
      let number = props.propertyValue.replace(/\D/g, "");
      props.setPropertyValue(number);
    },
  },
};
</script>
