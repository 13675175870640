import { Request } from "@/services/request";

const baseUrl = "api/v1/bid";

export default {
  fetchProposal(id) {
    return new Request().append(`${baseUrl}/${id}/`).method("get");
  },

  updateProposal(id, data) {
    return new Request()
      .append(`${baseUrl}/${id}/`)
      .method("patch")
      .setData(data);
  },

  acceptProvidersProposal(data) {
    return new Request()
      .append(`api/v1/bid_property/accept/`)
      .method("post")
      .setData(data);
  },
  getProposals({
    page = 1,
    startDate = null,
    endDate = null,
    search = null,
    status = null,
    category = null,
    contractType = null,
    level = null,
    skills = null,
    budgetType = null,
    paymentFrequency = null,
    jobType = null,
    budgetFrom = null,
    budgetTo = null,
  }) {
    return new Request().append(`api/v1/bid/my_bids/`).method("get").params({
      page,
      start_date: startDate,
      end_date: endDate,
      search,
      status,
      category,
      contract_type: contractType,
      level,
      skills,
      budget_type: budgetType,
      payment_frequency: paymentFrequency,
      job_type: jobType,
      budget_from: budgetFrom,
      budget_to: budgetTo,
    });
  },
};
