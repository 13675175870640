<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div
      class="modal-dialog modal-dialog-centered"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>

          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          />
        </div>

        <loader-spinner v-if="loading" />

        <form
          v-if="
            (action === 'create' && !loading) || (action === 'edit' && !loading)
          "
          @submit.prevent="submitCalendar"
        >
          <div class="modal-body px-3">
            <div class="row calendar-section">
              <div class="col-md-12 mb-3">
                <label class="form-label">
                  Project name <span class="text-danger">*</span>
                </label>
                <select
                  v-model="calendarData.bidsName"
                  class="form-select p-3 shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.calendarData.bidsName.$invalid &&
                        v$.calendarData.bidsName.$dirty) ||
                      errors.bidsNameErrorMessage,
                  }"
                  @blur="v$.calendarData.bidsName.$touch"
                >
                  <option
                    value="null"
                    disabled
                  >
                    Select a Job
                  </option>
                  <option
                    v-for="(item, index) in listData"
                    :key="index"
                    :value="item.job ? item.job.id : item.id"
                  >
                    {{ item.job ? item.job.title : item.title }}
                  </option>
                </select>

                <template
                  v-if="
                    (v$.calendarData.bidsName.$invalid &&
                      v$.calendarData.bidsName.$dirty) ||
                      errors.bidsNameErrorMessage
                  "
                >
                  <small
                    v-if="v$.calendarData.bidsName.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.bidsNameErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.bidsName }}
                  </small>
                </template>
              </div>

              <div
                v-if="false"
                class="col-md-12 mb-3"
              >
                <label class="form-label">
                  Location <span class="text-danger">*</span>
                </label>
                <GMapAutocomplete
                  ref="inputLocation"
                  type="text"
                  class="form-control shadow-none"
                  placeholder="Enter your Job Location"
                />
              </div>

              <div class="col-md-12 mb-3">
                <label class="form-label">
                  Start event <span class="text-danger">*</span>
                </label>
                <vue-date-picker
                  v-model="calendarData.start"
                  placeholder="Choose a Start Date"
                  class="datepicker form-control"
                  :class="{
                    'is-invalid':
                      (v$.calendarData.end.$invalid &&
                        v$.calendarData.end.$dirty) ||
                      errors.endErrorMessage,
                  }"
                  :enable-time-picker="true"
                  :is-24="false"
                  :format="'MM-dd-yyyy'"
                />

                <template
                  v-if="
                    (v$.calendarData.start.$invalid &&
                      v$.calendarData.start.$dirty) ||
                      errors.startErrorMessage
                  "
                >
                  <small
                    v-if="v$.calendarData.start.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.startErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.startErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-12 mb-3">
                <label class="form-label">
                  End event <span class="text-danger">*</span>
                </label>
                <vue-date-picker
                  v-model="calendarData.end"
                  placeholder="Choose a End Date"
                  class="datepicker form-control"
                  :class="{
                    'is-invalid':
                      (v$.calendarData.end.$invalid &&
                        v$.calendarData.end.$dirty) ||
                      errors.endErrorMessage,
                  }"
                  :enable-time-picker="true"
                  :is-24="false"
                  :format="'MM-dd-yyyy'"
                />

                <template
                  v-if="
                    (v$.calendarData.end.$invalid &&
                      v$.calendarData.end.$dirty) ||
                      errors.endErrorMessage
                  "
                >
                  <small
                    v-if="v$.calendarData.end.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.endErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.endErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-12 mb-3">
                <label class="form-label">
                  Description <span class="text-danger">*</span>
                </label>
                <textarea
                  v-model="calendarData.description"
                  placeholder="Leave a messagege"
                  class="form-control shadow-none"
                  cols="30"
                  rows="3"
                  :class="{
                    'is-invalid':
                      (v$.calendarData.description.$invalid &&
                        v$.calendarData.description.$dirty) ||
                      errors.descriptionErrorMessage,
                  }"
                  @blur="v$.calendarData.description.$touch"
                />
                <template
                  v-if="
                    (v$.calendarData.description.$invalid &&
                      v$.calendarData.description.$dirty) ||
                      errors.descriptionErrorMessage
                  "
                >
                  <small
                    v-if="v$.calendarData.description.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.descriptionErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.descriptionErrorMessage }}
                  </small>
                </template>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              :class="action === 'edit' ? 'btn-danger' : 'btn-outline'"
              @click="
                action === 'edit' ? changeActionModal('detail') : closeModal()
              "
            >
              {{ action === "edit" ? "Return" : "Close" }}
            </button>

            <button
              type="submit"
              class="btn-primary"
              :disabled="submitingCalendar"
            >
              <submission-spinner v-if="submitingCalendar" />
              {{ submitButtonTitle }}
            </button>
          </div>
        </form>

        <detail-event
          :detail="detail ?? {}"
          :loading="loading"
          :action="action"
          @edit="editCalendar"
          @deleteView="changeActionModal('delete')"
        />

        <delete-event
          :detail="detail ?? {}"
          :loading="loading"
          :action="action"
          :body="'Are you sure you want delete event ?'"
          @returnAction="changeActionModal('detail')"
          @deleteAction="deleteEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";

import LoaderSpinner from "@/components/LoaderSpinner.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import { toast } from "vue3-toastify";

import { required, maxLength } from "@vuelidate/validators";
import { DateHelpers } from "@/utils/date-helpers";
import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import DetailEvent from "./DetailEvent.vue";
import DeleteEvent from "./DeleteEvent.vue";
import { UserScopes } from "@/configs/constants/users";

export default {
  name: "CalendarModal",

  components: {
    LoaderSpinner,
    SubmissionSpinner,
    DetailEvent,
    DeleteEvent,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },

    submitButtonTitle: {
      type: String,
      required: false,
      default: null,
    },

    closeButtonTitle: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    showModal: false,
    fadeModal: false,
    loading: false,
    action: "create",
    detail: null,
    submitingCalendar: false,
    listData: [],

    calendarData: {
      bidsName: null,
      location: null,
      start: null,
      end: null,
      description: null,
    },

    errors: {
      bidsNameErrorMessage: null,
      locationErrorMessage: null,
      startErrorMessage: null,
      endErrorMessage: null,
      descriptionErrorMessage: null,
    },
  }),

  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    },
  },

  mounted() {
    this.fetchBids();
  },

  validations() {
    return {
      calendarData: {
        bidsName: {
          required,
          maxLength: maxLength(255),
        },

        start: {
          required,
        },

        end: {
          required,
        },

        description: {
          required,
        },

        // location: {
        //   required
        // }
      },
    };
  },

  methods: {
    ...DateHelpers,
    ...validationHelpers,

    async submitCalendar() {
      if (this.submitingCalendar) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      try {
        this.submitingCalendar = true;

        const data = {
          start: this.calendarData.start,
          end: this.calendarData.end,
          date: this.formatDate(this.calendarData.start, "YYYY-MM-DD"),
          time: this.formatDate(this.calendarData.start, "HH:MM:SS"),
          duration: this.formatDate(this.calendarData.start, "HH:MM:SS"),
          job: this.calendarData.bidsName,
          description: this.calendarData.description,
          user: this.currentUser.id,
        };

        if (this.action === "create") {
          if (data) {
            await api.createCalendarEvent(data);
            this.$emit("fetchCalendar");
            this.closeModal();
            const timeout = setTimeout(() => {
              toast.success("Your event create successfully.");
              clearTimeout(timeout);
            }, 1000);
          }
        }
        if (this.action === "edit") {
          if (data && this.currentUser.id) {
            await api.updateCalendarEvent(
              this.detail.event.extendedProps.idEvent,
              data
            );
            this.$emit("fetchCalendar");
            this.closeModal();
            const timeout = setTimeout(() => {
              toast.success("Your event update successfully.");
              clearTimeout(timeout);
            }, 1000);
          }
        }
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
      } finally {
        this.submitingCalendar = false;
      }
    },

    handleLocationChange(e) {
      if (!e.target.value) {
        this.calendarData.location = null;
      }
    },

    validateLocation() {
      setTimeout(() => {
        this.v$.calendarData.location.$touch();
      }, 200);
    },

    editCalendar() {
      if (!this.detail) {
        this.closeModal();
        return;
      }
      this.action = "edit";
      this.calendarData.bidsName = this.detail.event.extendedProps.idJob;
      this.calendarData.start = this.detail.event.start;
      this.calendarData.end = this.detail.event.extendedProps.endDate;
      this.calendarData.description =
        this.detail.event.extendedProps.description;
    },

    setJobLocation(values) {
      if (values) {
        const lat = values.geometry?.location?.lat();
        const lng = values.geometry?.location?.lng();

        this.calendarData.location = {
          type: "Point",
          coordinates: [lng, lat],
        };
        this.calendarData.location = values.formatted_address;
      }

      this.v$.calendarData.location.$touch();
    },

    openModal(operation) {
      operation && (this.action = operation);

      this.showModal = true;
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      if (this.detail !== null) {
        this.detail = null;
      }
      this.action = "create";
      this.resetValidation();
      this.clearCalendarForm();
      this.fadeModal = false;
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },

    changeActionModal(name) {
      this.action = name;
    },

    async fetchBids() {
      this.loading = true;

      if (this.currentUser.scope === UserScopes.CUSTOMER) {
        await api
          .customerDashboard()
          .then((response) => {
            console.log(response);
            this.listData = response.data.active_jobs ?? [];
          })
          .catch((_) => {
            /**
             * TODO
             * Handle errors
             */
            // this.errorHandler(error)
          })
          .then(() => {
            this.loading = false;
          });
      }

      if (this.currentUser.scope === UserScopes.PROVIDER) {
        await api
          .fetchBids()
          .then((response) => {
            // console.log(response);
            this.listData = response.data.data;
          })
          .catch((_) => {
            /**
             * TODO
             * Handle errors
             */
            // this.errorHandler(error)
          })
          .then(() => {
            this.loading = false;
          });
      }
    },

    async deleteEvent() {
      if (this.detail === null) {
        return;
      }

      try {
        this.loading = true;
        await api.deleteCalendarEvent(this.detail.event.id);

        this.$emit("fetchCalendar");
        this.closeModal();
        const timeout = setTimeout(() => {
          toast.success("Event successfully deleted.");
          clearTimeout(timeout);
        }, 1000);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    loadDetail(infos) {
      this.detail == null && (this.loading = true);
      const loadDetail = setTimeout(() => {
        if (this.action === "detail") {
          this.detail = infos;
        }
        this.loading = false;
        clearTimeout(loadDetail);
      }, 1500);
    },

    clearCalendarForm() {
      this.calendarData.bidsName = null;
      this.calendarData.location = null;
      this.calendarData.start = null;
      this.calendarData.end = null;
      this.calendarData.description = null;
    },
  },
};
</script>

<style>
.calendar-section {
  align-items: center;
  padding: 0.5rem 0.8rem;
}
</style>
