<template>
  <div class="row allMessage">
    <div class="col-4 left">
      <div class="top-nav">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
          >
          <span class="input-group-text">@</span>
        </div>
      </div>
      <div class="fix-nav py-3" />
    </div>
    <div class="col right">
      <div class="top-nav row align-items-center">
        <div class="header-message">
          <div class="infos">
            <h3 class="title">
              Lorem ipsum
            </h3>
            <span class="label">ipsum</span>
          </div>
          <div class="infos__icon">
            <span
              href="#"
              class="btn-round"
              aria-current="page"
            ><img
              :src="icons.infos"
              alt="Infos"
            ></span>
            <span
              href="#"
              class="btn-round"
              aria-current="page"
            ><img
              :src="icons.infos"
              alt="Infos"
            ></span>
          </div>
        </div>
      </div>
      <div class="interview">
        <div class="chat">
          <div class="messages-display">
            <div>
              <!--v-if-->
              <div
                class="msg-start d-flex justify-content-start text-start mb-4"
              >
                <div class="avatar-wrapper">
                  <img
                    src="https://serviceloop-static-files.s3.amazonaws.com/0.jpeg?AWSAccessKeyId=AKIAJ2GCRXOBHREHHBAA&amp;Signature=biyRR0FOaAwpMlf4sGdBigYEZ%2FI%3D&amp;Expires=1848787338"
                    alt="Avatar"
                    class="participant-avatar"
                  >
                </div>
                <div>
                  <div>
                    <p class="ms-receiver p-2 ms-3">
                      Baba
                    </p>
                  </div>
                  <div>
                    <!--v-if-->
                    <p class="ms-3 mb-3 rounded-3 hours">
                      19:18
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="divider d-flex align-items-center mb-4">
                <p class="text-center mx-3 mb-0">
                  07/25/2023
                </p>
              </div>
              <div
                class="msg-end d-flex justify-content-end text-end mb-4 pt-1"
              >
                <div>
                  <div>
                    <p class="ms-xender p-2 me-3">
                      Ya
                    </p>
                  </div>
                  <div>
                    <!--v-if-->
                    <p class="me-3 mb-3 d-flex justify-content-end hours">
                      19:18
                    </p>
                  </div>
                </div>
                <div class="avatar-wrapper">
                  <img
                    src="https://serviceloop-static-files.s3.amazonaws.com/Screen_Shot_2022-08-19_at_07.46.22.png?AWSAccessKeyId=AKIAJ2GCRXOBHREHHBAA&amp;Signature=Aq8BG88lk7GLOHrDhFOy6Yu2GIA%3D&amp;Expires=1848706760"
                    alt="Avatar"
                    class="participant-avatar"
                  ><!-- <div class="circle position-absolute" /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="message-sender text-muted">
            <div class="message-sender-box">
              <div class="message-text-box">
                <div
                  class="message-input form-control form-control-lg"
                  placeholder="Type message"
                  tabindex="0"
                  contenteditable="true"
                />
              </div>
              <input
                accept="application/pdf, image/*"
                type="file"
                class="shadow-none"
                style="display: none"
              ><img
                src="/img/add.5a3a8b3f.svg"
                class="cursor-pointer me-2 ms-3"
                alt="Upload Image Icon"
              ><img
                src="/img/send.24eae93e.svg"
                alt="Send Message Icon"
                class="cursor-pointer"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import infos from "@/assets/img/icons/infos.svg";
export default {
  name: "CustomerMessage",
  data: () => {
    return {
      icons: {
        infos,
      },
    };
  },
};
</script>

<style scoped>
.left {
  max-width: 300px;
  border: 1px solid rgba(34, 42, 54, 0.1);
  border-right: 0;
  padding: 0;
}

.top-nav {
  border-bottom: 1px solid rgba(34, 42, 54, 0.1);
  padding: 1rem 0;
  min-height: 98px;
}

.top-nav .right {
  padding: 1rem;
}

.right {
  border: 1px solid rgba(34, 42, 54, 0.1);
  padding: 0;
  display: flex;
  flex-direction: column;
}

.right .fix-nav {
  height: 100%;
}

.infos {
  width: 100%;
  padding: 0 1rem;
}

.infos h3 {
  font-size: 18px;
}

.infos span {
  color: rgb(109, 113, 117);
}

.infos__icon {
  display: flex;
  align-items: center;
}

.infos__icon .btn-round {
  padding: 0.6rem;
  margin-right: 0.5rem;
  border-radius: 34rem;
}

.btn-round img {
  max-width: 30px;
}

.header-message {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.allMessage {
  min-height: 80vh;
}

.input-group input,
.input-group .input-group-text {
  border: 0 !important;
  box-shadow: none !important;
  background: transparent;
}

.interview {
  position: relative;
  height: 100%;
}

.interview .chat {
  border: 0;
  height: 100%;
  position: relative;
  padding-bottom: 100px;
}

.interview .chat .messages-display {
  height: 100%;
}

.message-sender {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>
