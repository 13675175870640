<template>
  <section class="content change-password">
    <div class="page-header">
      <h3 class="page-title">
        Change Password
      </h3>

      <settings-menu />
    </div>

    <div class="page-content">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 box-form">
          <form @submit.prevent="handleChangePassword">
            <alerte-danger
              :head-error-message="errors.serverSideErrorMessage"
              @updateContent="errors.serverSideErrorMessage = $event"
            />

            <div class="mb-3">
              <label for="">
                Current Password <span class="text-danger">*</span>
              </label>
              <div class="mb-3 position-relative">
                <img
                  :src="icons.vectorPassword"
                  alt="Password Icon"
                  class="position-absolute mt-3 ms-3"
                >

                <img
                  v-if="showCurrentPassword"
                  :src="icons.vectorEye"
                  alt="Toggle password visibility icon"
                  class="position-absolute eye"
                  @click="showCurrentPassword = !showCurrentPassword"
                >
                <img
                  v-else
                  :src="icons.vectorEyeOff"
                  alt="Toggle password visibility icon"
                  class="position-absolute eye-off"
                  @click="showCurrentPassword = !showCurrentPassword"
                >

                <input
                  v-model.trim="formData.currentPassword"
                  :type="showCurrentPassword ? 'text' : 'password'"
                  class="form-control ps-5 shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.formData.currentPassword.$invalid &&
                        v$.formData.currentPassword.$dirty) ||
                      errors.currentPasswordErrorMessage,
                  }"
                  placeholder="Password"
                  @blur="v$.formData.currentPassword.$touch"
                >
              </div>
              <template
                v-if="
                  (v$.formData.currentPassword.$invalid &&
                    v$.formData.currentPassword.$dirty) ||
                    errors.currentPasswordErrorMessage
                "
              >
                <small
                  v-if="v$.formData.currentPassword.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="errors.currentPasswordErrorMessage"
                  class="invalid-feedback"
                >
                  {{ errors.currentPasswordErrorMessage }}
                </small>
              </template>
            </div>

            <div class="mb-3">
              <label for="">
                New Password <span class="text-danger">*</span>
              </label>
              <div class="mb-3 position-relative">
                <img
                  :src="icons.vectorPassword"
                  alt="Password Icon"
                  class="position-absolute mt-3 ms-3"
                >

                <img
                  v-if="showNewPassword"
                  :src="icons.vectorEye"
                  alt="Toggle password visibility icon"
                  class="position-absolute eye"
                  @click="showNewPassword = !showNewPassword"
                >
                <img
                  v-else
                  :src="icons.vectorEyeOff"
                  alt="Toggle password visibility icon"
                  class="position-absolute eye-off"
                  @click="showNewPassword = !showNewPassword"
                >

                <input
                  v-model.trim="formData.newPassword"
                  :type="showNewPassword ? 'text' : 'password'"
                  class="form-control ps-5 shadow-none"
                  placeholder="New Password"
                  :class="{
                    'is-invalid':
                      (v$.formData.newPassword.$invalid &&
                        v$.formData.newPassword.$dirty) ||
                      errors.newPasswordErrorMessage,
                  }"
                  @input="checkPasswordsValidity"
                  @blur="v$.formData.newPassword.$touch"
                >
              </div>
              <template
                v-if="
                  (v$.formData.newPassword.$invalid &&
                    v$.formData.newPassword.$dirty) ||
                    errors.newPasswordErrorMessage
                "
              >
                <small
                  v-if="v$.formData.newPassword.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="v$.formData.newPassword.minLength.$invalid"
                  class="invalid-feedback"
                >
                  The minimum size for this field is 6 characters
                </small>

                <small
                  v-else-if="errors.newPasswordErrorMessage"
                  class="invalid-feedback"
                >
                  {{ errors.newPasswordErrorMessage }}
                </small>
              </template>
            </div>

            <div class="mb-3">
              <label for="">
                Confirm New Password <span class="text-danger">*</span>
              </label>
              <div class="mb-3 position-relative">
                <img
                  :src="icons.vectorPassword"
                  alt="Password Icon"
                  class="position-absolute mt-3 ms-3"
                >

                <img
                  v-if="showConfirmPassword"
                  :src="icons.vectorEye"
                  alt="Toggle password visibility icon"
                  class="position-absolute eye"
                  @click="showConfirmPassword = !showConfirmPassword"
                >
                <img
                  v-else
                  :src="icons.vectorEyeOff"
                  alt="Toggle password visibility icon"
                  class="position-absolute eye-off"
                  @click="showConfirmPassword = !showConfirmPassword"
                >

                <input
                  v-model.trim="formData.confirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  class="form-control ps-5 shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.formData.confirmPassword.$invalid &&
                        v$.formData.confirmPassword.$dirty) ||
                      errors.confirmPasswordErrorMessage,
                  }"
                  placeholder="Confirm New Password"
                  @input="checkPasswordsValidity"
                  @blur="v$.formData.confirmPassword.$touch"
                >
              </div>
              <template
                v-if="
                  v$.formData.confirmPassword.$invalid &&
                    v$.formData.confirmPassword.$dirty
                "
              >
                <small
                  v-if="v$.formData.confirmPassword.sameAs.$invalid"
                  class="invalid-feedback"
                >
                  The passwords don't match
                </small>
              </template>
            </div>

            <div class="text-md-end py-3">
              <router-link
                :to="{ name: 'ProviderProfile' }"
                class="btn-outline me-3"
              >
                Cancel
              </router-link>

              <button
                type="submit"
                class="btn-save"
                :disabled="v$.formData.$invalid || loading"
              >
                <submission-spinner v-if="loading" />
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { toast } from "vue3-toastify";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";

import api from "@/services/api";
import validationHelpers from "@/utils/validation";
import errorMessages from "@/utils/error-messages";

import dotVectorIcon from "@/assets/img/icons/tree-dot.svg";
import vectorPassword from "@/assets/img/icons/symbols_password.svg";
import vectorEye from "@/assets/img/icons/symbols_eye.svg";
import vectorEyeOff from "@/assets/img/icons/symbols_eye_off.svg";

import SettingsMenu from "@/components/provider/SettingsMenu.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderChangePasswordPage",

  components: {
    SettingsMenu,
    SubmissionSpinner,
    AlerteDanger,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    icons: {
      vectorPassword,
      vectorEye,
      vectorEyeOff,
      dotVectorIcon,
    },
    loading: false,
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    formData: {
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
    },
    errors: {
      currentPasswordErrorMessage: null,
      newPasswordErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      formData: {
        currentPassword: {
          required,
          minLength: minLength(6),
        },

        newPassword: {
          required,
          minLength: minLength(6),
        },

        confirmPassword: {
          required,
          minLength: minLength(6),
          sameAs: sameAs(this.formData.newPassword),
        },
      },
    };
  },

  methods: {
    ...validationHelpers,

    checkPasswordsValidity() {
      this.v$.formData.newPassword.$touch();
      this.v$.formData.confirmPassword.$touch();
    },

    async handleChangePassword() {
      if (this.loading) return;

      if (this.v$.formData.$invalid) {
        this.forceValidation();
        return;
      }
      this.loading = true;
      this.clearErrorsMessages();

      try {
        await api.changePassword(
          this.formData.currentPassword,
          this.formData.newPassword
        );
        this.clearForm();
        this.v$.formData.$reset();

        toast.success("Password successfully updated.");
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.loading = false;
      }
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    clearForm() {
      for (const key in this.formData) {
        if (Object.hasOwnProperty.call(this.formData, key)) {
          this.formData[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 400) {
          for (const error in err.response.data) {
            if (error == "new_password") {
              this.errors.newPasswordErrorMessage = err.response.data[error][0];
            }
            if (error == "old_password") {
              this.errors.currentPasswordErrorMessage =
                err.response.data[error][0];
            }
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
