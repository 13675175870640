import { Request } from "@/services/request";

export default {
  updloadUserCV(data) {
    return new Request()
      .contentType("multipart/form-data")
      .append(`api/v1/user_cv/`)
      .method("post")
      .setData(data);
  },

  deleteUserCvFile(id) {
    return new Request()
      .append(`api/v1/user_cv/${id}/`)
      .method("delete");
  },
  
  updloadUserPortfolioFile(data) {
    return new Request()
      .contentType("multipart/form-data")
      .append(`api/v1/user_portfolio/`)
      .method("post")
      .setData(data);
  },

  deleteUserPortfolioFile(id) {
    return new Request()
      .append(`api/v1/user_portfolio/${id}/`)
      .method("delete");
  }
};