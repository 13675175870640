<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div 
      class="modal-dialog modal-lg modal-dialog-centered"
    >
      <div class="modal-content pt-0">
        <div class="modal-header p-0">
          <div
            class="col-12 d-block"
            style="min-height: 1rem"
          >
            <div
              class="col-12 d-block mb-1"
              :style="{
                'height': (config.priority.find((el) => el.id == datasend.priority) ? '0.5rem' : 'auto'),
                'background-color': config.priority.find((el) => el.id == datasend.priority)?.color
              }"
            />
            <button
              type="button"
              class="btn-close text-white float-end me-2 mt-2"
              @click="closeModal"
            />
          </div>
        </div>

        <div class="modal-body">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <div class="row">
            <h5
              v-if="focus.name == false"
              class="text-dark col-12 mb-5 text-wrap"
              @click="focus.name = true"
            >
              {{ datasend.name }}
            </h5>
            <div
              v-if="focus.name == true"
              class="col-12 my-3"
            >
              <input
                v-model="datasend.name"
                type="text"
                class="form-control pl-3 shadow-none"
                placeholder="Name"
                :autofocus="focus.name"
                @blur="update(), focus.name = false"
              >
              <template
                v-if="
                  (v$.datasend.name.$invalid && v$.datasend.name.$dirty) ||
                    errors.name
                "
              >
                <small
                  v-if="v$.datasend.name.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="v$.datasend.name.minLength.$invalid"
                  class="invalid-feedback"
                >
                  The minimum size for this field is 3 characters
                </small>

                <small
                  v-else-if="errors.name"
                  class="invalid-feedback"
                >
                  {{ errors.name }}
                </small>
              </template>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-8">
                  <label
                    v-if="config.assigned"
                    for="assigned"
                    class="form-label text-dark fw-bold"
                  >
                    Assigned to:
                  </label>
                  <p class="d-flex">
                    <span
                      v-for="(assign, l) in datasend.assigned"
                      :key="l"
                      class="avatar-wrapper me-2"
                      @click="singleUpdate('assigned', datasend.assigned.filter(el => (el !== assign)))"
                    >
                      <img
                        v-if="config.assigned.find(el => el.id == assign)?.url"
                        :src="config.assigned.find(el => el.id == assign)?.url"
                        class="user-avatar"
                        alt="Customer Profile Photo"
                      >
                      <avatar
                        v-else-if="config.assigned.find(el => el.id == assign)"
                        :name="config.assigned.find(el => el.id == assign)?.name"
                        :size="48"
                        :color="config.assigned.find(el => el.id == assign)?.avatarColorConfigs?.color"
                        :background="config.assigned.find(el => el.id == assign)?.avatarColorConfigs?.background"
                        class="proposal-avatar"
                        alt="Provider avatar"
                      />
                      <div class="circle position-absolute" />
                    </span>
                    <span
                      v-if="config.assigned.length > 0"
                      class="avatar-wrapper ms-2 dropdown"
                    >
                      <button
                        class="rounded-circle btn-primary"
                        style="width: 48px; height: 48px;"
                        alt="Provider avatar"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        +
                      </button>
                      <ul class="dropdown-menu">
                        <li 
                          v-for="(assign, l) in config.assigned"
                          :key="l"
                          class="dropdown-item d-flex"
                          @click="datasend.assigned.includes(assign.id) ? false : singleUpdate('assigned', [...datasend.assigned, assign.id])"
                        >
                          <div
                            class="avatar-wrapper"
                          >
                            <img
                              v-if=" assign?.url"
                              :src=" assign?.url"
                              class="user-avatar"
                              style="min-width: 48px"
                              alt="Customer Profile Photo"
                            >
                            <avatar
                              v-else
                              :name="assign?.name"
                              :size="48"
                              :color="assign?.avatarColorConfigs?.color"
                              :background="assign?.avatarColorConfigs?.background"
                              class="proposal-avatar"
                              alt="Provider avatar"
                            />
                            <div class="circle position-absolute" />
                          </div>
                          <div>
                            <div>
                              <strong class="provider-name">{{
                                assign?.name
                              }}</strong>
                            </div>

                            <div>
                              <p class="proposal-date">
                                Applied on
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </span>
                  </p>
                </div>
                <div class="col-4">
                  <div
                    v-if="config.status.length != 0"
                    class="mb-3 col-md-12"
                  >
                    <label
                      for="status"
                      class="form-label text-dark fw-bold"
                    >
                      Status
                    </label>
                    <p
                      class="w-100 text-wrap fw-normal"
                    >
                      <span class="badge bg-primary text-white fw-normal me-2">
                        {{ config.status.find((el) => el.id == datasend.status)?.name }}
                      </span>
                    </p>
                  </div>
                  <div
                    v-if="config.priority.length != 0"
                    class="mb-3 col-md-12"
                  >
                    <label
                      v-if="datasend.priority"
                      for="priority"
                      class="form-label text-dark fw-bold"
                    >
                      Priority
                    </label>
                    <p
                      v-if="datasend.priority"
                      class="w-100 text-wrap fw-normal"
                    >
                      <span
                        class="badge fw-normal me-2"
                        :style="{'background-color': config.priority.find((el) => el.id == datasend.priority)?.color}"
                      >
                        {{ config.priority.find((el) => el.id == datasend.priority)?.name }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div
                  v-if="datasend.checklist.length !== 0"
                  class="col-md-12 mb-3"
                >
                  <label
                    for=""
                    class="mb-2 text-dark fw-bold"
                  >
                    Check List:
                  </label>
                  <ul class="list-group">
                    <li 
                      v-for="(item, index) in datasend.checklist"
                      :key="index"
                      class="list-group-item"
                    >
                      <input
                        id="firstCheckboxStretched"
                        class="form-check-input me-2"
                        type="checkbox"
                        :checked="item.completed"
                        @change="updateCheckList('checkbox', item)"
                      >
                      <input
                        v-model="item.name"
                        type="text"
                        class="p-0 d-inline border-white"
                        style="width: 90%;"
                        :class="{'text-decoration-line-through': item.completed}"
                        @blur="updateCheckList('text', item)"
                      >
                      <img
                        alt="smallclose"
                        class="float-end p-1"
                        :src="croixVectorIcon"
                        @click="deleteCheckList(item)"
                      >
                    </li>
                  </ul>
                </div>
                <div
                  v-if="check"
                  class="col-md-12 text-end"
                >
                  <input
                    v-model="datasend1.name"
                    type="text"
                    placeholder="Write item here..."
                    class="form-control shadow-none mb-4"
                  >
                  <button
                    class="btn-secondary"
                    :disabled="datasend1.name == null"
                    @click="createCheckList"
                  >
                    Add Item
                  </button>
                </div>
              </div>
              <div class="mb-3 col-md-12">
                <label
                  class="pb-1 text-dark fw-bold"
                  @click="focus.description = true"
                >Description</label>
                <p
                  v-if="focus.description == false"
                  class="text-dark col-12 mb-5 text-wrap"
                  @click="focus.description = true"
                >
                  {{ datasend.description }}
                </p>
                <textarea
                  v-if="focus.description == true"
                  v-model="datasend.description"
                  cols="30"
                  rows="2"
                  class="form-control pl-3"
                  placeholder="Write your description here..."
                  :autofocus="focus.description"
                  @blur="update(), focus.description = false"
                />
                <p
                  v-if="!datasend.description?.trim() && focus.description == false"
                  @click="focus.description = true"
                >
                  Write your description here
                </p>
              </div>
            </div>
            <div class="col-4">
              <div class="col-12">
                <div class="col-12 btn-group mb-4">
                  <button
                    class="btn bg-secondary text-black dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                  >
                    Change status
                  </button>
                  <ul class="col-12 dropdown-menu">
                    <li 
                      v-for="(status, l) in config.status"
                      :key="l"
                      class="list-group-item"
                      @click="singleUpdate('status', status.id)"
                    >
                      <a
                        class="dropdown-item"
                        href="#"
                      >
                        {{ status?.name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-12">
                <div class="col-12 btn-group mb-4">
                  <button
                    class="btn bg-secondary text-black dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                  >
                    Add priority
                  </button>
                  <ul class="col-12 dropdown-menu">
                    <li 
                      v-for="(priority, k) in config.priority"
                      :key="k"
                      class="list-group-item"
                      @click="singleUpdate('priority', priority.id)"
                    >
                      <a
                        class="dropdown-item"
                        href="#"
                      >
                        <span 
                          v-if="priority?.color"
                          class="p-1 border border-light rounded-circle d-inline-block"
                          :style="{'background-color': priority?.color}"
                        />
                        {{ priority?.name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="col-12 mb-4"
              >
                <button
                  class="col-12 bg-secondary text-black"
                  @click="check = !check"
                >
                  Add checklist
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn-outline"
            @click="closeModal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn-primary"
            @click="closeModal"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import croixVectorIcon from "@/assets/img/icons/croix-red.svg";

import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import validationHelpers from "@/utils/validation";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";
import Avatar from "vue3-avatar";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: 'UpdateToDoTask',

  components: { Avatar, AlerteDanger },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    config: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    datasend1: {
      name: null,
      job: null,
      task: null,
    },
    datasend: {
      name: null,
      description: null,
      priority: null,
      assigned: [],
      checklist: [],
      status: null,
    },
    focus: {
      name: false,
      description: false,
      priority: false,
      assigned: false,
      status: false,
    },
    check: false, 
    id: null,
    errors: {
      name: null,
      priority: null,
      assigned: null,
      status: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      datasend: {
        name: {
          required,
          minLength: minLength(3),
        },
        status: {
          required,
        },
      }
    };
  },

  methods: {
    ...validationHelpers,

    openModal(item) {
      this.id = item.id;
      this.datasend1.job = item.job;
      this.datasend1.task = item.id;
      this.datasend.name = item.name;
      this.datasend.description = item.description;
      this.datasend.status = item.status;
      this.datasend.priority = item.priority;
      this.datasend.assigned = item.assigned || [];
      this.datasend.checklist = item.checklist || [];
      
      this.showModal = true;
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      this.fadeModal = false;
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },

    singleUpdate(attr, val) {
      this.datasend[attr] = val;
      api.updateToDoTask(this.id, this.datasend).then((response) => {
        this.$emit('updateList')
      })
      .catch(error => {
        this.errorsHandler(error)
      })
    },

    update() {
      if (this.loading) return

      if (this.v$.$invalid) {
        this.forceValidation()
        return
      }

      this.loading = true
      this.clearErrorsMessages();
      api.updateToDoTask(this.id, this.datasend).then((response) => {
        // this.datasend= {name: null,type: null,responses: []};
        this.$emit('updateList')
      })
      .catch(error => {
        this.errorsHandler(error)
      })
      .then(() => {
        this.loading = false
      })
    },

    clearErrorsMessages() {
      this.errors = {
        name: null,
        priority: null,
        assigned: null,
        status: null,
        serverSideErrorMessage: null,
      }
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] = err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    createCheckList() {
      api.createCheckList(this.datasend1)
        .then((response) => {
          this.datasend1.name = null;
          this.check = false;
          this.datasend.checklist.push(response.data)
          this.$emit('fetchCheckList')
        })
        .catch((error) => {
          console.log(error);
          // this.errorsHandler(error);
        })
    },

    deleteCheckList(el) {
      api.deleteCheckList(el.id)
        .then((response) => {
          this.datasend.checklist = this.datasend.checklist.filter(item => el.id !== item.id)
          this.$emit('fetchCheckList')
        })
        .catch((error) => {
          console.log(error);
          // this.errorsHandler(error);
        })
    },

    updateCheckList(type, el) {
      api.updateCheckList(el.id, type == 'checkbox' ? {completed: !el.completed} : {name: el.name})
      .then((response) => {
        // console.log(response.data);
        this.datasend.checklist[this.datasend.checklist.findIndex(item => el.id == item.id)] = response.data
        this.$emit('fetchCheckList')
      })
      .catch(error => {
        console.log(error);
        // this.errorsHandler(error)
      })
    },

  },
};
</script>

<style scoped></style>
