<template>
  <div>
    <loader-spinner v-if="loading" />

    <div
      v-else-if="errors.notFoundErrorMessage"
      class="row mt-4"
    >
      <div class="col-lg-8">
        <alerte-danger
          :head-error-message="errors.notFoundErrorMessage"
          @updateContent="errors.notFoundErrorMessage = $event"
        />
      </div>
    </div>

    <template v-else>
      <section
        v-if="proposal"
        class="content job-detail"
      >
        <div
          class="page-header proposal-details-actions"
          :class="{ 'has-sub-job': proposal.sub_job }"
        >
          <div class="">
            <router-link
              :to="previousPage"
              class="btn-danger me-3"
            >
              Go Back
            </router-link>

            <router-link
              :to="{
                name: 'ProviderJobDetails',
                params: {
                  id: $route.params.id,
                },
              }"
              class="btn-primary"
            >
              View Job
            </router-link>
          </div>

          <div v-if="canCreateSubcontract">
            <router-link
              :to="{
                name: 'CreateSubcontractJob',
                params: {
                  id: $route.params.id,
                  proposalId: $route.params.proposalId,
                },
              }"
              class="btn-primary"
              :class="{ 'me-3': proposal.sub_job }"
            >
              Find subcontractors
            </router-link>

            <router-link
              v-if="proposal.sub_job"
              :to="{
                name: 'ProviderSubcontracting',
                params: {
                  id: $route.params.id,
                  proposalId: $route.params.proposalId,
                },
              }"
              class="btn-secondary"
            >
              View subcontracting
            </router-link>
          </div>
        </div>

        <div class="page-content">
          <div>
            <router-link
              :to="{
                name: 'ProviderJobDetails',
                params: { id: proposal?.job.id },
              }"
              class="content-title"
            >
              {{ proposal?.job.title }}
            </router-link>

            <p class="content-subtitle pb-0">
              Posted at {{ formatDate(proposal.created_at, "MMMM Do, YYYY") }}

              <template v-if="proposal.job?.category?.name">
                in <span class="fw-bold">{{ proposal.job.category.name }}</span>
              </template>
            </p>
          </div>

          <div class="row pe-4 detail">
            <div
              class="col-md-8 py-4"
              :class="{
                'col-md-8 description': showCustomerDetails,
                'col-md-12': !showCustomerDetails,
              }"
            >
              <p
                class="category-name"
                v-html="sanitizedDescription"
              />
            </div>

            <div
              v-if="proposal?.status == jobProposalStatus.ACCEPTED"
              class="col-md-4"
            >
              <p class="about">
                About the client
              </p>
              <div class="d-flex">
                <p class="category">
                  Customer’s Name :
                </p>

                <p
                  v-if="
                    proposal?.job?.owner?.user?.full_name ||
                      proposal?.job?.owner_provider?.user?.full_name
                  "
                  class="category-name"
                >
                  {{
                    proposal?.job?.owner?.user?.full_name ||
                      proposal?.job?.owner_provider?.user?.full_name
                  }}
                </p>
              </div>

              <div
                v-if="proposal?.job?.owner?.user?.company_name"
                class="d-flex"
              >
                <p class="category">
                  Company Name :
                </p>

                <p class="category-name">
                  {{ proposal?.job?.owner?.user?.company_name }}
                </p>
              </div>
              <div
                v-if="proposal?.job?.owner?.user?.phone"
                class="d-flex"
              >
                <p class="category">
                  Contact :
                </p>

                <p class="category-name">
                  {{ proposal?.job?.owner?.user?.phone }}
                </p>
              </div>
              <div
                v-if="proposal?.job?.owner?.user?.email"
                class="d-inline-flex"
              >
                <p class="category">
                  E-mail :
                </p>

                <p class="category-name">
                  {{ proposal?.job?.owner?.user?.email }}
                </p>
              </div>
              <div
                v-if="proposal?.job.location_description"
                class="d-flex"
              >
                <p class="category">
                  Job Location:
                </p>

                <p class="category-name">
                  {{ proposal?.job.location_description }}
                </p>
              </div>
            </div>
          </div>

          <div
            v-if="
              job?.customer_propertie_associate.length > 0 &&
                job?.job_type == 'multi_site'
            "
            class="skills-part"
          >
            <p class="skill-title mb-3">
              Properties :
            </p>
            <div class="table-responsive mt-3">
              <table class="table">
                <head-table :list="thead" />
                <tbody>
                  <tr
                    v-for="(item, index) in job?.customer_propertie_associate"
                    :key="index"
                  >
                    <td>
                      {{ item.customer_propertie_associate.company }}
                    </td>
                    <td class="text-wrap">
                      {{
                        item.customer_propertie_associate.location_description
                      }}
                    </td>
                    <td
                      v-for="(ite, i) in item.items"
                      :key="i"
                    >
                      {{ formatPrice(ite.price) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="skills-part">
            <p class="skill-title">
              Skills :
            </p>

            <div class="auto-flex">
              <span
                v-for="skill in proposal?.job.skills"
                :key="skill.id"
                class="type-job"
              >
                {{ skill.name }}</span>
            </div>
          </div>

          <div
            v-if="proposal?.amount"
            class="skills-part"
          >
            <div class="d-md-flex justify-content-between mt-3">
              <p class="skill-title m-0">
                Your proposed terms :
              </p>

              <!-- <router-link
                v-if="
                  ![jobProposalStatus.ACCEPTED || jobProposalStatus.REJECTED].includes(proposal.status)
                "
                class="btn-primary"
                :to="{
                  name: 'ProviderEditProposal',
                  params: {
                    id: proposal.job.id,
                    proposalId: proposal.id,
                  },
                }"
              >
                Update proposal
              </router-link> -->
            </div>

            <div class="ms-3">
              <div class="mt-3">
                <div class="d-flex">
                  <p class="label">
                    Proposed Bid :&nbsp;
                  </p>
                  <p>{{ formatPrice(proposal.amount) }}</p>
                </div>
              </div>

              <p class="desk">
                This includes all milestones, and is the amount your client will
                see.
              </p>

              <div
                v-if="proposal.work_start_date"
                class="mt-3"
              >
                <div class="d-flex">
                  <p class="label">
                    Work Start Date :&nbsp;
                  </p>

                  <p>
                    {{ formatDate(proposal.work_start_date, "MMMM Do, YYYY") }}
                  </p>
                </div>
              </div>

              <div
                v-if="proposal.work_end_date"
                class="mt-3"
              >
                <div class="d-flex">
                  <p class="label">
                    Work End Date :&nbsp;
                  </p>
                  <p>
                    {{ formatDate(proposal.work_end_date, "MMMM Do, YYYY") }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="proposal.file"
            class="skills-part"
          >
            <p class="skill-title mt-3">
              Your document (CV, Insurance, COI, etc)
            </p>

            <div class="document mb-2 ms-3 col-lg-6">
              <a
                :href="proposal.file.url"
                target="_target"
              >
                <img
                  v-if="proposal.file.type === mediaTypesConstants.IMAGE"
                  :src="icons.imageUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                <img
                  v-else
                  :src="icons.documentUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                {{ getFileName(proposal.file.original_name) }}
              </a>
            </div>
          </div>

          <div
            v-if="proposal?.message"
            class="skills-part"
          >
            <p class="skill-title">
              Cover letter :
            </p>

            <p class="category-name ms-3">
              {{ proposal.message }}
            </p>
          </div>

          <div
            v-if="proposal?.sqresponse?.length > 0"
            class="skills-part"
          >
            <p class="skill-title">
              Screening Questions :
            </p>

            <ol class="questions ps-4">
              <li
                v-for="screeningQuestion in proposal?.sqresponse"
                :key="screeningQuestion.id"
                class="question pl-0 mb-3"
              >
                {{ screeningQuestion.question?.name }}

                <p
                  v-if="
                    screeningQuestion.question.type ===
                      jobScreeningQuestionTypes.SHORT ||
                      screeningQuestion.question.type ===
                      jobScreeningQuestionTypes.LONG
                  "
                >
                  {{ screeningQuestion.response }}
                </p>

                <ul v-else>
                  <li
                    v-for="selectedResponse in screeningQuestion.response_selected"
                    :key="selectedResponse.id"
                  >
                    {{ selectedResponse.response }}
                  </li>
                </ul>
              </li>
            </ol>
          </div>

          <div
            v-if="
              proposal.status === jobProposalStatus.ACCEPTED &&
                !fetchingProposalRoomId &&
                proposalRoomId
            "
            class="interview skills-part"
            :class="{
              'filter-blur': (includeService('communication') && includePlan('can_access_communication')),
            }"
          >
            <p class="skill-title">
              Project communication :
            </p>
            <template
              v-if="!(includeService('communication') && includePlan('can_access_communication'))"
            >
              <interview-chat
                :participants="participants"
                :can-init-chat="false"
                :provider="proposal.owner"
                :proposal-room-id="proposalRoomId"
                :customer="proposal.job?.owner"
                :proposal-id="$route.params.proposalId"
                :send-emojis="false"
              />
            </template>
          </div>
        </div>
      </section>
    </template>

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :title="modalConfigs.title"
      :ok-button-title="modalConfigs.confirmTitle"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </div>
</template>

<script>
import { nextTick } from "vue";
import DOMPurify from "dompurify";

import heartVectorIcon from "@/assets/img/icons/heart.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";

import api from "@/services/api";

import FileUtils from "@/utils/file";
import JobHelper from "@/utils/job";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";
import {
  JobScreeningQuestionTypes,
  JobProposalStatus,
  JobBudgetTypes,
  JobStatus,
} from "@/configs/constants/jobs";
import { GlobalEvents, MediaTypesConstants } from "@/configs/constants";
import { ProviderStatus } from "@/configs/constants/users";

import ConfirmModal from "@/components/ConfirmModal.vue";
import InterviewChat from "@/components/InterviewChat";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import HeadTable from "@/components/HeadTable.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderProposalsDetailsPage",

  components: {
    ConfirmModal,
    LoaderSpinner,
    InterviewChat,
    HeadTable,
    AlerteDanger,
  },

  data: () => ({
    previousRoute: null,
    icons: {
      heartVectorIcon,
      documentUploadedIcon,
      imageUploadedIcon,
    },
    loading: false,
    proposal: null,
    job: null,
    thead: ["Name", "Address"],
    actions: {
      ADD_TO_FAVORITES: "add-to-favorites",
      REMOVE_FROM_FAVORITES: "remove-from-favorites",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      title: null,
      showConfirmModal: false,
    },
    addingToFavorites: false,
    removingFromFavorites: false,
    showCustomerDetails: true,
    isJobFavorite: false,
    proposalRoomId: null,
    participants: [],
    fetchingProposalRoomId: false,
    errors: {
      notFoundErrorMessage: null,
      serverSideErrorMessage: null,
    },
    properties: [],
  }),

  computed: {
    jobScreeningQuestionTypes() {
      return JobScreeningQuestionTypes;
    },

    jobProposalStatus() {
      return JobProposalStatus;
    },

    jobBudgetTypes() {
      return JobBudgetTypes;
    },

    jobStatus() {
      return JobStatus;
    },

    currentProvider() {
      return this.$store.getters["user/user"];
    },

    sanitizedDescription() {
      return DOMPurify.sanitize(this.proposal.job?.description ?? "");
    },

    canApply() {
      return [ProviderStatus.OK, ProviderStatus.ACTIVE].includes(
        this.currentProvider?.status
      );
    },

    previousPage() {
      return window.history.state.back || window.history.state.forward;
    },

    mediaTypesConstants() {
      return MediaTypesConstants;
    },

    canCreateSubcontract() {
      return (
        this.proposal?.status == this.jobProposalStatus.ACCEPTED &&
        this.proposal?.job.status === this.jobStatus.ONGOING
      );
    },

    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },
  },

  mounted() {
    this.fetchBid();
    this.fetchRoomByProposalId();

    this.$emitter.on(GlobalEvents.chat.CHAT_MESSAGE_RECEIVED, (_) => {
      if (!this.proposalRoomId) {
        this.fetchRoomByProposalId();
      }
    });

    this.$emitter.on(
      GlobalEvents.job.PROPOSAL_RESPONSE,
      ({ status, proposalId }) => {
        if (this.proposal?.id === proposalId) {
          this.proposal.status = status;
        }
      }
    );

    /**
     * TODO
     * Update Job Status using websocket here
     */

    if ((this.includeService('communication') && this.includePlan('can_access_communication'))) {
      this.Update("communication");
    }
  },

  methods: {
    ...FileUtils,
    ...JobHelper,
    ...DateHelpers,
    ...PriceHelpers,

    includeService(code) {
      let service = this.currentPlanServices?.find(el=> el.code == code);
      if (service == undefined) {
        return true;
      } else {
        return false
      }
    },
    includePlan(key) {
      if (this.currentPlan && this.currentPlan[key] == null) {
        return true;
      } else {
        return false
      }
    },
    rollback() {
      this.$router.back();
    },

    gotoToDoList() {
      this.$router.push({
        name: "ProviderToDoList",
        params: { id: this.$route.params.id },
      });
    },

    async fetchRoomByProposalId() {
      try {
        this.fetchingProposalRoomId = true;

        const response = await api.fetchRoomByProposalId(
          this.$route.params.proposalId
        );
        this.proposalRoomId = response.data.data?.id || "";
        this.participants = response.data.data?.participants || [];
      } catch (_) {
      } finally {
        this.fetchingProposalRoomId = false;
      }
    },

    async fetchBid() {
      try {
        this.loading = true;
        const response = await api.fetchBid(this.$route.params.proposalId);
        response.data?.items.forEach((element) => {
          if (this.properties.includes(element.property.id) == false) {
            this.properties.push(element.property.id);
          }
        });
        this.proposal = response.data;
        this.isJobFavorite = this.job?.provider_favorite;
        this.fetchJob();
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchJob() {
      try {
        this.loadingJob = true;
        const response = await api.fetchJob(this.$route.params.id);
        this.thead = [
          ...this.thead,
          ...response.data.customer_propertie_associate[0]?.items.map(
            (el) => el.name
          ),
        ];
        this.job = {
          ...response.data,
          customer_propertie_associate:
            response.data.customer_propertie_associate
              .filter((el) =>
                this.properties.includes(el.customer_propertie_associate.id)
              )
              .map((el1) => ({
                ...el1,
                items: el1.items.map((el2) => ({
                  ...el2,
                  price: this.proposal.items.find(
                    (el3) => el3.item.id == el2.id
                  ).price,
                })),
              })),
        };
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loadingJob = false;
      }
    },

    handleToggleFavorite() {
      if (this.isJobFavorite) {
        this.Update(this.actions.REMOVE_FROM_FAVORITES);
      } else {
        this.Update(this.actions.ADD_TO_FAVORITES);
      }
    },

    Update(action) {
      switch (action) {
        case this.actions.ADD_TO_FAVORITES:
          this.modalConfigs.confirmTitle = "Add";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to add this job to your favorites ?";
          break;

        case this.actions.REMOVE_FROM_FAVORITES:
          this.modalConfigs.confirmTitle = "Remove";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to remove this job from your favorites ?";
          break;

        default:
          this.modalConfigs.confirmTitle = "Upgrade";
          this.modalConfigs.title = "Acces Restricted";
          this.modalConfigs.confirmMessage =
            "If you have a limited access to some features on this page. Please upgrade your account.";
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        // console.log({ confirmModal: this.$refs.confirmModal });
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.ADD_TO_FAVORITES:
          this.addJobToFavorites();
          break;

        case this.actions.REMOVE_FROM_FAVORITES:
          this.removeJobFromFavorites();
          break;

        default:
          this.$router.push({ name: "ProviderSubscription" });
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.title = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    async addJobToFavorites() {
      try {
        this.addingToFavorites = true;
        this.clearErrorsMessages();

        await api.addJobToFavorites({
          job: this.job.id,
        });
        this.isJobFavorite = true;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.addingToFavorites = false;
      }
    },

    async removeJobFromFavorites() {
      try {
        this.removingFromFavorites = true;
        this.clearErrorsMessages();

        await api.removeJobFromFavorites({
          job: this.job.id,
        });
        this.isJobFavorite = false;
        this.$emitter.emit(
          GlobalEvents.JOB_REMOVED_FROM_FAVORITES,
          this.job.id
        );
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.removingFromFavorites = false;
      }
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            this.errors.notFoundErrorMessage = errorMessages.PROPOSAL_NOT_FOUND;
            break;

          default:
            this.errors.serverSideErrorMessage =
              errorMessages.AN_ERROR_HAS_OCCURED;
            break;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.description {
  border-right: 1px solid #e5e8eb;
}
.about {
  font-weight: 600;
  font-size: 1.125rem;
  color: #209516;
}
.history {
  font-weight: 600;
  font-size: 1rem;
}
.detail {
  border-bottom: 1px solid #e5e8eb;
}
.label {
  color: #209516;
  font-size: 1rem;
}
.desk {
  color: #a7acb1;
  font-size: 0.875rem;
}

.proposal-details-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (max-width: 645px) {
  .proposal-details-actions.has-sub-job {
    display: block;
  }
  .proposal-details-actions.has-sub-job div:first-child {
    margin-bottom: 1rem;
  }
}
</style>
