<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h3>{{ title }}</h3>
        </div>

        <div class="modal-body text-center">
          <p>{{ message }}</p>
        </div>

        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn-outline"
            @click="closeModal"
          >
            {{ closeButtonTitle ? closeButtonTitle : "Close" }}
          </button>

          <button
            type="button"
            class="btn-primary px-4"
            @click="confirm"
          >
            {{ okButtonTitle }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },

    message: {
      type: String,
      required: false,
      default: null,
    },

    okButtonTitle: {
      type: String,
      required: false,
      default: null,
    },
    closeButtonTitle: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => ({
    showModal: false,
    fadeModal: false,
  }),

  methods: {
    confirm() {
      this.$emit("onConfirm");
      this.closeModal();
    },

    openModal() {
      this.showModal = true;
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      this.fadeModal = false;
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },
  },
};
</script>