export default {
  ACCOUNT_DELETED_OR_SUSPENDED:
    "Your account has been suspended or you may have delete it.",
  INVALID_CREDENTIALS: "Incorrect email or password.",
  NOT_AUTHORIZED_TO_AUTHENTICATE: "You are not allow to authenticate here.",
  AN_ERROR_HAS_OCCURED: "An error has occured. Please try later.",
  ESTIMATE_NOT_FOUND: "The estimate you are looking for doesn't exist.",
  JOB_NOT_FOUND: "The job you are looking for doesn't exist.",
  CANNOT_EDIT_JOB_YOU_DONT_OWN: "You cannot edit this job since you are not its creator.",
  CANNOT_ACCESS_JOB_YOU_DONT_OWN: "You cannot access a job that you don't own.",
  CREDIT_CARD_NOT_FOUND: "The credit card you are looking for doesn't exist.",
  PROPOSAL_NOT_FOUND: "The proposal you are looking for don't exist.",
  PROVIDER_ALREADY_INVITED: "This provider is already invited",
  PROVIDER_CANNOT_APPLY: "You cannot apply to this job and anymore",
  AN_ERROR_HAS_SCRENNING_QUESTION: "Please answer the question",
  CANNOT_ACCESS_ESTIMATE: "You cannot access this estimate.",
  INVOICE_NOT_FOUND: "The invoice you are looking for doesn't exist.",
  CANNOT_EDIT_INVOICE_YOU_DONT_OWN: "You cannot edit this invoice since you are not its creator.",
};