<template>
  <div>
    <list-header
      title="Client Portfolio"
      :add="true"
      :hide="(includeService('crm') && includePlan('can_access_crm'))"
      @create="create"
    />

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table
            class="table"
            :class="{'filter-blur': (includeService('crm') && includePlan('can_access_crm'))}"
          >
            <head-table
              :list="thead"
              :error="error"
              :hide="(includeService('crm') && includePlan('can_access_crm'))"
            />

            <tbody v-if="!fetching">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td class="text-primary">
                  <span
                    class="cursor-pointer text-primary"
                    @click="detail(item)"
                  >{{ item.company_name }}</span>
                </td>
                <td>
                  {{ item.full_name }}
                </td>
                <td>
                  {{ item.main_propriety?.location_description }}
                </td>
                <td
                  :class="{'filter-blur': (includeService('communication') && includePlan('can_access_communication'))}"
                >
                  <span
                    class="cursor-pointer"
                    @click="(includeService('communication') && includePlan('can_access_communication')) ? false : choose(item, 'call')"
                  >
                    <submission-spinner
                      v-if="makingCall && selectedClientId === item.id"
                      :color="'#209516'"
                    />
                    <img
                      v-else
                      :src="callIcon"
                      class="me-3"
                    >
                    {{ (includeService('communication') && includePlan('can_access_communication')) ? 'Locked' : item.phone }}
                  </span>
                </td>
                <td>
                  {{ item.primary_contact?.full_name }}
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>
                <td>
                  <submission-spinner
                    v-if="deletingClient && selectedClientId === item.id"
                    :color="'#209516'"
                  />
                  <option-action
                    v-else
                    :status="item.status"
                    :options="options"
                    @update:options="choose(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetching" />

          <app-pagination
            v-if="totalPages > 1 && (includeService('crm') && includePlan('can_access_crm'))"
            :align-end="true"
            :current-page="currentPage"
            :has-previous-page="hasPreviousPage"
            :previous-page="currentPage - 1 || 1"
            :has-next-page="hasNextPage"
            :next-page="currentPage + 1"
            :per-page="itemsPerPage"
            :total-elements="itemsCount"
            :total-pages="totalPages"
            @onGotoPage="fetchMyClients({ page: $event })"
            @onPrev="fetchMyClients({ page: currentPage - 1 || 1 })"
            @onNext="fetchMyClients({ page: currentPage + 1 })"
          />
        </div>
      </div>
    </div>

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </div>
</template>

<script>
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import callIcon from "@/assets/img/icons/CallVectorGreen.svg";

import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import OptionAction from"@/components/OptionAction.vue";
import AppPagination from "@/components/AppPagination.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";
import ListMixin from "@/mixins/list";

import statusToUI from "@/utils/statusFormate";
import { DateHelpers } from "@/utils/date-helpers";

export default {
  name: "ProviderClientPortfolioList",

  components: {
    ListHeader,
    HeadTable,
    OptionAction,
    AppPagination,
    LoaderSpinner,
    ConfirmModal,
    SubmissionSpinner,
  },

  mixins: [ListMixin(api.fetchMyClient, true)],

  data: () => ({
    callIcon,
    thead: [
      "Company",
      "Name",
      "Address",
      "Phone",
      "Primary Contact",
      "Create At",
      "",
    ],
    options: [
      {
        title: "Details",
        value: "details",
      },
      {
        title: "Edit",
        value: "edit",
      },
      {
        title: "Delete",
        value: "delete",
      },
    ],
    headerOptions: {
      title: "Status",
      list: [
        {
          title: "Status",
        },
        {
          title: "Pending",
          value: "pending",
        },
        {
          title: "Denied",
          value: "denied",
        },
        {
          title: "Canceled",
          value: "canceled",
        },
      ],
    },
    fetching: false,
    makingCall: false,
    deletingClient: false,
    selectedClientId: null,
    selectedClientPhone: null,
    errors: {
      clientErrorMessage: null,
      serverSideErrorMessage: null,
    },
    error: null,
    actions: {
      MAKE_CALL: "make-call",
      DELETE_CLIENT: "delete-client",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
  }),

  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    },
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },
  },
  watch: {
    listData(val) {
      this.fetching = false;
    },
  },

  mounted() {
    if (this.includeService('crm') && this.includePlan('can_access_crm')) {
      
    } else {
      this.fetchMyClients({ page: Number(this.$route.query.page) || 1 })
    }
  },

  methods: {
    ...DateHelpers,
    ...statusToUI,

    includeService(code) {
      let service = this.currentPlanServices?.find(el=> el.code == code);
      if (service == undefined) {
        return true;
      } else {
        return false
      }
    },
    includePlan(key) {
      if (this.currentPlan && this.currentPlan[key] == null) {
        return true;
      } else {
        return false
      }
    },
    fetchMyClients(params) {
      this.fetching = true;
      this.updateListFilters({
        ...params,
      });
    },

    create() {
      this.$router.push({
        name: "ClientPortfolioInfoCreate",
      });
    },

    detail(item) {
      this.$router.push({
        name: "ClientPortfolioDetail",
        params: { id: item.id, tag: "info" },
      });
    },

    choose(item, event) {
      switch (event) {
        case "details":
          this.detail(item);
          break;

        case "edit":
          this.$router.push({
            name: "ClientPortfolioInfoEdit",
            params: { id: item.id },
          });
          break;

        case "call":
          this.selectedClientId = item.id;
          this.selectedClientPhone = item.phone;
          this.confirmClaim(this.actions.MAKE_CALL);
          break;

        case "delete":
          this.selectedClientId = item.id;
          this.confirmClaim(this.actions.DELETE_CLIENT);
          break;
      }
    },

    confirmClaim(action) {
      switch (action) {
        case this.actions.MAKE_CALL:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            `Are you sure you want to call ${this.selectedClientPhone} ?`;
          break;

        case this.actions.DELETE_CLIENT:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            'Are you sure you want to delete this client ?';
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.MAKE_CALL:
          this.makeCall();
          break;

        case this.actions.DELETE_CLIENT:
          this.deleteClientPropriety();
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    deleteClientPropriety(id) {
      this.deletingClient = true;

      api
        .deleteClientPropriety(this.selectedClientId)
        .then((_) => {
          this.selectedClientId = null;
          this.fetchMyClients({
            page: Number(this.$route.query.page) || this.currentPage,
          });
          toast.success(`Client successfully deleted.`);
        })
        .catch((error) => {
          this.errorsHandler(error)
        })
        .then(() => {
          this.deletingClient = false;
        });
    },

    makeCall() {
      this.makingCall = true;
      this.error = null;

      this.clearErrorsMessages();
      api.makeCall({"client_id" : this.selectedClientId})
        .then((_) => {
          this.selectedClientId = null
          this.selectedClientPhone = null
        })
        .catch((error) => {
          this.errorsHandler(error)
        })
        .then(() => {
          this.makingCall = false;
        });
    },
    
    clearErrorsMessages() {
      this.errors.clientErrorMessage = null;
      this.errors.serverSideErrorMessage = null;
    },

    errorHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors.clientErrorMessage = err.response.data[error];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.error =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.error = err.response.data[error]
          }
        } else {
          this.error =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.error = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
