<template>
  <div class="post-job row">
    <section class="content">
      <div class="page-header">
        <h3>Create Client</h3>
      </div>

      <div class="page-content">
        <form @submit.prevent="save">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <div class="row">
            <div class="col-md-12">
              <p class="title mb-3">
                Client details
              </p>

              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Company name <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.company_name"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Write company name..."
                    :class="{
                      'is-invalid':
                        v$.datasend.company_name.$invalid &&
                        v$.datasend.company_name.$dirty,
                    }"
                    @blur="v$.datasend.company_name.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.company_name.$invalid &&
                        v$.datasend.company_name.$dirty) ||
                        errors.company_name
                    "
                  >
                    <small
                      v-if="v$.datasend.company_name.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="v$.datasend.company_name.minLength.$invalid"
                      class="invalid-feedback"
                    >
                      The minimum size for this field is 3 characters
                    </small>

                    <small
                      v-else-if="errors.company_name"
                      class="invalid-feedback"
                    >
                      {{ errors.company_name }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Client name <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.full_name"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Write client name..."
                    :class="{
                      'is-invalid':
                        v$.datasend.full_name.$invalid &&
                        v$.datasend.full_name.$dirty,
                    }"
                    @blur="v$.datasend.full_name.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.full_name.$invalid &&
                        v$.datasend.full_name.$dirty) ||
                        errors.full_name
                    "
                  >
                    <small
                      v-if="v$.datasend.full_name.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="v$.datasend.full_name.minLength.$invalid"
                      class="invalid-feedback"
                    >
                      The minimum size for this field is 3 characters
                    </small>

                    <small
                      v-else-if="errors.full_name"
                      class="invalid-feedback"
                    >
                      {{ errors.full_name }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Phone No. <span class="text-danger">*</span>
                  </label>
                  <vue-tel-input
                    v-model="datasend.phone"
                    class="form-control pl-3 shadow-none"
                    :class="{
                      'is-invalid':
                        (v$.datasend.phone.$invalid &&
                          v$.datasend.phone.$dirty) ||
                        errors.phone,
                    }"
                    @blur="v$.datasend.phone.$touch"
                  />
                  <template
                    v-if="
                      (v$.datasend.phone.$invalid &&
                        v$.datasend.phone.$dirty) ||
                        errors.phone
                    "
                  >
                    <small
                      v-if="v$.datasend.phone.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.phone"
                      class="invalid-feedback"
                    >
                      {{ errors.phone }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    E-mail <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.email"
                    type="email"
                    class="form-control pl-3 shadow-none"
                    placeholder="Write e-mail..."
                    :class="{
                      'is-invalid':
                        v$.datasend.email.$invalid && v$.datasend.email.$dirty,
                    }"
                    @blur="v$.datasend.email.$touch"
                    @input="
                      datasend.email = formatEmailAddress($event.target.value)
                    "
                  >
                  <template
                    v-if="
                      (v$.datasend.email.$invalid &&
                        v$.datasend.email.$dirty) ||
                        errors.email
                    "
                  >
                    <small
                      v-if="v$.datasend.email.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.email"
                      class="invalid-feedback"
                    >
                      {{ errors.email }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Country <span class="text-danger">*</span>
                  </label>

                  <select
                    v-model="datasend.main_propriety.country"
                    class="form-select p-3 shadow-none"
                    :class="{
                      'is-invalid':
                        v$.datasend.main_propriety.country.$invalid &&
                        v$.datasend.main_propriety.country.$dirty,
                    }"
                    @blur="v$.datasend.main_propriety.country.$touch"
                  >
                    <option
                      v-for="country in allCountries"
                      :key="country.code"
                      :value="country.code"
                    >
                      {{ country.label }}
                    </option>
                  </select>
                  <template
                    v-if="
                      (v$.datasend.main_propriety.country.$invalid &&
                        v$.datasend.main_propriety.country.$dirty) ||
                        errors.country
                    "
                  >
                    <small
                      v-if="
                        v$.datasend.main_propriety.country.required.$invalid
                      "
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.main_propriety.country"
                      class="invalid-feedback"
                    >
                      {{ errors.main_propriety.country }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Address <span class="text-danger">*</span>
                  </label>
                  <GMapAutocomplete
                    :value="datasend.main_propriety.location_description"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Write address..."
                    :class="{
                      'is-invalid':
                        (v$.datasend.main_propriety.location_description
                          .$invalid &&
                          v$.datasend.main_propriety.location_description
                            .$dirty) ||
                        errors.main_propriety.location_description,
                    }"
                    @blur="validateAddress"
                    @change="handleAddressChange"
                    @place_changed="setAddress"
                  />
                  <template
                    v-if="
                      (v$.datasend.main_propriety.location_description
                        .$invalid &&
                        v$.datasend.main_propriety.location_description
                          .$dirty) ||
                        errors.main_propriety.location_description
                    "
                  >
                    <small
                      v-if="
                        v$.datasend.main_propriety.location_description.required
                          .$invalid
                      "
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.main_propriety.location_description"
                      class="invalid-feedback"
                    >
                      {{ errors.main_propriety.location_description }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    City <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.main_propriety.city"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Write city..."
                    :class="{
                      'is-invalid':
                        v$.datasend.main_propriety.city.$invalid &&
                        v$.datasend.main_propriety.city.$dirty,
                    }"
                    @blur="v$.datasend.main_propriety.city.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.main_propriety.city.$invalid &&
                        v$.datasend.main_propriety.city.$dirty) ||
                        errors.main_propriety.city
                    "
                  >
                    <small
                      v-if="v$.datasend.main_propriety.city.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.main_propriety.city"
                      class="invalid-feedback"
                    >
                      {{ errors.main_propriety.city }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    State <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.main_propriety.state"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Write state..."
                    :class="{
                      'is-invalid':
                        v$.datasend.main_propriety.state.$invalid &&
                        v$.datasend.main_propriety.state.$dirty,
                    }"
                    @blur="v$.datasend.main_propriety.state.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.main_propriety.state.$invalid &&
                        v$.datasend.main_propriety.state.$dirty) ||
                        errors.main_propriety.state
                    "
                  >
                    <small
                      v-if="v$.datasend.main_propriety.state.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.main_propriety.state"
                      class="invalid-feedback"
                    >
                      {{ errors.main_propriety.state }}
                    </small>
                  </template>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">
                    Zip code <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.main_propriety.zip"
                    type="text"
                    class="form-control pl-3 shadow-none"
                    placeholder="Write zip code..."
                    :class="{
                      'is-invalid':
                        v$.datasend.main_propriety.zip.$invalid &&
                        v$.datasend.main_propriety.zip.$dirty,
                    }"
                    @blur="v$.datasend.main_propriety.zip.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.main_propriety.zip.$invalid &&
                        v$.datasend.main_propriety.zip.$dirty) ||
                        errors.main_propriety.zip
                    "
                  >
                    <small
                      v-if="v$.datasend.main_propriety.zip.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.main_propriety.zip"
                      class="invalid-feedback"
                    >
                      {{ errors.main_propriety.zip }}
                    </small>
                  </template>
                </div>
              </div>
            </div>

            <div class="col-md-12 mb-3">
              <div class="col-12 py-3 mb-3">
                <div class="row text-start">
                  <p class="col-6 title">
                    Primary Contact
                  </p>
                  <div class="col-6 mt-3 text-end" />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6 mb-3">
                  <label class="form-label">
                    Contact name <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.primary_contact.full_name"
                    type="text"
                    class="form-control p-3 shadow-none"
                    placeholder="Write contact name..."
                    :class="{
                      'is-invalid':
                        v$.datasend.primary_contact.full_name.$invalid &&
                        v$.datasend.primary_contact.full_name.$dirty,
                    }"
                    @blur="v$.datasend.primary_contact.full_name.$touch"
                  >
                  <template
                    v-if="
                      (v$.datasend.primary_contact.full_name.$invalid &&
                        v$.datasend.primary_contact.full_name.$dirty) ||
                        errors.primary_contact.full_name
                    "
                  >
                    <small
                      v-if="
                        v$.datasend.primary_contact.full_name.required.$invalid
                      "
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.primary_contact.full_name"
                      class="invalid-feedback"
                    >
                      {{ errors.primary_contact.full_name }}
                    </small>
                  </template>
                </div>

                <div class="col-md-6 mb-3">
                  <label class="form-label">
                    Phone <span class="text-danger">*</span>
                  </label>
                  <vue-tel-input
                    v-model="datasend.primary_contact.phone"
                    class="form-control p-3 shadow-none"
                    :class="{
                      'is-invalid':
                        v$.datasend.primary_contact.phone.$invalid &&
                        v$.datasend.primary_contact.phone.$dirty,
                    }"
                    @blur="v$.datasend.primary_contact.phone.$touch"
                  />
                  <template
                    v-if="
                      (v$.datasend.primary_contact.phone.$invalid &&
                        v$.datasend.primary_contact.phone.$dirty) ||
                        errors.primary_contact.phone
                    "
                  >
                    <small
                      v-if="v$.datasend.primary_contact.phone.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.primary_contact.phone"
                      class="invalid-feedback"
                    >
                      {{ errors.primary_contact.phone }}
                    </small>
                  </template>
                </div>

                <div class="col-md-6 mb-3">
                  <label class="form-label">
                    E-mail <span class="text-danger">*</span>
                  </label>
                  <input
                    v-model="datasend.primary_contact.email"
                    type="email"
                    class="form-control p-3 shadow-none"
                    placeholder="Write e-mail..."
                    :class="{
                      'is-invalid':
                        v$.datasend.primary_contact.email.$invalid &&
                        v$.datasend.primary_contact.email.$dirty,
                    }"
                    @blur="v$.datasend.primary_contact.email.$touch"
                    @input="
                      datasend.primary_contact.email = formatEmailAddress(
                        $event.target.value
                      )
                    "
                  >
                  <template
                    v-if="
                      (v$.datasend.primary_contact.email.$invalid &&
                        v$.datasend.primary_contact.email.$dirty) ||
                        errors.primary_contact.email
                    "
                  >
                    <small
                      v-if="v$.datasend.primary_contact.email.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.primary_contact.email"
                      class="invalid-feedback"
                    >
                      {{ errors.primary_contact.email }}
                    </small>
                  </template>
                </div>

                <div class="col-md-6 mb-3">
                  <label class="form-label">
                    Contact type <span class="text-danger">*</span>
                  </label>
                  <select
                    v-model="datasend.primary_contact.contact_type"
                    class="form-select p-3 shadow-none"
                    :class="{
                      'is-invalid':
                        v$.datasend.primary_contact.contact_type.$invalid &&
                        v$.datasend.primary_contact.contact_type.$dirty,
                    }"
                    @blur="v$.datasend.primary_contact.contact_type.$touch"
                  >
                    <option
                      value="null"
                      disabled
                    >
                      Select a contact type
                    </option>
                    <option
                      v-for="(type, i) in contact_type"
                      :key="i"
                      :value="type"
                    >
                      {{ type }}
                    </option>
                  </select>

                  <template
                    v-if="
                      (v$.datasend.primary_contact.contact_type.$invalid &&
                        v$.datasend.primary_contact.contact_type.$dirty) ||
                        errors.primary_contact.contact_type
                    "
                  >
                    <small
                      v-if="
                        v$.datasend.primary_contact.contact_type.required
                          .$invalid
                      "
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.primary_contact.contact_type"
                      class="invalid-feedback"
                    >
                      {{ errors.primary_contact.contact_type }}
                    </small>
                  </template>
                </div>

                <div class="col-md-12">
                  <label class="form-label">
                    Note <span class="text-danger">*</span>
                  </label>
                  <textarea
                    v-model="datasend.primary_contact.note"
                    cols="30"
                    rows="4"
                    class="form-control p-3 shadow-none"
                    placeholder="Write note..."
                    :class="{
                      'is-invalid':
                        v$.datasend.primary_contact.note.$invalid &&
                        v$.datasend.primary_contact.note.$dirty,
                    }"
                    @blur="v$.datasend.primary_contact.note.$touch"
                  />
                  <template
                    v-if="
                      (v$.datasend.primary_contact.note.$invalid &&
                        v$.datasend.primary_contact.note.$dirty) ||
                        errors.primary_contact.note
                    "
                  >
                    <small
                      v-if="v$.datasend.primary_contact.note.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </small>

                    <small
                      v-else-if="errors.primary_contact.note"
                      class="invalid-feedback"
                    >
                      {{ errors.primary_contact.note }}
                    </small>
                  </template>
                </div>
              </div>
            </div>

            <div class="col-md-12 mb-3">
              <div class="col-12 py-3 mb-3">
                <div class="row text-start">
                  <p class="col-6 title">
                    Extra contacts
                  </p>
                  <div class="col-6 mt-3 text-end">
                    <button
                      class="btn-primary px-5"
                      type="button"
                      @click="addContact"
                    >
                      Add contact
                    </button>
                  </div>
                </div>
              </div>

              <div
                v-for="(contact, index) in datasend.additional_contacts"
                :key="index"
                class="row mb-3"
              >
                <div class="col-12 my-3">
                  <div class="row text-start">
                    <label class="col-md-10 pl-3">
                      {{ index + 1 }}. Contact</label>

                    <div class="col-md-2 text-end">
                      <img
                        :src="croixVectorIcon"
                        class="cursor-pointer"
                        @click="removeContact(contact)"
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mb-3">
                  <label class="form-label">Contact name</label>
                  <input
                    v-model="contact.full_name"
                    type="text"
                    class="form-control p-3 shadow-none"
                    placeholder="Write contact name..."
                  >
                </div>

                <div class="col-md-6 mb-3">
                  <label class="form-label">Phone</label>
                  <vue-tel-input
                    v-model="contact.phone"
                    class="form-control p-3 shadow-none"
                  />
                </div>

                <div class="col-md-6 mb-3">
                  <label class="form-label">E-mail</label>
                  <input
                    v-model="contact.email"
                    type="email"
                    class="form-control p-3 shadow-none"
                    placeholder="Write e-mail..."
                    @input="
                      contact.email = formatEmailAddress($event.target.value)
                    "
                  >
                </div>

                <div class="col-md-6 mb-3">
                  <label class="form-label">Contact type</label>
                  <select
                    v-model="contact.contact_type"
                    class="form-select p-3 shadow-none"
                  >
                    <option
                      value="null"
                      disabled
                    >
                      Select a contact type
                    </option>
                    <option
                      v-for="(type, i) in contact_type"
                      :key="i"
                      :value="type"
                    >
                      {{ type }}
                    </option>
                  </select>
                </div>

                <div class="col-md-12">
                  <label class="form-label">Note</label>
                  <textarea
                    v-model="contact.note"
                    cols="30"
                    rows="4"
                    class="form-control pl-3"
                    placeholder="Write note..."
                  />
                </div>
              </div>

              <template v-if="errors.additional_contacts">
                <small
                  v-if="errors.additional_contacts"
                  class="invalid-feedback"
                >
                  {{ errors.additional_contacts }}
                </small>
              </template>
            </div>

            <div class="col-md-12 mb-3">
              <div class="col-12 py-3 mb-3">
                <div class="row text-start">
                  <p class="col-6 title">
                    Notes
                  </p>
                  <div class="col-6 mt-3 text-end">
                    <button
                      class="btn-primary px-5"
                      type="button"
                      @click="addNote"
                    >
                      Add note
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-for="(item, index) in datasend.notes"
                :key="index"
                class="mb-3"
              >
                <div class="col-12 my-3">
                  <div class="row text-start">
                    <label class="col-md-10 pl-3"> {{ index + 1 }}. Note</label>

                    <div class="col-md-2 text-end">
                      <img
                        :src="croixVectorIcon"
                        class="cursor-pointer"
                        @click="removeNote(item)"
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <label class="form-label">Note</label>
                  <textarea
                    v-model="item.text"
                    cols="30"
                    rows="4"
                    class="form-control pl-3"
                    placeholder="Write note..."
                  />
                </div>
              </div>
              <template v-if="errors.notes">
                <small
                  v-if="errors.notes"
                  class="invalid-feedback"
                >
                  {{ errors.notes }}
                </small>
              </template>
            </div>

            <div class="row m-0 p-0">
              <div class="col-6 pb-3 text-start">
                <button
                  class="btn-outline"
                  @click="back"
                >
                  Back
                </button>
              </div>

              <div class="col-6 pb-3 text-end">
                <button
                  class="btn-primary"
                  type="submit"
                  :disabled="loading"
                >
                  <submission-spinner
                    v-if="loading"
                    :color="'#209516'"
                  />
                  Create
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import croixVectorIcon from "@/assets/img/icons/poubell-red.svg";
import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

import LocationUtils from "@/utils/location";
import { GeneralHelpers } from "@/utils/general";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";
import { AllCountries } from "@/configs/countries";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderEstimateCreatePage",

  components: {
    SubmissionSpinner,
    AlerteDanger,
  },

  beforeRouteLeave(to, from) {
    if (!this.allCountries[0].code) {
      this.allCountries.shift();
    }
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    listData: [],
    contact_type: [
      "Customer",
      "Employee",
      "Prospect",
      "Vendor",
      "Subcontractor",
      "Facility Manager",
      "Procurement",
      "AP",
      "AR",
    ],
    datasend: {
      full_name: null,
      company_name: null,
      phone: null, //+1 (982) 389 8989
      email: null,
      notes: [],
      additional_contacts: [],
      main_propriety: {
        country: null,
        location_description: null,
        location: {
          type: "Point",
          coordinates: [0, 0],
        },
        zip: null,
        state: null,
        city: null,
      },
      primary_contact: {
        full_name: null,
        phone: null,
        email: null,
        contact_type: null,
        note: null,
      },
    },
    errors: {
      full_name: null,
      company_name: null,
      phone: null,
      email: null,
      notes: null,
      additional_contacts: null,
      main_propriety: {
        country: null,
        location_description: null,
        zip: null,
        state: null,
        city: null,
      },
      primary_contact: {
        full_name: null,
        phone: null,
        email: null,
        contact_type: null,
        note: null,
      },
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    allCountries() {
      return AllCountries;
    },
  },

  validations() {
    return {
      datasend: {
        company_name: {
          required,
          minLength: minLength(3),
        },
        full_name: {
          required,
          minLength: minLength(3),
        },
        phone: {
          required,
        },
        email: {
          required,
        },
        main_propriety: {
          country: {
            required,
          },
          location_description: {
            required,
          },
          city: {
            required,
          },
          state: {
            required,
          },
          zip: {
            required,
          },
        },
        primary_contact: {
          full_name: {
            required,
          },
          phone: {
            required,
          },
          email: {
            required,
          },
          contact_type: {
            required,
          },
          note: {
            required,
          },
        },
      },
    };
  },

  mounted() {
    this.allCountries.unshift({ label: "Select a country", code: null });
  },

  methods: {
    ...LocationUtils,
    ...GeneralHelpers,
    ...validationHelpers,

    back() {
      this.$router.back();
    },

    validateAddress() {
      setTimeout(() => {
        this.v$.datasend.main_propriety.location_description.$touch();
      }, 200);
    },

    handleAddressChange(value) {
      if (!value) {
        this.datasend.main_propriety.location_description = null;
      }
    },

    setAddress(values) {
      if (values) {
        const locationInfo = this.extractLocationInfo(
          values.address_components
        );
        if (locationInfo) {
          this.datasend.location_description = locationInfo.address;
          this.datasend.main_propriety.country = locationInfo.country.shortName;
          this.datasend.main_propriety.state = locationInfo.state;
          this.datasend.main_propriety.city = locationInfo.city;
          this.datasend.main_propriety.zip = locationInfo.pincode;
        }
        const lat = values.geometry?.location?.lat();
        const lng = values.geometry?.location?.lng();
        this.datasend.main_propriety.location = JSON.stringify({
          type: "Point",
          coordinates: [lng, lat],
        });
        this.v$.datasend.main_propriety.location_description.$touch();
      }
    },

    detail(id) {
      this.$router.push({
        name: "ClientPortfolioDetail",
        params: { id: id, tag: "info" },
      });
    },

    save() {
      if (this.loading) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.loading = true;
      this.clearErrorsMessages();

      api
        .createClient(this.datasend)
        .then((response) => {
          this.resetValidation();
          this.detail(response.data.id);
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.loading = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        full_name: null,
        company_name: null,
        phone: null,
        email: null,
        notes: null,
        additional_contacts: null,
        main_propriety: {
          location_description: null,
          zip: null,
          state: null,
          city: null,
        },
        primary_contact: {
          full_name: null,
          phone: null,
          email: null,
          contact_type: null,
          note: null,
        },
        serverSideErrorMessage: null,
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    addContact() {
      this.datasend.additional_contacts.push({
        full_name: null,
        phone: null,
        email: null,
        contact_type: null,
        note: null,
      });
    },

    removeContact(item) {
      this.datasend.additional_contacts =
        this.datasend.additional_contacts.filter((el) => el != item);
    },

    addNote() {
      this.datasend.notes.push({
        text: null,
      });
    },
    removeNote(item) {
      this.datasend.notes = this.datasend.notes.filter((el) => el != item);
    },

    formatNumber(value) {
      let number = value.replace(/\D/g, "");
      number = number.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      props.setPropertyValue(number);
    },

    formatNumberAdd$() {
      if (props.propertyValue != "") {
        let number = props.propertyValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        props.setPropertyValue(number + " $");
      }
    },

    formatNumberRem$() {
      let number = props.propertyValue.replace(/\D/g, "");
      props.setPropertyValue(number);
    },
  },
};
</script>
