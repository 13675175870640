<template>
  <section class="content delete-acccount">
    <div class="page-header">
      <h3 class="page-title">
        Email Verification
      </h3>

      <settings-menu />
    </div>

    <div
      class="page-content col-lg-8 offset-lg-2 py-6 row align-items-center justify-content-center"
    >
      <div class="content-verification text-center">
        <h4
          v-if="!currentProvider?.email_verified"
          class="title-content"
        >
          Account Email Verifiication
        </h4>

        <p class="mb-5">
          Please check the email we sent to <strong>{{ currentProvider?.email }}</strong> to confirm your account. Don't forget to check your spam folder if you don't see the email in your inbox.
        </p>

        <div v-if="currentProvider?.email_verified">
          <h4 class="title-content">
            Successfully verified
          </h4>
        </div>

        <div
          v-else
          class="mt-4"
        >
          <p>
            We sent you an email. Just click on the link inside it to verify
            your email. If you don't see it, you may need to check your spam
            folder.

            <span class="d-block mt-5">Still can't find the email?</span>
          </p>

          <span
            class="send-link mb-3"
            @click="intiateEmailVerificationProcess"
          >
            <submission-spinner
              v-if="submitting"
              :color="'#209516'"
            />
            Send Email Again
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import emailIcon from "@/assets/img/icons/mail-check-line.svg";
import notVerifiedIcon from "@/assets/img/icons/mail-valid.svg";

import api from "@/services/api";
import errorMessages from "@/utils/error-messages";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import SettingsMenu from "@/components/provider/SettingsMenu.vue";
import { GlobalConstants } from "@/configs/constants";

export default {
  name: "ProviderEmailVerificationPage",

  components: {
    SettingsMenu,
    SubmissionSpinner,
  },

  data: () => ({
    icons: {
      emailIcon,
      notVerifiedIcon,
    },
    submitting: false,
    errors: {
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    currentProvider() {
      return this.$store.getters["user/user"];
    },
  },

  created() {
    const allRoutes = this.$router.getRoutes();
    this.redirectLink = `${
      GlobalConstants.BASE_URL +
      allRoutes.find((route) => route.name == "VerifyEmail").path
    }`;
  },

  methods: {
    async intiateEmailVerificationProcess() {
      if (this.submitting) return;
      this.submitting = true;

      const formData = {
        user: this.currentProvider.id,
        redirectLink: this.redirectLink,
      };

      try {
        await api.intiateEmailVerificationProcess(formData);
        this.emailVerified = true;
      } catch (err) {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped>
.page-content {
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.content-verification {
  max-width: 650px;
  padding: 1.5rem 3rem;
  background: #fff;
  box-shadow: 0px 4px 14px 0px #0000000d;
}
.delete-acccount {
  color: rgba(101, 115, 106, 1);
}
.delete-acccount .contact {
  font-size: 1rem;
}
.delete-acccount .contact a {
  text-decoration: underline;
}
.title-content {
  font-weight: 600;
  font-size: 1.75rem;
  color: #209516;
  margin-bottom: 1rem;
}
.img-float {
  height: 4rem;
  max-width: 100px;
  margin-bottom: 1rem;
}

.send-link {
  color: #209516;
  padding: 0.6rem 0.85rem;
  border: 1px solid #e5e8eb;
  display: inline-block;
  cursor: pointer;
  margin-left: 0.8rem;
  box-shadow: 0px 4px 6px 0px #00000008;
}

img.status {
  margin-right: 0.2rem;
  width: 25px;
}
.page-content {
  height: 100%;
}
</style>
