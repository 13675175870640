<template>
  <li
    v-if="data"
    @mousedown="selectItem(data)"
  >
    <div class="d-inline-flex">
      <profile-avatar
        :url="data.provider?.user?.photo?.url"
        :fullname="data?.provider?.user.full_name"
        :color="data?.provider?.user.profile_color"
        :background="data?.provider?.user.profile_background"
      />
      <div class="ms-3">
        <p class="mb-0 full-name">
          {{ data?.provider?.user.full_name }}
        </p>
        <small>{{ data?.provider?.user.email }}</small>
      </div>
    </div>

    <p
      v-if="data.have_invitation"
      class="ms-5  fs-1"
    >
      Already invited
    </p>
  </li>
</template>

<script>
import ProfileAvatar from "@/components/ProfileAvatar.vue";

export default {
  name: "AutocompleteProviderItem",

  components: {
    ProfileAvatar,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  methods: {
    selectItem(data) {
        this.$emit("onSelectItem", data)
    }
  },
};
</script>