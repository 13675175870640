<template>
  <ul 
    v-if="infos.total_pages > 1"
    class="pagination justify-content-end"
  >
    <li 
      class="page-item"
      :class="{'disabled' : index === 1}"
    >
      <button
        class="page-link "
        :class="index === 1 ? 'text-secondary' : 'text-primary'"
        @click="update(index-1)"
      >
        Prev
      </button>
    </li>

    <li 
      v-for="num in infos.total_pages"
      :key="num"
      class="page-item"
      :class="{'disabled' : index === num}"
    >
      <button
        class="page-link"
        :class="index === num ? 'text-secondary' : 'text-primary'"
        @click="update(num)"
      >
        {{ num }}
      </button>
    </li>

    <li 
      class="page-item"
      :class="{'disabled' : index === infos.total_pages}"
    >
      <button
        class="page-link"
        :class="index === infos.total_pages ? 'text-secondary' : 'text-primary'"
        @click="update(index+1)"
      >
        Next
      </button>
    </li>
  </ul>
</template>

<script>

import Option from "@/assets/img/icons/Option.svg";
import statusToUI from '@/utils/statusFormate'
export default {
  name: "PageAction",
  
  props: {
    infos: {
      type: Object,
      required: false,
      default: null,
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data: () => ({
    Option
  }),
  methods: {
    ...statusToUI,

    update(select) {
      this.$emit("update:index", select);
    },
  }
};
</script>

<style scoped>
.pagination .page-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.pagination .page-item:first-child {
  margin-left: 0;
}
.pagination .page-item:last-child {
  margin-right: 0;
}
</style>