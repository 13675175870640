<template>
  <div v-if="detail !== null && action === 'delete' && !loading">
    <div class="modal-body">
      <p>{{ body }}</p>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn-outline"
        @click="returnAction"
      >
        Return
      </button>
      
      <button
        type="button"
        class="btn-primary"
        @click="deleteAction"
      >
        Delete
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    action: {
      type: String,
      required: true,
    },

    body: {
      type: String,
      required: true,
    },
  },

  methods: {
    deleteAction() {
      this.$emit("deleteAction");
    },

    returnAction() {
      this.$emit("returnAction");
    },
  },
};
</script>