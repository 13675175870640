<template>
  <div class="job-list">
    <job-item
      v-for="(job, index) in jobs"
      :key="job.id"
      :job="job"
      :is-last-item="index === jobs.length - 1"
      @isDelete="deleteJob"
      @isDuplicate="duplicateJob"
      @fetchNextPage="onFetchNextPage"
    />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import JobItem from "@/components/customer/jobs/JobItem";

export default {
  name: "CustomerJobsList",

  components: {
    JobItem,
  },

  props: {
    jobs: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    deleteJob(id) {
      if (id) {
        const index = this.jobs.findIndex((elmt) => elmt.id === id);
        if (index !== -1) {
          const jobs = this.jobs;
          jobs.splice(index, 1);
        }
      }
    },
    duplicateJob() {
      toast.success('Duplicate successfully done.')
    },
    onFetchNextPage() {
      this.$emit('fetchNextPage')
    }
  },
};
</script>
