<template>
    <Bar
        id="my-chart-id"
        :options="chartOptions"
        :data="data || chartData"
        class="col-md-12"
    />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
);

export default {
    name: "BarChart",

    components: { Bar },

    props: {
        data: {
            type: Object,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            chartData: {
                labels: ["Jan", "Feb", "Mar"],
                datasets: [
                    {
                        label: "Data One",
                        backgroundColor: "#f87979",
                        data: [40, 20, 12],
                    },
                ],
            },
            chartOptions: {
                responsive: true,
            },
        };
    },
};
</script>
