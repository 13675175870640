<template>
  <div
    id="super-admin-layout"
    :class="{ 'position-fixed': isShow }"
  >
    <super-admin-header
      :toggle-menu="toggleMenu"
      @toggleMenu="toggleMenu = !toggleMenu"
      @toggleMobileMenu="toggleMobileMenu"
    />

    <div class="superadmin-dashboard-layout">
      <div
        class="superadmin-navbar"
        :class="{ 'd-block ': isShow, 'width80px': toggleMenu }"
      >
        <super-admin-menu
          :toggle-menu="toggleMenu"
          @routeUpdated="isShow = false"
        />
      </div>

      <div
        ref="scrollContainer"
        :class="toggleMenu ? 'superadmin-dashboard-content-width' : 'superadmin-dashboard-content'"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import SuperAdminMenu from "@/components/superadmin/Menu";
import SuperAdminHeader from "@/components/superadmin/Header";

export default {
  name: "SuperAdminLayout",

  components: {
    SuperAdminMenu,
    SuperAdminHeader,
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$nextTick(() => {
        vm.$refs.scrollContainer.scrollTop = 0;
      });
    });
  },

  beforeRouteUpdate(to, from, next) {
    this.$nextTick(() => {
        this.$refs.scrollContainer.scrollTop = 0;
    });
    next();
  },

  data: () => ({
    isShow: false,
    toggleMenu: false
  }),

  methods: {
    toggleMobileMenu() {
      this.isShow = !this.isShow;
    },
  },
};
</script>

<style>
@import "@/assets/css/superadmin.css";

</style>
