import api from "@/services/api";
import { JobStatus } from '@/configs/constants/jobs'


export default {
  makeProviderActive(provider, callback) {
    if (this.loading) return

    this.loading = true

    api.makeProviderActive(provider.id)
    .then(response => {
      provider.user.is_active = true
      if (callback) {
        callback()
      }
    })
    .catch((_) => {
      // this.errorHandler(error)
    })
    .then(() => {
      this.loading = false
    })
  },
  
  makeProviderInactive(provider, callback) {
    if (this.loading) return

    this.loading = true

    api.makeProviderInactive(provider.id)
    .then(response => {
      provider.user.is_active = false
      if (callback) {
        callback()
      }
    })
    .catch(error => {
      // this.errorHandler(error)
    })
    .then(() => {
      this.loading = false
    })
  },
  
  makeCustomerActive(customer, callback) {
    if (this.loading) return;
    this.loading = true
    api.makeCustomerActive(customer.id)
    .then(response => {
      customer.user.is_active = true
      if (callback) callback()
    })
    .catch(error => {
      // this.errorHandler(error)
    })
    .then(() => {
      this.loading = false
    })
  },

  makeCustomerInactive(customer, callback) {
    if (this.loading) return;
    this.loading = true
    api.makeCustomerInactive(customer.id)
    .then(response => {
      customer.user.is_active = false
      if (callback) callback()
    })
    .catch(error => {
      // this.errorHandler(error)
    })
    .then(() => {
      this.loading = false
    })
  },

  makeJobActive(job, callback) {
    if (this.loading) return;
    this.loading = true
    api.makeJob(job.id, { status: JobStatus.ACTIVE })
      .then(response => {
        if (callback) callback()
      })
      .catch(error => {
        // this.errorHandler(error)
      })
      .then(() => {
        this.loading = false
      })
  },

  makeJobInactive(job, callback) {
    if (this.loading) return;
    this.loading = true
    api.makeJob(job.id, { status: JobStatus.DEACTIVATE })
      .then(response => {
        if (callback) callback()
      })
      .catch(error => {
        // this.errorHandler(error)
      })
      .then(() => {
        this.loading = false
      })
  },
}
