<template>
  <div
    v-if="!loading"
    class="admin-dashboard"
  >
    <list-header
      title="Dashboard admin"
      :start="start"
      :end="end"
      @update:start="fetchDashboard({ endDate: end, startDate: $event }), (start = $event)"
      @update:end="fetchDashboard({ startDate: start, endDate: $event }), (end = $event)"
    />

    <div class="row">
      <dashboard-statistic
        v-for="statistic in statistics"
        :key="statistic.title"
        :title="statistic.title"
        :count="statistic.count"
        :route="statistic.route"
      />
    </div>

    <div class="row mt-3">
      <div class="col-md-6">
        <div class="col-md-12 text-start">
          <h4>Invoices paid</h4>
          <bar-chart :data="invoicechartData.datasets.length > 0 ? invoicechartData : false" />
        </div>
      </div>

      <div class="col-md-6">
        <div class="col-md-12 text-start">
          <h4>Number of Jobs Awarded</h4>
          <bar-chart :data="jobchartData.datasets.length > 0 ? jobchartData : false" />
        </div>
      </div>
    </div>

    <div
      v-if="countries.length != 0"
      class="col-md-12 my-5"
    >
      <div class="col-md-12 text-start">
        <h4>Locations of projects</h4>
        <app-g-chart
          :settings="{ packages: ['geochart'] }"
          :data="countries"
          type="GeoChart"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListHeader from "@/components/ListHeader.vue";
import RectangleMap from "@/assets/img/icons/Rectanglemap.svg";

import { GChart } from "vue-google-charts";
import api from "@/services/api";
import BarChart from "@/components/superadmin/BarChart.vue";
import DashboardStatistic from "@/components/superadmin/DashboardStatistic";
import InvoiceHelper from '@/utils/invoice';

export default {
  name: "SuperadminDashboardPage",

  components: {
    AppGChart: GChart,
    ListHeader,
    BarChart,
    DashboardStatistic,
  },

  data: () => ({
    headerOptions: [
      {
        title: "2023",
        value: 2023,
      },
      {
        title: "2024",
        value: 2024,
      },
      {
        title: "2025",
        value: 2025,
      },
      {
        title: "2026",
        value: 2026,
      },
      {
        title: "2027",
        value: 2027,
      },
      {
        title: "2028",
        value: 2028,
      },
      {
        title: "2029",
        value: 2029,
      },
    ],
    start: null,
    end: null,
    RectangleMap,
    loading: false,
    invoicechartData: {
      labels: ["2023", "2024", "2025", "2026", "2027", "2028", "2029"],
      datasets: [],
    },
    jobchartData: {
      labels: ["2023", "2024", "2025", "2026", "2027", "2028", "2029"],
      datasets: [],
    },
    countries: [],
    statistics: {
      projects: {
        title: "Projects",
        count: 0,
        route: "SuperAdminJobs",
      },
      servicesProvided: {
        title: "Services provided",
        count: 0,
        route: "SuperAdminProviders",
      },
      customers: {
        title: "Customers",
        count: 0,
        route: "SuperAdminCustomers",
      },
      providers: {
        title: "Providers",
        count: 0,
        route: "SuperAdminProviders",
      },
    },
  }),

  mounted() {
    const d = new Date();

    this.start = d.toISOString().split('T')[0];
    this.end = d.toISOString().split('T')[0];
    this.fetchDashboard({ startDate: this.start, endDate: this.end });
  },

  methods: {
    ...InvoiceHelper,

    fetchDashboard(params) {
      this.loading = true;

      api
        .superAdminDashboard(params)
        .then((response) => {
          response.data.invoicechartData.forEach((item) => {
            console.log("item status: ", item.status);
            if (!this.invoicechartData.labels.includes("" + item.year + "")) {
              this.invoicechartData.labels.push(item.year);
            }

            this.invoicechartData.datasets.push({
              label: item.status,
              backgroundColor: this.getInvoiceStatusStyle(item.status).color,
              data: [item.total],
            });
          });

          response.data.jobchartData.forEach((item) => {
            if (!this.jobchartData.labels.includes("" + item.year + "")) {
              this.jobchartData.labels.push("" + item.year + "");
            }
            this.jobchartData.datasets.push({
              label: "total",
              backgroundColor: "#209516",
              data: [item.total],
            });
          });

          this.countries = [["Country", "Project"]].concat(
            (response.data.countries || []).map((t) => {
              return [t.country, t.count];
            })
          );

          this.statistics.projects.count = response.data.jobs ?? 0;
          this.statistics.servicesProvided.count =
            response.data.services_provided ?? 0;
          this.statistics.customers.count = response.data.customer ?? 0;
          this.statistics.providers.count = response.data.provider ?? 0;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    chartData(param) {
      let data = { labels: [], datasets: [{ data: [], backgroundColor: [] }] };

      param.forEach((item) => {
        const month = new Date(item.year, item.total - 1, 1).toLocaleString(
          "en-US",
          { month: "short" }
        );
        data.labels.push(month);
        data.datasets[0].data.push(item.total);

        data.datasets[0].backgroundColor.push(this.getInvoiceStatusStyle(item.status).color);
      });

      return data;
    },
  },
};
</script>
