import { Request } from "@/services/request";

export default {
  upload(data, folder, keepName = true) {
    return new Request()
      .append("api/v1/upload/")
      .method("post")
      .contentType("multipart/form-data")
      .params({ folder, "keep-name": keepName })
      .setData(data);
  },
};
