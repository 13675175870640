<template>
  <div>
    <div :class="`modal-body mb-10 ${classes}`">
      <div class="row mt-3 fs-2">
        <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
          <div
            class="share-option"
            @click="shareToLinkedIn"
          >
            <img
              :src="icons.linkdinVectorIcon"
              alt="LinkeddIn"
            >
          </div>
          <div
            class="share-option"
            @click="shareToTwitter"
          >
            <img
              :src="icons.twitterVectorIcon"
              alt="Twitter"
            >
          </div>
          <div
            class="share-option"
            @click="shareOnFacebook"
          >
            <img
              :src="icons.facebookVectorIcon"
              alt="Facebook"
              class="facebook"
            >
          </div>
          <div
            class="share-option"
            @click="getLink"
          >
            <img
              :src="icons.linkVectorIcon"
              alt="link"
            >
          </div>
        </div>
        <div
          v-if="islink"
          class="d-flex align-items-center w-100"
        >
          <p class=" mb-0 links">
            {{ redirectLink }}
          </p>
          <button
            class="btn-primary copy"
            @click="copyToClipboard"
          >
            Copy
          </button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="mb-3">
          <Autocomplete
            :job-id="jobId || ''"
            :is-send="isSend"
            @email="takeEmail"
            @isSelect="isSelected"
          />
          <small
            v-if="errors.providerErrorMessage"
            class="invalid-feedback"
          >
            {{ errors.providerErrorMessage }}
          </small>
        </div>
        <div class="col-lg-4 mb-3 mt-5">
          <button
            class="btn-danger"
            @click="back"
          >
            Back
          </button>
        </div>

        <div class="col-lg-8 text-lg-end px-0 mt-5">
          <button
            class="btn-primary"
            type="submit"
            :disabled="emails.length === 0 || loading"
            @click.prevent="sendInvitationProvider"
          >
            <submission-spinner v-if="loading" />
            Send Invite
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorMessages from "@/utils/error-messages";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import facebookVectorIcon from "@/assets/img/icons/facebook.svg";
import twitterVectorIcon from "@/assets/img/icons/twitter.svg";
import linkdinVectorIcon from "@/assets/img/icons/linkdin.svg";
import linkVectorIcon from "@/assets/img/icons/links.svg";
import Autocomplete from "@/components/provider/jobs/AutocompleteSubcontracting.vue";
import api from "@/services/api";
import { GlobalConstants } from "@/configs/constants";

export default {
  name: "SubcontractingModal",

  components: {
    Autocomplete,
    SubmissionSpinner,
  },

  props: {
    jobId: {
      type: String,
      required: true,
    },

    bid: {
      type: String,
      required: true,
    },

    idPost: {
      type: String,
      required: true,
    },

    classes: {
      type: String,
      required: false,
      default: "",
    },
  },

  data: () => ({
    showModal: false,
    fadeModal: false,
    isSend: false,
    route: null,
    message: null,
    textCopied: null,
    islink: false,
    icons: {
      linkVectorIcon,
      facebookVectorIcon,
      twitterVectorIcon,
      linkdinVectorIcon,
    },
    errors: {
      serverSideErrorMessage: null,
      providerErrorMessage: null,
    },
    redirectLink: null,
    subcontractor: [],
    emails: "",
    loading: false,
    idProvider: [],
    isSelect: false,
  }),

  methods: {
    async sendInvitationProvider() {
      if (this.loading) return;
      this.loading = true;
      this.route = this.getRoute();
      this.textCopied = GlobalConstants.BASE_URL + this.route;
      const dataSend = {
        job: this.jobId,
        provider: this.emails,
        url: this.textCopied,
      };

      try {
        let invitedProviders = [];

        if (this.emails.length) {
          const response = await api.sendInvitationProvider(dataSend);
          invitedProviders = response.data;
        }
        this.isSend = true;
        this.providers = invitedProviders;
        this.loading = false;
        this.emails = [];
        this.idProvider = [];
        this.isSelect = false;
      } catch (err) {
        this.loading = false;
        this.isSend = false;
        this.errorsHandler(err);
      } finally {
      }
    },

    confirm() {
      this.$emit("onConfirm");
      this.closeModal();
    },

    getLink() {
      this.islink = !this.islink;
      this.redirectLink = GlobalConstants.BASE_URL + this.getRoute();
    },

    copyToClipboard() {
      this.route = this.getRoute();
      this.textCopied = GlobalConstants.BASE_URL + this.route;
      const textArea = document.createElement("textarea");
      textArea.value = this.textCopied;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    },

    isSelected(selected) {
      this.isSelect = selected;
    },

    errorsHandler(err) {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            this.errors.providerErrorMessage =
              errorMessages.PROVIDER_ALREADY_INVITED;
            break;

          default:
            this.errors.serverSideErrorMessage =
              errorMessages.AN_ERROR_HAS_OCCURED;
            break;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    takeEmail(email) {
      this.emails = email;
    },

    shareOnFacebook() {
      const urlToShare = GlobalConstants.BASE_URL + this.getRoute();
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        urlToShare
      )}`;
      window.open(facebookUrl, "_blank");
    },

    shareToTwitter() {
      const urlToShare = GlobalConstants.BASE_URL + this.getRoute();
      const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        urlToShare
      )}`;
      window.open(twitterUrl, "_blank");
    },

    shareToLinkedIn() {
      const urlToShare = GlobalConstants.BASE_URL + this.getRoute();
      const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        urlToShare
      )}`;
      window.open(linkedinUrl, "_blank");
    },

    getRoute() {
      const route = this.$router.resolve({
        name: "ProviderSubcontractDetails",
        params: {
          id: this.$route.params.id,
        },
      });
      return route.href;
    },

    back() {
      if (this.idPost) {
        this.$router.push({
          name: "ProviderSubcontractDetails",
          params: { id: this.idPost ? this.idPost : this.$route.params.id },
          query: {
            idProposal: this.$route.params.proposalId,
            idJob: this.$route.query.idJob
              ? this.$route.query.idJob
              : this.$route.params.id,
            my_subcontract: true,
          },
        });
      } else {
        this.$router.push({
          name: "ProviderSubcontracting",
          params: {
            id: this.$route.params.id,
            proposalId: this.$route.params.proposalId,
          },
          query: {
            idJob: this.$route.query.idJob,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.link {
  background: #f5f5f5;
  padding: 0.93rem;
  justify-content: center;
  display: flex;
  border-radius: 6px;
  margin-right: 15px;
}
.full-name {
  font-weight: 500;
}
.providers-list {
  margin-top: 1rem;
  height: 5rem;
  overflow-y: scroll;
}
.providers-list::-webkit-scrollbar {
  display: none;
}
.circle {
  top: 60% !important;
}
.btn-primary {
  padding: 0.6rem 0.85rem !important;
  font-weight: 500;
  font-size: 1rem;
}
.btn-back {
  padding: 0.6rem 0.85rem!important;
  font-weight: 500;
  font-size: 1rem;
}
small {
  font-size: 0.9rem;
  color: #222a36;
}
.full-name {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: #222a36;
}
.email {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 21px;
  color: #222a36;
}
.modal-content {
  padding: 1.5rem 2rem;
  width: 565px;
}
.invite-modal h6 {
  
  font-weight: 500;
  font-size: 1.3rem;
  color: #222a36;
}
.share-option {
  background: #ffffff;
  border: 1px solid #c7cbce;
  border-radius: 50%;
  height: 65px;
  width: 65px;
  padding: 1rem 1.4rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.share-option img {
  height: 1.7rem;
}
.links {
  border: 1px solid #c7cbce;
  padding: 0.7rem;
  overflow: scroll;
  cursor: pointer;
  width: 100%;
  height: 50px;
  white-space: nowrap;
}
.links::-webkit-scrollbar {
  display: none;
}
.copy {
  border-radius: 0px !important;
}
.share-option:hover {
  background-color: #f5f5f5;
}
.facebook {
  padding: 0.17rem;
}
</style>
