<template>
  <div class="col-xl-8 mx-auto">
    <p class="title-auth text-center text-primary">
      Forgot password ?
    </p>

    <alerte-danger
      :head-error-message="errors.serverSideErrorMessage"
      @updateContent="errors.serverSideErrorMessage = $event"
    />

    <form @submit.prevent="forgotPassword">
      <div>
        <template v-if="emailSent">
          <p
            v-if="!submitting"
            class="text-center mb-3"
          >
            Please check your emails for next steps. If you haven't received an
            email, check to see if you can find it in your spam folder.
          </p>
        </template>

        <template v-else>
          <label class="mb-2">Email <span class="text-danger">*</span></label>
          <div class="mb-3">
            <div class="position-relative">
              <img
                :src="icons.vectorEmail"
                alt="icon email"
                class="position-absolute ms-3 email"
              >
              <input
                v-model="credentials.email"
                type="email"
                class="form-control ps-5 shadow-none"
                :class="{
                  'is-invalid':
                    (v$.credentials.email.$invalid &&
                      v$.credentials.email.$dirty) ||
                    errors.emailErrorMessage,
                }"
                placeholder="Email"
                @blur="v$.credentials.email.$touch"
              >
            </div>
            <template
              v-if="
                (v$.credentials.email.$invalid &&
                  v$.credentials.email.$dirty) ||
                  errors.emailErrorMessage
              "
            >
              <small
                v-if="v$.credentials.email.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </small>

              <small
                v-else-if="v$.credentials.email.$invalid"
                class="invalid-feedback"
              >
                Must be a valid email address
              </small>

              <small
                v-else-if="errors.emailErrorMessage"
                class="invalid-feedback"
              >
                {{ errors.emailErrorMessage }}
              </small>
            </template>
          </div>
        </template>

        <div class="text-center">
          <button
            type="submit"
            class="btn-primary submit-button w-100"
            :disabled="submitting"
          >
            <submission-spinner v-if="submitting" />
            <span v-if="emailSent">Send Email Again</span>
            <span v-else>Submit</span>
          </button>
        </div>

        <p class="back-to-login">
          Back to
          <router-link
            :to="{ name: 'Login' }"
            class="text-decoration-underline"
          >
            Log In
          </router-link>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import vectorEmail from "@/assets/img/icons/symbols_mail.svg";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import errorMessages from "@/utils/error-messages";
import api from "@/services/api";

import { GlobalConstants } from "@/configs/constants";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ForgotPasswordPage",

  components: {
    SubmissionSpinner,
    AlerteDanger,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    icons: {
      vectorEmail,
    },
    credentials: {
      email: null,
      redirectLink: null,
    },
    token: null,
    submitting: false,
    emailSent: false,
    errors: {
      emailErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      credentials: {
        email: {
          required,
          email,
        },
      },
    };
  },

  created() {
    const allRoutes = this.$router.getRoutes();
    this.credentials.redirectLink = `${
      GlobalConstants.BASE_URL +
      allRoutes.find((route) => route.name == "ResetPassword").path
    }`;
  },

  methods: {
    async forgotPassword() {
      if (this.submitting) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }
      this.submitting = true;
      this.clearErrorsMessages();

      try {
        await api.forgotPassword(this.credentials);
        this.emailSent = true;
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.submitting = false;
      }
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data.email) {
            this.errors.emailErrorMessage = err.response.data.email[0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>