// layouts

import DefaultLayout from "@/layouts/DefaultLayout.vue";

// view

import ProviderProfile from "@/views/ProviderProfile.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import SharedEstimateDetails from "@/views/SharedEstimateDetails.vue";
import SharedInvoiceDetails from "@/views/SharedInvoiceDetails.vue";

export default [
  {
    path: "/",
    redirect: "/auth/login",
    component: DefaultLayout,
    children: [
      {
        path: "/:username(\\@[^/]+)",
        name: "ProviderPublicProfile",
        component: ProviderProfile,
        props: true,
      },

      {
        path: "/page-not-found",
        name: "PageNotFound",
        component: PageNotFound,
      },

      {
        path: "/shared/estimates/:id",
        name: "SharedEstimateDetails",
        component: SharedEstimateDetails,
      },

      {
        path: "/shared/invoices/:id",
        name: "SharedInvoiceDetails",
        component: SharedInvoiceDetails,
      },

      {
        path: "/:pathMatch(.*)*",
        redirect: "/auth/login",
      },
    ],
  },
];
