import { Request } from "@/services/request";

export default {
  fetchContent() {
    return new Request().append("api/v1/content/1/").method("get");
  },

  updateContent(data) {
    return new Request()
      .append("api/v1/content/1/")
      .method("patch")
      .setData(data);
  },
};
