import { Request } from "@/services/request";

export default {
  fetchMyClient({ page = 1, search }) {
    return new Request()
      .append("api/v1/client-portfolio/")
      .method("get")
      .params({ page, search });
  },

  fetchClientJobs({
    page = 1,
    client_id = null,
    provider_propertie_associate = null
  }) {
    return new Request().append(`api/v1/client-portfolio/${client_id}/job_list/`)
    .method("get")
    .params({ page, provider_propertie_associate });
  },
  fetchClientDetail(id) {
    return new Request().append(`api/v1/client-portfolio/${id}/`).method("get");
  },

  fetchClientProprieties({ id, page }) {
    return new Request()
      .append(`api/v1/client-portfolio/${id}/proprieties_list/`)
      .method("get")
      .params({ page });
  },

  downloadClientCSV() {
    return new Request()
      .append("api/v1/client-portfolio/generate_csv/")
      .method("get");
  },

  downloadClientPropertiesCSV(id) {
    return new Request()
      .append(`api/v1/client-portfolio/${id}/properties_csv/`)
      .method("get");
  },

  createClient(data) {
    return new Request()
      .append("api/v1/client-portfolio/")
      .method("post")
      .setData(data);
  },

  updateClient(id, data) {
    return new Request()
      .append(`api/v1/client-portfolio/${id}/`)
      .method("patch")
      .setData(data);
  },

  fetchClientProperties({ page = 1, search = null, clientId }) {
    return new Request()
      .append(`api/v1/client-portfolio-property/`)
      .method("get")
      .params({ page, search, client_id: clientId });
  },
  fetchClientProperty(id) {
    return new Request()
      .append(`api/v1/client-portfolio-property/${id}/`)
      .method("get");
  },

  fetchClientAllProperty(id) {
    return new Request()
      .append(`api/v1/client-portfolio/${id}/all_properties_list/`)
      .method("get");
  },
  addClientPropriety(data) {
    return new Request()
      .append(`api/v1/client-portfolio-property/`)
      .method("post")
      .setData(data);
  },

  updateClientPropriety(id, data) {
    return new Request()
      .append(`api/v1/client-portfolio-property/${id}/`)
      .method("patch")
      .setData(data);
  },

  deleteClientPropriety(id) {
    return new Request()
      .append(`api/v1/client-portfolio/${id}/`)
      .method("delete");
  },

  inviteClient(id) {
    return new Request()
      .append(`api/v1/client-portfolio/${id}/invite/`)
      .method("post");
  },

  requestClientPropertieBulkCreation(data) {
    return new Request()
      .contentType("multipart/form-data")
      .append(`api/v1/client-request-property-bulk/`)
      .method("post")
      .setData(data);
  },

  fetchClientProprietiesBulkRequests({ id, page }) {
    return new Request()
      .append(`api/v1/client-portfolio/${id}/proprieties_bulk_requests_list/`)
      .method("get")
      .params({ page });
  },

  fetchPropertyBulRequestDetail({ id }) {
    return new Request()
      .append(`api/v1/client-request-property-bulk/${id}/`)
      .method("get");
  },

  fetchClientSummary() {
    return new Request()
      .append(`api/v1/client-portfolio/my_client/`)
      .method("get");
  },
};
