import { Request } from "@/services/request";

const baseUrl = "api/v1/notification";

export default {
  fetchAllNotifications({ page = 1 }) {
    return new Request()
      .append(`${baseUrl}/my_notification/`)
      .method("get")
      .params({
        page,
      });
  },

  fetchLatestNotifications() {
    return new Request().append(`${baseUrl}/latest/`).method("get");
  },

  markNotificationAsRead(id) {
    return new Request()
      .append(`${baseUrl}/${id}/mark_read/`)
      .method("patch");
  },

  markAllNotificationsAsRead() {
    return new Request()
      .append(`${baseUrl}/mark_all_read/`)
      .method("patch");
  },
};
