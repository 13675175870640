import { NotificationTypes } from "@/configs/constants";

export default {
  getNotificationRedirectionRoute(isCustomer, type, data) {
    let route = {};

    switch (type) {
      case NotificationTypes.JOB:
      case NotificationTypes.START_REQUEST:
        route = {
          name: isCustomer ? "CustomerJobDetails" : "ProviderJobDetails",
          params: {
            id: data.job_id,
          },
        };
        break;

      case NotificationTypes.BID:
        route = {
          name: isCustomer ? "CustomerJobProposal" : "ProviderProposalDetails",
          params: {
            id: data.job_id,
            proposalId: data.proposal_id,
          },
        };
        break;

      case NotificationTypes.SIMPLE:
        route = {
          name: isCustomer ? "" : "",
        };
        break;

      case NotificationTypes.TODO_LIST:
        route = {
          name: isCustomer ? "CustomerToDoList" : "ProviderToDoList",
          params: {
            id: data.job_id,
          },
        };
        break;

      case NotificationTypes.JOB_INVITATION:
        route = {
          name: "ProviderJobDetails",
          params: {
            id: data.job_id,
          },
        };
        break;

      case NotificationTypes.CLIENT_INVITATION:
        route = {
          name: "",
        };
        break;

      case NotificationTypes.INVOICE:
        route = {
          name: isCustomer ? "CustomerInvoiceDetails" : "ProviderInvoiceDetails",
          params: {
            id: data.invoice_id,
          },
        };
        break;

      default:
        break;
    }

    return route;
  },
};
