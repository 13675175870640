import { Request } from "@/services/request";

export default {
  addBulkPropriety(data) {
    return new Request()
      .append(`api/v1/customerpropertybulk/`)
      .contentType("multipart/form-data")
      .method("post")
      .setData(data);
  },
  fetchBulkProprieties({ page = 1, search = null}) {
    return new Request()
      .append(`api/v1/customerpropertybulk/proprieties_bulk_requests_list/`)
      .method("get")
      .params({ page, search });
  },
  deleteBulkPropriety(id) {
    return new Request()
      .append(`api/v1/customerpropertybulk/${id}/`)
      .method("delete")
  },
}
