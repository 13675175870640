<template>
  <section class="content">
    <div class="page-content">
      <loader-spinner v-if="loading" />

      <div
        v-else
        class="row gap"
      >
        <div class="my-3">
          <div class="photo-wrapper">
            <div
              :style="{
                'background-image': `url(${
                  provider.photo?.url || images.user
                })`,
              }"
              class="profile-photo"
            />

            <div>
              <h4
                class="username mb-0"
                v-html="accountStatus"
              />
              <rating-stars :rating="provider.rating ?? 0" />
              <span class="time mt-2">
                <img
                  :src="
                    provider.statusConnection === 'offline' && images.clockIcon
                  "
                  class="icon-profil d-block ms-0 me-2"
                  alt="Time icon"
                >
                {{ statusConnection }}
              </span>
            </div>

            <router-link
              v-if="false"
              class="btn-primary ml-auto"
            >
              Contact
            </router-link>
          </div>
        </div>

        <div class="row py-3">
          <div class="col-md-3">
            <div class="card">
              <div class="card-header">
                <div>
                  <h4 class="mb-0">
                    {{ zeroPad(provider.number_of_jobs_awarded) }}
                  </h4>
                </div>
              </div>
              <hr class="horizontal">
              <div class="card-footer">
                <h3 class="input-label">
                  Total jobs
                </h3>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <div class="card-header">
                <div>
                  <h4 class="mb-0">
                    {{ zeroPad(provider.ongoing_job) }}
                  </h4>
                </div>
              </div>
              <hr class="horizontal">
              <div class="card-footer">
                <h3 class="input-label">
                  Ongoing jobs
                </h3>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <div class="card-header">
                <div>
                  <h4 class="mb-0">
                    {{ zeroPad(provider.completed_job) }}
                  </h4>
                </div>
              </div>
              <hr class="horizontal">
              <div class="card-footer">
                <h3 class="input-label">
                  Completed Jobs
                </h3>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <div class="card-header">
                <div>
                  <h4 class="mb-0">
                    {{ zeroPad(completionRate) }}
                  </h4>
                </div>
              </div>
              <hr class="horizontal">
              <div class="card-footer">
                <h3 class="input-label">
                  Completion rate <span class="percent">(%)</span>
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="provider.profile_description"
          class="row"
        >
          <div
            v-show="provider.profile_description"
            class="col-lg-8"
          >
            <h3 class="input-label">
              Description
            </h3>
            <p class="label-content">
              {{ provider.profile_description }}
            </p>
          </div>

          <div class="col-lg-4">
            <div v-if="provider.state">
              <p class="label-content">
                <span class="input-label">State :</span>
                {{ provider.state }}
              </p>
            </div>

            <div v-if="provider.city">
              <p class="label-content">
                <span class="input-label">City:</span>
                {{ provider.city }}
              </p>
            </div>

            <div v-if="provider.website">
              <p class="label-content">
                <span class="input-label">Website :</span>
                {{ provider.website }}
              </p>
            </div>

            <div v-if="provider.company_name">
              <p class="label-content">
                <span class="input-label">Company Name :</span>
                {{ provider.company_name }}
              </p>
            </div>
          </div>
        </div>

        <div
          v-else
          class="row"
        >
          <div class="col-lg-6">
            <div v-if="provider.state">
              <p class="label-content">
                <span class="input-label">State :</span>
                {{ provider.state }}
              </p>
            </div>

            <div v-if="provider.city">
              <p class="label-content">
                <span class="input-label">City:</span>
                {{ provider.city }}
              </p>
            </div>
          </div>

          <div class="col-lg-6">
            <div v-if="provider.website">
              <p class="label-content">
                <span class="input-label">Website :</span>
                {{ provider.website }}
              </p>
            </div>

            <div v-if="provider.company_name">
              <p class="label-content">
                <span class="input-label">Company Name :</span>
                {{ provider.company_name }}
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="provider.twelve_last_ongoing.length > 0"
          class="job-list-section"
        >
          <h3 class="input-label">
            Ongoing jobs
          </h3>
          <ul
            id="jobOnGoing"
            class="accordion list-unstyled"
          >
            <item-job-profil
              v-for="item in provider.twelve_last_ongoing"
              :key="item.job.id"
              :job="item.job"
              :parent="'jobOnGoing'"
            />
          </ul>
        </div>

        <div
          v-if="provider.twelve_last_completed.length > 0"
          class="job-list-section"
        >
          <h3 class="input-label">
            Recently Completed Jobs
          </h3>
          <ul
            id="jobCompleted"
            class="accordion list-unstyled"
          >
            <item-job-profil
              v-for="item in provider.twelve_last_completed"
              :key="item.job.id"
              :job="item.job"
              :parent="'jobCompleted'"
            />
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from "@/services/api";
import DOMPurify from "dompurify";

import user from "@/assets/img/user.svg";
import certificateCircle from "@/assets/img/icons/certificateCircle.svg";
import clockIcon from "@/assets/img/icons/clock.svg";

import CountryUtils from "@/utils/country";
import { ProviderStatus } from "@/configs/constants/users";
import BusinessTypesUtils from "@/utils/business-type";
import { DateHelpers } from "@/utils/date-helpers";

import LoaderSpinner from "@/components/LoaderSpinner.vue";
import RatingStars from "@/components/RatingStars.vue";
import ItemJobProfil from "@/components/ItemJobProfil.vue";

export default {
  name: "ProviderProfilePage",

  components: {
    LoaderSpinner,
    RatingStars,
    ItemJobProfil,
  },

  beforeRouteEnter(to, from, next) {
    async function fetchProvider() {
      try {
        const response = await api.fetchProviderByUsername(
          to.params.username?.slice(1)
        );

        next(async (vm) => {
          vm.provider = {
            id: response.data.data.user.id,
            full_name: response.data.data.user.full_name,
            profile_description: response.data.data.user.profile_description,
            website: response.data.data.user.website,
            statusConnection: response.data.data.user.connection_status,
            photo: response.data.data.user.photo,
            city: response.data.data.user.city,
            company_name: response.data.data.user.company_name,
            statusProfil: response.data.data.status,
            state: response.data.data.user.state,
            rating: response.data.data.rating,
            last_login: response.data.data.last_login,
            active_date: response.data.data.active_date,
            completed_job: response.data.data.completed_job,
            current_working_on: response.data.data.current_working_on,
            number_of_jobs_awarded: response.data.data.number_of_jobs_awarded,
            ongoing_job: response.data.data.ongoing_job,
            twelve_last_completed: response.data.data.twelve_last_completed,
            twelve_last_ongoing: response.data.data.twelve_last_ongoing,
          };
          vm.loading = false;
        });
      } catch (_) {
        next({ name: "PageNotFound" });
      }
    }

    fetchProvider();
  },
  props: {
    username: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    images: {
      user,
      clockIcon,
    },
    loading: true,
    provider: {},
    bids: {},
    errorMessage: null,
  }),

  computed: {
    accountStatus() {
      if (this.provider.statusProfil === ProviderStatus.ACTIVE) {
        return DOMPurify.sanitize(
          this.provider.full_name +
            `<img class="icon-profil" src="${certificateCircle}" />`
        );
      }
      return this.provider.full_name;
    },

    statusConnection() {
      if (this.provider.statusConnection === "online") {
        return "Online";
      }
      return `Last login: ${this.formatDateHourToDay(
        this.provider.last_login
      )}`;
    },

    completionRate() {
      const completed = this.provider?.completed_job || 0;
      const total = this.provider?.number_of_jobs_awarded || 1;
      return ((completed / total) * 100).toFixed(0);
    },
  },

  methods: {
    ...CountryUtils,
    ...BusinessTypesUtils,
    ...DateHelpers,

    zeroPad(num) {
      return (num < 10 ? "0" : "") + num.toString();
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 0.5rem;
}

.icon-profil {
  width: 1.3rem;
  margin-left: 0.5rem;
}

.job-list-section {
  margin-top: 1rem;
}
.job-list-section h3 {
  border-bottom: 1px solid #7d7d7d4c;
  padding-bottom: 0.5rem;
}

.time {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #999;
}

hr.horizontal {
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.4),
    transparent
  );
  margin: 0;
}

.card {
  margin-bottom: 1rem;
}
.card .percent {
  font-size: 0.6rem;
  font-style: italic;
}

.card-header,
.card-footer {
  border: none;
}
.empty {
  display: inline-flex;
  align-items: center;
  height: 150px;
  justify-content: center;
  width: 100%;
  font-size: 0.8rem;
  color: #c1c1c1;
}
</style>
