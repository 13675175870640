<template>
  <div
    class="col-md-6 col-xl-5 mb-3 position-relative"
    @click="selectPlan"
  >
    <div
      class="item"
      :class="{ active: isSelected }"
    >
      <div
        class="pricing-card"
        role="button"
      >
        <p class="pricing-title">
          {{ plan.name }}
        </p>

        <div class="price-card p-3">
          <p class="price">
            <span>
              {{
                formatPrice(
                  (currentPlan?.id === plan.id ? plan.basePrice : plan.value) ||
                    0
                )
              }}
            </span>
          </p>

          <span class="sub-title">
            {{ plan.commercial_description }}
          </span>
          <p class="mt-5">
            <span class="sub-title">
              Number of Bid{{ plan.number_of_bids > 1 ? "s" : "" }}:
            </span>
            <span class="text-primary">
              {{ plan.number_of_bids }}
            </span>
          </p>

          <div class="list-pricing mb-5">
            <ul>
              <template v-for="feature in features">
                <feature-item
                  v-if="plan[feature.condition]"
                  :key="feature.condition"
                  :title="feature.title"
                  :checked="isSelected"
                />
              </template>
            </ul>
            <div class="d-block pricing-bottom-btn">
              <p
                v-if="currentPlan?.id === plan.id"
                class="text-danger text-center my-1"
              >
                You have this plan already activate
              </p>
              <button
                class="btn d-block w-100"
                :class="{
                  'btn-secondary': currentPlan?.id === plan.id,
                  'btn-primary': currentPlan?.id !== plan.id,
                }"
                :disabled="currentPlan?.id === plan.id"
              >
                {{
                  currentPlan?.id === plan.id ? "Current Plan" : "Choose Plan"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PriceHelpers from "@/utils/price-format";

import vectorCircleGris from "@/assets/img/icons/circle-gris.svg";
import vectorCircleGreen from "@/assets/img/icons/circle-green.svg";

import FeatureItem from "@/components/subscription-plans/FeatureItem.vue";

import { SubscriptionPlanFeatures } from "@/configs/constants/subscription-plans";

export default {
  name: "PlanItem",

  components: {
    FeatureItem,
  },

  props: {
    plan: {
      type: Object,
      required: true,
    },

    isSelected: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    icons: {
      vectorCircleGreen,
      vectorCircleGris,
    },
  }),

  computed: {
    featureIcon() {
      return this.isSelected
        ? this.icons.vectorCircleGreen
        : this.icons.vectorCircleGris;
    },

    features() {
      return SubscriptionPlanFeatures;
    },

    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
  },

  methods: {
    ...PriceHelpers,

    selectPlan() {
      this.$emit("onSelectPlan");
    },
  },
};
</script>
