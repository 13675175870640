import { Request } from "@/services/request";

const baseUrl = "api/v1/customer";

export default {
  customerDashboard() {
    return new Request().append(`${baseUrl}/dashboard/`).method("get");
  },

  fetchAllCustomers(data) {
    return new Request().append(`${baseUrl}/`).params(data).method("get");
  },

  fetchCustomer(id) {
    return new Request().append(`${baseUrl}/${id}/`).method("get");
  },

  makeCustomerActive(id) {
    return new Request().append(`${baseUrl}/${id}/make_active/`).method("post");
  },

  makeCustomerInactive(id) {
    return new Request()
      .append(`${baseUrl}/${id}/make_inactive/`)
      .method("post");
  },

  updateCustomer(id, data) {
    return new Request()
      .append(`${baseUrl}/${id}/`)
      .method("patch")
      .setData(data);
  },
  sendInvitationCustomer(data) {
    return new Request()
      .append(`api/v1/job_invitation/`)
      .method("post")
      .setData(data);
  },
  sendInvitationCustomerByMail(data) {
    return new Request()
      .append(`api/v1/job_invitation/invitation_by_mail/`)
      .method("post")
      .setData(data);
  },

}
