<template>
  <span
    v-if="getInvoiceStatusStyle(status).class"
    class="invoice-status"
    :class="`${getInvoiceStatusStyle(status).class} ${short ? 'short': ''}`"
  >
    <span class="mb-0">{{ formatInvoiceStatus(status) }}</span>
  </span>
</template>

<script>
import InvoiceHelper from "@/utils/invoice";

export default {
  name: "InvoiceStatus",

  props: {
    status: {
      type: String,
      required: true,
    },

    short: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  methods: {
    ...InvoiceHelper,
  },
};
</script>
