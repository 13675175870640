export const SuperAdminDashboardMenuItems = [
  {
    title: "Dashboard",
    routeName: "SuperAdminDashboard",
    icon: "superAdminDashboardIcon",
  },
  {
    title: "Providers",
    routeName: "SuperAdminProviders",
    icon: "superAdminListIcon",
    showProvidersIndicator: true,
  },
  {
    title: "Customers",
    routeName: "SuperAdminCustomers",
    icon: "superAdminListIcon",
    showCustomersIndicator: true,
  },
  {
    title: "Jobs",
    routeName: "SuperAdminJobs",
    icon: "superAdminBagIcon",
    showJobsIndicator: true,
  },
  {
    title: "Invoices",
    routeName: "SuperAdminInvoices",
    icon: "superAdminDocumentIcon",
    showInvoicesIndicator: true,
  },
  {
    title: "Estimates",
    routeName: "SuperAdminEstimates",
    icon: "superAdminIconEstimates",
    showEstimatesIndicator: true,
  },
  {
    title: "Settings",
    routeName: "SuperAdminJobsSettings",
    icon: "superAdminSettingsIcon",
  },

  {
    title: "Subscription",
    routeName: "SuperAdminPlans",
    icon: "superAdminRecycleIcon",
  },
  {
    title: "Services",
    routeName: "SuperAdminServices",
    icon: "superAdminRecycleIcon",
  },
  {
    title: "Bids",
    routeName: "SuperAdminBids",
    icon: "superAdminRecycleIcon",
  },

  {
    title: "Discount Coupons",
    routeName: "SuperAdminCoupon",
    icon: "superAdminTicketIcon",
  },
  {
    title: "Historical Statistics",
    routeName: "SuperAdminStatistics",
    icon: "superAdminStatisticsIcon",
  },
];
