<template>
  <div :class="`profile-photo-wrapper ${classes}`">
    <div
      v-if="url"
      :style="{ 'background-image': `url(${url})` }"
      class="user-profile-photo"
    />
    <avatar
      v-else
      :name="fullname"
      :size="size || 48"
      :color="color"
      :background="background"
      class="proposal-avatar"
      alt="Profile avatar"
    />
    <div class="online-status is-online" />
  </div>
</template>

<script>
import Avatar from "vue3-avatar";

export default {
  name: "ProfileAvatar",

  components: {
    Avatar,
  },

  props: {
    url: {
      type: String,
      required: false,
      default: ""
    },

    fullname: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      required: true,
    },

    background: {
      type: String,
      required: true,
    },

    size: {
      type: Number,
      required: false,
      default: 48
    },

    classes: {
      type: String,
      required: false,
      default: ""
    },
  },
};
</script>

<style scoped>
.profile-photo-wrapper {
  position: relative;
  height: fit-content;
  margin-right: 0.375rem;
}
.profile-photo-wrapper .user-profile-photo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.profile-photo-wrapper .proposal-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #fff;
}
.profile-photo-wrapper .online-status {
  position: absolute;
  right: 4%;
  bottom: 8%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px #fff solid;
}
.profile-photo-wrapper .online-status.is-online {
  background: #209516;
}
.profile-photo-wrapper .online-status.is-offline {
  background: #a9ada9;
}
</style>