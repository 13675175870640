<template>
  <li class="module-item">
    <div>
      <img
        :src="featureIcon"
        alt="Feature Seleted"
        class="check"
      >
      <span>{{ title }}</span>
    </div>

    <img
      v-if="removable"
      :src="icons.croixVectorIcon"
      alt="Remove Icon"
      class=""
      @click="remove"
    >
  </li>
</template>

<script>
import vectorCircleGris from "@/assets/img/icons/circle-gris.svg";
import vectorCircleGreen from "@/assets/img/icons/circle-green.svg";
import croixVectorIcon from "@/assets/img/icons/poubell-red.svg";

export default {
  name: "FeatureItem",

  props: {
    title: {
      type: String,
      required: true,
    },

    checked: {
      type: Boolean,
      required: true,
    },

    removable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    icons: {
      croixVectorIcon,
      vectorCircleGris,
      vectorCircleGreen,
    },
  }),

  computed: {
    featureIcon() {
      return this.checked
        ? this.icons.vectorCircleGreen
        : this.icons.vectorCircleGris;
    },
  },

  methods: {
    remove() {
      this.$emit("onRemove");
    },
  },
};
</script>

<style scoped>
.module-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.remove-module {
  opacity: 0;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  transition: all 0.3s;
}
.module-item:hover .remove-module {
  opacity: 1;
}
</style>
