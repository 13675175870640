<template>
  <div>
    <list-header
      title="Subscription"
      :add="true"
      @create="create"
    />

    <div
      class="row"
    >
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="thead" />

            <tbody v-if="!loading">
              <tr
                v-for="(item, index) in plans"
                :key="index"
              >
                <td
                  class="py-3"
                >
                  <router-link
                    class="cursor-pointer text-primary"
                    :to="{
                      name: 'SuperAdmiPlanDetails',
                      params: { id: item.id },
                    }"
                  >
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  {{ item.commercial_description }}
                </td>
                <td>
                  {{ item.type }}
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>
                <td>
                  <router-link
                    class="cursor-pointer text-primary"
                    :to="{
                      name: 'SuperAdmiPlanEdit',
                      params: { id: item.id },
                    }"
                  >
                    Edit
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";

import api from "@/services/api";
import { DateHelpers } from "@/utils/date-helpers";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

export default {
  name: "ProviderWalletInvoicesPage",

  components: {
    ListHeader,
    HeadTable,
    LoaderSpinner,
  },

  data: () => ({
    thead: [
      "Name",
      "Description",
      "Type",
      "Created at",
      "",
    ], 
    plans: [],
    loading: false,
  }),

  mounted() {
    this.fetchSubscriptionPlans();
  },

  methods: {
    ...DateHelpers,

    fetchSubscriptionPlans() {
      this.loading = true;

      api
        .fetchSubscriptionPlans()
        .then((response) => {
          this.plans = response.data.data;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    create() {
      this.$router.push({
        name: "SuperAdmiPlanCreate",
      });
    },
  },
};
</script>
