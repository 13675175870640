<template>
  <section class="content delete-acccount">
    <div class="page-header">
      <h3 class="page-title">
        Manage payment method
      </h3>

      <settings-menu />
    </div>

    <div
      class="page-content col-lg-8 offset-lg-2 py-6 row align-items-center justify-content-center"
    >
      <div class="content-verification text-center">
        <h4
          v-if="!currentProvider?.email_verified"
          class="title-content"
        >
          Stripe account activation
        </h4>

        <p class="mb-5">
          Your Stripe account is used for all recurring payments.
        </p>

        <div
          v-if="
            currentProvider?.stripe_verification ===
              stripeAccountActivationStatus.PENDING
          "
          class="mt-4"
        >
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <alerte-danger
                :head-error-message="errors.serverSideErrorMessage"
                @updateContent="errors.serverSideErrorMessage = $event"
              />
            </div>
          </div>

          <span
            class="send-link mb-3"
            @click="activateStripeAccount"
          >
            <submission-spinner
              v-if="submitting"
              :color="'#209516'"
            />
            Activate my Stripe account
          </span>
        </div>

        <div v-else>
          <h5
            class="stripe-account-status"
            :class="activationStatusClass"
          >
            {{ activationStatus }}
          </h5>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { toast } from "vue3-toastify";

import api from "@/services/api";
import errorMessages from "@/utils/error-messages";

import SettingsMenu from "@/components/provider/SettingsMenu.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import { StripeAccountActivationStatus } from "@/configs/constants/users";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderBillingAndPaymentsPage",

  components: {
    SettingsMenu,
    SubmissionSpinner,
    AlerteDanger,
  },

  data: () => ({
    submitting: false,
    errors: {
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    stripeAccountActivationStatus() {
      return StripeAccountActivationStatus;
    },

    currentProvider() {
      return this.$store.getters["user/user"];
    },

    activationStatus() {
      let status = "";

      switch (this.currentProvider?.stripe_verification) {
        case this.stripeAccountActivationStatus.NEED_STRIPE_APPROVAL:
          status = "Your account is being validated";
          break;

        case this.stripeAccountActivationStatus.REFUSED:
          status = "Your account activation has been refued";
          break;

        case this.stripeAccountActivationStatus.ACCEPTED:
          status = "Your account has been successfully activated";
          break;
      }

      return status;
    },

    activationStatusClass() {
      let status = "";

      switch (this.currentProvider?.stripe_verification) {
        case this.stripeAccountActivationStatus.NEED_STRIPE_APPROVAL:
          status = "text-warning";
          break;

        case this.stripeAccountActivationStatus.REFUSED:
          status = "text-danger";
          break;

        case this.stripeAccountActivationStatus.ACCEPTED:
          status = "text-primary";
          break;
      }

      return status;
    },
  },

  methods: {
    async activateStripeAccount() {
      if (this.submitting) return;
      this.submitting = true;

      try {
        await api.activateStripeAccount();
        toast.success(
          "The request to activate your stripe account is successfully sent."
        );
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.submitting = false;
      }
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 400) {
          this.errors.serverSideErrorMessage = err.response.data.error;
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.page-content {
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.content-verification {
  max-width: 650px;
  padding: 1.5rem 3rem;
  background: #fff;
  box-shadow: 0px 4px 14px 0px #0000000d;
}
.delete-acccount {
  color: rgba(101, 115, 106, 1);
}
.delete-acccount .contact {
  font-size: 1rem;
}
.delete-acccount .contact a {
  text-decoration: underline;
}
.title-content {
  font-weight: 600;
  font-size: 1.75rem;
  color: #209516;
  margin-bottom: 1rem;
}
.stripe-account-status {
  font-weight: 600;
  color: #209516;
  margin-bottom: 1rem;
}
.img-float {
  height: 4rem;
  max-width: 100px;
  margin-bottom: 1rem;
}

.send-link {
  color: #209516;
  padding: 0.6rem 0.85rem;
  border: 1px solid #e5e8eb;
  display: inline-block;
  cursor: pointer;
  margin-left: 0.8rem;
  box-shadow: 0px 4px 6px 0px #00000008;
}

img.status {
  margin-right: 0.2rem;
  width: 25px;
}
.page-content {
  height: 100%;
}
</style>
