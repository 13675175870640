import { Request } from "@/services/request";

const baseUrl = "api/v1/plan";

export default {
  fetchSubscriptionPlans() {
    return new Request().append(`${baseUrl}/`).method("get");
  },

  fetchSubscriptionPlanServices() {
    return new Request().append(`api/v1/plan_services/`).method("get");
  },

  createPlan(data) {
    return new Request().append(`${baseUrl}/`).method("post").setData(data);
  },

  updatePlan(id, data) {
    return new Request()
      .append(`${baseUrl}/${id}/`)
      .method("put")
      .setData(data);
  },

  updatePlanOneAttr(id, data) {
    return new Request()
      .append(`${baseUrl}/${id}/`)
      .method("patch")
      .setData(data);
  },

  fetchSubscriptionPlanById(id) {
    return new Request().append(`${baseUrl}/${id}/`).method("get");
  },

  fetchSubscriptionPlanDiscountByID(id, coupon) {
    return new Request()
      .append(`${baseUrl}/${id}/with_discount/`)
      .method("get")
      .params({ coupon });
  },

  subscribeToPlan(id, data) {
    return new Request()
      .append(`${baseUrl}/${id}/make_subscription/`)
      .method("post")
      .setData(data);
  },

  executePaypalSubscription(code) {
    return new Request()
      .append(`${baseUrl}/execute_paypal_agreement/`)
      .method("post")
      .setData({ code });
  },

  buyBids(id, data) {
    return new Request()
      .append(`${baseUrl}/${id}/buy_bids/`)
      .method("post")
      .setData(data)
  },

  fetchPlanServicesBidList() {
    return new Request().append(`api/v1/plan_services/bid_list/`).method("get");
  },
};
