<template>
  <div
    v-if="!loading"
    class="admin-dashboard"
  >
    <list-header
      title="Statistics"
      :start="start"
      :end="end"
      @update:start="fetchDashboard({ endDate: end, startDate: $event }), (start = $event)"
      @update:end="fetchDashboard({ startDate: start, endDate: $event }), (end = $event)"
    />

    <div class="row mt-3">
      <div class="col-md-6">
        <div class="col-md-12 text-start">
          <h4>Invoices paid</h4>
          <bar-chart :data="invoicechartData" />
        </div>
      </div>

      <div class="col-md-6">
        <div class="col-md-12 text-start">
          <h4>Number of Jobs Awarded</h4>
          <bar-chart :data="jobchartData" />
        </div>
      </div>

      <div class="col-12 col-md-6 row text-end align-items-center">
        <div class="col">
          <div class="dropdown">
            <div class="col-12 col-md-6 row text-end align-items-center">
              <div class="col">
                <div class="dropdown">
                  <a
                    class="btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Project Types
                  </a>
                  <div class="dropdown-menu">
                    content
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import ListHeader from "@/components/ListHeader.vue";
import RectangleMap from "@/assets/img/icons/Rectanglemap.svg";

import BarChart from "@/components/superadmin/BarChart.vue";

export default {
  name: "SuperadminDashboardPage",

  components: {
    ListHeader,
    BarChart,
  },

  data: () => ({
    headerOptions: [
      {
        title: "2023",
        value: 2023,
      },
      {
        title: "2024",
        value: 2024,
      },
      {
        title: "2025",
        value: 2025,
      },
      {
        title: "2026",
        value: 2026,
      },
      {
        title: "2027",
        value: 2027,
      },
      {
        title: "2028",
        value: 2028,
      },
      {
        title: "2029",
        value: 2029,
      },
    ],
    start: null,
    end: null,
    RectangleMap,
    loading: false,
    invoicechartData: {
      labels: ["2023", "2024", "2025", "2026", "2027", "2028", "2029"],
      datasets: [],
    },
    jobchartData: {
      labels: ["2023", "2024", "2025", "2026", "2027", "2028", "2029"],
      datasets: [],
    },
  }),

  mounted() {
    const d = new Date();

    this.start = d.toISOString().split('T')[0];
    this.end = d.toISOString().split('T')[0];
    this.fetchDashboard({ startDate: this.start, endDate: this.end });
  },

  methods: {
    fetchDashboard(params) {
      this.loading = true;

      api
        .superAdminDashboard(params)
        .then((response) => {
          response.data.invoicechartData.forEach((item) => {
            if (!this.invoicechartData.labels.includes("" + item.year + "")) {
              this.invoicechartData.labels.push(item.year);
            }
            this.invoicechartData.datasets.push({
              label: item.status,
              backgroundColor: item.status == "paid" ? "#209516" : "#f87979",
              data: [item.total],
            });
          });
          response.data.jobchartData.forEach((item) => {
            if (!this.jobchartData.labels.includes("" + item.year + "")) {
              this.jobchartData.labels.push("" + item.year + "");
            }
            this.jobchartData.datasets.push({
              label: "total",
              backgroundColor: "#209516",
              data: [item.total],
            });
          });

          this.statistics.projects.count = response.data.jobs ?? 0;
          this.statistics.servicesProvided.count =
            response.data.services_provided ?? 0;
          this.statistics.users.count = response.data.users ?? 0;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
