<template>
  <section class="content profile dashboard-provider">
    <div class="page-header">
      <div class="title-part mb-0">
        <h4 class="title-content">
          Calls History
        </h4>

        <button
          class="btn btn-primary float-end"
          type="button"
          :class="{'filter-blur': (includeService('communication') && includePlan('can_access_communication'))}"
          :disabled="(includeService('communication') && includePlan('can_access_communication'))"
          @click="(includeService('communication') && includePlan('can_access_communication')) ? false : activateCall"
        >
          <span class="mb-0 text-white fw-normal">Activate Call</span>
        </button>
      </div>
    </div>

    <div
      class="col-md-12  py-4"
      :class="{'filter-blur': (includeService('communication') && includePlan('can_access_communication'))}"
    >
      <div class="row">
        <div class="row mb-3">
          <div class="col-md-4">
            <div class="statistic-item">
              <div class="card mb-0">
                <h3>{{ (includeService('communication') && includePlan('can_access_communication')) ? 0 : calls.all_call }}</h3>

                <p>{{ (includeService('communication') && includePlan('can_access_communication')) ? "Locked" : "All Calls" }}</p>

                <span class="linear-bar" />
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="statistic-item">
              <div class="card mb-0">
                <h3>{{ (includeService('communication') && includePlan('can_access_communication')) ? 0 : calls.call_made }}</h3>

                <p>{{ (includeService('communication') && includePlan('can_access_communication')) ? "Locked" : "Received Calls" }}</p>

                <span class="linear-bar" />
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="statistic-item">
              <div class="card mb-0">
                <h3>{{ (includeService('communication') && includePlan('can_access_communication')) ? 0 : calls.missed }}</h3>

                <p>{{ (includeService('communication') && includePlan('can_access_communication')) ? "Locked" : "Missed Calls" }}</p>

                <span class="linear-bar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row"
    >
      <div class="col-md-12 my-3">
        <h4 class="title-content">
          History
        </h4>
      </div>

      <div class="table-responsive">
        <loader-spinner v-if="loading && (includeService('communication') && includePlan('can_access_communication'))" />
        <table
          v-if="!loading && (includeService('communication') && includePlan('can_access_communication'))"
          class="table"
          :class="{'filter-blur': (includeService('communication') && includePlan('can_access_communication'))}"
        >
          <head-table
            :list="thead" 
            :hide="(includeService('communication') && includePlan('can_access_communication'))"
          />
          <tbody>
            <tr
              v-for="(item, index) in calls.data"
              :key="index"
            >
              <td>
                {{ item.client.full_name }}
              </td>
              <td>
                {{ item.client.phone }}
              </td>
              <td>
                {{ formatDate(item.send) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <activate-call
      ref="ActivateCall"
      @updateList="fetchMyCalls"
    />
  </section>
</template>

<script>
import documentIcon from "@/assets/img/icons/document-icon.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import api from "@/services/api";
import { MediaTypesConstants } from "@/configs/constants";

import { DateHelpers } from "@/utils/date-helpers";
import { CustomerStatusList } from "@/configs/constants/users";
import HeadTable from "@/components/HeadTable.vue";
import LoaderSpinner from '@/components/LoaderSpinner.vue';
import ActivateCall from '@/components/provider/ActivateCall.vue';

export default {
  name: "SuperadminCustomerDetailsPage",

  components: { 
    HeadTable,
    LoaderSpinner,
    ActivateCall,
  },

  data() {
    return {
      icons: {
        imageUploadedIcon,
        documentUploadedIcon,
        documentIcon,
      },
      CustomerStatusList,
      thead: ["Client", "Phone", "Date"], 
      listWithdraw: [],
      calls: { 
        all_call: 0,
        call_made: 0,
        data: [],
        missed: 0
      },
      client: null,
      loadingInvoices: false,
      loading: true,
      confirmationOkBtnText: null,
      confirmationMessage: null,
      selected: null,
      submenu: "Client info",
    };
  },

  computed: {
    mediaTypesConstants() {
      return MediaTypesConstants;
    },
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },
  },

  async mounted() {
    this.fetchMyCalls();
  },

  methods: {
    ...DateHelpers,

    includeService(code) {
      let service = this.currentPlanServices?.find(el=> el.code == code);
      if (service == undefined) {
        return true;
      } else {
        return false
      }
    },
    includePlan(key) {
      if (this.currentPlan && this.currentPlan[key] == null) {
        return true;
      } else {
        return false
      }
    },
    fetchMyCalls() {
      this.loading = true;

      api
        .fetchMyCalls()
        .then((response) => {
          this.calls = response.data;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    activateCall() {
      this.$refs.ActivateCall.openModal();
    },
  },
};
</script>
