<template>
  <div class="job-list row">
    <div
      class="col-xl-4 filter-col filter-container"
      :class="showFilter && 'd-block'"
    >
      <a
        type="button"
        class="btn-close bg-primary"
        aria-label="Close"
        @click="showFilter = false"
      />
      <job-filters
        @onCriteriasChange="(includeService('subcontracting') && includePlan('can_access_subcontract')) ? false : filterJos($event)"
      />
    </div>

    <div
      class="col-xl-8 job-list-col position-relative"
      :class="showFilter && 'not'"
    >
      <div class="section position-relative">
        <div class="title-section d-sm-flex justify-content-between">
          <p class="title-content">
            {{ pageTitle }}
          </p>
        </div>

        <jobs-list
          :jobs="jobs"
          :hide="(includeService('marketplace') && includePlan('can_access_marketplace'))"
          @fetchNextPage="(includeService('subcontracting') && includePlan('can_access_subcontract')) ? false : onFetchNextJobsPage"
        />

        <loader-spinner v-if="fetching" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import ListMixin from "@/mixins/list";

import plusVector from "@/assets/img/icons/plus.svg";
import searchVector from "@/assets/img/icons/search.svg";

import JobsList from "@/components/provider/jobs/JobsList";
import JobFilters from "@/components/provider/jobs/JobFilters";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import { JobStatus } from "@/configs/constants/jobs";

export default {
  name: "CustomerJobs",

  components: {
    JobsList,
    JobFilters,
    LoaderSpinner,
  },

  mixins: [ListMixin(api.fetchMyJobs, true, false)],

  data: () => ({
    showFilter: false,
    status: JobStatus.ACTIVE,
    pageTitle: "Active Jobs",
    icons: {
      plusVector,
      searchVector,
    },
    jobs: [],
    fetching: false,
    maxPagesReached: false,
    isFilteringJobs: false,
  }),

  computed: {
    filterOptions() {
      const items = [
        { label: "Active", value: JobStatus.ACTIVE },
        { label: "In Progress", value: JobStatus.ONGOING },
        { label: "Completed", value: JobStatus.COMPLETED },
        { label: "Draft", value: JobStatus.DRAFT },
      ];

      return items;
    },

    selectedJobStatusLabel() {
      return this.filterOptions.find(
        (option) => option.value === this.status
      )?.label;
    },
    currentUser() {
      return this.$store.getters["user/user"];
    },
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },
  },

  watch: {
    $route() {
      this.fetchJobs();
    },

    listData(val) {
      if (this.isFilteringJobs) {
        this.jobs = val;
      } else {
        this.jobs = this.jobs.concat(val);
      }
      this.fetching = false;
    },

    isFilteringJobs(val) {
      if (!val) {
        this.jobs = []
      }
    }
  },

  mounted() {
    this.fetchJobs();
  },

  methods: {

    includeService(code) {
      let service = this.currentPlanServices?.find(el=> el.code == code);
      if (service == undefined) {
        return true;
      } else {
        return false
      }
    },
    includePlan(key) {
      if (this.currentPlan && this.currentPlan[key] == null) {
        return true;
      } else {
        return false
      }
    },
    handleJobsStatusChange(status) {
      this.$router.push({
        query: {
          ...this.$route.query,
          status,
        },
      });
      this.jobs = []
      this.fetching = true;
      this.fetchJobs()
    },

    fetchJobs() {
      if (this.$route.query?.status) {
        switch (this.$route.query.status.toLowerCase()) {
          case JobStatus.ONGOING:
            this.status = JobStatus.ONGOING;
            this.pageTitle = "Jobs In Progress";
            break;

          case JobStatus.ACTIVE:
            this.status = JobStatus.ACTIVE;
            this.pageTitle = "Active Jobs";
            break;

          case JobStatus.COMPLETED:
            this.status = JobStatus.COMPLETED;
            this.pageTitle = "Completed Jobs";
            break;

          case JobStatus.DRAFT:
            this.status = JobStatus.DRAFT;
            this.pageTitle = "Draft Jobs";
            break;
        }
      }
      this.fetching = true;
      this.updateListFilters({
        page: 1,
        status: this.status,
      });
    },

    onFetchNextJobsPage() {
      if (this.currentPage >= this.totalPages) {
        this.maxPagesReached = true;
      }

      if (this.fetching || this.maxPagesReached) return;
      this.fetching = true;

      this.updateListFilters({
        ...this.filters,
        status: this.status,
        page: this.currentPage + 1,
      });
    },

    filterJos(filters) {
      this.filters = filters;
      let allFiltersVoided = true;
      for (const key in this.filters) {
        if (Object.hasOwnProperty.call(this.filters, key)) {
          if (this.filters[key]) {
            allFiltersVoided = false;
          }
        }
      }
      this.maxPagesReached = false;
      this.isFilteringJobs = !allFiltersVoided;
      this.fetching = true;
      this.updateListFilters({
        ...this.filters,
        page: 1,
        status: this.status,
      });
    },
  },
};
</script>
