<template>
  <div class="d-flex justify-content-center align-items-center mb-4">
    <div>
      <p class="notification">
        {{ message.content }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationMessage",

  props: {
    message: {
      type: Object,
      required: true,
      default: null,
    },
  },
};
</script>
