<template>
  <section class="content delete-acccount">
    <div class="page-header">
      <h3 class="page-title">
        Delete Account
      </h3>

      <settings-menu />
    </div>

    <div class="page-content">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 box-form">
          <p class="title mt-3">
            This action is irreversible. You will not be able to recover your
            files.
          </p>

          <div class="option-content">
            <form @submit.prevent="confirmClaim">
              <alerte-danger
                :head-error-message="errors.serverSideErrorMessage"
                @updateContent="errors.serverSideErrorMessage = $event"
              />
              
              <p>Can you tell us why you're leaving?</p>

              <div class="ps-4 mb-4">
                <div
                  v-for="(reason, index) in leavingReasons"
                  :key="index"
                  class="form-check mb-3"
                >
                  <input
                    :id="`leaving-reason-${index}`"
                    v-model="formData.leavingReason"
                    class="form-check-input shadow-none"
                    type="radio"
                    :value="reason"
                  >
                  <label
                    class="form-check-label"
                    :for="`leaving-reason-${index}`"
                  >
                    {{ reason }}
                  </label>
                </div>
              </div>

              <div>
                <p>Please list any other reasons why you might be leaving:</p>

                <textarea
                  v-model="formData.otherReasons"
                  rows="3"
                  class="form-control"
                  placeholder="Write the others reasons here..."
                />
              </div>

              <div class="text-md-end buttons py-3">
                <router-link
                  :to="{ name: 'CustomerProfile' }"
                  class="btn-outline me-3"
                >
                  Cancel
                </router-link>

                <button
                  type="submit"
                  class="btn-delete"
                  :disabled="loading"
                >
                  <submission-spinner v-if="loading" />
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <confirm-modal
      ref="confirmModal"
      ok-button-title="Delete my account"
      :message="'Are you sure you want to delete your account ?'"
      @onConfirm="handleDeleteAccount"
    />
  </section>
</template>

<script>
import dotVectorIcon from "@/assets/img/icons/tree-dot.svg";

import api from "@/services/api";
import errorMessages from "@/utils/error-messages";

import { GlobalEvents } from "@/configs/constants";

import ConfirmModal from "@/components/ConfirmModal.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import SettingsMenu from "@/components/customer/SettingsMenu.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "CustomerDeleteAccountPage",

  components: {
    ConfirmModal,
    SettingsMenu,
    SubmissionSpinner,
    AlerteDanger,
  },

  data: () => ({
    icons: {
      dotVectorIcon,
    },
    loading: false,
    formData: {
      leavingReason: "I don't understand how it works",
      otherReasons: null,
    },
    errors: {
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    leavingReasons() {
      const items = [
        "I don't understand how it works",
        "I have safety concerns",
        "I have privacy concerns",
      ];
      return items;
    },
  },

  methods: {
    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },

    async handleDeleteAccount() {
      if (this.loading) return;

      this.loading = true;
      this.clearErrorsMessages();

      try {
        const data = {
          deletion_reason: this.formData.leavingReason,
          other_deletion_reason: this.formData.otherReasons
        }

        await api.deleteAccount(data);
        this.$emitter.emit(GlobalEvents.LOGOUT);
      } catch (err) {
        this.errorsHandler(err);
      } finally {
        this.loading = false;
      }
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (err.response) {
        this.errors.serverSideErrorMessage = err.response.message;
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
