<template>
  <div>
    <list-header
      title="Bids"
      :add="true"
      @create="create"
    />
    <loader-spinner v-if="loading" />

    <div
      v-else
      class="row"
    >
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="thead" />
            <tbody>
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td
                  class="py-3"
                >
                  <span
                    class="cursor-pointer text-primary"
                    @click="detail(item)"
                  >{{
                    item.name
                  }}</span>
                </td>
                <td>
                  {{ item.bid_number }}
                </td>
                <td>
                  {{ formatPrice(item.value) }}
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>
                <td>
                  <option-action
                    :status="null"
                    :options="options"
                    @update:options="choose(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <page-action
        :index="page"
        :infos="pages"
        @update:index="fetchBidList({ page: $event, status }), (page = $event)"
      />
    </div>

    <confirm-modal
      ref="confirmModal"
      ok-button-title="Delete"
      :message="'Are you sure you want to delete ' +selected?.name + ' ?'"
      @onConfirm="deleteService"
    />
  </div>
</template>

<script>
import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import { toast } from "vue3-toastify";

import api from "@/services/api";
import PageAction from "@/components/PageAction.vue";
import { DateHelpers } from "@/utils/date-helpers";
import PriceHelpers from "@/utils/price-format";
import statusToUI from "@/utils/statusFormate";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import ConfirmModal from '@/components/ConfirmModal.vue';
import OptionAction from '@/components/OptionAction.vue';

export default {
  name: "ProviderWalletBidPage",

  components: {
    ListHeader,
    HeadTable,
    PageAction,
    LoaderSpinner,
    ConfirmModal,
    OptionAction,
  },

  data: () => ({
    thead: [
      "Name", 
      "Bid number", 
      "Price", 
      "Created at", 
      ""
    ], 
    listData: [],
    page: 1,
    options: [
      {
        title: "Delete",
        value: "delete",
      },
      {
        title: "Edit",
        value: "edit",
      },
    ],
    pages: {
      count: null,
      max_page_size: null,
      total_pages: null,
    },
    loading: false,
    deleting: false,
    selected: null
  }),

  mounted() {
    this.fetchBidList({ page: this.page });
  },

  methods: {
    ...DateHelpers,
    ...statusToUI,
    ...PriceHelpers,

    fetchBidList(param) {
      this.loading = true;

      api
        .fetchBidList(param)
        .then((response) => {
          this.listData = response.data.data;
          this.pages = {
            count: response.data.count,
            max_page_size: response.data.max_page_size,
            total_pages: response.data.total_pages,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    create() {
      this.$router.push({
        name: "SuperAdminBidsCreate",
      });
    },

    detail(item) {
      this.$router.push({
        name: "SuperAdminBidsDetails",
        params: { id: item.id },
      });
    },
    confirmClaim(coupon) {
      this.selected = coupon;

      this.$refs.confirmModal.openModal();
    },
    deleteService() {
      this.deleting = true;
      api.deleteService(this.selected.id)
        .then((_) => {
          toast.success(`${this.selected.name} successfully deleted.`);
          this.selected = null;
          this.fetchBidList({ page: this.page });
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deleting = false;
        });
    },
    choose(item, event) {
      switch (event) {
        case "edit":
          this.edit(item);
          break;
        case "delete":
          this.confirmClaim(item)
          break;

        default:
          break;
      }
    },
    edit(item) {
      this.$router.push({
        name: "SuperAdminBidsEdit",
        params: { id: item.id },
      });
    },

  },
};
</script>