<template>
  <div class="row">
    <div>
      <div class="row">
        <div class="col-lg-10 ">
          <section class="content delete-account">
            <div class="page-header">
              <h3 class="page-title text-primary">
                Edit an existing draft
              </h3>
            </div>

            <div class="page-content">
              <div class="row">
                <div class="col-lg-10  mt-5">
                  <div class="d-flex justify-content-between">
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input shadow-none"
                        type="radio"
                      >
                      <label class="form-check-label">
                        Cache Big Data Chart Database
                      </label>
                    </div>
                    <img
                      :src="icons.smallclose"
                      alt="poubel icon"
                    >
                  </div>

                  <div class="d-flex justify-content-between">
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input shadow-none"
                        type="radio"
                      >
                      <label class="form-check-label">
                        Cache Big Data Chart Database
                      </label>
                    </div>
                    <img
                      :src="icons.smallclose"
                      alt="poubel icon"
                    >
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input shadow-none"
                        type="radio"
                      >
                      <label class="form-check-label">
                        Cache Big Data Chart Database
                      </label>
                    </div>
                    <img
                      :src="icons.smallclose"
                      alt="poubel icon"
                    >
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <div>
                    <router-link
                      :to="{ name: 'CustomerJobs' }"
                      class="btn-outline mb-3"
                    >
                      Back
                    </router-link>
                  </div>
                  <div class="buttons">
                    <router-link
                      :to="{ name: 'CustomerJobCreate' }"
                      class="btn-secondary me-3"
                    >
                      Start over
                    </router-link>
                    <router-link
                      :to="{ name: 'CustomerJobCreate' }"
                      class="btn-primary continue"
                    >
                      Continue
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import smallclose from "@/assets/img/icons/poubell-red.svg";

export default {
  name: "CustomerJobDraft",
  data() {
    return {
      icons: {
        smallclose,
      },
      loading: false,
    };
  },
};
</script>

<style scoped>
.btn {
  padding: 0.75rem 2.5rem;
}
@media (max-width: 418px) {
  .continue {
    margin-top: 1rem;
  }
}
</style>
