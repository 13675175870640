export const JobSettingsStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const JobStatus = {
  WAITING: "waiting",
  ACTIVE: "active",
  DEACTIVATE: "deactivate",
  COMPLETED: "completed",
  DRAFT: "draft",
  ONGOING: "on_going",
};

export const StartJobRequestStatus = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
};

export const JobStatusList = [
  {
    title: "Project Status",
  },
  {
    title: "Pending",
    value: "waiting",
  },
  {
    title: "Completed",
    value: "completed",
  },
  {
    title: "Active",
    value: "active",
  },
  {
    title: "Disabled",
    value: "deactivate",
  },
  {
    title: "Draft",
    value: "draft",
  },
];

export const JobActionsList = [
  {
    title: "Active",
    value: "active",
  },
  {
    title: "Deactivate",
    value: "deactivate",
  },
];

export const actionsList = [
  {
    title: "Activate",
    value: "ACTIVE",
  },
  {
    title: "Edit",
    value: "edit",
  },
  {
    title: "Delete",
    value: "delete",
  },
];

export const JobBudgetTypes = {
  FIXED: "fixed",
  RANGE: "range",
  NOT_SURE: "not_sure",
};

export const JobPaymentFrequencies = {
  FIXED: "fixed",
  HOURLY: "hourly",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  ANNUAL: "annually",
};

export const ScreeningQuestionResponseTypes = {
  TEXT: "text",
  SELECT_OPTION: "select_option",
};

export const JobTypes = {
  ON_SITE: "on_site",
  REMOTE: "remote",
  MULTISITE: "multi_site",
};

export const JobProposalStatus = {
  SENDED: "sended",
  ACCEPTED: "accepted",
  REJECTED: "refused",
};

export const JobScreeningQuestionTypes = {
  SHORT: "SHORT",
  LONG: "LONG",
  MULTIPLE: "MULTIPLE",
  SINGLE: "SINGLE",
};

export const ProposalStatus = {
  REFUSED: "refused",
  ACCEPETED: "accepted",
  ACTIVE: "active",
  SENDED: "sended",
};
