<template>
  <div>
    <loader-spinner v-if="loading" />
    <div
      v-else-if="errors.notFoundErrorMessage"
      class="row mt-4"
    >
      <div class="col-lg-8">
        <alerte-danger
          :head-error-message="errors.notFoundErrorMessage"
          @updateContent="errors.notFoundErrorMessage = $event"
        />
      </div>
    </div>

    <template v-else>
      <section
        v-if="job"
        class="content job-detail"
      >
        <div class="header">
          <div class="d-flex title-part mb-0">
            <template v-if="!$route?.query?.idJob">
              <router-link
                :to="{
                  name: 'ProviderSubcontract',
                }"
                class="title me-1"
              >
                Subcontracts
              </router-link>
            </template>
            <template v-else>
              <router-link
                :to="{
                  name: 'ProviderSubcontracting',
                  params: {
                    id: $route?.query?.idJob,
                    proposalId: $route?.query?.idProposal,
                  },
                }"
                class="title me-1"
              >
                Subcontracts
              </router-link>
            </template>

            <p
              v-if="job?.title"
              class="title-content"
            >
              / {{ job?.title }}
            </p>
          </div>
          <div class="d-flex justify-content-end mb-2">
            <router-link
              v-if="
                currentProvider.id !== job?.owner_provider?.user?.id &&
                  job.total_bids === 0
              "
              :to="{
                name: 'ProviderJobApply',
                query: {
                  is_subcontract: true,
                  idJob: $route.query.idJob,
                  proposalId: $route.query.idProposal,
                },
                params: { id: job?.id },
              }"
              class="btn-primary"
            >
              Apply
            </router-link>

            <router-link
              v-if="
                $route.query.my_subcontract &&
                  mainJob?.status === jobStatus.ACTIVE
              "
              :to="{
                name: 'ProviderSendInvitation',
                params: {
                  id: $route.params.id,
                  proposalId: $route.query.idProposal,
                },
                query: { idJob: $route.query.idJob },
              }"
              class="btn-primary"
            >
              Find subcontractor
            </router-link>
            <img
              v-if="
                mainJob?.status === jobStatus.ACTIVE &&
                  job.provider_bids === false
              "
              :src="icons.removeVectorIcon"
              alt="remove"
              class="ms-3"
              @click.prevent="confirmClaim"
            >
          </div>
        </div>

        <div class="page-content">
          <div>
            <p class="content-title mt-0">
              {{ job?.title }}
            </p>

            <p class="content-subtitle pb-0">
              Posted at {{ formatDate(job?.created_at, "MMMM Do, YYYY") }}

              <template v-if="job.category?.name">
                in <span class="fw-bold">{{ job.category.name }}</span>
              </template>
            </p>
          </div>

          <div class="row pe-4 detail">
            <div
              class="col-md-8 py-2"
              :class="{
                'col-md-8 description': showCustomerDetails,
                'col-md-12': !showCustomerDetails,
              }"
            >
              <p
                class="category-name"
                v-html="sanitizedDescription"
              />
            </div>

            <div
              v-if="showCustomerDetails"
              class="col-md-4"
            >
              <p class="about">
                About the client
              </p>
              <p>Payment method not verified</p>
              <p class="history">
                History :
              </p>
              <ul>
                <li>20 to 50 proposal</li>
                <li>2 jobs posted</li>
              </ul>
            </div>
          </div>

          <div class="category-part">
            <div class="row mt-4">
              <div
                v-if="job.contract_type"
                class="col-md-6 col-lg-4 infos"
              >
                <div class="d-flex">
                  <p class="category-name">
                    <span class="category"> Contract Type : </span>
                    {{ job.contract_type.name }}
                  </p>
                </div>
              </div>

              <div
                v-if="job.level"
                class="col-md-6 col-lg-4 infos"
              >
                <div class="d-flex">
                  <p class="category-name">
                    <span class="category"> Level : </span>
                    {{ job.level.name }}
                  </p>
                </div>
              </div>

              <div
                v-if="job.job_language"
                class="col-md-6 col-lg-4 infos"
              >
                <div class="d-flex">
                  <p class="category-name">
                    <span class="category"> Job Language : </span>
                    {{ job.job_language.name }}
                  </p>
                </div>
              </div>

              <div
                v-if="job.budget_type"
                class="col-md-6 col-lg-4 infos"
              >
                <div class="d-flex">
                  <p class="category-name">
                    <span class="category"> Budget Type : </span>
                    {{ formatJobBudgetTypes(job.budget_type) }}
                  </p>
                </div>
              </div>

              <div
                v-if="job.payment_frequency"
                class="col-md-6 col-lg-4 infos"
              >
                <div class="d-flex">
                  <p class="category-name">
                    <span class="category"> Payment Frequency : </span>
                    {{
                      formatJobPaymentFrequencies(job.payment_frequency).long
                    }}
                  </p>
                </div>
              </div>

              <div
                v-if="job.budget_type === jobBudgetTypes.FIXED"
                class="col-md-6 col-lg-4 infos"
              >
                <div class="d-flex">
                  <p
                    v-if="job.budget !== null && job.budget !== undefined"
                    class="category-name"
                  >
                    <span class="category"> Budget : </span>
                    {{ formatPrice(job.budget) }}
                  </p>
                </div>
              </div>

              <template v-else>
                <div
                  v-if="
                    job.budget_from !== null && job.budget_from !== undefined
                  "
                  class="col-md-6 col-lg-4 infos"
                >
                  <div class="d-flex">
                    <p class="category-name">
                      <span class="category">Budget :</span>
                      {{ formatPrice(job.budget_from) }}
                      <template
                        v-if="
                          job.payment_frequency &&
                            formatJobPaymentFrequencies(job.payment_frequency)
                              .short
                        "
                      />
                    </p>
                    <span class="category ms-1">to</span>
                    <p
                      v-if="
                        job.budget_to !== null && job.budget_to !== undefined
                      "
                      class="category-name"
                    >
                      {{ formatPrice(job.budget_to) }}
                      <template
                        v-if="
                          job.payment_frequency &&
                            formatJobPaymentFrequencies(job.payment_frequency)
                              .short
                        "
                      >
                        /{{
                          formatJobPaymentFrequencies(job.payment_frequency)
                            .short
                        }}
                      </template>
                    </p>
                  </div>
                </div>
              </template>

              <div
                v-if="job.category"
                class="col-md-6 col-lg-4 infos"
              >
                <div class="d-flex">
                  <p class="category-name">
                    <span class="category"> Job Type : </span>
                    {{ formatJobType(job.job_type) }}
                  </p>
                </div>
              </div>

              <div
                v-if="job.location_description"
                class="col-md-6 col-lg-4 infos"
              >
                <div class="d-flex">
                  <p class="category-name">
                    <span class="category"> Job Location : </span>
                    {{ job.location_description }}
                  </p>
                </div>
              </div>
              <div
                v-if="job.start_date && job.end_date"
                class="col-md-6 col-lg-4 infos"
              >
                <div class="d-flex">
                  <p>
                    <span class="category"> Date :</span>

                    <template
                      v-if="
                        formatDate(job.start_date, 'MMMM') ===
                          formatDate(job.end_date, 'MMMM') &&
                          formatDate(job.start_date, 'YYYY') ===
                          formatDate(job.end_date, 'YYYY')
                      "
                    >
                      {{ formatDate(job.start_date, "MMMM") }}
                      {{ formatDate(job.start_date, "Do") }} to
                      {{ formatDate(job.end_date, "Do") }}
                      {{ formatDate(job.end_date, "YYYY") }}
                    </template>

                    <template v-else>
                      {{ formatDate(job.start_date, "MMMM Do, YYYY") }} to
                      {{ formatDate(job.end_date, "MMMM Do, YYYY") }}
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="skills-part pb-2">
            <p class="skill-title">
              Skills :
            </p>

            <div class="auto-flex">
              <span
                v-for="skill in job.skills"
                :key="skill.id"
                class="type-job"
              >
                {{ skill.name }}</span>
            </div>
          </div>

          <div
            v-if="job.files?.length"
            class="skills-part pb-3"
          >
            <p class="skill-title">
              Photos and Documents
            </p>

            <div class="row">
              <div
                v-for="(file, i) in job.files"
                :key="i"
                class="col-md-12 col-lg-6"
                :class="{ 'mb-3': i < job.files.length - 1 }"
              >
                <div class="document">
                  <a
                    :href="file.url"
                    target="_target"
                  >
                    <img
                      v-if="file.type === mediaTypesConstants.IMAGE"
                      :src="icons.imageUploadedIcon"
                      alt="File Uploaded"
                      class="file-icon me-1"
                    >
                    <img
                      v-else
                      :src="icons.documentUploadedIcon"
                      alt="File Uploaded"
                      class="file-icon me-1"
                    >
                    {{ getFileName(file.original_name) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import DOMPurify from "dompurify";

import heartVectorIcon from "@/assets/img/icons/heart.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";
import removeVectorIcon from "@/assets/img/icons/poubell-red.svg";

import api from "@/services/api";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
// import ConfirmModal from "@/components/ConfirmModal.vue";

import JobHelper from "@/utils/job";
import FileUtils from "@/utils/file";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";

import {
  JobProposalStatus,
  JobBudgetTypes,
  JobStatus,
} from "@/configs/constants/jobs";
import { ProviderStatus } from "@/configs/constants/users";
import { MediaTypesConstants } from "@/configs/constants";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderSubcontractDetails",

  components: {
    LoaderSpinner,
    AlerteDanger,
    // ConfirmModal,
  },

  data: () => ({
    icons: {
      heartVectorIcon,
      imageUploadedIcon,
      documentUploadedIcon,
      removeVectorIcon,
    },
    loading: false,
    job: null,
    mainJob: null,
    showCustomerDetails: false,
    errors: {
      notFoundErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    jobProposalStatus() {
      return JobProposalStatus;
    },

    jobBudgetTypes() {
      return JobBudgetTypes;
    },

    jobStatus() {
      return JobStatus;
    },

    currentProvider() {
      return this.$store.getters["user/user"];
    },

    sanitizedDescription() {
      return DOMPurify.sanitize(this.job?.description ?? "");
    },

    canApply() {
      return [ProviderStatus.OK, ProviderStatus.ACTIVE].includes(
        this.currentProvider?.status
      );
    },

    mediaTypesConstants() {
      return MediaTypesConstants;
    },
  },

  mounted() {
    this.fetchJob();
    if (this.$route.query.idJob) {
      this.fetchMainJob();
    }
  },

  methods: {
    ...FileUtils,
    ...JobHelper,
    ...DateHelpers,
    ...PriceHelpers,

    async fetchJob() {
      try {
        this.loading = true;
        const response = await api.fetchJob(this.$route.params.id);
        this.job = response.data;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchMainJob() {
      try {
        this.loading = true;
        const response = await api.fetchJob(this.$route.query.idJob);
        this.mainJob = response.data;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },
    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },
    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },
    async deleteJob() {
      try {
        this.loadingJob = true;
        await api.deleteJob(this.$route.params.id);
        this.$router.push({ name: "ProviderSubcontract" });
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loadingJob = false;
      }
    },
    errorsHandler(err) {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            this.errors.notFoundErrorMessage = errorMessages.JOB_NOT_FOUND;
            break;

          default:
            this.errors.serverSideErrorMessage =
              errorMessages.AN_ERROR_HAS_OCCURED;
            break;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.description {
  border-right: 1px solid #e5e8eb;
}
.about {
  font-weight: 600;
  font-size: 1.125rem;
  color: #209516;
}
.history {
  font-weight: 600;
  font-size: 1rem;
}
.detail {
  border-bottom: 1px solid #e5e8eb;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #e5e8eb;
}
</style>
