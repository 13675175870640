import { UserScopes } from "@/configs/constants/users";

// layouts

import ProviderLayout from "@/layouts/ProviderLayout.vue";

// view

import ProviderDashboard from "@/views/provider/Dashboard.vue";
import ProviderProfile from "@/views/provider/profile/Profile.vue";
import ProviderProfileEdit from "@/views/provider/profile/Edit.vue";
import ProviderChangePassword from "@/views/provider/profile/ChangePassword.vue";
import ProviderDeleteAccount from "@/views/provider/profile/DeleteAccount.vue";
import ProviderEmailVerification from "@/views/provider/profile/EmailVerification.vue";
import ProviderJobs from "@/views/provider/jobs/List.vue";
import ProviderSavedJobs from "@/views/provider/jobs/Saved.vue";
import ProviderCompletedJobs from "@/views/provider/jobs/Completed.vue";
import ProviderJobDetails from "@/views/provider/jobs/Details.vue";
import ProviderJobApply from "@/views/provider/jobs/Apply.vue";
import ProviderProposals from "@/views/provider/jobs/proposals/List.vue";
import ProviderActiveJob from "@/views/provider/jobs/proposals/ActiveJob.vue";
import ProviderProposalDetails from "@/views/provider/jobs/proposals/Details.vue";
import ProviderEditProposal from "@/views/provider/jobs/proposals/Edit.vue";
import ProviderSubcontracting from "@/views/provider/jobs/proposals/MySubcontracting.vue";
import ProviderSendInvitation from "@/views/provider/subcontract/SendInvitation.vue";
import ProviderSubcontract from "@/views/provider/subcontract/List.vue";
import ProviderInvitationsToBid from "@/views/provider/providerinvitationstobid/List.vue";
import CreateSubcontractJob from "@/views/provider/subcontract/Create.vue";
import ProviderSubcontractDetails from "@/views/provider/subcontract/Detail.vue";
import ProviderInvoices from "@/views/provider/invoices/List.vue";
import ProviderInvoiceCreate from "@/views/provider/invoices/Create.vue";
import ProviderInvoiceDetails from "@/views/provider/invoices/Detail.vue";
import ProviderInvoiceEdit from "@/views/provider/invoices/Edit.vue";
import ProviderCalendar from "@/views/provider/calendar/Index.vue";
import ProviderToDoList from "@/views/provider/jobs/ToDoList.vue";
import ProviderSubscription from "@/views/provider/Subscription.vue";
import ProviderNotifications from "@/views/provider/Notifications.vue";
import ProviderWaitingForApproval from "@/views/provider/approval/WaitingForApproval.vue";
import ProviderEstimates from "@/views/provider/estimates/List.vue";
import ProviderEstimateCreate from "@/views/provider/estimates/Create.vue";
import ProviderEstimateDetails from "@/views/provider/estimates/Detail.vue";
import ProviderEstimateEdit from "@/views/provider/estimates/Edit.vue";
import ClientPortfolioList from "@/views/provider/client-portfolio/List.vue";
import ClientPortfolioDetail from "@/views/provider/client-portfolio/Detail.vue";
import ClientPortfolioJobCreate from "@/views/provider/client-portfolio/jobs/Create.vue";
import ClientPortfolioJobDetails from "@/views/provider/client-portfolio/jobs/Details.vue";
import ClientPortfolioJobEdit from "@/views/provider/client-portfolio/jobs/Edit.vue";
import ClientPortfolioJobToDoList from "@/views/provider/client-portfolio/jobs/ToDoList.vue";
import ClientPortfolioInfoCreate from "@/views/provider/client-portfolio/Create.vue";
import ClientPortfolioInfoEdit from "@/views/provider/client-portfolio/Edit.vue";
import ClientPortfolioPropertyCreate from "@/views/provider/client-portfolio/property/Create.vue";
import ClientPortfolioPropertyEdit from "@/views/provider/client-portfolio/property/Edit.vue";
import ClientPortfolioInfoBulk from "@/views/provider/client-portfolio/Bulk.vue";
import ProviderCreditCardsList from "@/views/share/wallet/List.vue";
import ProviderCreateCreditCard from "@/views/share/wallet/Create.vue";
import ProviderWithdraw from "@/views/provider/withdraw/Index.vue";
import ProviderBankAccount from "@/views/provider/withdraw/bankAccount/Create.vue";
import ProviderCall from "@/views/provider/Call.vue";
import ProviderRepports from "@/views/provider/Reports.vue";
import ProviderBillingAndPayments from "@/views/provider/profile/BillingAndPayments.vue";
import ProviderBuyBids from "@/views/provider/BuyBids.vue";
export default [
  {
    path: "/provider",
    redirect: "/provider/home",
    meta: {
      neededScope: [UserScopes.PROVIDER],
      authRequired: true,
    },
    component: ProviderLayout,
    children: [
      {
        path: "home",
        name: "ProviderDashboard",
        component: ProviderDashboard,
      },

      {
        path: "profile",
        name: "ProviderProfile",
        component: ProviderProfile,
      },

      {
        path: "profile/edit",
        name: "ProviderProfileEdit",
        component: ProviderProfileEdit,
      },

      {
        path: "profile/change-password",
        name: "ProviderChangePassword",
        component: ProviderChangePassword,
      },

      {
        path: "profile/delete-account",
        name: "ProviderDeleteAccount",
        component: ProviderDeleteAccount,
      },

      {
        path: "profile/billing-and-payment",
        name: "ProviderBillingAndPayments",
        component: ProviderBillingAndPayments,
      },

      {
        path: "profile/email-verification",
        name: "ProviderEmailVerification",
        component: ProviderEmailVerification,
      },

      {
        path: "jobs",
        name: "ProviderJobs",
        component: ProviderJobs,
      },

      {
        path: "jobs/saved",
        name: "ProviderSavedJobs",
        component: ProviderSavedJobs,
      },

      {
        path: "jobs/completed",
        name: "ProviderCompletedJobs",
        component: ProviderCompletedJobs,
      },

      {
        path: "jobs/:id/details",
        name: "ProviderJobDetails",
        component: ProviderJobDetails,
      },

      {
        path: "jobs/:id/apply/:properties",
        name: "ProviderJobApply",
        component: ProviderJobApply,
      },

      {
        path: "proposals/:status",
        name: "ProviderProposals",
        component: ProviderProposals,
      },

      {
        path: "jobs/active",
        name: "ProviderActiveJob",
        component: ProviderActiveJob,
      },

      {
        path: "jobs/:id/proposals/:proposalId/details",
        name: "ProviderProposalDetails",
        component: ProviderProposalDetails,
      },

      {
        path: "jobs/:id/proposals/:proposalId/edit",
        name: "ProviderEditProposal",
        component: ProviderEditProposal,
      },

      {
        path: "jobs/:id/proposals/:proposalId/my-subcontracting",
        name: "ProviderSubcontracting",
        component: ProviderSubcontracting,
      },

      {
        path: "jobs/:id/proposals/:proposalId/invitation",
        name: "ProviderSendInvitation",
        component: ProviderSendInvitation,
      },

      {
        path: "provider-invitations-to-bid",
        name: "ProviderInvitationsToBid",
        component: ProviderInvitationsToBid,
      },
      {
        path: "subcontract",
        name: "ProviderSubcontract",
        component: ProviderSubcontract,
      },
      {
        path: "subcontract/:id/detail/:proposalId/create",
        name: "CreateSubcontractJob",
        component: CreateSubcontractJob,
      },
      {
        path: "subcontract/:id/details",
        name: "ProviderSubcontractDetails",
        component: ProviderSubcontractDetails,
      },

      {
        path: "invoices",
        name: "ProviderInvoices",
        component: ProviderInvoices,
      },

      {
        path: "invoices/create",
        name: "ProviderInvoiceCreate",
        component: ProviderInvoiceCreate,
      },

      {
        path: "invoices/:id/details",
        name: "ProviderInvoiceDetails",
        component: ProviderInvoiceDetails,
      },

      {
        path: "invoices/:id/edit",
        name: "ProviderInvoiceEdit",
        component: ProviderInvoiceEdit,
      },

      {
        path: "calendar",
        name: "ProviderCalendar",
        component: ProviderCalendar,
      },

      {
        path: "jobs/:id/to-do-list",
        name: "ProviderToDoList",
        component: ProviderToDoList,
      },

      {
        path: "subscription",
        name: "ProviderSubscription",
        component: ProviderSubscription,
      },

      {
        path: "notifications",
        name: "ProviderNotifications",
        component: ProviderNotifications,
      },

      {
        path: "approval/waiting",
        name: "ProviderWaitingForApproval",
        meta: {
          needStatus: ["need_approval", "need_stripe_approval"],
        },
        component: ProviderWaitingForApproval,
      },

      {
        path: "estimates",
        name: "ProviderEstimates",
        component: ProviderEstimates,
      },
      {
        path: "estimates/create",
        name: "ProviderEstimateCreate",
        component: ProviderEstimateCreate,
      },

      {
        path: "estimates/:id/details",
        name: "ProviderEstimateDetails",
        component: ProviderEstimateDetails,
      },

      {
        path: "estimates/:id/edit",
        name: "ProviderEstimateEdit",
        component: ProviderEstimateEdit,
      },

      {
        path: "client-portfolio",
        name: "ClientPortfolioList",
        component: ClientPortfolioList,
      },

      {
        path: "client-portfolio/:id/detail/:tag",
        name: "ClientPortfolioDetail",
        component: ClientPortfolioDetail,
      },


      {
        path: "client-portfolio/jobs/:id/create",
        name: "ClientPortfolioJobCreate",
        component: ClientPortfolioJobCreate,
      },

      {
        path: "client-portfolio/jobs/:id/details",
        name: "ClientPortfolioJobDetails",
        component: ClientPortfolioJobDetails,
      },

      {
        path: "client-portfolio/jobs/:id/edit",
        name: "ClientPortfolioJobEdit",
        component: ClientPortfolioJobEdit,
      },
      {
        path: "client-portfolio/jobs/:id/to-do-list",
        name: "ClientPortfolioJobToDoList",
        component: ClientPortfolioJobToDoList,
      },
      {
        path: "client-portfolio/create",
        name: "ClientPortfolioInfoCreate",
        component: ClientPortfolioInfoCreate,
      },

      {
        path: "client-portfolio/detail/:id/edit",
        name: "ClientPortfolioInfoEdit",
        component: ClientPortfolioInfoEdit,
      },

      {
        path: "client-portfolio/property/:id/create",
        name: "ClientPortfolioPropertyCreate",
        component: ClientPortfolioPropertyCreate,
      },

      {
        path: "client-portfolio/property/:id/edit",
        name: "ClientPortfolioPropertyEdit",
        component: ClientPortfolioPropertyEdit,
      },
      {
        path: "client-portfolio/:id/bulk",
        name: "ClientPortfolioInfoBulk",
        component: ClientPortfolioInfoBulk,
      },

      {
        path: "wallet",
        name: "ProviderCreditCardsList",
        component: ProviderCreditCardsList,
      },

      {
        path: "wallet/cerate-credit-card",
        name: "ProviderCreateCreditCard",
        component: ProviderCreateCreditCard,
      },
      {
        path: "withdraw",
        name: "ProviderWithdraw",
        component: ProviderWithdraw,
      },

      {
        path: "withdraw/bank-account/create",
        name: "ProviderBankAccount",
        component: ProviderBankAccount,
      },
      {
        path: "call",
        name: "ProviderCall",
        component: ProviderCall,
      },
      {
        path: "repports",
        name: "ProviderRepports",
        component: ProviderRepports,
      },

      {
        path: "Buy-bids",
        name: "ProviderBuyBids",
        component: ProviderBuyBids,
      },
    ],
  },
];
