<template>
  <div
    ref="messageDisplayContainer"
    class="messages-display"
    @scroll="updateScrollState"
  >
    <div
      v-if="loading"
      class="loader"
    >
      <submission-spinner :color="'#209516'" />
    </div>

    <div
      v-for="(message, index) in messages"
      :key="message.id"
    >
      <messages-date
        v-if="
          showMessagesDate(
            index === 0 ? null : messages[index - 1],
            messages[index]
          )
        "
        :date="message.created_at"
      />

      <template v-if="message.type !== chatMessageTypes.NOTIFICATION">
        <my-message
          v-if="message.myself"
          :message="message"
          @onDownloadFile="onDownloadFile"
        />

        <other-message
          v-else
          :message="message"
          :participant="message.owner"
          @onDownloadFile="onDownloadFile"
        />
      </template>

      <notification-message
        v-else
        :message="message"
      />
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";

import MyMessage from "@/components/chat/MyMessage.vue";
import OtherMessage from "@/components/chat/OtherMessage.vue";
import MessagesDate from "@/components/chat/MessagesDate.vue";
import NotificationMessage from "@/components/chat/NotificationMessage.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import { DateHelpers } from "@/utils/date-helpers";

import { ChatMessageTypes } from "@/configs/constants/";

export default {
  name: "MessageDisplay",

  components: {
    MyMessage,
    OtherMessage,
    MessagesDate,
    SubmissionSpinner,
    NotificationMessage,
  },

  props: {
    messages: {
      type: Array,
      required: true,
    },

    canInitChat: {
      type: Boolean,
      required: true,
    },

    scrollBottom: {
      type: Object,
      required: true,
    },

    loadMoreMessages: {
      type: Function,
      required: false,
      default: null,
    },

    stopFetchingLastMessages: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    loading: false,
    lastMessage: null,
    updateScroll: true,
    previousScrollPosition: 0,
    scrollingUp: false,
    page: 1,
  }),

  computed: {
    myself() {
      return this.$store.getters["user/user"];
    },

    chatMessageTypes() {
      return ChatMessageTypes;
    },
  },

  mounted() {
    this.goToBottom();

    nextTick(() => {
      this.$refs.messageDisplayContainer.dispatchEvent(
        new CustomEvent("scroll")
      );
    });
  },

  updated() {
    const lastMessageIndex = this.messages.length - 1;
    if (
      this.messages.length &&
      !this.messageCompare(this.messages[lastMessageIndex], this.lastMessage)
    ) {
      if (
        this.updateScroll ||
        (this.scrollBottom.messageSent &&
          this.messages[lastMessageIndex].owner?.id == this.myself.id) ||
        (this.scrollBottom.messageReceived &&
          this.messages[lastMessageIndex].owner?.id != this.myself.id)
      ) {
        this.goToBottom();
        if (this.messages.length) {
          this.lastMessage = this.messages[lastMessageIndex];
        }
      }
    }
  },

  methods: {
    ...DateHelpers,

    showMessagesDate(previousMessage, currentMessage) {
      if (!previousMessage) return true;

      return !this.isSame(
        currentMessage.created_at,
        previousMessage.created_at
      );
    },

    onDownloadFile(message) {
      this.$emit("onDownloadFile", message);
    },

    goToBottom() {
      let scrollDiv = this.$refs.messageDisplayContainer;
      scrollDiv.scrollTop = scrollDiv.scrollHeight;

      this.updateScroll = false;
    },

    messageCompare(message1, message2) {
      if (!message2 || !message1) {
        return message1 === message2;
      }

      let isSameParticipant = message1.owner?.id == message2.owner?.id;
      let isSameContent = message1.content == message2.content;
      let isSameCreatedAt = this.isSame(
        message1.created_at,
        message2.created_at,
        "second"
      );

      return isSameParticipant && isSameContent && isSameCreatedAt;
    },

    updateScrollState({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.updateScrollPositon(scrollTop);

      this.updateScroll = scrollTop + clientHeight >= scrollHeight;

      if (
        !this.stopFetchingLastMessages &&
        this.scrollingUp &&
        typeof this.loadMoreMessages === "function" &&
        scrollTop < 20 &&
        !this.loading
      ) {
        this.loading = true;
        ++this.page;

        this.loadMoreMessages(this.page, () => {
          this.loading = false;
        });
      }
    },

    updateScrollPositon(scrollTop) {
      const currentScrollPosition = scrollTop;
      if (currentScrollPosition > this.previousScrollPosition) {
        this.scrollingUp = false;
      } else if (currentScrollPosition < this.previousScrollPosition) {
        this.scrollingUp = true;
      }
      this.previousScrollPosition = currentScrollPosition;
    },
  },
};
</script>
