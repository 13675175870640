<template>
  <div class="msg-end d-flex justify-content-end text-end mb-4 pt-1">
    <div>
      <div v-if="message.type == chatMessageTypes.TEXT">
        <p class="ms-xender p-2 me-3">
          {{ message.content }}
        </p>
      </div>

      <div
        v-else
        class="message-image mine"
      >
        <template v-if="message.uploaded">
          <img
            class="message-image-display"
            :src="message.file?.url"
            @click="onDownloadFile(message)"
          >
        </template>

        <template v-else>
          <img
            class="message-image-display img-overlay"
            :src="message.preview"
          >
        </template>
      </div>

      <div>
        <p
          v-if="false"
          class="p-2 me-3 mb-1 ms-xender"
        >
          {{ message.content }}
        </p>

        <p class="me-3 mb-3 d-flex justify-content-end hours">
          {{ formatDate(message.created_at, "HH:mm") }}
        </p>
      </div>
    </div>

    <profile-avatar
      :classes="'mr-0'"
      :url="myself?.photo?.url || ''"
      :fullname="myself?.full_name || ''"
      :color="myself?.profile_color || ''"
      :background="myself?.profile_background || ''"
    />
  </div>
</template>

<script>
import { DateHelpers } from "@/utils/date-helpers";

import { ChatMessageTypes } from "@/configs/constants/";

import ProfileAvatar from "@/components/ProfileAvatar.vue";

export default {
  name: "MyMessage",

  components: {
    ProfileAvatar
  },

  props: {
    message: {
      type: Object,
      required: true,
      default: null,
    },
  },

  computed: {
    chatMessageTypes() {
      return ChatMessageTypes;
    },

    myself() {
      return this.$store.getters["user/user"];
    },
  },

  methods: {
    ...DateHelpers,

    onDownloadFile(message) {
      this.$emit("onDownloadFile", message);
    },
  },
};
</script>
