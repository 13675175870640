<template>
  <div v-if="detail !== null && action === 'detail' && !loading">
    <div class="modal-body">
      <div class="calendar-section">
        <span>Title</span>
        <p>{{ detail.event.title }}</p>
      </div>

      <div class="calendar-section">
        <span>Description</span>
        <p v-html="detail.event.extendedProps.description" />
      </div>

      <div class="calendar-section">
        <span>Start Date</span>
        <p>{{ formatDate(detail.event.start,'MM-DD-YYYY / hh:mma') }}</p>
      </div>

      <div class="calendar-section">
        <span>End Date</span>
        <p>{{ formatDate(detail.event.extendedProps.endDate,'MM-DD-YYYY / hh:mma') }}</p>
      </div>

      <div class="calendar-section">
        <span>Duration</span>
        <p>
          {{
            diffDays(detail.event.start, detail.event.extendedProps.endDate) ===
              0
              ? "Your event is over"
              : diffDays(
                detail.event.start,
                detail.event.extendedProps.endDate
              ) + " days"
          }}
        </p>
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn-danger"
        @click="deteleAction"
      >
        Delete
      </button>
      <button
        type="button"
        class="btn-primary"
        @click="edit"
      >
        Edit
      </button>
    </div>
  </div>
</template>

<script>
import { DateHelpers } from "@/utils/date-helpers";

export default {
  props: {
    detail: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
      required: true,
    },

    action: {
      type: String,
      required: true,
    },
  },

  methods: {
    ...DateHelpers,

    deteleAction() {
      this.$emit("deleteView");
    },

    edit() {
      this.$emit("edit");
    },
  },
};
</script>
