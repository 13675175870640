import { UserScopes } from "@/configs/constants/users";
// layouts

import SuperAdminAuthLayout from "@/layouts/SuperAdminAuthLayout.vue";
import SuperAdminLayout from "@/layouts/SuperAdminLayout.vue";

// view

import SuperAdminLogin from "@/views/superadmin/auth/Login.vue";
import SuperAdminDashboard from "@/views/superadmin/Dashboard.vue";
import SuperAdminCustomers from "@/views/superadmin/customers/List.vue";
import SuperAdminCustomerDetails from "@/views/superadmin/customers/Details.vue";
import SuperAdminProviders from "@/views/superadmin/providers/List.vue";
import SuperAdminProviderDetails from "@/views/superadmin/providers/Details.vue";
import SuperAdminProvidersAprovals from "@/views/superadmin/providers/approvals/List.vue";
import SuperAdminProvidersAprovalDetails from "@/views/superadmin/providers/approvals/Details.vue";
import SuperAdminJobs from "@/views/superadmin/jobs/List.vue";
import SuperAdminJobDetails from "@/views/superadmin/jobs/Details.vue";
import SuperAdminJobsSettings from "@/views/superadmin/jobs/settings/Index.vue";
import SuperAdminJobsSettingsSkills from "@/views/superadmin/jobs/settings/Skills.vue";
import SuperAdminJobsSettingsContractTypes from "@/views/superadmin/jobs/settings/ContractTypes.vue";
import SuperAdminJobsSettingsLevels from "@/views/superadmin/jobs/settings/Levels.vue";
import SuperAdminJobsSettingsLanguages from "@/views/superadmin/jobs/settings/Languages.vue";
import SuperAdminJobsSettingsScreeningQuestions from "@/views/superadmin/jobs/settings/ScreeningQuestions.vue";
import SuperAdminJobsSettingsJobCategories from "@/views/superadmin/jobs/settings/Categories.vue";
import SuperAdminJobsSettingsJobItems from "@/views/superadmin/jobs/settings/Items.vue";
import SuperAdminJobsSettingsJobVersions from "@/views/superadmin/jobs/settings/Versions.vue";
import SuperAdminInvoices from "@/views/superadmin/invoices/List.vue";
import SuperAdminInvoiceDetails from "@/views/superadmin/invoices/Detail.vue";
import SuperAdminEstimates from "@/views/superadmin/estimations/List.vue";
import SuperAdminEstimateDetails from "@/views/superadmin/estimations/Detail.vue";
import SuperAdminStatistics from "@/views/superadmin/statistics/Index.vue";
import SuperAdminPlans from "@/views/superadmin/plans/List.vue";
import SuperAdmiPlanCreate from "@/views/superadmin/plans/Create.vue";
import SuperAdmiPlanDetails from "@/views/superadmin/plans/Detail.vue";
import SuperAdmiPlanEdit from "@/views/superadmin/plans/Edit.vue";
import SuperAdminCoupon from "@/views/superadmin/coupon/List.vue";
import SuperAdminCouponCreate from "@/views/superadmin/coupon/Create.vue";
import SuperAdminCouponDetails from "@/views/superadmin/coupon/Detail.vue";
import SuperAdminCouponEdit from "@/views/superadmin/coupon/Edit.vue";
import SuperAdminServices from "@/views/superadmin/services/List.vue";
import SuperAdminServicesCreate from "@/views/superadmin/services/Create.vue";
import SuperAdminServicesDetails from "@/views/superadmin/services/Detail.vue";
import SuperAdminServicesEdit from "@/views/superadmin/services/Edit.vue";
import SuperAdminBids from "@/views/superadmin/bids/List.vue";
import SuperAdminBidsCreate from "@/views/superadmin/bids/Create.vue";
import SuperAdminBidsDetails from "@/views/superadmin/bids/Detail.vue";
import SuperAdminBidsEdit from "@/views/superadmin/bids/Edit.vue";

export default [
  {
    path: "/superadmin-auth",
    component: SuperAdminAuthLayout,
    children: [
      {
        path: "",
        name: "SuperAdminLogin",
        component: SuperAdminLogin,
      },
    ],
  },

  {
    path: "/superadmin",
    redirect: "/superadmin/home",
    meta: {
      neededScope: [UserScopes.SUPER_ADMIN],
      authRequired: true,
    },
    component: SuperAdminLayout,
    children: [
      {
        path: "home",
        name: "SuperAdminDashboard",
        component: SuperAdminDashboard,
      },

      {
        path: "customers",
        name: "SuperAdminCustomers",
        component: SuperAdminCustomers,
      },

      {
        path: "customers/:id/details/:tag",
        name: "SuperAdminCustomerDetails",
        component: SuperAdminCustomerDetails,
      },

      {
        path: "providers",
        name: "SuperAdminProviders",
        component: SuperAdminProviders,
      },

      {
        path: "providers/:id/details/:tag",
        name: "SuperAdminProviderDetails",
        component: SuperAdminProviderDetails,
      },

      {
        path: "providers/approvals/list",
        name: "SuperAdminProvidersAprovals",
        component: SuperAdminProvidersAprovals,
      },
      {
        path: "providers/approvals/:id/details",
        name: "SuperAdminProvidersAprovalDetails",
        component: SuperAdminProvidersAprovalDetails,
      },

      {
        path: "services",
        name: "SuperAdminServices",
        component: SuperAdminServices,
      },

      {
        path: "jobs",
        name: "SuperAdminJobs",
        component: SuperAdminJobs,
      },

      {
        path: "jobs/:id/details",
        name: "SuperAdminJobDetails",
        component: SuperAdminJobDetails,
      },

      {
        path: "jobs/settings",
        name: "SuperAdminJobsSettings",
        component: SuperAdminJobsSettings,
      },
      {
        path: "jobs/settings/skills",
        name: "SuperAdminJobsSettingsSkills",
        component: SuperAdminJobsSettingsSkills,
      },
      {
        path: "jobs/settings/contract-types",
        name: "SuperAdminJobsSettingsContractTypes",
        component: SuperAdminJobsSettingsContractTypes,
      },
      {
        path: "jobs/settings/levels",
        name: "SuperAdminJobsSettingsLevels",
        component: SuperAdminJobsSettingsLevels,
      },
      {
        path: "jobs/settings/languages",
        name: "SuperAdminJobsSettingsLanguages",
        component: SuperAdminJobsSettingsLanguages,
      },
      {
        path: "jobs/settings/screening-questions",
        name: "SuperAdminJobsSettingsScreeningQuestions",
        component: SuperAdminJobsSettingsScreeningQuestions,
      },
      {
        path: "jobs/settings/categories",
        name: "SuperAdminJobsSettingsJobCategories",
        component: SuperAdminJobsSettingsJobCategories,
      },
      {
        path: "jobs/settings/items",
        name: "SuperAdminJobsSettingsJobItems",
        component: SuperAdminJobsSettingsJobItems,
      },
      {
        path: "jobs/settings/versions",
        name: "SuperAdminJobsSettingsJobVersions",
        component: SuperAdminJobsSettingsJobVersions,
      },

      {
        path: "invoices",
        name: "SuperAdminInvoices",
        component: SuperAdminInvoices,
      },
      {
        path: "invoices/:id",
        name: "SuperAdminInvoiceDetails",
        component: SuperAdminInvoiceDetails,
      },
      {
        path: "estimations",
        name: "SuperAdminEstimates",
        component: SuperAdminEstimates,
      },

      {
        path: "estimations/:id/detail",
        name: "SuperAdminEstimateDetails",
        component: SuperAdminEstimateDetails,
      },
      {
        path: "statistics",
        name: "SuperAdminStatistics",
        component: SuperAdminStatistics,
      },

      {
        path: "plans",
        name: "SuperAdminPlans",
        component: SuperAdminPlans,
      },
      {
        path: "plans/create",
        name: "SuperAdmiPlanCreate",
        component: SuperAdmiPlanCreate,
      },
      {
        path: "plans/:id",
        name: "SuperAdmiPlanDetails",
        component: SuperAdmiPlanDetails,
      },
      {
        path: "plans/:id/edit",
        name: "SuperAdmiPlanEdit",
        component: SuperAdmiPlanEdit,
      },

      {
        path: "coupon",
        name: "SuperAdminCoupon",
        component: SuperAdminCoupon,
      },
      {
        path: "coupon/create",
        name: "SuperAdminCouponCreate",
        component: SuperAdminCouponCreate,
      },
      {
        path: "coupon/:id",
        name: "SuperAdminCouponDetails",
        component: SuperAdminCouponDetails,
      },
      {
        path: "coupon/:id/edit",
        name: "SuperAdminCouponEdit",
        component: SuperAdminCouponEdit,
      },
      {
        path: "services",
        name: "SuperAdminServices",
        component: SuperAdminServices,
      },
      {
        path: "services/create",
        name: "SuperAdminServicesCreate",
        component: SuperAdminServicesCreate,
      },
      {
        path: "services/:id",
        name: "SuperAdminServicesDetails",
        component: SuperAdminServicesDetails,
      },
      {
        path: "services/:id/edit",
        name: "SuperAdminServicesEdit",
        component: SuperAdminServicesEdit,
      },
      {
        path: "bids",
        name: "SuperAdminBids",
        component: SuperAdminBids,
      },
      {
        path: "bids/create",
        name: "SuperAdminBidsCreate",
        component: SuperAdminBidsCreate,
      },
      {
        path: "bids/:id",
        name: "SuperAdminBidsDetails",
        component: SuperAdminBidsDetails,
      },
      {
        path: "bids/:id/edit",
        name: "SuperAdminBidsEdit",
        component: SuperAdminBidsEdit,
      },
    ],
  },
];
