<template>
  <div class="dashboard-provider">
    <list-header
      title="Invoices"
      :add="true"
      :hide="(includeService('invoicing') && includePlan('invoice_management'))"
      :options="headerOptions"
      @create="create"
      @update:options="onListHeaderUpdate"
    />

    <div
      class="row mb-3"
      :class="{'filter-blur': (includeService('invoicing') && includePlan('invoice_management'))}"
    >
      <div
        v-for="statistic in statistics"
        :key="statistic.value"
        class="col-md-6 col-lg-3"
      >
        <div class="statistic-item">
          <div class="card">
            <h3>{{ (includeService('invoicing') && includePlan('invoice_management')) ? 0 : statistic.count }}</h3>

            <p>{{ (includeService('invoicing') && includePlan('invoice_management')) ? 'Locked' : statistic.title }}</p>

            <span class="linear-bar" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table
            class="table"
            :class="{'filter-blur': (includeService('invoicing') && includePlan('invoice_management'))}"
          >
            <head-table
              :list="thead"
              :hide="(includeService('invoicing') && includePlan('invoice_management'))"
            />

            <tbody v-if="!fetching">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td class="py-3">
                  <span
                    class="text-primary cursor-pointer"
                    @click="detail(item)"
                  >{{ item.invoice_no }}</span>
                </td>
                <td>
                  {{ item.customer_name }}
                </td>
                <td>
                  {{ formatPrice((item.total || 0).toFixed(2)) }}
                </td>
                <td>
                  {{ formatDate(item.created_at, "MMMM Do, YYYY hh:mm") }}
                </td>
                <td>
                  {{ formatDate(item.send) }}
                </td>
                <td>
                  <app-invoice-status
                    :status="item.status || ''"
                    :short="true"
                  />
                </td>
                <td>
                  <submission-spinner
                    v-if="
                      (sendingInvoice ||
                        remindingInvoice ||
                        deletingInvoice ||
                        submittingExternalPayment) &&
                        selectedInvoice?.id === item.id
                    "
                    :color="'#209516'"
                  />
                  <option-action
                    v-else
                    :status="item.status"
                    :options="getActions(item.status, item.send)"
                    @update:options="choose(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetching" />

          <app-pagination
            v-if="totalPages > 1"
            :align-end="true"
            :current-page="currentPage"
            :has-previous-page="hasPreviousPage"
            :previous-page="currentPage - 1 || 1"
            :has-next-page="hasNextPage"
            :next-page="currentPage + 1"
            :per-page="itemsPerPage"
            :total-elements="itemsCount"
            :total-pages="totalPages"
            @onGotoPage="fetchInvoices({ page: $event })"
            @onPrev="fetchInvoices({ page: currentPage - 1 || 1 })"
            @onNext="fetchInvoices({ page: currentPage + 1 })"
          />
        </div>
      </div>

      <confirm-modal
        v-if="modalConfigs.showConfirmModal"
        ref="confirmModal"
        :ok-button-title="modalConfigs.confirmTitle"
        :close-button-title="'No'"
        :message="modalConfigs.confirmMessage"
        @onConfirm="onModalConfirm"
      />
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import OptionAction from "@/components/OptionAction.vue";

import api from "@/services/api";
import ListMixin from "@/mixins/list";

import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import statusToUI from "@/utils/statusFormate";
import {
  GlobalConstants,
  InvoiceStatus,
  InvoiceStatusList,
} from "@/configs/constants";

import ConfirmModal from "@/components/ConfirmModal.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import AppPagination from "@/components/AppPagination.vue";
import AppInvoiceStatus from "@/components/InvoiceStatus.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

export default {
  name: "ProviderWalletInvoicesPage",

  components: {
    ListHeader,
    HeadTable,
    LoaderSpinner,
    AppPagination,
    ConfirmModal,
    SubmissionSpinner,
    OptionAction,
    AppInvoiceStatus,
  },

  mixins: [ListMixin(api.fetchInvoices, true)],

  data: () => ({
    headerOptions: {
      title: InvoiceStatusList[0].title,
      list: InvoiceStatusList,
    },
    thead: [
      "Invoice No.",
      "Vendor name",
      "Amount",
      "Creation date",
      "Sent date",
      "Status",
      "",
    ],
    status: null,
    search: null,
    fetching: false,
    statistics: [
      {
        title: "Unsent",
        value: InvoiceStatus.UNSENT,
        count: 0,
      },
      {
        title: "Pending",
        value: InvoiceStatus.PENDING,
        count: 0,
      },
      {
        title: "Partial",
        value: InvoiceStatus.PARTIAL,
        count: 0,
      },
      {
        title: "Paid",
        value: InvoiceStatus.PAID,
        count: 0,
      },
      {
        title: "Reversed",
        value: InvoiceStatus.REFUND,
        count: 0,
      },
      {
        title: "External Pay",
        value: InvoiceStatus.EXTERNAL_PAY,
        count: 0,
      },
    ],
    actions: {
      SEND: "send-invoice",
      REMIND: "remind-invoice",
      DELETE: "delete-invoice",
      EXTERNAL_PAYMENT: "external-payment",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    selectedInvoice: null,
    sendingInvoice: false,
    remindingInvoice: false,
    deletingInvoice: false,
    submittingExternalPayment: false,
  }),

  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    },
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },
  },
  watch: {
    listData(val) {
      this.fetching = false;
    },
  },

  mounted() {
    if (this.includeService('invoicing') && this.includePlan('invoice_management')) {
      
    } else {
      this.fetchInvoicesStatistics();
      this.fetchInvoices({ page: Number(this.$route.query.page) || 1 });
    }

  },

  methods: {
    ...DateHelpers,
    ...statusToUI,
    ...PriceHelpers,

    includeService(code) {
      let service = this.currentPlanServices?.find(el=> el.code == code);
      if (service == undefined) {
        return true;
      } else {
        return false
      }
    },
    includePlan(key) {
      if (this.currentPlan && this.currentPlan[key] == null) {
        return true;
      } else {
        return false
      }
    },
    getActions(status, sendDate) {
      let items = [
        {
          title: "Details",
          value: "details",
        },
      ];
      if (this.canEditOrSendInvoice(status)) {
        items.push({
          title: "Edit",
          value: "edit",
        });
        items.push({
          title: "Send",
          value: "send",
        });
      }
      if (this.canRemindInvoice(status, sendDate)) {
        items.push({
          title: "Remind",
          value: "remind",
        });
      }
      if (this.canDeleteInvoice(status)) {
        items.push({
          title: "Delete",
          value: "delete",
        });
      }
      if (this.canMakeExternalPayment(status, sendDate)) {
        items.push({
          title: "External payment",
          value: "externalpayment",
        });
      }

      return items;
    },

    canEditOrSendInvoice(status) {
      if (!status) return false;
      return status === InvoiceStatus.UNSENT;
    },

    canRemindInvoice(status, sendDate) {
      if (!status || !sendDate) return false;
      return (
        status === InvoiceStatus.PENDING &&
        new Date().getTime() > new Date(sendDate).getTime()
      );
    },

    canDeleteInvoice(status) {
      if (!status) return false;
      return [InvoiceStatus.UNSENT, InvoiceStatus.PENDING].includes(status);
    },

    canMakeExternalPayment(status) {
      if (!status) return false;
      return status === InvoiceStatus.PENDING;
    },

    choose(item, event) {
      switch (event) {
        case "details":
          this.detail(item);
          break;

        case "edit":
          this.$router.push({
            name: "ProviderInvoiceEdit",
            params: { id: item.id },
          });
          break;

        case "send":
          this.confirmClaim(item, this.actions.SEND);
          break;

        case "remind":
          this.confirmClaim(item, this.actions.REMIND);
          break;

        case "delete":
          this.confirmClaim(item, this.actions.DELETE);
          break;

        case "externalpayment":
          this.confirmClaim(item, this.actions.EXTERNAL_PAYMENT);
          break;
      }
    },

    confirmClaim(item, action) {
      switch (action) {
        case this.actions.SEND:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to send the invoice ${item.invoice_no} ?`;
          break;

        case this.actions.REMIND:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to remind the customer for invoice ${item.invoice_no} payment ?`;
          break;

        case this.actions.DELETE:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to delete the invoice ${item.invoice_no} ?`;
          break;

        case this.actions.EXTERNAL_PAYMENT:
          this.modalConfigs.confirmTitle = "Confirm";
          this.modalConfigs.confirmMessage = `Confirm that the invoice ${item.invoice_no} has been paid outside of the ServiceLoop system`;
          break;
      }
      this.selectedInvoice = item;
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.SEND:
          this.sendInvoice();
          break;

        case this.actions.REMIND:
          this.remindInvoice();
          break;

        case this.actions.DELETE:
          this.deleteInvoice();
          break;

        case this.actions.EXTERNAL_PAYMENT:
          this.setInvoiceExternalPayment();
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    onListHeaderUpdate(event) {
      this.fetchInvoices({ page: 1, status: event.value ?? undefined });
      this.headerOptions.title = event.title;
      this.status = event.value;
    },
    onListHeaderSearch(event) {
      this.fetchInvoices({ page: 1, status: event });
    },

    fetchInvoices(params) {
      this.fetching = true;
      this.updateListFilters({
        ...params,
      });
    },

    async fetchInvoicesStatistics() {
      try {
        const response = await api.fetchInvoicesStatistics();
        const statistics = response.data;
        this.statistics = (this.statistics ?? []).map((statistic) => {
          const matchingStatistic = statistics.find(
            (x) => x.status === statistic.value
          );
          return {
            ...statistic,
            count: matchingStatistic?.count ?? 0,
          };
        });
      } catch (_) {}
    },

    create() {
      this.$router.push({
        name: "ProviderInvoiceCreate",
        query: { ...this.$route.query },
      });
    },

    detail(item) {
      this.$router.push({
        name: "ProviderInvoiceDetails",
        params: { id: item.id },
      });
    },

    sendInvoice() {
      this.sendingInvoice = true;

      const path = this.$router.resolve({
        name: "SharedInvoiceDetails",
        params: {
          id: this.selectedInvoice.id,
        },
      })?.path;
      const redirectLink = `${GlobalConstants.BASE_URL + path}`;

      api
        .sendInvoice(this.selectedInvoice.id, redirectLink)
        .then((_) => {
          const index = this.listData.findIndex(
            (x) => x.id === this.selectedInvoice.id
          );
          if (index !== -1) {
            this.listData[index].status = InvoiceStatus.PENDING;
          }
          toast.success(
            `Invoice ${this.selectedInvoice.invoice_no} successfully sent.`
          );
          this.selectedInvoice = null;
          this.fetchInvoicesStatistics();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.sendingInvoice = false;
        });
    },

    remindInvoice() {
      this.remindingInvoice = true;

      api
        .remindInvoice(this.selectedInvoice.id)
        .then((_) => {
          toast.success(
            `Invoice ${this.selectedInvoice.invoice_no} successfully reminded.`
          );
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.remindingInvoice = false;
        });
    },

    deleteInvoice() {
      this.deletingInvoice = true;

      api
        .deleteInvoice(this.selectedInvoice.id)
        .then((_) => {
          toast.success(
            `Invoice ${this.selectedInvoice.invoice_no} successfully deleted.`
          );
          this.selectedInvoice = null;
          this.fetchInvoices({
            page: Number(this.$route.query.page) || this.currentPage,
          });
          this.fetchInvoicesStatistics();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingInvoice = false;
        });
    },

    setInvoiceExternalPayment() {
      if (this.submittingExternalPayment) return;
      this.submittingExternalPayment = true;

      api
        .setExternalPayment(this.selectedInvoice.id)
        .then((response) => {
          console.log({ response });
          const index = this.listData.findIndex(
            (x) => x.id === this.selectedInvoice.id
          );
          if (index !== -1) {
            this.listData[index].status = InvoiceStatus.PAID;
          }
          toast.success(
            `Invoice ${this.selectedInvoice.invoice_no} has been externally paid.`
          );
          this.selectedInvoice = null;
          this.fetchInvoicesStatistics();
        })
        .catch((err) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.submittingExternalPayment = false;
        });
    },
  },
};
</script>
