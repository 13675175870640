<template>
  <div class="mb-4">
    <div class="job-card w-100 h-100">
      <div class="d-flex justify-content-between">
        <p class="date mb-0">
          {{ formatDate(createdAt, "MMMM Do, YYYY") }}
        </p>

        <div class="d-flex justify-content-center align-items-center mb-0">
          <div
            v-if="getJobStatusStyle(status).color"
            class="circle me-2"
            :style="{ background: getJobStatusStyle(status).color }"
          />

          <p class="mb-0">
            {{ formatJobStatus(status) }}
          </p>
        </div>
      </div>

      <div class="job-card-title mt-2">
        {{ title }}
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <p>
          {{ numberOfProposal }}
          {{ numberOfProposal != 1 ? 'bids': 'bid' }}
        </p>
        <router-link
          :to="{ name: 'CustomerJobDetails', params: { id: slug } }"
          class="btn-secondary"
        >
          View Job
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import JobHelper from "@/utils/job";
import { DateHelpers } from "@/utils/date-helpers";

export default {
  name: "JobItemCard",

  props: {
    slug: {
      type: String,
      required: true,
      default: null,
    },
    title: {
      type: String,
      required: true,
      default: null,
    },
    createdAt: {
      type: String,
      required: true,
      default: "",
    },
    status: {
      type: String,
      required: true,
      default: null,
    },
    numberOfProposal: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  methods: {
    ...JobHelper,
    ...DateHelpers,
  },
};
</script>
<style scoped>
.job-card-title {
  /* width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  height: 2.5em;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  align-items: center;
  line-height: 1.2;
}
</style>
