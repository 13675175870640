<template>
  <div class="dashboard-provider">
    <div
      class="row"
    >
      <dashboard-statistic
        v-for="statistic in statistics"
        :key="statistic.title"
        :title="statistic.title"
        :count="statistic.count"
        :route="statistic.route"
        :is-amount="statistic.isAmount"
      />
    </div>
    <loader-spinner v-if="loading" />

    <section
      v-else-if="proposedJobs.length > 0"
      class="content"
    >
      <div class="page-header">
        <div class="w-100">
          <h3>Jobs you might like</h3>

          <button
            v-if="(includeService('marketplace') && includePlan('can_access_marketplace'))"
            disabled
            class="btn-primary"
            :class="{'filter-blur': (includeService('marketplace') && includePlan('can_access_marketplace'))}"
          >
            Locked
          </button>
          <router-link
            v-else
            :to="{ name: 'ProviderJobs' }"
            class="btn-primary"
          >
            View All
          </router-link>
        </div>
      </div>

      <div
        v-if="proposedJobs.length > 0"
        class="favorites-jobs job-list"
      >
        <job-item-card
          v-for="job in proposedJobs"
          :key="job.id"
          :job="job"
          :hide="(includeService('marketplace') && includePlan('can_access_marketplace'))"
          :is-favorite="job.provider_favorite || false"
        />
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/services/api";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import JobItemCard from "@/components/provider/jobs/JobItem.vue";
import DashboardStatistic from "@/components/provider/DashboardStatistic.vue";

import vectorSearch from "@/assets/img/icons/search-icon.svg";
import vectorheart from "@/assets/img/icons/heart.svg";

import { ProposalStatus } from "@/configs/constants/jobs";

export default {
  name: "ProviderDashboardPage",

  components: {
    JobItemCard,
    DashboardStatistic,
    LoaderSpinner,
  },

  data: () => ({
    icons: {
      vectorSearch,
      vectorheart,
    },
    loading: false,
    statistics: {
      bidsProjects: {
        title: "Projects Bid On",
        count: 0,
        isAmount: false,
        route: { 
          name: "ProviderProposals",
          params: { status: 'proposal'}
        },
      },
      rating: {
        title: "Rating",
        count: 0,
        isAmount: false,
        route: { name: "ProviderProfile" },
      },
      amountWon: {
        title: "Amount won",
        count: 0,
        isAmount: true,
        route: { name: "ProviderDashboard" },
      },
      bidsWon: {
        title: "Bids won",
        count: 0,
        isAmount: false,
        route: {
          name: "ProviderProposals",
          params: { status: ProposalStatus.ACCEPETED },
        },
      },
      active_job: {
        title: "Active Jobs",
        count: 0,
        isAmount: false,
        route: {
          name: "ProviderActiveJob",
        },
      },
    },
    proposedJobs: [],
    search: null,
  }),

  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    },
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },
  },
  mounted() {
    this.fetchProviderDashboardData();
  },

  methods: {

    includeService(code) {
      let service = this.currentPlanServices?.find(el=> el.code == code);
      if (service == undefined) {
        return true;
      } else {
        return false
      }
    },
    includePlan(key) {
      if (this.currentPlan && this.currentPlan[key] == null) {
        return true;
      } else {
        return false
      }
    },
    async fetchProviderDashboardData() {
      try {
        this.loading = true;
        const response = await api.providerDashboard();

        if (response.data) {
          this.statistics.bidsProjects.count = response.data.bids ?? 0;
          this.statistics.bidsWon.count = response.data.bids_won ?? 0;
          this.statistics.active_job.count = response.data.active_job ?? 0;
          this.statistics.rating.count = response.data.rating ?? 0;
          this.statistics.amountWon.count = response.data.amount ?? 0;
          this.proposedJobs = response.data.jobs ?? [];
        }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
