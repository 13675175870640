<template>
  <div class="d-flex justify-content-center my-5">
    <div class="custom-loader" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.custom-loader {
  width: 50px;
  height: 50px;
  --c: radial-gradient(farthest-side,#209516 92%,#209516);
  background: 
    var(--c) 50%  0, 
    var(--c) 50%  100%, 
    var(--c) 100% 50%, 
    var(--c) 0    50%;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  animation: s8 1s infinite;
  position: relative;
}
.custom-loader::before {    
  content:"";
  position: absolute;
  inset:0;
  margin: 3px;
  background:repeating-conic-gradient(#209516 0 35deg,#209516 0 90deg);
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 3px),#000 0);
  border-radius: 50%;
}

@keyframes s8 { 
  100%{transform: rotate(.5turn)}
}

</style>
