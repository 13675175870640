<template>
  <div
    v-if="customer.user != undefined"
    class="col-md-12"
  >
    <!-- Content Header (Page header) -->
    <section class="content">
      <h3>
        <router-link :to="{ name: 'SuperAdminCustomers' }">
          Customers
        </router-link>
        <span class="fw-lighter"> / {{ customer.user.full_name }}</span>
      </h3>

      <!-- Start content -->
      <div class="col-md-12 row p-4 page-content">
        <div class="col-md-12 row mb-3">
          <div class="col-md-6 d-flex">
            <profile-avatar
              :size="50"
              :url="customer.user.photo?.url"
              :fullname="customer.user.full_name"
              :color="customer.user.profile_color"
              :background="customer.user.profile_background"
            />
            <div class="ms-3">
              <h5 class="text-primary">
                {{ customer.user.full_name }}
              </h5>

              <p>{{ formatDate(customer.user.created_at) }}</p>
            </div>
          </div>

          <div class="col-md-6 text-end">
            <button
              class="btn d-block d-sm-inline-block mx-sm-3 mt-3 mt-sm-0 px-3 py-2"
              :class="
                customer.user.is_active == true
                  ? 'btn-outline-primary'
                  : 'btn-outline-danger'
              "
            >
              <span>
                {{ statusToUI(customer.user.is_active) }}
              </span>
            </button>
            <option-action
              :status="customer.user.is_active == true ? 'True' : 'False'"
              :options="CustomerStatusList"
              @update:options="confirmClaim($event)"
            />
          </div>
        </div>

        <ul class="nav nav-tabs my-5 me-3">
          <li class="nav-item">
            <div
              class="nav-link"
              :class="{ 'text-primary active': submenu == 'info' }"
              aria-current="page"
              @click="switchTag('info')"
            >
              Info
            </div>
          </li>
          <li class="nav-item">
            <div
              class="nav-link"
              :class="{ 'text-primary active': submenu == 'invoice' }"
              @click="switchTag('invoice')"
            >
              Invoices
            </div>
          </li>
          <li class="nav-item">
            <div
              class="nav-link"
              :class="{ 'text-primary active': submenu == 'job' }"
              @click="switchTag('job')"
            >
              Jobs
            </div>
          </li>
        </ul>
        <div
          v-if="submenu == 'info'"
          class="row"
        >
          <div class="col-md-12 my-3">
            <h4 class="border-bottom section-title">
              Basic Details
            </h4>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Full Name</label>
            <p class="label-content username">
              {{ customer.user.full_name }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Email</label>
            <p class="label-content">
              {{ customer.user.email }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Phone No.</label>
            <p class="label-content">
              {{ customer.user.phone }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Website</label>
            <p class="label-content">
              {{ customer.user.website }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Business Type</label>
            <p
              v-if="getBusinessType(customer.user.business_type)"
              class="label-content"
            >
              {{ getBusinessType(customer.user.business_type).title }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Country</label>
            <p
              v-if="getCountry(customer.user.country)"
              class="label-content"
            >
              {{ getCountry(customer.user.country).label }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">SSN</label>
            <p class="label-content">
              {{ customer.user.ssn }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">EIN</label>
            <p class="label-content">
              {{ customer.user.ein }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <label class="input-label fw-bold">Birthdate</label>
            <p class="label-content">
              {{ customer.user.date_of_birth }}
            </p>
          </div>
        </div>

        <div
          v-if="submenu == 'info'"
          class="row"
        >
          <div class="col-md-12 mt-3">
            <h4 class="border-bottom section-title">
              Profile Detail
            </h4>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Company Name</label>
            <p class="label-content">
              {{ customer.user.company_name }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Address Line 1</label>
            <p class="label-content">
              {{ customer.user.address_line_1 }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Address Line 2</label>
            <p class="label-content">
              {{ customer.user.address_line_2 }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">Zip Code</label>
            <p class="label-content">
              {{ customer.user.pin_code }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">City</label>
            <p class="label-content">
              {{ customer.user.city }}
            </p>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <label class="input-label fw-bold">State</label>
            <p class="label-content">
              {{ customer.user.state }}
            </p>
          </div>

          <div class="col-md-12">
            <label class="input-label fw-bold">Profile Description</label>
            <p class="label-content">
              {{ customer.user.profile_description }}
            </p>
          </div>

          <div class="col-md-12 col-lg-6">
            <label class="input-label fw-bold">Insurance Document</label>
            <div
              v-if="customer.user.insurance"
              class="document"
            >
              <a
                :href="customer.user.insurance.url"
                target="_target"
              >
                <img
                  v-if="
                    customer.user.insurance.type === mediaTypesConstants.IMAGE
                  "
                  :src="icons.imageUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                <img
                  v-else
                  :src="icons.documentUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                {{ getFileName(customer.user.insurance.original_name) }}
              </a>
            </div>
          </div>

          <div class="col-md-12 col-lg-6">
            <label class="input-label fw-bold">W9 Document</label>
            <div
              v-if="customer.user.w9document"
              class="document"
            >
              <a
                :href="customer.user.w9document.url"
                target="_target"
              >
                <img
                  v-if="
                    customer.user.w9document.type === mediaTypesConstants.IMAGE
                  "
                  :src="icons.imageUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                <img
                  v-else
                  :src="icons.documentUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                {{ getFileName(customer.user.w9document.original_name) }}
              </a>
            </div>
          </div>

          <div class="col-md-12 col-lg-6 mt-3">
            <label class="input-label">Identity Document - Front</label>
            <div
              v-if="customer.user.idfront"
              class="document"
            >
              <a
                :href="customer.user.idfront.url"
                target="_target"
              >
                <img
                  v-if="
                    customer.user.idfront.type === mediaTypesConstants.IMAGE
                  "
                  :src="icons.imageUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                <img
                  v-else
                  :src="icons.documentUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                {{ getFileName(customer.user.idfront.original_name) }}
              </a>
            </div>
          </div>

          <div class="col-md-12 col-lg-6 mt-3">
            <label class="input-label">Identity Document - Back</label>
            <div
              v-if="customer.user.idback"
              class="document"
            >
              <a
                :href="customer.user.idback.url"
                target="_target"
              >
                <img
                  v-if="customer.user.idback.type === mediaTypesConstants.IMAGE"
                  :src="icons.imageUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                <img
                  v-else
                  :src="icons.documentUploadedIcon"
                  alt="File Uploaded"
                  class="file-icon me-1"
                >
                {{ getFileName(customer.user.idback.original_name) }}
              </a>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <label class="input-label fw-bold">Portfolio (upload recent work)</label>
            <div
              v-if="customer.user.portfolio?.length > 0"
              class="row"
            >
              <div
                v-for="(file, i) in customer.user.portfolio"
                :key="i"
                class="col-md-12 col-lg-6"
                :class="{ 'mb-3': i < customer.user.portfolio.length - 1 }"
              >
                <div class="document">
                  <a
                    :href="file.portfolio?.url"
                    target="_target"
                  >
                    <img
                      v-if="file.portfolio?.type === mediaTypesConstants.IMAGE"
                      :src="icons.imageUploadedIcon"
                      alt="File Uploaded"
                      class="file-icon me-1"
                    >
                    <img
                      v-else
                      :src="icons.documentUploadedIcon"
                      alt="File Uploaded"
                      class="file-icon me-1"
                    >
                    {{ getFileName(file.portfolio?.original_name) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="submenu == 'invoice'">
          <div class="col-12">
            <div class="table-responsive">
              <loader-spinner v-if="loading" />
              <table
                v-if="!loading"
                class="table"
              >
                <head-table :list="invoice.thead" />
                <tbody>
                  <tr
                    v-for="(item, index) in invoice.listData"
                    :key="index"
                  >
                    <td class="py-3">
                      <span
                        class="cursor-pointer text-primary"
                        @click="detailinvoice(item)"
                      >{{ item.invoice_no }}</span>
                    </td>
                    <td>
                      {{ item.job_name }}
                    </td>
                    <td>
                      {{ formatPrice(item.total) }}
                    </td>
                    <td>
                      {{ formatDate(item.created_at) }}
                    </td>
                    <td>
                      {{ statusToUI(item.status) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <page-action
            :index="invoice.page"
            :infos="invoice.pages"
            @update:index="
              customerInvoices({ page: $event }), (invoice.page = $event)
            "
          />
        </div>
        <div v-if="submenu == 'job'">
          <div class="col-12">
            <div class="table-responsive">
              <loader-spinner v-if="loading" />
              <table
                v-if="!loading"
                class="table"
              >
                <head-table :list="job.thead" />
                <tbody>
                  <tr
                    v-for="(item, index) in job.listData"
                    :key="index"
                  >
                    <td>
                      <router-link
                        :to="{
                          name: 'SuperAdminJobDetails',
                          params: { id: item.id },
                        }"
                        class="text-primary"
                      >
                        {{ item.title }}
                      </router-link>
                    </td>
                    <td>
                      <template
                        v-if="item.budget_type === jobBudgetTypes.FIXED"
                      >
                        <template
                          v-if="
                            item.budget !== null && item.budget !== undefined
                          "
                        >
                          {{ formatPrice(item.budget) }}
                        </template>
                      </template>
                      <template v-else>
                        {{ formatPrice(item.budget_from ?? 0) }}
                        -
                        {{ formatPrice(item.budget_to ?? 0) }}
                      </template>
                    </td>
                    <td>
                      {{ item.level.name }}
                    </td>
                    <td>
                      {{ formatDate(item.created_at) }}
                    </td>
                    <td>
                      {{ statusToUI(item.status) }}
                    </td>
                    <td>
                      <option-action
                        :status="item.status"
                        :options="JobActionsList"
                        @update:options="confirmClaimJob(item, $event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <page-action
            :index="job.page"
            :infos="job.pages"
            @update:index="fetchAllJobs({ page: $event }), (job.page = $event)"
          />
        </div>
      </div>
      <!-- End content -->
      <confirm-modal
        ref="confirmModal"
        :ok-button-title="confirmationOkBtnText"
        :message="confirmationMessage"
        @onConfirm="updateStatus"
      />
      <confirm-modal
        ref="confirmModalJob"
        :ok-button-title="confirmationOkBtnText"
        :message="confirmationMessage"
        @onConfirm="updateStatusJob"
      />
    </section>
  </div>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal.vue";
import OptionAction from "@/components/OptionAction.vue";
import user from "@/assets/img/user.svg";
import documentIcon from "@/assets/img/icons/document-icon.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import api from "@/services/api";
import MakeActive from "@/utils/make_active";
import FileUtils from "@/utils/file";
import CountryUtils from "@/utils/country";
import BusinessTypesUtils from "@/utils/business-type";
import { MediaTypesConstants } from "@/configs/constants";

import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import { CustomerStatusList } from "@/configs/constants/users";
import {
  JobStatusList,
  JobBudgetTypes,
  JobActionsList,
} from "@/configs/constants/jobs";
import statusToUI from "@/utils/statusFormate";
import PageAction from "@/components/PageAction.vue";
import HeadTable from "@/components/HeadTable.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import ProfileAvatar from "@/components/ProfileAvatar.vue";

export default {
  name: "SuperadminCustomerDetailsPage",
  components: {
    ConfirmModal,
    OptionAction,
    PageAction,
    HeadTable,
    LoaderSpinner,
    ProfileAvatar,
  },

  data() {
    return {
      JobStatusList,
      JobActionsList,
      headerOptions: {
        title: JobStatusList[0].title,
        list: JobStatusList,
      },
      images: {
        user,
      },
      icons: {
        imageUploadedIcon,
        documentUploadedIcon,
        documentIcon,
      },
      CustomerStatusList,
      customer: {},
      loading: true,
      confirmationOkBtnText: null,
      confirmationMessage: null,
      selected: null,
      submenu: null,
      invoice: {
        thead: [
          "Invoice Title",
          "Project name",
          "Amount",
          "Date sent",
          "Status",
        ],
        listData: [],
        page: 1,
        pages: {
          count: null,
          max_page_size: null,
          total_pages: null,
        },
      },
      job: {
        thead: ["Job Title", "Budget", "Level", "Creation date", "Status", ""],
        listData: [],
        page: 1,
        pages: {
          count: null,
          max_page_size: null,
          total_pages: null,
        },
      },
    };
  },
  computed: {
    mediaTypesConstants() {
      return MediaTypesConstants;
    },
    jobBudgetTypes() {
      return JobBudgetTypes;
    },
  },

  async mounted() {
    this.submenu = this.$route.params.tag;
    await this.fetchCustomer();
    await this.init();
  },

  methods: {
    ...PriceHelpers,
    ...MakeActive,
    ...statusToUI,
    ...DateHelpers,
    ...FileUtils,
    ...CountryUtils,
    ...BusinessTypesUtils,

    async init() {
      switch (this.submenu) {
        case "invoice":
          this.customerInvoices({ page: this.page });
          break;
        case "job":
          this.fetchAllJobs({ page: this.page });
          break;

        default:
          break;
      }
    },
    switchTag(tag) {
      this.$router.replace({
        name: "SuperAdminCustomerDetails",
        params: { id: this.$route.params.id, tag: tag },
      });
      this.submenu = tag;
      this.init();
    },
    customerInvoices(param) {
      this.loading = true;

      api
        .customerInvoices({ id: this.$route.params.id, page: param })
        .then((response) => {
          this.invoice.listData = response.data.data;
          this.invoice.pages = {
            count: response.data.count,
            max_page_size: response.data.max_page_size,
            total_pages: response.data.total_pages,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },
    detailinvoice(item) {
      this.$router.push({
        name: "SuperAdminInvoiceDetails",
        params: { id: item.id },
      });
    },

    async fetchCustomer() {
      let id = this.$route.params.id;
      this.loading = true;

      try {
        let response = await api.fetchCustomer(id);
        this.customer = response.data;
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorHandler(e)
      } finally {
        this.loading = false;
      }
    },

    confirmClaim(status) {
      this.status = status === "True" ? true : false;

      this.confirmationMessage = `Are you sure you want to make ${this.statusToUI(
        this.status
      )} ${this.customer.user.full_name} account ?`;
      this.confirmationOkBtnText = "Confirm";

      this.$refs.confirmModal.openModal();
    },

    updateStatus() {
      if (this.loading) return;

      this.loading = true;
      let _api = this.status
        ? api.makeCustomerActive
        : api.makeCustomerInactive;

      _api(this.customer.id)
        .then((response) => {
          this.fetchCustomer();
          this.confirmationOkBtnText = null;
          this.confirmationMessage = null;
          this.status = null;
          this.$refs.confirmModal.closeModal();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorsHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },
    confirmClaimJob(element, status) {
      this.selected = element;
      this.status = status;

      this.confirmationMessage = `Are you sure you want to make ${this.statusToUI(
        status
      )} ${this.selected.title} account ?`;
      this.confirmationOkBtnText = "Confirm";

      this.$refs.confirmModalJob.openModal();
    },

    updateStatusJob() {
      if (this.loading) return;

      this.loading = true;

      api
        .makeJob(this.selected.id, { status: this.status })
        .then((response) => {
          this.fetchAllJobs({ page: this.page });
          this.confirmationOkBtnText = null;
          this.confirmationMessage = null;
          this.selected = null;
          this.status = null;
          this.$refs.confirmModal.closeModal();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorsHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },

    fetchAllJobs(param) {
      this.loading = true;

      api
        .fetchAllJobs(param)
        .then((response) => {
          this.job.listData = response.data.data.filter(
            (el) => el.owner.id == this.$route.params.id
          );
          this.job.pages = {
            count: response.data.count,
            max_page_size: response.data.max_page_size,
            total_pages: response.data.total_pages,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
