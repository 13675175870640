<template>
  <section class="content profile">
    <alerte-danger
      :head-error-message="error"
      @updateContent="error = $event"
    />

    <div class="float-end">
      <option-action
        :status="null"
        :options="options"
        @update:options="choose($event)"
      />
    </div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <div
          class="nav-link"
          :class="{ 'text-primary active': submenu == 'info' }"
          aria-current="page"
          @click="switchTag('info')"
        >
          Client info
        </div>
      </li>
      <li class="nav-item">
        <div
          class="nav-link"
          :class="{ 'text-primary active': submenu == 'properties' }"
          @click="switchTag('properties')"
        >
          Properties
        </div>
      </li>
      <li class="nav-item">
        <div
          class="nav-link"
          :class="{ 'text-primary active': submenu == 'jobs' }"
          @click="switchTag('jobs')"
        >
          Jobs
        </div>
      </li>
      <li class="nav-item">
        <div
          class="nav-link"
          :class="{ 'text-primary active': submenu == 'estimates' }"
          @click="switchTag('estimates')"
        >
          Estimates
        </div>
      </li>
      <li class="nav-item">
        <div
          class="nav-link"
          :class="{ 'text-primary active': submenu == 'bulk' }"
          @click="switchTag('bulk')"
        >
          Properties Bulk Requests
        </div>
      </li>
    </ul>
    <div
      v-if="client && submenu == 'info'"
      class="col-md-12 row page-content"
    >
      <div class="col-md-12 row">
        <div class="col-md-2">
          <avatar
            :name="client.full_name"
            :size="130"
            :color="'#fff'"
            :background="'#209516'"
            class="user-image"
            alt="User Image"
          />
        </div>
        <div class="col-md-10 pl-5">
          <div class="row">
            <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
              <label class="input-label fw-bold">Client Name</label>
              <p class="label-content username">
                {{ client.full_name }}
              </p>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
              <label class="input-label fw-bold">Company Name</label>
              <p class="label-content">
                {{ client.company_name }}
              </p>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
              <label class="input-label fw-bold">Created At</label>
              <p class="label-content">
                {{ formatDate(client.created_at) }}
              </p>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
              <label class="input-label fw-bold">Email</label>
              <p class="label-content text-truncate">
                {{ client.email }}
              </p>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
              <label class="input-label fw-bold">Phone No.</label>
              <p class="label-content">
                {{ client.phone }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="client.main_propriety"
        class="row"
      >
        <div class="col-md-12 mt-3">
          <h4 class="border-bottom section-title">
            Main property
          </h4>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Address</label>
          <p class="label-content">
            {{ client.main_propriety.location_description }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Zip Code</label>
          <p class="label-content">
            {{ client.main_propriety.zip }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">City</label>
          <p class="label-content">
            {{ client.main_propriety.city }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">State</label>
          <p class="label-content">
            {{ client.main_propriety.state }}
          </p>
        </div>
      </div>

      <div
        v-if="client.primary_contact"
        class="row"
      >
        <div class="col-md-12 mt-3">
          <h4 class="border-bottom section-title">
            Primary Contact
          </h4>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Name</label>
          <p class="label-content">
            {{ client.primary_contact.full_name }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Phone</label>
          <p class="label-content">
            {{ client.primary_contact.phone }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">E-mail</label>
          <p class="label-content text-truncate">
            {{ client.primary_contact.email }}
          </p>
        </div>

        <div class="col-md-12">
          <label class="input-label fw-bold">Note</label>
          <p class="label-content">
            {{ client.primary_contact.note }}
          </p>
        </div>
      </div>

      <div
        v-for="(contact, index) in client.additional_contacts"
        :key="index"
        class="row"
      >
        <div class="col-md-12 mt-3">
          <h4 class="border-bottom section-title">
            Additional Contact
          </h4>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Name</label>
          <p class="label-content">
            {{ contact.full_name }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">Phone</label>
          <p class="label-content">
            {{ contact.phone }}
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <label class="input-label fw-bold">E-mail</label>
          <p class="label-content text-truncate">
            {{ contact.email }}
          </p>
        </div>

        <div class="col-md-12">
          <label class="input-label fw-bold">Note</label>
          <p class="label-content">
            {{ contact.note }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-3">
          <h4 class="border-bottom section-title">
            Notes
          </h4>
        </div>

        <div
          v-for="(note, index) in client.notes"
          :key="index"
          class="col-md-12"
        >
          <label class="input-label fw-bold">Note</label>
          <p class="label-content">
            {{ note.text }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="submenu == 'properties'">
      <div class="d-flex">
        <button
          class="btn-primary my-3 me-3"
          type="button"
          @click="addProperty"
        >
          Add Property
        </button>
        <button
          class="btn-primary my-3"
          type="button"
          :disabled="exportloading"
          @click="downloadClientPropertiesCSV"
        >
          <submission-spinner
            v-if="exportloading"
            :color="'#209516'"
          />
          Export
        </button>
      </div>
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="thead" />

            <tbody v-if="!fetchingClientProperties">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td class="py-3 text-primary">
                  {{ item.location_description }}
                </td>
                <td>
                  {{ item.zip }}
                </td>

                <td>
                  {{ item.city }}
                </td>
                <td>
                  {{ item.state }}
                </td>
                <td>
                  {{ item.is_main ? "Yes" : "No" }}
                </td>
                <td>
                  <submission-spinner
                    v-if="
                      deletingClientProperty &&
                        selectedClientPropertyId === item.id
                    "
                    :color="'#209516'"
                  />
                  <option-action
                    v-else
                    :status="item.status"
                    :options="optionsAction"
                    @update:options="chooseProperty(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetchingClientProperties" />

          <app-pagination
            v-if="clientPropertiesPage.totalPages > 1"
            :align-end="true"
            :current-page="clientPropertiesPage.currentPage"
            :has-previous-page="clientPropertiesPage.hasPreviousPage"
            :previous-page="clientPropertiesPage.currentPage - 1 || 1"
            :has-next-page="clientPropertiesPage.hasNextPage"
            :next-page="clientPropertiesPage.currentPage + 1"
            :per-page="clientPropertiesPage.itemsPerPage"
            :total-elements="clientPropertiesPage.itemsCount"
            :total-pages="clientPropertiesPage.totalPages"
            @onGotoPage="fetchClientProperties($event)"
            @onPrev="
              fetchClientProperties(clientPropertiesPage.currentPage - 1 || 1)
            "
            @onNext="
              fetchClientProperties(clientPropertiesPage.currentPage + 1)
            "
          />
        </div>
      </div>
    </div>

    <div v-if="submenu == 'jobs'">
      <list-header
        title="Jobs"
        :add="true"
        :options="propertiesOption"
        @create="addJob"
        @update:options="fetchClientJobs({page: jobPage.currentPage, provider_propertie_associate:$event.value})"
      />
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="theadJob" />

            <tbody v-if="!fetchingJobs">
              <tr
                v-for="(item, index) in listJob"
                :key="index"
              >
                <td class="py-3">
                  <span
                    class="cursor-pointer text-primary"
                    @click="detailJob(item)"
                  >{{ item.job.title }}</span>
                </td>
                <td class="text-wrap">
                  {{ item.provider_propertie_associate.location_description }}
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>
                <td>
                  {{ statusToUI(item.status) }}
                </td>
                <td>
                  <submission-spinner
                    v-if="deletingJob && selectedJobId === item.id"
                    :color="'#209516'"
                  />
                  <option-action
                    v-else
                    :status="item.status"
                    :options="optionsAction"
                    @update:options="chooseJob(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetchingJobs" />

          <app-pagination
            v-if="jobPage.totalPages > 1"
            :align-end="true"
            :current-page="jobPage.currentPage"
            :has-previous-page="jobPage.hasPreviousPage"
            :previous-page="jobPage.currentPage - 1 || 1"
            :has-next-page="jobPage.hasNextPage"
            :next-page="jobPage.currentPage + 1"
            :per-page="jobPage.itemsPerPage"
            :total-elements="jobPage.itemsCount"
            :total-pages="jobPage.totalPages"
            @onGotoPage="fetchClientJobs({ page: $event, search })"
            @onPrev="
              fetchClientJobs({
                page: jobPage.currentPage - 1 || 1,
                search,
              })
            "
            @onNext="
              fetchClientJobs({ page: jobPage.currentPage + 1, search })
            "
          />
        </div>
      </div>
    </div>
    <div v-if="submenu == 'estimates'">
      <button
        class="btn-primary my-3 me-3"
        type="button"
        @click="addEstimate"
      >
        Add Estimate
      </button>
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="theadEstimate" />

            <tbody v-if="!fetchingEstimates">
              <tr
                v-for="(item, index) in listEstimate"
                :key="index"
              >
                <td class="py-3">
                  <span
                    class="cursor-pointer text-primary"
                    @click="detailEstimate(item)"
                  >{{ item.title }}</span>
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>
                <td>
                  {{ statusToUI(item.status) }}
                </td>
                <td>
                  <submission-spinner
                    v-if="deletingEstimate && selectedEstimateId === item.id"
                    :color="'#209516'"
                  />
                  <option-action
                    v-else
                    :status="item.status"
                    :options="optionsAction"
                    @update:options="chooseEstimate(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetchingEstimates" />

          <app-pagination
            v-if="estimatePage.totalPages > 1"
            :align-end="true"
            :current-page="estimatePage.currentPage"
            :has-previous-page="estimatePage.hasPreviousPage"
            :previous-page="estimatePage.currentPage - 1 || 1"
            :has-next-page="estimatePage.hasNextPage"
            :next-page="estimatePage.currentPage + 1"
            :per-page="estimatePage.itemsPerPage"
            :total-elements="estimatePage.itemsCount"
            :total-pages="estimatePage.totalPages"
            @onGotoPage="fetchEstimates({ page: $event, search })"
            @onPrev="
              fetchEstimates({
                page: estimatePage.currentPage - 1 || 1,
                search,
              })
            "
            @onNext="
              fetchEstimates({ page: estimatePage.currentPage + 1, search })
            "
          />
        </div>
      </div>
    </div>

    <div v-if="submenu == 'bulk'">
      <button
        class="btn-primary my-3 me-3"
        type="button"
        @click="addBulkProperty"
      >
        Bulk Add Property
      </button>
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="theadBulk" />

            <tbody v-if="!fetchingBulkRequests">
              <tr
                v-for="(item, index) in listDataBulk"
                :key="index"
              >
                <td class="py-3 text-primary text-wrap">
                  <a :href="item.csv_url.url">
                    {{ item.csv_url.original_name }}
                  </a>
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>

                <td>
                  {{ formatDate(item.updated_at) }}
                </td>
                <td>
                  {{ statusToUI(item.status) }}
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetchingBulkRequests" />

          <app-pagination
            v-if="dataBulkPage.totalPages > 1"
            :align-end="true"
            :current-page="dataBulkPage.currentPage"
            :has-previous-page="dataBulkPage.hasPreviousPage"
            :previous-page="dataBulkPage.currentPage - 1 || 1"
            :has-next-page="dataBulkPage.hasNextPage"
            :next-page="dataBulkPage.currentPage + 1"
            :per-page="dataBulkPage.itemsPerPage"
            :total-elements="dataBulkPage.itemsCount"
            :total-pages="dataBulkPage.totalPages"
            @onGotoPage="fetchBulkRequests($event)"
            @onPrev="fetchBulkRequests(dataBulkPage.currentPage - 1 || 1)"
            @onNext="fetchBulkRequests(dataBulkPage.currentPage + 1)"
          />
        </div>
      </div>
    </div>
    <!-- End content -->

    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'No'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </section>
</template>

<script>
import Avatar from "vue3-avatar";
import { nextTick } from "vue";
import { toast } from "vue3-toastify";

import documentIcon from "@/assets/img/icons/document-icon.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import api from "@/services/api";
import { MediaTypesConstants, PaginationContants } from "@/configs/constants";
import { DateHelpers } from "@/utils/date-helpers";

import { CustomerStatusList } from "@/configs/constants/users";
import statusToUI from "@/utils/statusFormate";
import HeadTable from "@/components/HeadTable.vue";
import OptionAction from"@/components/OptionAction.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import AppPagination from "@/components/AppPagination.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import ListHeader from '@/components/ListHeader.vue';
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "SuperadminCustomerDetailsPage",

  components: {
    Avatar,
    HeadTable,
    OptionAction,
    AppPagination,
    LoaderSpinner,
    ConfirmModal,
    SubmissionSpinner,
    ListHeader,
    AlerteDanger,
    OptionAction,
  },

  data() {
    return {
      icons: {
        imageUploadedIcon,
        documentUploadedIcon,
        documentIcon,
      },
      propertiesOption: {},
      CustomerStatusList,
      thead: ["Address", "Zip", "City", "State", "is_main", ""],
      theadBulk: ["CSV", "Created At", "Updated At", "Status"],
      theadEstimate: ["Title", "Date sent", "Status", ""],
      theadJob: ["Title", "Client property", "Date sent", "Status", ""],
      listData: [],
      listDataBulk: [],
      listEstimate: [],
      listJob: [],
      client: null,
      loading: true,
      exportloading: false,
      confirmationOkBtnText: null,
      confirmationMessage: null,
      selected: null,
      submenu: null,
      fetchingClientProperties: true,
      clientPropertiesPage: {
        currentPage: 1,
        itemsCount: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        itemsPerPage: PaginationContants.itemsPerPage,
      },
      fetchingBulkRequests: true,
      dataBulkPage: {
        currentPage: 1,
        itemsCount: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        itemsPerPage: PaginationContants.itemsPerPage,
      },
      fetchingEstimates: false,
      estimatePage: {
        currentPage: 1,
        itemsCount: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        itemsPerPage: PaginationContants.itemsPerPage,
      },
      fetchingJobs: false,
      jobPage: {
        currentPage: 1,
        itemsCount: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        itemsPerPage: PaginationContants.itemsPerPage,
      },
      options: [
        {
          title: "Edit",
          value: "edit",
        },
        {
          title: "Invite",
          value: "invite",
        },
      ],
      optionsAction: [
        {
          title: "Edit",
          value: "edit",
        },
        {
          title: "Delete",
          value: "delete",
        },
      ],
      actions: {
        DELETE_ESTIMATE: "delete-estimate",
        DELETE_JOB: "delete-job",
        DELETE_CLIENT_PROPERTY: "delete-client-property",
        INVITE_CLIENT: "invite-client",
      },
      modalConfigs: {
        confirmTitle: null,
        currentAction: null,
        confirmMessage: null,
        showConfirmModal: false,
      },
      deletingJob: false,
      selectedJobId: null,
      deletingEstimate: false,
      selectedEstimateId: null,
      deletingClientProperty: false,
      selectedClientPropertyId: null,
      error: null
    };
  },

  computed: {
    mediaTypesConstants() {
      return MediaTypesConstants;
    },
  },

  async mounted() {
    this.submenu = this.$route.params.tag;
    await this.init();
  },

  methods: {
    ...statusToUI,
    ...DateHelpers,

    async init() {
      switch (this.submenu) {
        case "info":
          this.fetchClientDetail();
          break;
        case "properties":
          this.fetchClientProperties(1);
          break;
        case "jobs":
          this.fetchClientJobs({ page: 1 });
          break;
        case "estimates":
          this.fetchEstimates({ page: 1 });
          break;
        case "bulk":
          this.fetchBulkRequests(1);
          break;

        default:
          break;
      }
    },

    switchTag(tag) {
      this.$router.replace({
        name: "ClientPortfolioDetail",
        params: { id: this.$route.params.id, tag: tag },
      });
      this.submenu = tag;
      this.init();
    },

    async fetchClientDetail() {
      this.loading = true;

      try {
        let response = await api.fetchClientDetail(this.$route.params.id);
        this.client = response.data;
      } catch (_) {
        /**
         * TODO
         * Handle erros
         */
        // this.errorHandler(e)
      } finally {
        this.loading = false;
      }
    },

    fetchBulkRequests(page) {
      this.fetchingBulkRequests = true;

      api
        .fetchClientProprietiesBulkRequests({
          page,
          id: this.$route.params.id,
        })
        .then((response) => {
          this.listDataBulk = response.data.data;
          this.dataBulkPage = {
            currentPage: page,
            itemsCount: response.data.count,
            totalPages: response.data.total_pages,
            hasNextPage: response.data.links?.next ? true : false,
            hasPreviousPage: response.data.links?.previous ? true : false,
            itemsPerPage:
              response.data.max_page_size || PaginationContants.itemsPerPage,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.fetchingBulkRequests = false;
        });
    },

    fetchClientProperties(page) {
      this.fetchingClientProperties = true;

      api
        .fetchClientProperties({
          page,
          clientId: this.$route.params.id,
        })
        .then((response) => {
          this.listData = response.data.data;
          this.clientPropertiesPage = {
            currentPage: page,
            itemsCount: response.data.count,
            totalPages: response.data.total_pages,
            hasNextPage: response.data.links?.next ? true : false,
            hasPreviousPage: response.data.links?.previous ? true : false,
            itemsPerPage:
              response.data.max_page_size || PaginationContants.itemsPerPage,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.fetchingClientProperties = false;
        });
    },

    confirmClaim(id, action) {
      switch (action) {
        case this.actions.DELETE_ESTIMATE:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to delete this estimate ?`;
          this.selectedEstimateId = id;
          break;

        case this.actions.DELETE_JOB:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to delete this job ?`;
          this.selectedJobId = id;
          break;

        case this.actions.DELETE_CLIENT_PROPERTY:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to delete this client property ?`;
          this.selectedClientPropertyId = id;
          break;

        case this.actions.INVITE_CLIENT:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage = `Are you sure you want to invite this client ?`;
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.DELETE_ESTIMATE:
          this.deleteEstimate(`Estimate successfully deleted.`);
          break;

        case this.actions.DELETE_JOB:
          this.deleteJob(`Job successfully deleted.`);
          break;
        case this.actions.DELETE_CLIENT_PROPERTY:
          this.deleteClientPropriety(`Client property successfully deleted.`);
          break;
        case this.actions.INVITE_CLIENT:
          this.invite();
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    edit() {
      this.$router.push({
        name: "ClientPortfolioInfoEdit",
        params: { id: this.$route.params.id },
      });
    },
    invite() {
      api
        .inviteClient(this.$route.params.id)
        .then((response) => {

          toast.success(`Client invitation successfully send.`);
          
        })
        .catch((error) => {
          this.errorsHandler(error)
        })
    },

    choose(event) {
      switch (event) {
        case "edit":
          this.edit()
          break;

        case "invite":
          this.confirmClaim(null, this.actions.INVITE_CLIENT);
          break;
      }
    },
    downloadClientPropertiesCSV() {
      this.exportloading = true;

      api
        .downloadClientPropertiesCSV(this.$route.params.id)
        .then((response) => {
          // console.log(response);
          this.downloadFile(response);
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.exportloading = false;
        });
    },
    downloadFile(response) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] }); // Create a Blob from the data
      const url = window.URL.createObjectURL(blob); // Create a URL for the Blob

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'client-properties.csv'); // Set the download attribute with the filename

      // Append the link to the document and trigger the click
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link from the document
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    addBulkProperty() {
      this.$router.push({
        name: "ClientPortfolioInfoBulk",
        params: { id: this.$route.params.id },
      });
    },

    addProperty() {
      this.$router.push({
        name: "ClientPortfolioPropertyCreate",
        params: { id: this.$route.params.id },
      });
    },
    chooseProperty(item, event) {
      switch (event) {
        case "edit":
          this.$router.push({
            name: "ClientPortfolioPropertyEdit",
            params: { id: item.id },
          });
          break;

        case "delete":
          this.confirmClaim(item.id, this.actions.DELETE_CLIENT_PROPERTY);
          break;
      }
    },

    deleteClientPropriety(successMessage) {
      this.deletingClientProperty = true;

      api
        .deleteClientPropriety(this.selectedClientPropertyId)
        .then((_) => {
          toast.success(successMessage);
          this.selectedClientPropertyId = null;
          this.fetchClientProperties(this.clientPropertiesPage.currentPage);
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingClientProperty = false;
        });
    },

    detailProperty(item) {
      this.$router.push({
        name: "ProviderEstimateDetails",
        params: { id: item.id },
      });
    },

    detailEstimate(item) {
      this.$router.push({
        name: "ProviderEstimateDetails",
        params: { id: item.id },
      });
    },

    addEstimate() {
      this.$router.push({
        name: "ProviderEstimateCreate",
      });
    },

    chooseEstimate(item, event) {
      switch (event) {
        case "edit":
          this.$router.push({
            name: "ProviderEstimateEdit",
            params: { id: item.id },
          });
          break;

        case "delete":
          this.confirmClaim(item.id, this.actions.DELETE_ESTIMATE);
          break;
      }
    },
    fetchEstimates(params) {
      this.fetchingEstimates = true;

      api
        .fetchEstimates({ ...params, client_id: this.$route.params.id })
        .then((response) => {
          this.listEstimate = response.data.data;
          this.estimatePage = {
            currentPage: params.page,
            itemsCount: response.data.count,
            totalPages: response.data.total_pages,
            hasNextPage: response.data.links?.next ? true : false,
            hasPreviousPage: response.data.links?.previous ? true : false,
            itemsPerPage:
              response.data.max_page_size || PaginationContants.itemsPerPage,
          };
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.fetchingEstimates = false;
        });
    },

    deleteEstimate(successMessage) {
      this.deletingEstimate = true;

      api
        .deleteEstimate(this.selectedEstimateId)
        .then((_) => {
          toast.success(successMessage);
          this.selectedEstimateId = null;
          this.fetchEstimates({ page: this.estimatePage.currentPage });
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingEstimate = false;
        });
    },
    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.error =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.error = err.response.data[error]
          }
        } else {
          this.error =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.error = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    detailJob(item) {
      this.$router.push({
        name: "ClientPortfolioJobDetails",
        params: { id: item.job.id },
      });
    },

    addJob() {
      this.$router.push({
        name: "ClientPortfolioJobCreate",
        params: { id: this.$route.params.id },
      });
    },

    chooseJob(item, event) {
      switch (event) {
        case "edit":
          this.$router.push({
            name: "ClientPortfolioJobEdit",
            params: { id: item.job.id },
          });
          break;

        case "delete":
          this.confirmClaim(item.job.id, this.actions.DELETE_JOB);
          break;
      }
    },

    fetchClientAllProperty() {
      api
        .fetchClientAllProperty(this.$route.params.id)
        .then((response) => {
           this.propertiesOption = {
            title: "Property",
            list: [
              {
                title: "Property",
              },
            ],
          }
      
          this.propertiesOption.list = this.propertiesOption.list.concat(response.data.data.map(el => ({
            title: el.location_description,
            value: el.id,
          })));
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
    },
    fetchClientJobs(params) {
      this.fetchingJobs = true;

      api
        .fetchClientJobs({ ...params, client_id: this.$route.params.id })
        .then((response) => {
          this.listJob = response.data.data;
          this.jobPage = {
            currentPage: params.page,
            itemsCount: response.data.count,
            totalPages: response.data.total_pages,
            hasNextPage: response.data.links?.next ? true : false,
            hasPreviousPage: response.data.links?.previous ? true : false,
            itemsPerPage:
              response.data.max_page_size || PaginationContants.itemsPerPage,
          };
          this.fetchClientAllProperty()
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.fetchingJobs = false;
        });
    },

    deleteJob(successMessage) {
      this.deletingJob = true;

      api
        .deleteJob(this.selectedJobId)
        .then((_) => {
          toast.success(successMessage);
          this.selectedJobId = null;
          this.fetchClientJobs({ page: this.jobPage.currentPage });
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.deletingJob = false;
        });
    },
  },
};
</script>

<style>
.float-end {
  padding: 0.6rem;
}
</style>
