<template>
  <header>
    <div
      class="logo-section position-relative"
      :class="{'minwidth80px': toggleMenu}"
    >
      <router-link
        :to="{ name: 'SuperAdminDashboard' }"
        class="position-relative"
      >
        <img
          v-if="toggleMenu"
          :src="images.logoS"
          alt="App Logo"
          class="logo"
        >
        <img
          v-else
          :src="images.appLogo"
          alt="App Logo"
          class="logo"
        >
        <span class="position-absolute bottom-0 end-25 badge rounded-pill bg-transparent">
          {{ version[0]?.name }} 
        </span>
      </router-link>
      <img
        :src="toggleMenu ? images.le_arrow : images.ri_arrow"
        alt="ri arrow"
        class="position-absolute arrow"
        @click="$emit('toggleMenu')"
      >
    </div>

    <div class="header-section content">
      <div class="d-inline-flex align-items-center ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 btn-toogle px-1 py-0 toggle"
          @click.prevent="toggleMobileMenu"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </div>

      <div class="d-inline-flex align-items-center">
        <notification-dropdown />

        <avatar
          :name="username"
          :size="40"
          class="user-image"
          alt="User Image"
        />

        <div class="dropdown p-0">
          <a
            id="dropdownMenuLink"
            class="px-1 text-white dropdown-toggle text-decoration-none"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Admin
          </a>

          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownMenuLink"
          >
            <li>
              <a
                class="dropdown-item"
                href="#"
              >Change Password</a>
            </li>

            <li>
              <a
                class="dropdown-item "
                @click.prevent="confirmClaim"
              >Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <confirm-modal
      ref="confirmModal"
      ok-button-title="Logout"
      :message="'Are you sure, you want to disconnect  your account ?'"
      @onConfirm="logout"
    />
  </header>
</template>

<script>
import Avatar from "vue3-avatar";
import ConfirmModal from "@/components/ConfirmModal.vue";

import appLogo from "@/assets/img/white_logo.svg";
import notification from "@/assets/img/icons/notification.svg";
import logoS from "@/assets/img/icons/logoS.svg";
import logoT from "@/assets/img/icons/logoT.svg";
import ri_arrow from "@/assets/img/icons/ri_arrow.svg";
import le_arrow from "@/assets/img/icons/le_arrow.svg";

import api from '@/services/api';
import SocketService from "@/utils/socket-service";
import {
  GlobalConstants,
  GlobalEvents,
  WebSocketEvents,
} from "@/configs/constants";
import NotificationDropdown from '../notifications/NotificationDropdown.vue';
export default {
  name: "SuperAdminHeader",
  components: {
    Avatar,
    ConfirmModal,
    NotificationDropdown,
  },
  props: {
    toggleMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
  },


  data: () => ({
    images: {
      appLogo,
      notification,
      ri_arrow,
      le_arrow,
      logoS,
      logoT,
    },
    version: [],
  }),

  computed: {
    username() {
      return "ServiceLoop ADMIN";
    },
  },
  mounted(){
    this.connectSocketAndListenEvents();
    this.fetchVersion()
  },
  methods: {
    toggleMobileMenu() {
      this.$emit("toggleMobileMenu");
    },

    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },

    logout() {
      this.$emitter.emit(GlobalEvents.LOGOUT);
    },
    fetchVersion() {

      api.fetchVersion()
        .then((response) => {
          this.version = response.data.data;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        })
    },
    connectSocketAndListenEvents() {
      this.socketService = new SocketService(
        `${GlobalConstants.WEB_SOCKET_SERVER_URL + "/ws/events/"}`
      );
      this.socket = this.socketService.connect();

      this.socket.addEventListener("close", (event) => {
        setTimeout(() => this.connectSocketAndListenEvents(), 1000)
      });

      this.socketService.on((event) => {
        const data = JSON.parse(event.data);
        console.log("Event received", data);
        this.processSocketEventsData(data);
      });

      this.$emitter.on(GlobalEvents.EMIT_WEBSOCKET_EVENT, (data) => {
        this.socketService.emit(data);
      });
    },

    processSocketEventsData(data) {
      switch (data.event_type) {
        case WebSocketEvents.PROVIDER_STATUS_UPDATED:
          this.$store.dispatch("user/saveUser", {
            ...this.currentProvider,
            status: data.new_status,
          });

          switch (data.new_status) {
            case ProviderStatus.ACTIVE:
              this.openCongratulationsModal();
              break;

            case ProviderStatus.DEACTIVATE:
              this.logout();
              break;
          }
          break;

        case WebSocketEvents.chat.IS_TYPING:
          this.$emitter.emit(GlobalEvents.chat.IS_TYPING, data.payload);
          break;

        case WebSocketEvents.chat.MESSAGE_RECEIVED:
          this.$emitter.emit(
            GlobalEvents.chat.CHAT_MESSAGE_RECEIVED,
            data.message_sent
          );
          break;

        case WebSocketEvents.NOTIFICATION_RECEIVED:
          this.$emitter.emit(GlobalEvents.NEW_NOTIFICATION, {
            notification: data.new_notification,
            unreadNotifications: data.notification_count,
          });
          break;

        case WebSocketEvents.PROFILE_UPDATED:
          this.$store.dispatch("user/saveUser", {
            ...this.currentProvider,
            ...data.data,
          });
          break;


      }
    },
  },
};
</script>

<style>



</style>
