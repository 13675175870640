<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Invoice payment
          </h5>
        </div>

        <div class="modal-body">
          <p>Enter the amount you want to pay</p>

          <div class="col-md-12">
            <money3
              v-model="amount"
              v-bind="config"
              class="form-control pl-3"
              :class="{
                'is-invalid': v$.amount.$invalid && v$.amount.$dirty,
              }"
              @blur="v$.amount.$touch"
              @change="checkPaymentType"
            />
            <template
              v-if="(v$.amount.$invalid && v$.amount.$dirty) || errorMessage"
            >
              <small
                v-if="v$.amount.minValue.$invalid"
                class="invalid-feedback"
              >
                The amount cannot be negative
              </small>

              <small
                v-if="v$.amount.maxValue.$invalid"
                class="invalid-feedback"
              >
                The amount cannot be greater than the balance due
              </small>

              <small
                v-else-if="errorMessage"
                class="invalid-feedback"
              >
                {{ errorMessage }}
              </small>
            </template>
          </div>
        </div>

        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn-danger"
            @click="closeModal"
          >
            Close
          </button>

          <button
            type="button"
            class="btn-primary"
            :disabled="submitting"
            @click="payInvoice"
          >
            <submission-spinner
              v-if="submitting"
              :color="'#209516'"
            />
            {{ submitBtnText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import { Money3Component } from "v-money3";
import { useVuelidate } from "@vuelidate/core";
import { minValue, maxValue } from "@vuelidate/validators";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";

import api from "@/services/api";
import validationHelpers from "@/utils/validation";
import errorMessages from '@/utils/error-messages';

export default {
  name: "InvoicePaymentModal",

  components: {
    SubmissionSpinner,
    money3: Money3Component,
  },

  props: {
    invoiceId: {
      type: String,
      required: true,
    },

    balanceDue: {
      type: Number,
      required: true,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    amount: 0,
    showModal: false,
    fadeModal: false,
    isPartialPayment: false,
    submitting: false,
    errorMessage: null,
  }),

  validations() {
    return {
      amount: {
        minValue: minValue(0),
        maxValue: maxValue(this.balanceDue),
      },
    };
  },

  computed: {
    config() {
      return {
        prefix: "$",
        disableNegative: true,
        min: 0,
        max: this.balanceDue,
      };
    },

    submitBtnText() {
      return this.isPartialPayment
        ? "Validate partial payment"
        : "Validate payment";
    },
  },

  watch: {
    balanceDue(val) {
      this.amount = val
    }
  },

  methods: {
    ...validationHelpers,

    checkPaymentType() {
      if (this.amount < this.balanceDue) {
        this.isPartialPayment = true;
      } else {
        this.isPartialPayment = false;
      }
    },

    payInvoice() {
      if (this.submitting) return
      this.submitting = true;

      const data = {
        payed_value: Number(this.amount),
        total: Number(this.balanceDue),
      }

      api
        .payInvoice(this.invoiceId, data)
        .then((response) => {
          this.confirm({
            status: response.data.status,
            paidValue: response.data.payed_value,
            paymentDate: response.data.payed_date
          })
        })
        .catch((err) => {
          let message = "";

          if (err.response) {
            if (err.response.status === 400) {
              message = err.response.data.error
            } else {
              message = errorMessages.AN_ERROR_HAS_OCCURED;
            }
          } else {
            message = errorMessages.AN_ERROR_HAS_OCCURED;
          }
          toast.error(message);
        })
        .then(() => {
          this.submitting = false;
        });
    },

    confirm(data) {
      this.$emit("onConfirm", data);
      // this.amount = this.balanceDue;
      this.closeModal();
    },

    openModal() {
      this.showModal = true;
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      this.fadeModal = false;
      this.resetValidation();
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },
  },
};
</script>
