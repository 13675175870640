<template>
  <div class="dashboard-provider">
    <list-header
      title="Invoices"
      :options="headerOptions"
      @update:options="onListHeaderUpdate"
    />

    <div
      class="row mb-3"
    >
      <div
        v-for="statistic in statistics"
        :key="statistic.value"
        class="col-md-6 col-lg-3"
      >
        <div class="statistic-item">
          <div class="card">
            <h3>{{ statistic.count }}</h3>

            <p>{{ statistic.title }}</p>

            <span class="linear-bar" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table
            class="table"
          >
            <head-table
              :list="thead"
              :error="error"
            />

            <tbody v-if="!fetching">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td class="py-3">
                  <router-link
                    :to="{
                      name: 'CustomerInvoiceDetails',
                      params: { id: item.id },
                    }"
                    class="cursor-pointer text-primary"
                  >
                    {{ item.invoice_no }}
                  </router-link>
                </td>
                <td class="with-job">
                  <router-link
                    :to="{
                      name: 'CustomerJobDetails',
                      params: { id: item.job.id },
                    }"
                    class="cursor-pointer text-primary"
                  >
                    {{ item.job_name }}
                  </router-link>
                </td>
                <td>
                  {{ formatPrice((item.total || 0).toFixed(2)) }}
                </td>
                <td>
                  {{ formatDate(item.created_at) }}
                </td>
                <td>
                  <app-invoice-status
                    :status="item.status || ''"
                    :short="true"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <loader-spinner v-if="fetching" />

          <app-pagination
            v-if="totalPages > 1"
            :align-end="true"
            :current-page="currentPage"
            :has-previous-page="hasPreviousPage"
            :previous-page="currentPage - 1 || 1"
            :has-next-page="hasNextPage"
            :next-page="currentPage + 1"
            :per-page="itemsPerPage"
            :total-elements="itemsCount"
            :total-pages="totalPages"
            @onGotoPage="fetchInvoices({ page: $event })"
            @onPrev="fetchInvoices({ page: currentPage - 1 || 1 })"
            @onNext="fetchInvoices({ page: currentPage + 1 })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadTable from "@/components/HeadTable.vue";
import ListHeader from "@/components/ListHeader.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import AppPagination from "@/components/AppPagination.vue";
import AppInvoiceStatus from "@/components/InvoiceStatus.vue";

import api from "@/services/api";
import ListMixin from "@/mixins/list";

import PriceHelpers from "@/utils/price-format";
import statusToUI from "@/utils/statusFormate";
import { DateHelpers } from "@/utils/date-helpers";
import {
  GlobalConstants,
  InvoiceStatus,
  InvoiceStatusList,
} from "@/configs/constants";

export default {
  name: "CustomerInvoicesPage",

  components: {
    ListHeader,
    HeadTable,
    LoaderSpinner,
    AppPagination,
    AppInvoiceStatus
  },

  mixins: [ListMixin(api.fetchInvoices, true)],

  data: () => ({
    thead: ["Invoice No", "Job", "Amount", "Date sent", "Status"],
    status: null,
    search: null,
    fetching: false,
    headerOptions: {
      title: InvoiceStatusList[0].title,
      list: InvoiceStatusList,
    },
    statistics: [
      {
        title: "Pending",
        value: InvoiceStatus.PENDING,
        count: 0,
      },
      {
        title: "Paid",
        value: InvoiceStatus.PAID,
        count: 0,
      },
      {
        title: "Partial",
        value: InvoiceStatus.PARTIAL,
        count: 0,
      },
      {
        title: "Refund",
        value: InvoiceStatus.REFUND,
        count: 0,
      },
      {
        title: "External Pay",
        value: InvoiceStatus.EXTERNAL_PAY,
        count: 0,
      },
    ],
    error: null,
  }),

  watch: {
    listData() {
      this.fetching = false;
    },
  },

  mounted() {
      this.fetchInvoicesStatistics();
      this.fetchInvoices({ page: Number(this.$route.query.page) || 1 });
  },

  methods: {
    ...DateHelpers,
    ...statusToUI,
    ...PriceHelpers,

    detail(item) {
      this.$router.push({
        name: "CustomerInvoiceDetails",
        params: { id: item.id },
      });
    },

    onListHeaderUpdate(event) {
      this.fetchInvoices({ page: 1, status: event.value ?? undefined });
      this.headerOptions.title = event.title;
      this.status = event.value;
    },

    fetchInvoices(params) {
      this.fetching = true;
      this.updateListFilters({
        ...params,
      });
    },

    async fetchInvoicesStatistics() {
      try {
        const response = await api.fetchInvoicesStatistics();
        const statistics = response.data;
        this.statistics = (this.statistics ?? []).map((statistic) => {
          const matchingStatistic = statistics.find(
            (x) => x.status === statistic.value
          );
          return {
            ...statistic,
            count: matchingStatistic?.count ?? 0,
          };
        });

      } catch (_) {}
    },
  },
};
</script>

<style scoped>
.table td.with-job {
  white-space: normal !important;
  max-width: 200px !important;
}
@media(max-width: 720px) {
  .table td.with-job {
    white-space: wrap !important;
    max-width: max-content !important;
  }
}
</style>