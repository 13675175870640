<template>
  <section class="content profile">
    <div class="page-header">
      <h3 class="page-title">
        Profile
      </h3>

      <settings-menu />
    </div>

    <div class="page-content">
      <form
        v-if="currentUser"
        class="row"
        @submit.prevent="save"
      >
        <div class="col-lg-8 offset-lg-2 box-form">
          <div class="row">
            <alerte-danger
              :head-error-message="errors.serverSideErrorMessage"
              @updateContent="errors.serverSideErrorMessage = $event"
            />

            <alerte-danger
              :head-error-message="errors.otherErrorMessage"
              @updateContent="errors.otherErrorMessage = $event"
            />
            <div class="col-md-12 my-3">
              <div class="photo-wrapper">
                <div
                  :style="{
                    'background-image': `url(${
                      currentUser.photo?.url || images.user
                    })`,
                  }"
                  class="profile-photo"
                />

                <h4 class="username">
                  {{ currentUser.full_name }}
                </h4>
              </div>

              <input
                ref="profilePhoto"
                class="d-none shadow-none"
                type="file"
                accept="image/*"
                @change="uploadProfilePhoto"
              >
              <a
                class="text-primary"
                @click="$refs.profilePhoto.click()"
              >
                Upload Photo
              </a>
            </div>

            <div class="row">
              <div class="col-md-12 my-3">
                <h4 class="border-bottom section-title">
                  Basic Details
                </h4>
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">
                  Full Name <span class="text-danger">*</span>
                </label>
                <input
                  v-model="currentUser.full_name"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.currentUser.full_name.$invalid &&
                        v$.currentUser.full_name.$dirty) ||
                      errors.fullnameErrorMessage,
                  }"
                  placeholder="Full Name"
                  @blur="v$.currentUser.full_name.$touch"
                >
                <template
                  v-if="
                    (v$.currentUser.full_name.$invalid &&
                      v$.currentUser.full_name.$dirty) ||
                      errors.fullnameErrorMessage
                  "
                >
                  <small
                    v-if="v$.currentUser.full_name.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="v$.currentUser.full_name.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    Must contain at least 3 characters
                  </small>

                  <small
                    v-else-if="errors.fullnameErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.fullnameErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">
                  Email <span class="text-danger">*</span>
                </label>
                <input
                  v-model="currentUser.email"
                  type="email"
                  class="form-control pl-3 shadow-none"
                  placeholder="Email"
                  :class="{
                    'is-invalid':
                      (v$.currentUser.email.$invalid &&
                        v$.currentUser.email.$dirty) ||
                      errors.emailErrorMessage,
                  }"
                  @blur="v$.currentUser.email.$touch"
                >
                <template
                  v-if="
                    (v$.currentUser.email.$invalid &&
                      v$.currentUser.email.$dirty) ||
                      errors.emailErrorMessage
                  "
                >
                  <small
                    v-if="v$.currentUser.email.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="v$.currentUser.email.email.$invalid"
                    class="invalid-feedback"
                  >
                    Must be a valid email address
                  </small>

                  <small
                    v-else-if="errors.emailErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.emailErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">
                  Phone No. <span class="text-danger">*</span>
                </label>
                <vue-tel-input
                  v-model="currentUser.phone"
                  class="form-control pl-3 shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.currentUser.phone.$invalid &&
                        v$.currentUser.phone.$dirty) ||
                      errors.phoneErrorMessage,
                  }"
                  @blur="v$.currentUser.phone.$touch"
                />
                <template
                  v-if="
                    (v$.currentUser.phone.$invalid &&
                      v$.currentUser.phone.$dirty) ||
                      errors.phoneErrorMessage
                  "
                >
                  <small
                    v-if="v$.currentUser.phone.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.phoneErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.phoneErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">Website</label>
                <input
                  v-model="currentUser.website"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  placeholder="https://yourwebsite.com"
                >
                <template v-if="errors.websiteErrorMessage">
                  <small class="invalid-feedback">
                    {{ errors.websiteErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">
                  Business Type <span class="text-danger">*</span>
                </label>
                <select
                  v-model="currentUser.business_type"
                  class="form-select p-3 shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.currentUser.business_type.$invalid &&
                        v$.currentUser.business_type.$dirty) ||
                      errors.businessTypeErrorMessage,
                  }"
                  @blur="v$.currentUser.business_type.$touch"
                >
                  <option
                    v-for="businessType in businessTypes"
                    :key="businessType.value"
                    :value="businessType.value"
                  >
                    {{ businessType.title }}
                  </option>
                </select>
                <template
                  v-if="
                    (v$.currentUser.business_type.$invalid &&
                      v$.currentUser.business_type.$dirty) ||
                      errors.businessTypeErrorMessage
                  "
                >
                  <small
                    v-if="v$.currentUser.business_type.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.businessTypeErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.businessTypeErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">
                  Country <span class="text-danger">*</span>
                </label>
                <select
                  v-model="currentUser.country"
                  class="form-select p-3 shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.currentUser.country.$invalid &&
                        v$.currentUser.country.$dirty) ||
                      errors.countryErrorMessage,
                  }"
                  @blur="v$.currentUser.country.$touch"
                >
                  <option
                    v-for="country in allCountries"
                    :key="country.code"
                    :value="country.code"
                  >
                    {{ country.label }}
                  </option>
                </select>
                <template
                  v-if="
                    (v$.currentUser.country.$invalid &&
                      v$.currentUser.country.$dirty) ||
                      errors.countryErrorMessage
                  "
                >
                  <small
                    v-if="v$.currentUser.country.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.countryErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.countryErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <input
                  v-model="ssn_ein"
                  type="radio"
                  name="fav_language"
                  value="ssn"
                  class="d-inline"
                >
                  <label
                  for="ssn"
                  class="input-label d-inline"
                >SSN </label>
                  <input
                  v-model="ssn_ein"
                  type="radio"
                  name="fav_language"
                  value="ein"
                  class="d-inline"
                >
                  <label
                  for="ein"
                  class="input-label d-inline"
                >EIN </label>
                <input
                  v-model="currentUser[ssn_ein]"
                  type="text"
                  :placeholder="ssn_ein.toUpperCase()"
                  class="form-control pl-3 shadow-none mt-3"
                  :class="{
                    'is-invalid': (errors.ssnErrorMessage || errors.einErrorMessage),
                  }"
                >
                <template
                  v-if="(errors.ssnErrorMessage || errors.einErrorMessage)"
                >
                  <small
                    v-if="errors.ssnErrorMessage || errors.einErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.ssnErrorMessage || errors.einErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 pb-3">
                <label class="input-label">
                  Birthdate <span class="text-danger">*</span>
                </label>
                <vue-date-picker
                  v-model="currentUser.date_of_birth"
                  placeholder="Choose a date"
                  class="datepicker form-control"
                  :class="{
                    'is-invalid':
                      (v$.currentUser.date_of_birth.$invalid &&
                        v$.currentUser.date_of_birth.$dirty) ||
                      errors.dateOfBirthErrorMessage,
                  }"
                  auto-apply
                  :enable-time-picker="false"
                  :clearable="false"
                  :max-date="minDate"
                  :start-date="minDate"
                  :format="'MM-dd-yyyy'"
                />
                <template
                  v-if="
                    (v$.currentUser.date_of_birth.$invalid &&
                      v$.currentUser.date_of_birth.$dirty) ||
                      errors.dateOfBirthErrorMessage
                  "
                >
                  <small
                    v-if="v$.currentUser.date_of_birth.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.dateOfBirthErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.dateOfBirthErrorMessage }}
                  </small>
                </template>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 my-3">
                <h4 class="border-bottom section-title">
                  Profile Detail
                </h4>
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">Company Name</label>
                <input
                  v-model="currentUser.company_name"
                  type="text"
                  class="form-control shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.currentUser.company_name.$invalid &&
                        v$.currentUser.company_name.$dirty) ||
                      errors.companyNameErrorMessage,
                  }"
                  placeholder="Company Name"
                  @blur="v$.currentUser.company_name.$touch"
                >
                <template
                  v-if="
                    (v$.currentUser.company_name.$invalid &&
                      v$.currentUser.company_name.$dirty) ||
                      errors.companyNameErrorMessage
                  "
                >
                  <small
                    v-if="v$.currentUser.company_name.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    Must contain at least 3 characters
                  </small>

                  <small
                    v-else-if="errors.companyNameErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.companyNameErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">
                  Address Line 1 <span class="text-danger">*</span>
                </label>
                <GMapAutocomplete
                  :value="currentUser.address_line_1"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  placeholder="Address Line 1"
                  :class="{
                    'is-invalid': errors.addressLine1ErrorMessage,
                  }"
                  @change="
                    handleAddressChange($event.target.value, 'address_line_1')
                  "
                  @place_changed="setAddress($event, 'address_line_1')"
                />
                <small
                  v-if="errors.addressLine1ErrorMessage"
                  class="invalid-feedback"
                >
                  {{ errors.addressLine1ErrorMessage }}
                </small>
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">Address Line 2</label>
                <GMapAutocomplete
                  :value="currentUser.address_line_2"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  placeholder="Address Line 2"
                  @change="
                    handleAddressChange($event.target.value, 'address_line_2')
                  "
                  @place_changed="setAddress($event, 'address_line_2')"
                />
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">
                  Zip Code <span class="text-danger">*</span>
                </label>
                <input
                  v-model="currentUser.pin_code"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.currentUser.pin_code.$invalid &&
                        v$.currentUser.pin_code.$dirty) ||
                      errors.pinCodeErrorMessage,
                  }"
                  placeholder="Zip Code"
                  @blur="v$.currentUser.pin_code.$touch"
                >
                <template
                  v-if="
                    (v$.currentUser.pin_code.$invalid &&
                      v$.currentUser.pin_code.$dirty) ||
                      errors.pinCodeErrorMessage
                  "
                >
                  <small
                    v-if="v$.currentUser.pin_code.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-if="v$.currentUser.pin_code.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    Must contain at least 3 characters
                  </small>

                  <small
                    v-else-if="errors.pinCodeErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.pinCodeErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">
                  State <span class="text-danger">*</span>
                </label>
                <input
                  v-model="currentUser.state"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  :class="{ 'is-invalid': errors.stateErrorMessage }"
                  placeholder="State"
                  @blur="v$.currentUser.state.$touch"
                >
                <template
                  v-if="
                    (v$.currentUser.state.$invalid &&
                      v$.currentUser.state.$dirty) ||
                      errors.stateErrorMessage
                  "
                >
                  <small
                    v-if="v$.currentUser.state.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.stateErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.stateErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-6 mb-3">
                <label class="input-label">
                  City <span class="text-danger">*</span>
                </label>
                <input
                  v-model="currentUser.city"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  :class="{ 'is-invalid': errors.cityErrorMessage }"
                  placeholder="City"
                  @blur="v$.currentUser.city.$touch"
                >
                <template
                  v-if="
                    (v$.currentUser.city.$invalid &&
                      v$.currentUser.city.$dirty) ||
                      errors.cityErrorMessage
                  "
                >
                  <small
                    v-if="v$.currentUser.city.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.cityErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.cityErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-12 mb-3">
                <label class="input-label">Profile Description</label>
                <textarea
                  v-model="currentUser.profile_description"
                  class="form-control p-3 shadow-none"
                  :class="{
                    'is-invalid':
                      (v$.currentUser.profile_description.$invalid &&
                        v$.currentUser.profile_description.$dirty) ||
                      errors.profileDescriptionErrorMessage,
                  }"
                  placeholder="Write here"
                  @blur="v$.currentUser.profile_description.$touch"
                />
                <template
                  v-if="
                    (v$.currentUser.profile_description.$invalid &&
                      v$.currentUser.profile_description.$dirty) ||
                      errors.profileDescriptionErrorMessage
                  "
                >
                  <small
                    v-if="v$.currentUser.profile_description.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    Must contain at least 3 characters
                  </small>

                  <small
                    v-if="v$.currentUser.profile_description.maxLength.$invalid"
                    class="invalid-feedback"
                  >
                    Must contain no more than 550 characters
                  </small>

                  <small
                    v-else-if="errors.profileDescriptionErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.profileDescriptionErrorMessage }}
                  </small>
                </template>
              </div>

              <div class="col-md-12 col-lg-6 mb-3">
                <label class="input-label">
                  Identity Document - Front <span class="text-danger">*</span>
                </label>
                <div
                  class="upload-file-wrapper"
                  :class="{ 'is-invalid': errors.idFrontErrorMessage }"
                >
                  <input
                    ref="idFront"
                    class="d-none shadow-none"
                    type="file"
                    accept="application/pdf,image/*"
                    @change="onIdFrontFileChange"
                  >
                  <span v-if="currentUser.idfront?.filename">
                    {{ currentUser.idfront.filename }}
                  </span>
                  <span v-else> Please upload your document. </span>

                  <button
                    type="button"
                    class="btn-upload"
                    :disabled="uploadingIdFrontFile || uploadingIdBackFile"
                    @click="$refs.idFront.click()"
                  >
                    <submission-spinner
                      v-if="uploadingIdFrontFile"
                      :color="'#209516'"
                    />
                    <img
                      v-else
                      :src="icons.vectorUploadIcon"
                      alt="icon Upload"
                    >

                    <template v-if="currentUser.idfront">
                      Change
                    </template>
                    <template v-else>
                      Upload
                    </template>
                  </button>
                </div>
                <small
                  v-if="errors.idFrontErrorMessage"
                  class="invalid-feedback"
                >
                  This field is required
                </small>
              </div>

              <div class="col-md-12 col-lg-6 mb-3">
                <label class="input-label">
                  Identity Document - Back <span class="text-danger">*</span>
                </label>
                <div
                  class="upload-file-wrapper"
                  :class="{ 'is-invalid': errors.idBackErrorMessage }"
                >
                  <input
                    ref="idBack"
                    class="d-none shadow-none"
                    type="file"
                    accept="application/pdf,image/*"
                    @change="onIdBackFileChange"
                  >
                  <span v-if="currentUser.idback?.filename">
                    {{ currentUser.idback.filename }}
                  </span>
                  <span v-else> Please upload your document. </span>

                  <button
                    type="button"
                    class="btn-upload"
                    :disabled="uploadingIdFrontFile || uploadingIdBackFile"
                    @click="$refs.idBack.click()"
                  >
                    <submission-spinner
                      v-if="uploadingIdBackFile"
                      :color="'#209516'"
                    />
                    <img
                      v-else
                      :src="icons.vectorUploadIcon"
                      alt="icon Upload"
                    >

                    <template v-if="currentUser.idback">
                      Change
                    </template>
                    <template v-else>
                      Upload
                    </template>
                  </button>
                </div>
                <small
                  v-if="errors.idBackErrorMessage"
                  class="invalid-feedback"
                >
                  This field is required
                </small>
              </div>

              <div class="col-md-12 mb-3">
                <label class="input-label">Portfolio (upload recent work)</label>
                <div
                  class="drap-and-drop-file"
                  @drop.prevent="addPortfolioFile"
                  @dragover.prevent
                >
                  <div
                    v-if="
                      !currentUser.portfolio ||
                        currentUser.portfolio.length === 0
                    "
                  >
                    <div class="py-2">
                      Drag or upload project files
                    </div>

                    <img
                      :src="icons.cloudIcon"
                      alt="icon Upload"
                      class="d-block mx-auto mb-2"
                    >
                  </div>

                  <div
                    v-else
                    class="row file-list"
                  >
                    <div
                      v-for="(file, i) in currentUser.portfolio"
                      :key="i"
                      class="col-md-12 col-lg-6"
                    >
                      <div class="file-thumb">
                        <div class="file-description">
                          <img
                            v-if="file.isImage"
                            :src="icons.imageUploadedIcon"
                            alt="File Uploaded"
                            class="file-icon"
                          >
                          <img
                            v-else
                            :src="icons.documentUploadedIcon"
                            alt="File Uploaded"
                            class="file-icon"
                          >
                          <span>{{ file.filename }}</span>
                        </div>

                        <div
                          class="remove-file"
                          @click="removeFile(file)"
                        >
                          <img
                            :src="icons.closeIcon"
                            alt="File Uploaded"
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <input
                    ref="portfolioFiles"
                    class="d-none shadow-none"
                    type="file"
                    multiple
                    @change="choosePortfolioFile"
                  >
                  <button
                    type="button"
                    class="btn-upload"
                    :disabled="submittingPortfolioRequest"
                    @click="$refs.portfolioFiles.click()"
                  >
                    <submission-spinner
                      v-if="submittingPortfolioRequest"
                      :color="'#209516'"
                    />
                    Upload
                  </button>
                </div>
              </div>

              <div class="col-md-12 text-end mt-3">
                <button
                  type="submit"
                  class="px-5 btn-primary"
                  :disabled="
                    loading ||
                      submittingPortfolioRequest ||
                      uploadingIdFrontFile ||
                      uploadingIdBackFile
                  "
                >
                  <submission-spinner
                    v-if="
                      loading &&
                        !submittingPortfolioRequest &&
                        !uploadingIdFrontFile &&
                        !uploadingIdBackFile
                    "
                  />
                  <span> Update Profile </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { toast } from "vue3-toastify";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, maxLength } from "@vuelidate/validators";
import { mapGetters } from "vuex";

import user from "@/assets/img/user.svg";
import dotVectorIcon from "@/assets/img/icons/tree-dot.svg";
import vectorUploadIcon from "@/assets/img/icons/Vector.svg";
import closeIcon from "@/assets/img/icons/remove.svg";
import cloudIcon from "@/assets/img/icons/cloud.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import api from "@/services/api";
import validationHelpers from "@/utils/validation";
import errorMessages from "@/utils/error-messages";
import fileUtils from "@/utils/file";
import LocationUtils from "@/utils/location";
import { DateHelpers } from "@/utils/date-helpers";
import { GeneralHelpers } from "@/utils/general";

import { AllCountries } from "@/configs/countries";
import { BusinessTypes } from "@/configs/constants/users";
import { MediaTypesConstants } from "@/configs/constants";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import SettingsMenu from "@/components/customer/SettingsMenu.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "CustomerEditProfilePage",

  components: {
    SettingsMenu,
    SubmissionSpinner,
    AlerteDanger,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    icons: {
      vectorUploadIcon,
      closeIcon,
      cloudIcon,
      imageUploadedIcon,
      documentUploadedIcon,
      dotVectorIcon,
    },
    images: {
      user,
    },
    ssn_ein: 'ssn',
    loading: false,
    submittingPortfolioRequest: false,
    uploadingIdFrontFile: false,
    uploadingIdBackFile: false,
    id: null,
    currentUser: null,
    minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 12)),
    errors: {
      emailErrorMessage: null,
      fullnameErrorMessage: null,
      phoneErrorMessage: null,
      websiteErrorMessage: null,
      profileDescriptionErrorMessage: null,
      companyNameErrorMessage: null,
      addressLine1ErrorMessage: null,
      pinCodeErrorMessage: null,
      stateErrorMessage: null,
      cityErrorMessage: null,
      dateOfBirthErrorMessage: null,
      countryErrorMessage: null,
      ssnErrorMessage: null,
      einErrorMessage: null,
      idFrontErrorMessage: null,
      idBackErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      currentUser: {
        full_name: {
          required,
          minLength: minLength(3),
        },

        date_of_birth: {
          required,
        },

        business_type: {
          required,
        },

        country: {
          required,
        },

        email: {
          required,
          email,
        },

        phone: {
          required,
        },

        profile_description: {
          minLength: minLength(3),
          maxLength: maxLength(550),
        },

        pin_code: {
          required,
          minLength: minLength(3),
        },

        company_name: {
          minLength: minLength(3),
        },

        address_line_1: {
          required,
        },

        state: {
          required,
        },

        city: {
          required,
        },
      },
    };
  },

  computed: {
    ...mapGetters(["user/user"]),

    businessTypes() {
      return BusinessTypes;
    },

    allCountries() {
      return AllCountries;
    },
  },

  mounted() {
    this.initFormData();
  },

  methods: {
    ...fileUtils,
    ...DateHelpers,
    ...LocationUtils,
    ...validationHelpers,

    handleAddressChange(value, field) {
      if (!value) {
        this.currentUser[field] = null;
      }
    },

    setAddress(values, field) {
      if (values) {
        this.currentUser[field] = values.formatted_address;

        if (field === "address_line_1") {
          const locationInfo = this.extractLocationInfo(
            values.address_components
          );
          if (locationInfo) {
            this.currentUser.address_line_1 = locationInfo.address;
            this.currentUser.country = locationInfo.country.shortName;
            this.currentUser.state = locationInfo.state;
            this.currentUser.city = locationInfo.city;
            this.currentUser.pin_code = locationInfo.pincode;
          }

          const lat = values.geometry?.location?.lat();
          const lng = values.geometry?.location?.lng();
          this.currentUser.location = JSON.stringify({
            type: "Point",
            coordinates: [lng, lat],
          });
        }
      }
    },

    initFormData() {
      let savedUser = this.$store.getters["user/user"];
      const user = {
        ...savedUser,
        idfront: savedUser.idfront ? { ...savedUser.idfront } : null,
        idback: savedUser.idback ? { ...savedUser.idback } : null,
        portfolio: savedUser.portfolio
          ? [...savedUser.portfolio].map((x) => ({ ...x }))
          : [],
      };

      const attrs = [
        "full_name",
        "phone",
        "email",
        "date_of_birth",
        "website",
        "business_type",
        "profile_description",
        "address_line_1",
        "address_line_2",
        "country",
        "state",
        "city",
        "pin_code",
        "company_name",
        "photo",
        "portfolio",
        "idfront",
        "idback",
        "ssn",
        "ein",
      ];
      let stock = {};
      for (const key in user) {
        if (attrs.find((el) => el === key)) {
          stock[key] =
            user[key] || user[key] !== "null" ? user[key] : undefined;
        }
      }
      if (stock.portfolio?.length > 0) {
        const tmpPortfolio = [];
        stock.portfolio.forEach((f) => {
          if (f.portfolio) {
            tmpPortfolio.push(f);
          }
        });

        stock.portfolio = tmpPortfolio.map((f) => {
          const userPortfolio = { ...f.portfolio, paraent_id: f.id };
          if (f.portfolio.original_name && f.portfolio.type) {
            userPortfolio.filename = this.getFileName(
              f.portfolio.original_name
            );
            userPortfolio.isImage =
              f.portfolio.type === MediaTypesConstants.IMAGE;
          }
          return userPortfolio;
        });
      } else {
        stock.portfolio = [];
      }
      if (stock.idfront && stock.idfront.original_name) {
        stock.idfront.filename = this.getFileName(stock.idfront.original_name);
      }
      if (stock.idback && stock.idback.original_name) {
        stock.idback.filename = this.getFileName(stock.idback.original_name);
      }

      this.id = user.id;
      this.currentUser = { ...stock };
    },

    uploadProfilePhoto(event) {
      this.updateUser(
        {
          photo: event.target.files[0],
        },
        false
      );
    },

    addPortfolioFile(e) {
      const droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;

      const filesToUpload = [];

      [...droppedFiles].forEach((f) => {
        const fileAlreadyAdded = this.currentUser.portfolio.find((emt) => {
          return emt == f;
        });
        if (fileAlreadyAdded) return;
        filesToUpload.push(f);
        this.currentUser.portfolio.push({
          file: f,
          isImage: this.isImage(f.type),
          filename: this.getFileName(f.name),
        });
      });

      for (let index = 0; index < filesToUpload.length; index++) {
        const portfolioFile = filesToUpload[index];

        this.submittingPortfolioRequest = true;
        this.uploadingUserPortfolio({
          portfolio: portfolioFile,
        });
      }
    },

    choosePortfolioFile(e) {
      let uploadedFiles = e.target.files;
      if (!uploadedFiles) return;

      const filesToUpload = [];

      [...uploadedFiles].forEach((f) => {
        const fileAlreadyAdded = this.currentUser.portfolio.find((emt) => {
          return emt == f;
        });
        if (fileAlreadyAdded) return;
        filesToUpload.push(f);
        this.currentUser.portfolio.push({
          file: f,
          isImage: this.isImage(f.type),
          filename: this.getFileName(f.name),
        });
      });

      for (let index = 0; index < filesToUpload.length; index++) {
        const portfolioFile = filesToUpload[index];

        this.submittingPortfolioRequest = true;
        this.uploadingUserPortfolio({
          portfolio: portfolioFile,
        });
      }

      toast.success(
        `Portfolio ${
          filesToUpload.length > 1 ? "files" : "file"
        } successfully uploaded.`
      );
    },

    async uploadingUserPortfolio(data) {
      try {
        this.clearErrorsMessages();

        let response = await api.updloadUserPortfolioFile(data);
        this.$store.dispatch("user/saveUser", response.data.user);
        this.initFormData();
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.submittingPortfolioRequest = false;
      }
    },

    async removeFile(file) {
      try {
        this.submittingPortfolioRequest = true;
        this.clearErrorsMessages();

        await api.deleteUserPortfolioFile(file.paraent_id);
        this.currentUser.portfolio = this.currentUser.portfolio.filter((f) => {
          return f != file;
        });

        const savedUser = this.$store.getters["user/user"];
        const finalPortfolios = savedUser.portfolio.filter((x) => {
          return (
            this.currentUser.portfolio.findIndex(
              (elmt) => x.id === elmt.paraent_id
            ) !== -1
          );
        });
        this.$store.dispatch("user/saveUser", {
          ...savedUser,
          portfolio: finalPortfolios,
        });

        toast.success("Portfolio file successfully removed.");
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.submittingPortfolioRequest = false;
      }
    },

    onIdFrontFileChange(event) {
      const uploadedFile = event.target.files[0];
      this.currentUser.idfront = {
        filename: this.getFileName(uploadedFile.name),
      };

      this.uploadingIdFrontFile = true;
      this.updateUser(
        {
          idfront: event.target.files[0],
        },
        false
      );
    },

    onIdBackFileChange(event) {
      const uploadedFile = event.target.files[0];
      this.currentUser.idback = {
        filename: this.getFileName(uploadedFile.name),
      };

      this.uploadingIdBackFile = true;
      this.updateUser(
        {
          idback: event.target.files[0],
        },
        false
      );
    },

    isRequiredFiledValid() {
      if (!this.currentUser.idfront || !this.currentUser.idback) {
        return false;
      }

      return true;
    },

    save() {
      if (!this.isRequiredFiledValid()) {
        toast.error("Please fill out required fields.");

        if (!this.currentUser.idfront) {
          this.errors.idFrontErrorMessage = "This field is required.";
        }
        if (!this.currentUser.idback) {
          this.errors.idBackErrorMessage = "This field is required.";
        }

        if (this.v$.$invalid) {
          this.forceValidation();
          return;
        }
        return;
      } else {
        this.errors.idFrontErrorMessage = null;
        this.errors.idBackErrorMessage = null;
      }

      if (this.v$.$invalid) {
        this.forceValidation();
        toast.error("Please fill out required fields.");
        return;
      }

      const formadata = { ...this.currentUser };
      delete formadata.photo;
      delete formadata.portfolio;
      delete formadata.idfront;
      delete formadata.idback;

      if (formadata.date_of_birth) {
        formadata.date_of_birth = this.formatDate(
          formadata.date_of_birth,
          "YYYY-MM-DD"
        );
      }

      this.updateUser(formadata);
    },

    async updateUser(data, initForm = true) {
      try {
        if (this.loading) return;

        this.loading = true;
        this.clearErrorsMessages();

        let response = await api.updateUser(this.id, data);
        this.$store.dispatch("user/saveUser", response.data);

        if (initForm) {
          this.initFormData();
        }

        toast.success("Profile successfully updated.");
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loading = false;
        this.uploadingIdFrontFile = false;
        this.uploadingIdBackFile = false;
      }
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          /**
           * TODO
           * Logout user
           */
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors[`${error}ErrorMessage`] = err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
