<template>
  <div class="job-list">
    <subcontract-item
      v-for="subcontract in subcontracts"
      :key="subcontract.id"
      :subcontract="subcontract.job"
    />
  </div>
</template>

<script>
import SubcontractItem from "@/components/provider/jobs/SubcontractItem.vue";

export default {
  name: "SubcontractList",

  components: {
    SubcontractItem,
  },

  props: {
    subcontracts: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>