<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="sidebar">
    <ul class="navbar-nav">
      <template
        v-for="(item, index) in dashboardMenuItems"
        :key="item.routeName"
      >
        <li
          v-if="item.hasSubmenu"
          :id="`accordionExample-${index}`"
          class="accordion"
        >
          <div class="accordion-item">
            <a
              class="accordion-button font-menu"
              @click="(e) => accordionToggle(e)"
            >
              <img :src="icons[item.icon]">
              <template v-if="!toggleMenu">{{ item.title }}</template>
            </a>

            <div
              ref="collapse"
              class="accordion-collapse collapse"
            >
              <div class="accordion-body">
                <ul
                  v-if="!toggleMenu"
                  class="navbar-nav submenu"
                >
                  <li
                    v-for="submenuItem in item.submenu"
                    :key="submenuItem.routeName"
                    class="font-menu"
                  >
                    <router-link
                      v-if="submenuItem.params"
                      :to="{ name: submenuItem.routeName, params: submenuItem.params }"
                      @click.prevent="goto(submenuItem)"
                    >
                      {{ submenuItem.title }}
                    </router-link>

                    <router-link
                      v-else
                      :to="{ name: submenuItem.routeName }"
                      @click.prevent="goto(submenuItem)"
                    >
                      {{ submenuItem.title }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li
          v-else
          class="font-menu"
        >
          <router-link
            :to="{ name: item.routeName }"
            class="nav-link"
            aria-current="page"
            @click.prevent="goto(item)"
          >
            <img :src="icons[item.icon]">
            <template v-if="!toggleMenu">
              {{ item.title }}
            </template>
          </router-link>
        </li>
      </template>
    </ul>

    <confirm-modal
      ref="confirmModal"
      title="Access restricted"
      message="You have a limited access to some features on this page. Please upgrade your account."
      ok-button-title="Upgrade"
      @onConfirm="$router.push({ name: 'ProviderSubscription' })"
    />
  </div>
</template>

<script>
import dashboardIcon from "@/assets/img/icons/dashboard.svg";
import superAdminListIcon from "@/assets/img/icons/list.svg";
import bagIcon from "@/assets/img/icons/bag.svg";
import iconclientportfolio from "@/assets/img/icons/iconclientportfolio.svg";
import walletIcon from "@/assets/img/icons/wallet.svg";
import withdrawIcon from "@/assets/img/icons/Withdraw.svg";
import callIcon from "@/assets/img/icons/CallVector.svg";
import iconestimates from "@/assets/img/icons/iconestimates.svg";
import documentIcon from "@/assets/img/icons/document.svg";
import calendarIcon from "@/assets/img/icons/calendar.svg";
import ConfirmModal from "../ConfirmModal.vue";
import { ProposalStatus } from "@/configs/constants/jobs";

export default {
  name: "ProviderMenu",
  components: { ConfirmModal },
  props: {
    toggleMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    icons: {
      dashboardIcon,
      superAdminListIcon,
      bagIcon,
      documentIcon,
      iconclientportfolio,
      walletIcon,
      iconestimates,
      withdrawIcon,
      callIcon,
      calendarIcon,
    },
    selected: {},
    services: [],
  }),

  computed: {
    currentProvider() {
      return this.$store.getters["user/user"];
    },

    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },
    dashboardMenuItems() {
      const items = [
        {
          title: "Dashboard",
          routeName: "ProviderDashboard",
          hide: false,
          hasSubmenu: false,
          icon: "dashboardIcon",
        },
        {
          title: "Jobs Board",
          routeName: "ProviderJobs",
          hide: false,
          hasSubmenu: true,
          icon: "bagIcon",
          submenu: [
            {
              title: "Jobs",
              hide:
                this.includeService("marketplace") &&
                this.includePlan("can_access_marketplace"),
              routeName: "ProviderJobs",
            },
            {
              title: "Saved Jobs",
              hide:
                this.includeService("marketplace") &&
                this.includePlan("can_access_marketplace"),
              routeName: "ProviderSavedJobs",
            },
            {
              title: "Active Jobs",
              hide:
                this.includeService("marketplace") &&
                this.includePlan("can_access_marketplace"),
              routeName: "ProviderActiveJob",
            },
            {
              title: "Completed Jobs",
              hide:
                this.includeService("marketplace") &&
                this.includePlan("can_access_marketplace"),
              routeName: "ProviderCompletedJobs",
            },
            {
              title: "Bids won",
              hide:
                this.includeService("marketplace") &&
                this.includePlan("can_access_marketplace"),
              routeName: "ProviderProposals",
              params: { status: ProposalStatus.ACCEPETED },
            },
            {
              title: "Subcontracts",
              hide:
                this.includeService("subcontracting") &&
                this.includePlan("can_access_subcontract"),
              routeName: "ProviderSubcontract",
            },
          ],
        },
        {
          title: "Invoices",
          routeName: "ProviderInvoices",
          icon: "documentIcon",
          hide:
            this.includeService("invoicing") &&
            this.includePlan("invoice_management"),
          hasSubmenu: false,
        },
        {
          title: "Calendar",
          routeName: "ProviderCalendar",
          icon: "calendarIcon",
          hide:
            this.includeService("calendar") &&
            this.includePlan("can_access_calendar"),
          hasSubmenu: false,
        },
        {
          title: "Estimates",
          routeName: "ProviderEstimates",
          icon: "iconestimates",
          hide:
            this.includeService("estimates") &&
            this.includePlan("estimate_database"),
          hasSubmenu: false,
        },
        {
          title: "Client Portfolio",
          routeName: "ClientPortfolioList",
          icon: "iconclientportfolio",
          hide:
            this.includeService("crm") && this.includePlan("can_access_crm"),
          hasSubmenu: false,
        },
        {
          title: "Wallet",
          routeName: "ProviderCreditCardsList",
          icon: "walletIcon",
          hide: false,
          hasSubmenu: false,
        },
        {
          title: "Withdraw",
          routeName: "ProviderWithdraw",
          icon: "withdrawIcon",
          hide: false,
          hasSubmenu: false,
        },
        {
          title: "Call History",
          routeName: "ProviderCall",
          icon: "callIcon",
          hide:
            this.includeService("communication") &&
            this.includePlan("can_access_communication"),
          hasSubmenu: false,
        },
      ];
      return items;
    },
  },
  mounted() {

    if (this.dashboardMenuItems) {
      this.dashboardMenuItems.forEach((menu) => {
        if (menu.routeName === this.$route.name && menu.hide != false) {
          this.confirmClaim();
        } else if (menu.submenu) {
          menu.submenu.forEach((submenu) => {
            if (
              submenu.routeName === this.$route.name &&
              submenu.hide != false
            ) {
              this.confirmClaim();
            }
          });
        }
      });
    }
  },

  methods: {
    includeService(code) {
      let service = this.currentPlanServices?.find((el) => el.code == code);
      if (service == undefined) {
        return true;
      } else {
        return false;
      }
    },
    includePlan(key) {
      if (this.currentPlan && this.currentPlan[key] == null) {
        return true;
      } else {
        return false;
      }
    },

    goto(el) {
      if (el.hide != false) {
        this.selected = el;
        this.confirmClaim();
      }
      if (el.routeName === this.$route.name) {
        if (el.params) {
          if (el.params.status === this.$route.params.status) {
            window.location.reload();
            return;
          } 
        } else {
          window.location.reload();
          return;
        }
      }
      // console.log(el.params);
      if (el?.params) {
        this.$router.push({ name: el.routeName, params: el.params });
      } else {
        this.$router.push({ name: el.routeName });
      }

      this.$emit("closeMobileMenu");
    },

    accordionToggle(e) {
      e.target.classList.toggle("collapsed");
      this.$refs.collapse.forEach((e) => {
        e.classList.add("collapsing");
        const time = setTimeout(() => {
          e.classList.remove("collapsing");
          e.classList.toggle("show");
          clearTimeout(time);
        }, 300);
      });
    },

    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },

    logout() {
      this.$emitter.emit(GlobalEvents.LOGOUT);
    },
  },
};
</script>
