<template>
  <div class="post-job row">
    <section class="content">
      <div class="page-header">
        <h3>Create Estimate</h3>
      </div>

      <div class="page-content">
        <form @submit.prevent="save">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <div class="row">
            <div class="col-md-12">
              <p class="title mb-3">
                Estimate Details
              </p>

              <div class="mb-3 col-md-12">
                <label class="form-label">
                  Title <span class="text-danger">*</span>
                </label>
                <input
                  v-model="datasend.title"
                  type="text"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write title..."
                  :class="{
                    'is-invalid':
                      v$.datasend.title.$invalid && v$.datasend.title.$dirty,
                  }"
                  @blur="v$.datasend.title.$touch"
                >
                <template
                  v-if="
                    (v$.datasend.title.$invalid && v$.datasend.title.$dirty) ||
                      errors.title
                  "
                >
                  <small
                    v-if="v$.datasend.title.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="v$.datasend.title.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 3 characters
                  </small>

                  <small
                    v-else-if="errors.title"
                    class="invalid-feedback"
                  >
                    {{ errors.title }}
                  </small>
                </template>
              </div>

              <div class="mb-3 col-md-12">
                <label class="form-label">
                  Client <span class="text-danger">*</span>
                </label>
                <select
                  v-model="datasend.client"
                  class="form-select p-3 shadow-none"
                  :class="{
                    'is-invalid':
                      v$.datasend.client.$invalid && v$.datasend.client.$dirty,
                  }"
                  @blur="v$.datasend.client.$touch"
                >
                  <option
                    value="null"
                    disabled
                  >
                    Select a Client
                  </option>
                  <option
                    v-for="(item, index) in clientsList"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.company_name }}
                  </option>
                </select>
                <template
                  v-if="
                    (v$.datasend.client.$invalid &&
                      v$.datasend.client.$dirty) ||
                      errors.client
                  "
                >
                  <small
                    v-if="v$.datasend.client.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="errors.client"
                    class="invalid-feedback"
                  >
                    {{ errors.client }}
                  </small>
                </template>
              </div>

              <div class="mb-3 col-md-12">
                <label class="form-label"> Send Date </label>

                <vue-date-picker
                  v-model="datasend.send"
                  placeholder="Send Date"
                  class="datepicker form-control"
                  :class="{ 'is-invalid': errors.send }"
                  auto-apply
                  :enable-time-picker="true"
                  :min-date="new Date()"
                  :format="'MM-dd-yyyy HH:mm'"
                />

                <small
                  v-if="errors.send"
                  class="invalid-feedback"
                >
                  {{ errors.send }}
                </small>
              </div>
              <div class="mb-3 col-md-12">
                <label class="form-label">
                  Message <span class="text-danger">*</span>
                </label>
                <textarea
                  v-model="datasend.message"
                  cols="30"
                  rows="4"
                  class="form-control pl-3 shadow-none"
                  placeholder="Write message..."
                  :class="{
                    'is-invalid':
                      v$.datasend.message.$invalid &&
                      v$.datasend.message.$dirty,
                  }"
                  @blur="v$.datasend.message.$touch"
                />
                <template
                  v-if="
                    (v$.datasend.message.$invalid &&
                      v$.datasend.message.$dirty) ||
                      errors.message
                  "
                >
                  <small
                    v-if="v$.datasend.message.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </small>

                  <small
                    v-else-if="v$.datasend.message.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    The minimum size for this field is 3 characters
                  </small>
                  <small
                    v-if="v$.datasend.message.maxLength.$invalid"
                    class="invalid-feedback"
                  >
                    Must contain no more than 255 characters
                  </small>

                  <small
                    v-else-if="errors.message"
                    class="invalid-feedback"
                  >
                    {{ errors.message }}
                  </small>
                </template>
              </div>
            </div>

            <div class="col-md-12 mb-3">
              <div class="col-12 py-3 mb-3">
                <div class="row text-start">
                  <p class="col-6 title">
                    Item & Description
                  </p>
                  <div class="col-6 mt-3 text-end">
                    <button
                      class="btn-primary px-5"
                      type="button"
                      @click="addResponse"
                    >
                      Add Item
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-for="(item, i) in datasend.items"
                :key="i"
                class="mb-3"
              >
                <div class="col-12 my-3">
                  <div class="row text-start">
                    <label class="col-md-10 pl-3">{{ i + 1 }}. Item</label>

                    <div class="col-md-2 text-end">
                      <img
                        :src="croixVectorIcon"
                        alt="Remove Icon"
                        class="p-2 cursor-pointer"
                        @click="removeResponse(item)"
                      >
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-12">
                    <label class="form-label">Item</label>
                    <input
                      v-model="item.item"
                      class="form-control p-3 shadow-none"
                      type="text"
                      placeholder="Item"
                    >
                    <template v-if="errors.items[i]?.item">
                      <small
                        v-for="(error, j) in errors.items[i]?.item"
                        :key="j"
                        class="invalid-feedback"
                      >
                        {{ error }}
                      </small>
                    </template>
                  </div>
                  <div class="col-md-6 text-end">
                    <div class="my-2 text-start">
                      <label class="form-label">Quantity</label>
                      <input
                        v-model="item.quantity"
                        class="form-control p-3 shadow-none"
                        type="number"
                        @input="
                          item.quantity = formatPositiveNumber(
                            $event.target.value
                          )
                        "
                      >
                      <template v-if="errors.items[i]?.quantity">
                        <small
                          v-for="(error, j) in errors.items[i]?.quantity"
                          :key="j"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </small>
                      </template>
                    </div>
                  </div>
                  <div class="col-md-6 text-end">
                    <div class="my-2 text-start">
                      <label class="form-label">Price</label>
                      <money3
                        v-model="item.value"
                        prefix="$"
                        class="form-control p-3"
                      />
                      <template v-if="errors.items[i]?.value">
                        <small
                          v-for="(error, j) in errors.items[i]?.value"
                          :key="j"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </small>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 my-5 text-end d-flex">
              <div class="col-md-12 align-self-end mb-3">
                <div class="text-end">
                  <div class="d-inline-block text-bold">
                    <p>Sub Total : &nbsp;</p>
                    <p>Tax(8%) : &nbsp;</p>
                    <p>Service Fee : &nbsp;</p>
                    <p>You will receive : &nbsp;</p>
                    <p>Total : &nbsp;</p>
                  </div>
                  <div class="d-inline-block">
                    <p>{{ formatPrice(subTotal) }}</p>
                    <p>{{ tax }}</p>
                    <p>{{ formatPrice(serviceFee) }}</p>
                    <p>{{ formatPrice(willReceive) }}</p>
                    <p>{{ formatPrice(total) }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row m-0 p-0">
              <div class="col-6 pb-3 text-start">
                <button
                  class="btn-outline"
                  type="button"
                  @click="back"
                >
                  Back
                </button>
              </div>

              <div class="col-6 pb-3 text-end">
                <button
                  class="btn-primary"
                  type="submit"
                  :disabled="submitting"
                >
                  <submission-spinner
                    v-if="submitting"
                    :color="'#209516'"
                  />
                  Create
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import croixVectorIcon from "@/assets/img/icons/poubell-red.svg";
import validationHelpers from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";

import { Money3Component } from "v-money3";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";

import api from "@/services/api";
import { GeneralHelpers } from "@/utils/general";
import { GlobalConstants } from "@/configs/constants";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderEstimateCreatePage",

  components: {
    money3: Money3Component,
    SubmissionSpinner,
    AlerteDanger,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    croixVectorIcon,
    showModal: false,
    fadeModal: false,
    loading: false,
    submitting: false,
    clientsList: [],
    datasend: {
      items: [],
      message: "",
      title: "",
      send: null,
      client: null,
    },
    errors: {
      items: [],
      message: null,
      title: null,
      error: null,
      send: null,
      client: null,
      serverSideErrorMessage: null,
    },
  }),

  validations() {
    return {
      datasend: {
        title: {
          required,
          minLength: minLength(3),
        },

        message: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255)
        },

        client: {
          required,
        },
      },
    };
  },

  computed: {
    subTotal() {
      return this.datasend.items.reduce((prev, curr) => {
        return prev + curr.quantity * curr.value;
      }, 0);
    },

    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },

    tax() {
      return (this.currentPlan?.tax_percentage / 100) * this.subTotal;
    },

    total() {
      return this.subTotal + this.tax;
    },

    serviceFee() {
      let total =
        (this.currentPlan?.invoice_fee_percentage / 100) * this.subTotal +
        this.currentPlan?.invoice_fee_fix;
      return total.toFixed(2);
    },

    willReceive() {
      let total = this.subTotal - this.serviceFee;
      return Math.max(total, 0);
    },
  },

  mounted() {
    this.fetchClientSummary();
  },

  methods: {
    ...DateHelpers,
    ...GeneralHelpers,
    ...validationHelpers,
    ...PriceHelpers,

    back() {
      this.$router.back();
    },

    save() {
      if (this.submitting) return;

      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }

      this.submitting = true;
      this.clearErrorsMessages();

      if (this.datasend.send) {
        this.datasend.send = this.formatDate(
          this.datasend.send,
          "YYYY-MM-DD HH:mm"
        );
        const allRoutes = this.$router.getRoutes();
        this.datasend.redirectLink = `${
          GlobalConstants.BASE_URL +
          allRoutes.find((route) => route.name == "SharedEstimateDetails").path
        }`;
      }

      api
        .createEstimate({
          ...this.datasend,
          items: this.datasend.items.map((item) => ({
            ...item,
            value: +item.value,
            quantity: +item.quantity,
          })),
        })
        .then((_) => {
          this.resetValidation();
          this.$router.push({
            name: "ProviderEstimates",
          });
        })
        .catch((error) => {
          this.errorsHandler(error);
        })
        .then(() => {
          this.submitting = false;
        });
    },

    clearErrorsMessages() {
      this.errors = {
        items: [],
        client: null,
        title: null,
        message: null,
        error: null,
        send: null,
        serverSideErrorMessage: null,
      };
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            if (error == 'items') {
              this.errors[error] = err.response.data[error]
            } else {
              this.errors[error] =
              error == "error"
                ? err.response.data[error]
                : err.response.data[error][0];
            }
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    addResponse() {
      this.datasend.items.push({
        item: '',
        value: 0,
        quantity: 0,
      });
      this.errors.items.push({
        item: null,
        value: null,
        quantity: null,
      });
    },

    removeResponse(item) {
      this.datasend.items = this.datasend.items.filter((el) => el != item);
    },

    fetchClientSummary() {
      this.loading = true;

      api
        .fetchClientSummary()
        .then((response) => {
          this.clientsList = response.data.data;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
