<template>
  <div>
    <loader-spinner v-if="loading" />
    <div
      v-if="!loading"
      class="row"
    >
      <div class="col-lg-3">
        <div class="">
          <h2>
            All Events ({{ INITIAL_EVENTS.length }})
          </h2>
          <ul
            class="list-unstyled"
          >
            <li
              v-if="INITIAL_EVENTS.length === 0"
              class="py-3"
            >
              <span class="py-3">
                {{ 'No Events' }}
              </span>
            </li>
            <li
              v-for="event in INITIAL_EVENTS"
              :key="event.id"
              class="py-3"
            >
              <small>{{ formatDate(event.startStr) }} :</small><br>
              <span @click="goTo(event.startStr)">
                {{ event.title }}
              </span>
            </li>
          </ul>
        </div>
        <div
          class="d-flex align-items-center my-3"
        >
          <input
            type="checkbox"
            class="me-2 shadow-none shadow-none"
            :checked="!calendarOptions.weekends"
            @change="handleWeekendsToggle"
          >
          <label>
            {{ 'Hide  Weekends' }}  
          </label>
        </div>
      </div>
      <div
        class="col-lg-9"
      >
        <full-calendar :options="calendarOptions">
          <template 
            #eventContent="arg"
          >
            <b>{{ arg.timeText }}</b>
            <i>{{ arg.event.title }}</i>
          </template>
        </full-calendar>
      </div>
      <calendar-modal 
        ref="calendarModalSubmit" 
        title="Calendar" 
        close-button-title="Close"
        submit-button-title="Submit"
        @fetchCalendar="fetchProviderCalendatData()"
      />
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import { toast } from "vue3-toastify";

import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import CalendarModal from "@/components/calendar/CalendarModal.vue";
import { DateHelpers } from '@/utils/date-helpers';

export default {
  name: "CustomerCalendar",
  components: {
    LoaderSpinner,
    CalendarModal,
  },

  data() {
    return {
      loading: false,
      openCalendarModal: false,
      INITIAL_EVENTS: [],
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,listWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        timeZone: "local",
        allDaySlot: true,
        editable: true,
        selectable: true,
        aspectRatio: 1.2,
        height: "auto",
        // titleFormat: { year: "numeric", month: "long", day: "numeric" },
        nowIndicator: true,
        navLinks: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventChange:this.eventDrop
      },
    };
  },

  computed: {
    currentUser() {
      return this.$store.getters["user/user"];
    }
  },

  mounted() {
    this.fetchProviderCalendatData();
  },

  methods: {
    ...DateHelpers,
    async fetchProviderCalendatData() {
      try {
        this.loading = true;
        this.INITIAL_EVENTS = [];
        const response = await api.fetchCalendarEvents();
        if (response.data) {
          response.data.data.forEach(
            (item) =>
              item.job_detail !== null &&
              this.INITIAL_EVENTS.push({
                id: item.id,
                title: item.job_detail.title,
                start: item.start,
                end: item.end,
                endDate: item.end,
                description: item.description,
                idJob: item.job_detail.id,
                idEvent: item.id,
              })
          );
          this.calendarOptions.events = this.INITIAL_EVENTS;
        }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },

    handleDateSelect(selectInfo) {
      this.$refs.calendarModalSubmit.openModal("create");
      this.$refs.calendarModalSubmit.calendarData.start = selectInfo.start;
      this.$refs.calendarModalSubmit.calendarData.end = selectInfo.end;
    },

    handleEventClick(clickInfo) {
      this.$refs.calendarModalSubmit.loadDetail(clickInfo);
      this.$refs.calendarModalSubmit.openModal("detail");
    },

    handleEvents(events) {
      this.INITIAL_EVENTS = events;
    },
    goTo(date) {
      const calendar = this.$refs.calendar.getApi()
     calendar.gotoDate(date)
    },
    async eventDrop(info) {
      const { event, oldEvent, delta, revert } = info;
      try{
        const data = {
          start: this.formatDate(event.start, "YYYY-MM-DD"),
          end: this.formatDate(event.end, "YYYY-MM-DD"),
          date: this.formatDate(event.start, "YYYY-MM-DD"),
          time: this.formatDate(event.start, "HH:MM:SS"),
          duration: this.formatDate(event.start, "HH:MM:SS"),
          job: event.extendedProps.idJob,
          description: event.extendedProps.description,
          user: this.currentUser.id
        };

        if (data) {
          await api.updateCalendarEvent(event.extendedProps.idEvent,data);
          toast.success("Your event update successfully.");
        }

      }catch(err){
        this.fetchProviderCalendatData()
        toast.error("Please try again, your event could not be updated.");
      }
    },
  },
};
</script>

<style>
.fc .fc-scrollgrid table {
  margin: 0 !important;
}

.fc-day {
  padding: 0 !important;
}

.fc .fc-daygrid-event{
  overflow: hidden;
}
.fc .fc-event b{
  margin-right: .5rem;
}

</style>
