<template>
  <thead>
    <tr
      v-if="error"
      class="table-light"
    >
      <th
        class="p-0 fw-normal"
        :colspan="list.length"
        style="text-transform: none;"
      >
        <div
          v-if="error !== null"
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {{ error }}

          
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          />
        </div>
      </th>
    </tr>
    <tr class="table-dark">
      <th
        v-for="(item, index) in list"
        :key="index"
        scope="col"
      >
        <span v-if="hide">
          Locked
        </span>

        <span v-else-if="item == 'checkbox'">
          <input
            type="checkbox"
            class="d-inline mx-2"
            :checked="checked"
            @change="$emit('change')"
          >
        </span>

        <span v-else>
          {{ item }}
        </span>
      </th>
    </tr>
  </thead>
</template>

<script>

export default {
  name: "HeadTable",

  props: {
    list: {
      type: Array,
      required: true,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    hide: {
      type: Boolean,
      required: false,
      default: false,
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style scoped>
.alert-danger button.btn-close:hover {
  background-color: transparent !important;
}
</style>