<template>
  <div>
    <header>
      <div
        class="logo-section position-relative"
        :class="{ minwidth80px: toggleMenu }"
      >
        <router-link
          :to="{ name: 'ProviderDashboard' }"
          class="position-relative"
        >
          <img
            v-if="toggleMenu"
            :src="icons.logoS"
            alt="App Logo"
            class="logo"
          >
          <img
            v-else
            :src="icons.appLogo"
            alt="App Logo"
            class="logo"
          >
          <span
            class="position-absolute bottom-0 end-25 badge rounded-pill bg-transparent"
          >
            {{ version[0]?.name }}
          </span>
        </router-link>
        <img
          :src="toggleMenu ? icons.le_arrow : icons.ri_arrow"
          alt="ri arrow"
          class="position-absolute arrow"
          @click="$emit('toggleMenu')"
        >
      </div>
      <div class="position-relative w-100">
        <div
          class="header-section content border-bottom border-primary"
        >
          <div class="d-inline-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 btn-toogle px-1 py-0 toggle"
              @click.prevent="toggleMobileMenu"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>

          <div class="profile-section me-3 my-2">
            <notification-dropdown />

            <img
              v-if="false"
              :src="icons.commentIcon"
              alt="commentaire"
              class="me-3"
            >
            <div class="px-1">
              <a
                class="nav-link"
                href="#"
              >
                <i class="bi bi-chat-left-text" />
              </a>
            </div>

            <div
              v-if="currentProvider"
              class="dropdown"
            >
              <div
                class="dropdown-toggle mb-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <profile-avatar
                  :url="currentProvider.photo?.url || ''"
                  :fullname="currentProvider.full_name"
                  :color="currentProvider.profile_color"
                  :background="currentProvider.profile_background"
                />

                {{ currentProvider.full_name }}
              </div>

              <ul class="dropdown-menu">
                <li>
                  <router-link
                    :to="{ name: 'ProviderProfile' }"
                    class="dropdown-item"
                  >
                    Profile
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'ProviderSubscription' }"
                    class="dropdown-item"
                  >
                    Subscription
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'ProviderBuyBids' }"
                    class="dropdown-item"
                  >
                    Buy Bids
                  </router-link>
                </li>

                <li>
                  <a
                    class="dropdown-item"
                    @click.prevent="confirmClaim"
                  >Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          v-if="
            [
              providerStatus.NEED_APPROVAL,
              providerStatus.NEED_COMPLETE_PROFILE,
              providerStatus.REFUSED,
            ].includes(currentProvider?.status)
          "
          class=""
          :to="{ name: 'ProviderProfileEdit' }"
          :class="
            toggleMenu
              ? 'need-to-complete-profile-banner-width'
              : 'need-to-complete-profile-banner'
          "
        >
          <div
            class="alert clearfix pb-0 pt-2"
            role="alert"
            :class="
              currentProvider?.status === providerStatus.NEED_APPROVAL ||
                currentProvider?.status === providerStatus.REFUSED
                ? 'alert-warning'
                : 'alert-danger'
            "
          >
            <div class="float-start ms-4">
              <h4 class="alert-heading">
                {{ getBannerErrormessage?.title }}
              </h4>
              <p>
                {{ getBannerErrormessage?.message }}
              </p>
            </div>
            <router-link
              v-if="getBannerErrormessage?.action"
              :to="{ name: getBannerErrormessage?.route }"
              class="btn float-end px-3 mt-2 me-5"
              :class="
                currentProvider?.status === providerStatus.NEED_APPROVAL ||
                  currentProvider?.status === providerStatus.REFUSED
                  ? 'btn-outline-warning'
                  : 'btn-outline-danger'
              "
            >
              {{ getBannerErrormessage?.action }}
            </router-link>
          </div>
        </div>
      </div>
      <confirm-modal
        ref="confirmModal"
        title="Logout"
        ok-button-title="Logout"
        :message="'Are you sure, you want to disconnect  your account ?'"
        @onConfirm="logout"
      />

      <modal ref="modal">
        <div class="text-center">
          <img
            class="icon"
            :src="icons.checkIcon"
            alt="success"
          >
          <div class="content-modal d-block py-3">
            <h3>Congratulations !</h3>
            <p>Your account has been activated. You can now apply to jobs.</p>
          </div>
          <a
            class="btn-outline px-4"
            @click="closeModal"
          >Close</a>
        </div>
      </modal>
    </header>
  </div>
</template>

<script>
import { nextTick } from "vue";

import commentIcon from "@/assets/img/icons/insert-comment.svg";
import appLogo from "@/assets/img/white_logo.svg";
import notificationIcon from "@/assets/img/icons/notification.svg";
import checkIcon from "@/assets/img/icons/check.svg";
import logoS from "@/assets/img/icons/logoS.svg";
import logoT from "@/assets/img/icons/logoT.svg";
import ri_arrow from "@/assets/img/icons/ri_arrow.svg";
import le_arrow from "@/assets/img/icons/le_arrow.svg";

import Modal from "@/components/Modal.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import ProfileAvatar from "@/components/ProfileAvatar.vue";
import NotificationDropdown from "@/components/notifications/NotificationDropdown.vue";

import api from "@/services/api";
import SocketService from "@/utils/socket-service";

import {
  GlobalConstants,
  GlobalEvents,
  WebSocketEvents,
} from "@/configs/constants";
import { ProviderStatus } from "@/configs/constants/users";

export default {
  name: "ProviderHeader",

  components: {
    Modal,
    ConfirmModal,
    ProfileAvatar,
    NotificationDropdown,
  },
  props: {
    toggleMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    icons: {
      appLogo,
      notificationIcon,
      commentIcon,
      checkIcon,
      ri_arrow,
      le_arrow,
      logoS,
      logoT,
    },
    version: [],
    needToCompleteProfile: {
      title: "Profile not complete",
      message: "Please complete profile for approval",
      action: "Edit Profile",
      route: "ProviderProfileEdit",
    },
    completedProfile: {
      title: "Profile completed.",
      message: "Awaiting Approval",
      action: false,
      route: "",
    },
    needToActiveProfile: {
      title: "Your profile is not approved.",
      message: "Check your notification or your email",
      action: "Notifications",
      route: "ProviderNotifications",
    },
  }),

  computed: {
    currentProvider() {
      return this.$store.getters["user/user"];
    },

    providerStatus() {
      return ProviderStatus;
    },

    getBannerErrormessage() {
      let message = {};

      switch (this.currentProvider?.status) {
        case this.providerStatus.NEED_APPROVAL:
          message = this.completedProfile;
          break;

        case this.providerStatus.NEED_COMPLETE_PROFILE:
          message = this.needToCompleteProfile;
          break;

        case this.providerStatus.REFUSED:
          message = this.needToActiveProfile;
          break;
      }

      return message;
    },
  },

  mounted() {
    this.connectSocketAndListenEvents();
    this.fetchVersion();
  },

  methods: {
    toggleMobileMenu() {
      this.$emit("toggleMobileMenu");
    },

    openCongratulationsModal() {
      nextTick(() => {
        this.$refs.modal.openModal();
      });
    },

    closeModal() {
      nextTick(() => {
        this.$refs.modal.closeModal();
      });
    },

    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },

    logout() {
      this.$emitter.emit(GlobalEvents.LOGOUT);
    },

    connectSocketAndListenEvents() {
      this.socketService = new SocketService(
        `${GlobalConstants.WEB_SOCKET_SERVER_URL + "/ws/events/"}`
      );
      this.socket = this.socketService.connect();

      this.socket.addEventListener("close", (event) => {
        setTimeout(() => this.connectSocketAndListenEvents(), 1000);
      });

      this.socketService.on((event) => {
        const data = JSON.parse(event.data);
        console.log("Event received", data);
        this.processSocketEventsData(data);
      });

      this.$emitter.on(GlobalEvents.EMIT_WEBSOCKET_EVENT, (data) => {
        this.socketService.emit(data);
      });
    },

    processSocketEventsData(data) {
      switch (data.event_type) {
        case WebSocketEvents.PROVIDER_STATUS_UPDATED:
          this.$store.dispatch("user/saveUser", {
            ...this.currentProvider,
            status: data.new_status,
          });

          switch (data.new_status) {
            case ProviderStatus.ACTIVE:
              this.openCongratulationsModal();
              break;

            case ProviderStatus.REFUSED:
              // this.openCongratulationsModal();
              break;

            case ProviderStatus.DEACTIVATE:
              this.logout();
              break;
          }
          break;

        case WebSocketEvents.chat.IS_TYPING:
          this.$emitter.emit(GlobalEvents.chat.IS_TYPING, data.payload);
          break;

        case WebSocketEvents.chat.MESSAGE_RECEIVED:
          this.$emitter.emit(
            GlobalEvents.chat.CHAT_MESSAGE_RECEIVED,
            data.message_sent
          );
          break;

        case WebSocketEvents.NOTIFICATION_RECEIVED:
          this.$emitter.emit(GlobalEvents.NEW_NOTIFICATION, {
            notification: data.new_notification,
            unreadNotifications: data.notification_count,
          });
          break;

        case WebSocketEvents.PROFILE_UPDATED:
          this.$store.dispatch("user/saveUser", {
            ...this.currentProvider,
            ...data.data,
          });
          break;

        case WebSocketEvents.todo.TASK_CREATED:
          this.$emitter.emit(GlobalEvents.todo.TASK_CREATED, data.data);
          break;

        case WebSocketEvents.todo.TASK_UPDATED:
          this.$emitter.emit(GlobalEvents.todo.TASK_UPDATED, data.data);
          break;

        case WebSocketEvents.todo.TASK_DELETE:
          this.$emitter.emit(GlobalEvents.todo.TASK_DELETE, data.data);
          break;

        case WebSocketEvents.todo.TASK_CHECK_LIST_CREATED:
          this.$emitter.emit(
            GlobalEvents.todo.TASK_CHECK_LIST_CREATED,
            data.data
          );
          break;

        case WebSocketEvents.todo.TASK_CHECK_LIST_UPDATED:
          this.$emitter.emit(
            GlobalEvents.todo.TASK_CHECK_LIST_UPDATED,
            data.data
          );
          break;

        case WebSocketEvents.todo.TASK_CHECK_LIST_DELETE:
          this.$emitter.emit(
            GlobalEvents.todo.TASK_CHECK_LIST_DELETE,
            data.data
          );
          break;

        case WebSocketEvents.todo.STATUS_CREATE:
          this.$emitter.emit(GlobalEvents.todo.STATUS_CREATE, data.data);
          break;

        case WebSocketEvents.todo.STATUS_UPDATED:
          this.$emitter.emit(GlobalEvents.todo.STATUS_UPDATED, data.data);
          break;

        case WebSocketEvents.todo.STATUS_DELETED:
          this.$emitter.emit(GlobalEvents.todo.STATUS_DELETED, data.data);
          break;

        case WebSocketEvents.todo.PRIORITY_CREATE:
          this.$emitter.emit(GlobalEvents.todo.PRIORITY_CREATE, data.data);
          break;

        case WebSocketEvents.todo.PRIORITY_UPDATED:
          this.$emitter.emit(GlobalEvents.todo.PRIORITY_UPDATED, data.data);
          break;

        case WebSocketEvents.todo.PRIORITY_DELETE:
          this.$emitter.emit(GlobalEvents.todo.PRIORITY_DELETE, data.data);
          break;

        case WebSocketEvents.job.PROPOSAL_RESPONSE:
          this.$emitter.emit(GlobalEvents.job.PROPOSAL_RESPONSE, {
            proposalId: data.bid,
            status: data.bid_status,
          });
          break;
      }
    },
    fetchVersion() {
      api
        .fetchVersion()
        .then((response) => {
          this.version = response.data.data;
        })
        .catch((_) => {
          /**
           * TODO
           * Handle erros
           */
          // this.errorHandler(error)
        });
    },
  },
};
</script>

<style>
.need-to-complete-profile-banner {
  position: absolute;
  z-index: 998;
  display: block;
  width: calc(100vw - 245px);
}
.need-to-complete-profile-banner-width {
  position: absolute;
  z-index: 998;
  display: block;
  width: calc(100vw - 80px);
}

.need-to-complete-profile-banner:hover {
  text-decoration: none;
}

.need-to-complete-profile-banner .error-feedback {
  padding: 1rem;
  margin-bottom: 0 !important;
}

.need-to-complete-profile-banner .error-feedback p {
  font-size: 1.1rem !important;
}

.notifications-unread {
  position: relative;
}

.notifications-unread::after {
  background-color: rgb(250, 4, 4);
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 100px;
  right: -1px;
  top: 2px;
  border: 1px #fff solid;
}

@media (max-width: 992px) {
  .need-to-complete-profile-banner {
    position: relative;
    width: 100% !important;
  }
}
</style>
