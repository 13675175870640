<template>
  <div>
    <list-header
      title="Customers"
      :options="headerOptions"
      :search="true"
      @update:options="
        fetchAllCustomers(
          $event.value ? { page: 1, status: $event.value } : { page: 1 }
        ),
        (headerOptions.title = $event.title),
        (status = $event.value),
        (page = 1)
      "
      @update:search="
        fetchAllCustomers($event ? { page: 1, search: $event } : { page: 1 }),
        (search = $event),
        (page = 1)
      "
    />

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <head-table :list="thead" />
            <tbody v-if="!fetching">
              <tr
                v-for="(item, index) in listData"
                :key="index"
              >
                <td>
                  <span
                    class="cursor-pointer text-primary"
                    @click="detail(item)"
                  >
                    {{ item.user.full_name }}
                  </span>
                  <span 
                    v-if="item?.new"
                    class="p-1 bg-danger border border-light rounded-circle d-inline-block"
                  />
                </td>
                <td>
                  {{ item.user.email }}
                </td>
                <td>
                  {{ item.user.phone }}
                </td>
                <td>
                  {{ statusToUI(item.user.is_active) }}
                </td>
                <td>
                  <option-action
                    :status="item.user.is_active == true ? 'True' : 'False'"
                    :options="[...CustomerStatusList, {
                      title: 'Login as',
                      value: 'login',
                    }]"
                    @update:options="confirmClaim(item, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <loader-spinner v-if="fetching" />
        </div>
      </div>

      
      <app-pagination
        v-if="totalPages > 1"
        :align-end="true"
        :current-page="currentPage"
        :has-previous-page="hasPreviousPage"
        :previous-page="currentPage - 1 || 1"
        :has-next-page="hasNextPage"
        :next-page="currentPage + 1"
        :per-page="itemsPerPage"
        :total-elements="itemsCount"
        :total-pages="totalPages"
        @onGotoPage="fetchAllCustomers({ page: $event, search })"
        @onPrev="fetchAllCustomers({ page: currentPage - 1 || 1, search })"
        @onNext="fetchAllCustomers({ page: currentPage + 1, search })"
      />
    </div>

    <confirm-modal
      ref="confirmModal"
      :ok-button-title="confirmationOkBtnText"
      :message="confirmationMessage"
      @onConfirm="updateStatus"
    />
  </div>
</template>

<script>
import ListHeader from "@/components/ListHeader.vue";
import HeadTable from "@/components/HeadTable.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import OptionAction from"@/components/OptionAction.vue";

import api from "@/services/api";
import ListMixin from "@/mixins/list";

import { CustomerStatusList, UserScopes } from "@/configs/constants/users";
import statusToUI from "@/utils/statusFormate";
import LoaderSpinner from '@/components/LoaderSpinner.vue';
import AppPagination from '@/components/AppPagination.vue';

export default {
  name: "SuperadminCustomersPage",
  components: {
    ListHeader,
    HeadTable,
    ConfirmModal,
    OptionAction,
    LoaderSpinner,
    AppPagination,
  },
  mixins: [ListMixin(api.fetchAllCustomers, true)],
  data: () => ({
    CustomerStatusList,
    headerOptions: {
      title: CustomerStatusList[0].title,
      list: CustomerStatusList,
    },
    thead: [
      "Fullname",
      "Email",
      "Phone",
      "Status",
      "",
    ],
    confirmationOkBtnText: null,
    confirmationMessage: null,
    selected: null,
    status: null,
    listData: [],
    page: 1,
    pages: {
      count: null,
      max_page_size: null,
      total_pages: null,
    },
    loading: false,
    fetching: false,
  }),

  watch: {
    listData(val) {
      this.fetching = false;
    },
  },

  mounted() {
    this.fetchAllCustomers({ page: this.page });
  },
  methods: {
    ...statusToUI,
    
    detail(item) {
      this.$router.push({
        name: "SuperAdminCustomerDetails",
        params: { id: item.id, tag: 'info' },
      });
    },

    confirmClaim(element, status) {
      this.selected = element;
      if (status == 'login') {
        this.status = status;
        this.confirmationMessage = `Are you sure you want to login as ${this.selected.user.full_name} ?`;
      } else {
        this.status = status === "True" ? true : false;
        this.confirmationMessage = `Are you sure you want to make ${this.statusToUI(
          this.status
        )} ${this.selected.user.full_name} account ?`;
      }

      this.confirmationOkBtnText = "Confirm";

      this.$refs.confirmModal.openModal();
    },

    updateStatus() {
      if (this.status == 'login') {
        this.loginAsCustomer()
      } else { 
      if (this.loading) return;

      this.loading = true;
      let _api = this.status
        ? api.makeCustomerActive
        : api.makeCustomerInactive;

      _api(this.selected.id)
        .then((response) => {
          this.fetchAllCustomers({ page: this.page });
          this.confirmationOkBtnText = null;
          this.confirmationMessage = null;
          this.selected = null;
          this.status = null;
          this.$refs.confirmModal.closeModal();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorsHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
      }
    },

    fetchAllCustomers(params) {
      this.fetching = true;
      this.updateListFilters({
        ...params,
      });
    },

    async loginAsCustomer() {
      this.loading = true;

      try {
        await api.loginAsCustomer(this.selected.id, (data) => {
         if (
            this.$route.query.redirectFrom?.includes(
              UserScopes.PROVIDER.toLowerCase()
            )
          ) {
            this.$route.query.redirectFrom = null;
          }

          this.$router.push(
            this.$route.query.redirectFrom || { name: "CustomerDashboard" }
          );
        });
      } catch (err) {
        console.log(err);
        // this.errorsHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
