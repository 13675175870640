<template>
  <section class="content proposal">
    <div class="page-header  pb-0 border-0">
      <h3 class="mt-3 mind text-primary">
        <router-link
          :to="{
            name: 'CustomerJobDetails',
            params: { id: $route.params.id },
            query: { ...$route.query },
          }"
          class="title me-1 pb-3"
        >
          Proposals
        </router-link>
        <span
          v-if="job?.title"
          class="fw-lighter text-black"
        >
          / {{ job.title }}
        </span>
      </h3>
    </div>

    <div
      class="page-content"
    >
      <div class="job-detail p-0">
        <div
          v-if="listData.length > 0"
          class="proposals p-0 border-top border-0"
        >
          <form
            class="col-12"
            @submit.prevent="acceptProvidersProposal"
          >
            <alerte-danger
              :head-error-message="errors.serverSideErrorMessage"
              @updateContent="errors.serverSideErrorMessage = $event"
            />

            <alerte-danger
              :head-error-message="errors.notFoundErrorMessage"
              @updateContent="errors.notFoundErrorMessage = $event"
            />
            <div class="border-proposal">
              <div
                v-if="job"
                class="table-responsive mt-3"
              >
                <table class="table">
                  <head-table
                    :list="thead"
                    :checked="datasend.bid_properties.length > 0 && bid_properties.length == datasend.bid_properties.length"
                    @change="checkBids()"
                  />
                  <tbody
                    v-for="(property, i) in job.customer_propertie_associate"
                    :key="i"
                  >
                    <tr>
                      <td />
                      <td>
                        {{ property?.customer_propertie_associate.company }}
                      </td>
                      <td class="text-wrap">
                        {{ property?.customer_propertie_associate.location_description }}
                      </td>
                      <td>
                        {{ property?.customer_propertie_associate.city }}
                      </td>
                      <td>
                        {{ property?.customer_propertie_associate.state }}
                      </td>
                      <td />
                      <td />
                      <td />
                      <td
                        v-for="(item, j) in job.items"
                        :key="j"
                      />
                      <td />
                    </tr>
                    <tr
                      v-for="(provider, k) in listData"
                      :key="k"
                    >
                      <td>
                        <input
                          v-if="provider.items.filter(el => el.property.id == property.customer_propertie_associate.id).length !== 0"
                          type="checkbox"
                          class="d-inline mx-2"
                          :disabled="property?.status"
                          :checked="property?.status && provider.items.filter(el => el.property.id == property.customer_propertie_associate.id && el.status == 'accepted') ? true : datasend?.bid_properties?.find(el => el == provider?.items?.filter(el => el.property.id == property.customer_propertie_associate.id)[0]?.id) != undefined"
                          @change="checkBid(provider?.items?.filter(el => el.property.id == property.customer_propertie_associate.id)[0]?.id)"
                        >
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        <template
                          v-if="provider.items.filter(el => el.property.id == property.customer_propertie_associate.id).length !== 0 "
                        >
                          {{ provider.owner?.user?.full_name }}
                          <button
                            v-if="property?.status && provider.items.filter(el => el.property.id == property.customer_propertie_associate.id && el.status == 'accepted') ? true : datasend?.bid_properties?.find(el => el == provider?.items?.filter(el => el.property.id == property.customer_propertie_associate.id)[0]?.id) != undefined"
                            class="btn-favorite mx-3"
                            :class="{ added: provider?.owner_liked }"
                            :disabled="addingToFavorites || removingFromFavorites"
                            @click="handleToggleFavorite(provider)"
                          >
                            <submission-spinner
                              v-if="addingToFavorites || removingFromFavorites"
                              :color="'#209516'"
                            />
                            <img
                              v-else
                              :src="icons.heartVectorIcon"
                              alt="Favorite Icon"
                            >
                          </button>
                        </template>
                      </td>
                      <td />
                      <td>
                        {{ provider.items.filter(el => el.property.id == property.customer_propertie_associate.id).length != 0 ?
                          formatPrice(
                            provider.items
                              .filter(el => el.property.id == property.customer_propertie_associate.id)
                              .reduce((prev, curr) => (prev + parseInt(curr.price)), 0)) 
                          : null
                        }}
                      </td>
                      <td
                        v-for="(item, l) in provider.items.filter(el => el.property.id == property?.customer_propertie_associate.id)"
                        :key="l"
                      >
                        {{ formatPrice(item.price) }}
                      </td>
                      <td />
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td>
                        SUMMARY OF BIDS
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td
                        v-for="(item, j) in job.items"
                        :key="j"
                      />
                      <td />
                    </tr>
                    <tr
                      v-for="(provider, k) in listData"
                      :key="k"
                    >
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        {{ provider.owner?.user?.full_name }}
                      </td>
                      <td>
                        {{ 
                          provider.items.length != 0 ? 
                            job.customer_propertie_associate
                              .filter(el1 => job.customer_propertie_blocked
                                .find(el2 => el1.id !== el2.property.id))
                              .filter(el1 => provider.items
                                .find(el2 => el1.id == el2.property.id)).length : 0
                        }}
                      </td>
                      <td />
                      <td
                        v-for="(item, j) in job.items"
                        :key="j"
                      />
                      <td>
                        {{ 
                          provider.items.length != 0 ? formatPrice(provider.items.reduce((prev, curr) => (prev + parseInt(curr.price)), 0)) : 0
                        }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div
              v-if="datasend?.bid_properties.length != 0"
              class="btn-block mt-4"
            >
              <div class="col-12 text-end">
                <button
                  :disabled="submitting"
                  class="btn-save btn-primary"
                >
                  <submission-spinner v-if="submitting" />
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>

        <loader-spinner v-if="fetching" />
      </div>
    </div>
    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :message="modalConfigs.confirmMessage"
      @onConfirm="onModalConfirm"
    />
  </section>
</template>

<script>
import { nextTick } from "vue";
import api from "@/services/api";
import ListMixin from "@/mixins/list";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import errorMessages from "@/utils/error-messages";

import JobHelper from "@/utils/job";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import { ProposalStatus } from "@/configs/constants/jobs";
import HeadTable from '@/components/HeadTable.vue';
import SubmissionSpinner from '@/components/SubmissionSpinner.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import heartVectorIcon from "@/assets/img/icons/heart.svg";
import { toast } from 'vue3-toastify';
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderProposalsSommaryPage",

  components: {
    LoaderSpinner,
    HeadTable,
    SubmissionSpinner,
    ConfirmModal,
    AlerteDanger,
  },

  mixins: [ListMixin(api.fetchJobBids, false)],

  data: () => ({
    icons: {
      heartVectorIcon,
    },
    status: ProposalStatus.ACCEPETED,
    job: null,
    fetching: false,
    submitting: false,
    bid_properties: [],
    datasend: {
      bid_properties: []
    },
    thead: ["checkbox", "Name", "Address", "City", "State", "Provider", "Bids", "Overall"],
    maxPagesReached: false,
    addingToFavorites: false,
    removingFromFavorites: false,
    actions: {
      ADD_TO_FAVORITES: "add-to-favorites",
      REMOVE_FROM_FAVORITES: "remove-from-favorites",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    showCustomerDetails: false,
    errors: {
      notFoundErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    proposalStatus() {
      return ProposalStatus
    }
  },

  watch: {
    listData() {
      this.fetching = false;
    },
  },

  mounted() {
    this.fetchJobBids();
    this.fetchJob()
  },

  methods: {
    ...JobHelper,
    ...DateHelpers,
    ...PriceHelpers,

    async fetchJob() {
      try {
        this.fetchingJob = true;
        const response = await api.fetchJob(this.$route.params.id);
        
        this.job = response.data;
        // console.log(response.data);
        this.thead = [...this.thead , ...response.data.customer_propertie_associate[0].items.map(el=> (el.name)), "Total"];
        this.job = {
          ...response.data,
          customer_propertie_associate : response.data.customer_propertie_associate
          .map(el1=> ({
            ...el1,
            status: response.data.customer_propertie_blocked.find(el => el1.customer_propertie_associate.id == el.property.id) !== undefined ? true : false,
          }))
        }
        // console.log(this.job.customer_propertie_associate);
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.fetchingJob = false;
      }
    },
    fetchJobBids() {
      this.fetchingJobBids = true;
      this.updateListFilters({
        id: this.$route.params.id,
      });
      this.listData.forEach(provider => {
        provider.items.forEach(element => {
          if (this.bid_properties?.find(el => el == element.id) == undefined) {
            this.bid_properties?.push(element.id)
          } 
        });
      });
    },

    checkBid(id){
      if (this.datasend.bid_properties?.find(el => el == id) == undefined) {
        this.datasend.bid_properties?.push(id)
      } else {
        this.datasend.bid_properties =  this.datasend.bid_properties?.filter(el => el != id)
      }
      // console.log(this.datasend.bid_properties);
    },
    checkBids(){
      this.listData.forEach(provider => {
        provider.items.forEach(element => {
          if (this.datasend.bid_properties?.find(el => el == element.id) == undefined) {
            this.datasend.bid_properties?.push(element.id)
          } 
        });
      });
      // console.log(this.datasend.bid_properties);
    },

    async acceptProvidersProposal() {
      try {
        this.submitting = true;
        await api.acceptProvidersProposal(this.datasend);

        toast.success(`Providers's job application successfully accepted.`);
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.submitting = false;
      }
    },
    handleToggleFavorite(param) {
      if (param?.owner_liked) {
        this.confirmClaim(this.actions.REMOVE_FROM_FAVORITES, param?.provider_saved_id);
      } else {
        this.confirmClaim(this.actions.ADD_TO_FAVORITES, param?.owner?.id);
      }
    },
    confirmClaim(action, id) {
      switch (action) {
        case this.actions.ADD_TO_FAVORITES:
          this.modalConfigs.confirmTitle = "Add";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to save this provider's profile ?";
          break;

        case this.actions.REMOVE_FROM_FAVORITES:
          this.modalConfigs.confirmTitle = "Remove";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to remove this provider's profile from favorites ?";
          break;

      }
      this.modalConfigs.provider_saved_id = id;
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.ADD_TO_FAVORITES:
          this.addProviderToFavorites(this.modalConfigs.provider_saved_id);
          break;

        case this.actions.REMOVE_FROM_FAVORITES:
          this.removeProviderFromFavorites(this.modalConfigs.provider_saved_id);
          break;

        default:
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.provider_saved_id = null;
      this.modalConfigs.showConfirmModal = false;
    },
    

    async addProviderToFavorites(id) {
      try {
        this.addingToFavorites = true;

        await api.addProviderToFavorites({
          provider: id,
        });
        this.fetchJobBids();
        toast.success(`Provider's profile successfully added to favorites.`);
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.addingToFavorites = false;
      }
    },

    async removeProviderFromFavorites(id) {
      try {
        this.removingFromFavorites = true;

        await api.removeProviderFromFavorites(id);
        this.fetchJobBids();
        toast.success(
          `Provider's profile successfully removed from favorites.`
        );
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.removingFromFavorites = false;
      }
    },

    async fetchRoomByProposalId() {
      try {
        this.fetchingProposalRoomId = true;

        const response = await api.fetchRoomByProposalId(
          this.$route.params.proposalId
        );
        this.proposalRoomId = response.data.data?.id || "";
        this.participants = response.data.data?.participants || [];
      } catch (_) {
      } finally {
        this.fetchingProposalRoomId = false;
      }
    },

    errorsHandler(err) {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            this.errors.notFoundErrorMessage = errorMessages.JOB_NOT_FOUND;
            break;

          default:
            this.errors.serverSideErrorMessage =
              errorMessages.AN_ERROR_HAS_OCCURED;
            break;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
