export const setApproval = (state, payload) => {
  state.approvalNumber = payload;
};
export const setJobProfile = (state, payload) => {
  state.jobNumber = payload;
};
export const setInvoiceProfile = (state, payload) => {
  state.invoiceNumber = payload;
};
export const setEstimateProfile = (state, payload) => {
  state.estimateNumber = payload;
};

export const setCustomerNeededToCompleteProfile = (state, payload) => {
  state.customerNeededToCompleteProfile = payload;
};
