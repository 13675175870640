<template>
  <div
    class="modal fade invite-modal"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>

          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          />
        </div>

        <div class="modal-body">
          <div class="row">
            <div
              class="mb-3"
              :class="{ 'col-lg-8': jobId, 'col-lg-12': !jobId }"
            >
              <Autocomplete
                :job-id="$route.params.id || ''"
                :is-send="isSend"
                @email="takeIdProvider"
                @emailNoAccount="takeEmail"
                @isSelect="isSelected"
              />
              <small
                v-if="errors.providerErrorMessage"
                class="invalid-feedback"
              >
                {{ errors.providerErrorMessage }}
              </small>
            </div>

            <div
              v-if="jobId"
              class="col-lg-4 mb-3"
            >
              <button
                class="btn-primary"
                type="submit"
                :disabled="(emails.length === 0 && idProvider.length === 0) || loading"
                @click.prevent="sendInvitationProvider"
              >
                <submission-spinner v-if="loading" />
                <template v-else>
                  Send invite
                </template>
              </button>
            </div>

            <div class="col-lg-12">
              <div v-if="isSelect && jobId">
                <textarea
                  v-model="message"
                  class="shadow-none"
                  placeholder="Leave a message"
                />
              </div>

              <div
                v-if="jobId"
                class="d-flex my-3"
              >
                <div class="link">
                  <img
                    :src="icons.linkVectorIcon"
                    alt="Icon"
                  >
                </div>

                <div>
                  <p class="link-title">
                    Invite with a link
                  </p>
                  <small
                    class="fw-normal"
                    @click.prevent="copyToClipboard"
                  ><a
                    href=""
                    class="text-decoration-underline text-dark"
                  >Copy link</a></small>
                </div>
              </div>

              <div
                v-if="providers.length > 0"
                class="providers-list proposal mx-0 ms-2"
              >
                <invited-provider-item
                  v-for="provider in providers"
                  :key="provider.id"
                  :data="provider"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal-footer border-0"
          :class="{
            'd-none':
              !jobId && (emails.length === 0 && idProvider.length === 0),
          }"
        >
          <button
            v-if="jobId"
            type="button"
            class="btn-outline"
            @click="closeModal"
          >
            Close
          </button>

          <template v-else>
            <button
              v-if="emails.length > 0 || idProvider.length > 0"
              type="button"
              class="btn-primary"
              @click="onConfirmProvidersSelection"
            >
              Confirm
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";

import errorMessages from "@/utils/error-messages";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import InvitedProviderItem from "@/components/customer/jobs/InvitedProviderItem.vue";

import linkVectorIcon from "@/assets/img/icons/link.svg";
import Autocomplete from "@/components/customer/jobs/Autocomplete.vue";
import api from "@/services/api";
import { GlobalConstants } from "@/configs/constants";

export default {
  name: "InviteModal",

  components: {
    Autocomplete,
    SubmissionSpinner,
    InvitedProviderItem,
  },

  props: {
    jobId: {
      type: String,
      required: false,
      default: "",
    },

    title: {
      type: String,
      required: false,
      default: "Invite provider",
    },
  },

  data: () => ({
    showModal: false,
    fadeModal: false,
    isSend: false,
    route: null,
    message: null,
    textCopied: null,
    icons: {
      linkVectorIcon,
    },
    errors: {
      serverSideErrorMessage: null,
      providerErrorMessage: null,
    },
    providers: [],
    emails: [],
    loading: false,
    idProvider: [],
    providerByEmail: [],
    providerAccount: [],
    isSelect: false,
  }),

  mounted() {
    this.getInvitedProviders(this.jobId);
  },

  methods: {
    getInvitedProviders(id) {
      if (!id) return

      api
        .getInvitedProvider(id)
        .then((res) => {
          this.providers = res.data;
        })
        .catch((error) => {});
    },

    async sendInvitationProvider() {
      if (this.loading) return;
      this.loading = true;
      this.route = this.getRoute();
      this.textCopied = GlobalConstants.BASE_URL + this.route;
      const dataSend = {
        provider: this.idProvider,
        job: this.jobId,
        message: this.message,
        url: this.textCopied,
      };
      const dataSendByMail = {
        email: this.emails,
        job: this.jobId,
        message: this.message,
        url: this.textCopied,
      };

      try {
        let invitedProviders = [];
        if (this.idProvider.length) {
          const res = await api.sendInvitationProvider(dataSend);
          invitedProviders = res.data;
        }
        if (this.emails.length) {
          const response = await api.sendInvitationByMail(dataSendByMail);
          invitedProviders = response.data;
        }

        this.providers = invitedProviders;

        this.isSend = true;
        this.emails = [];
        this.idProvider = [];
        this.isSelect = false;
        toast.success(`Your invitation(s) has been successfully sent.`);
      } catch (err) {
        this.isSend = false;
        this.errorsHandler(err);
      } finally {
        this.loading = false;
      }
    },

    confirm() {
      this.$emit("onConfirm");
      this.closeModal();
    },

    openModal() {
      this.showModal = true;
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      this.fadeModal = false;
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },

    onConfirmProvidersSelection() {
      this.$emit(
        "onConfirmProvidersSelection",
        this.idProvider.concat(this.emails)
      );
      this.closeModal();
    },

    takeIdProvider(idProvider) {
      this.idProvider = idProvider;
    },

    isSelected(selected) {
      this.isSelect = selected;
    },

    errorsHandler(err) {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            this.errors.providerErrorMessage =
              errorMessages.PROVIDER_ALREADY_INVITED;
            break;

          default:
            this.errors.serverSideErrorMessage =
              errorMessages.AN_ERROR_HAS_OCCURED;
            break;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },

    takeEmail(emails) {
      this.emails = emails;
      if (!this.jobId) {
        this.$emit("emails", this.emails);
      }
    },

    getRoute() {
      const route = this.$router.resolve({
        name: "ProviderJobDetails",
        params: {
          id: this.$route.params.id,
        },
      });

      return route.href;
    },

    copyToClipboard() {
      this.route = this.getRoute();
      this.textCopied = GlobalConstants.BASE_URL + this.route;
      const textArea = document.createElement("textarea");
      textArea.value = this.textCopied;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      toast(`Invitation link copied.`, { hideProgressBar: true });
    },
  },
};
</script>

<style scoped>
.link {
  background: #f5f5f5;
  padding: 0.93rem;
  justify-content: center;
  display: flex;
  border-radius: 6px;
  margin-right: 15px;
}
.full-name {
  font-weight: 500;
}
.providers-list {
  overflow-y: scroll;
  max-height: 13rem;
  padding: 1rem 0 !important;
}
.providers-list::-webkit-scrollbar {
  display: none;
}
.circle {
  top: 60% !important;
}
.btn-primary {
  padding: 0.6rem 0.85rem !important;
  font-weight: 500;
  font-size: 1rem;
}
.btn-back {
  padding: 0.6rem 0.85rem !important;
  font-weight: 500;
  font-size: 1rem;
}
small {
  font-size: 0.9rem;
  color: #222a36;
}
.full-name {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: #222a36;
}
.email {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 21px;
  color: #222a36;
}
</style>
