<template>
  <div class="job-detail">
    <loader-spinner v-if="loading" />

    <section v-else class="content">
      <div class="page-content box-form">
        <div class="col-md-12 my-5">
          <div class="row">
            <div class="col-md-6 text-start">
              <div class="col-md-8 mb-3">
                <h5 class="mb-3 text-primary">Hoelo</h5>
                <p class="my-0">AGLA</p>
                <p class="my-0">COTONOU</p>
              </div>
            </div>
            <div class="col-md-6 text-end d-flex">
              <div class="col-md-12 align-self-end mb-3">
                <h6 class="mb-2 text-secondary">Balance Due</h6>
                <h5 class="text-bold mb-0">$454</h5>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 my-5">
          <div class="row">
            <div class="col-12 col-lg-5 text-start">
              <h5 class="mb-3 text-primary">SHREF</h5>
              <p class="my-0">FLOOD</p>
              <p class="my-0">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Maiores sapiente culpa quo officiis eos repellat.
              </p>
            </div>

            <div class="col-12 col-lg-7 text-end">
              <div class="row mb-3">
                <div class="col-12">
                  <div class="row text-end">
                    <p class="col-md-6 col-lg-8 text-primary">Status :</p>
                    <p class="col-md-6 col-lg-4">DESABLE</p>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row text-end">
                    <p class="col-md-6 col-lg-8 text-primary">Send Date :</p>
                    <p class="col-md-6 col-lg-4">22/01/1998</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 md-3">
          <div class="table-responsive">
            <table class="table">
              <head-table :list="thead" />
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Food</td>
                  <td>3</td>
                  <td>400</td>
                  <td>45 000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-12 my-5 text-end d-flex">
          <div class="col-md-12 align-self-end mb-3">
            <div class="text-end">
              <div class="d-inline-block text-bold">
                <p>Sub Total : &nbsp;</p>
                <p>Tax(8%) : &nbsp;</p>
                <p>Service Fee : &nbsp;</p>
                <p>You will receive : &nbsp;</p>
                <p>Total : &nbsp;</p>
              </div>
              <div class="d-inline-block">
                <p>23 0045 43</p>
                <p>5%</p>
                <p>23 00</p>
                <p>34 54t</p>
                <p>2345 00435 4</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 text-start">
          <div class=" mb-3">
            <h5 class="mb-3 text-primary">Note :</h5>
            <p class="my-0">Lorem ipsum</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import statusToUI from "@/utils/statusFormate";
import HeadTable from "@/components/HeadTable.vue";

import LoaderSpinner from "@/components/LoaderSpinner.vue";

export default {
  name: "SharedEstimateDetailsPage",
  data: () => ({
    loading: false,
    thead: ["#", "Item", "Quantity", "Price", "Total"],
  }),
  methods: {
    ...DateHelpers,
    ...PriceHelpers,
    ...statusToUI,
  },
  components: {
    LoaderSpinner,
    HeadTable
  },
};
</script>
