export const ProviderStatus = {
  NEED_COMPLETE_PROFILE: "need_complete_profile",
  NEED_APPROVAL: "need_approval",
  NEED_STRIPE_APPROVAL: "need_stripe_approval",
  REFUSED: "refused",
  OK: "ok",
  ACTIVE: "active",
  DEACTIVATE: "deactivate",
};

export const CustomerStatus = {
  NEED_COMPLETE_PROFILE: "need_complete_profile",
  NEED_STRIPE_APPROVAL: "need_stripe_approval",
  REFUSED: "refused",
  DEACTIVATE: "deactivate",
  ACTIVE: "active",
};

export const ProviderStatusList = [
  {
    title: "All Status",
  },
  {
    title: "Activate",
    value: ProviderStatus.ACTIVE,
  },
  {
    title: "Unauthorize",
    value: ProviderStatus.REFUSED,
  },
  {
    title: "Deactivate",
    value: ProviderStatus.DEACTIVATE,
  },
];

export const CustomerStatusList = [
  {
    title: "Status",
  },
  {
    title: "Active",
    value: "True",
  },
  {
    title: "Disable",
    value: "False",
  },
];

export const UserScopes = {
  CUSTOMER: "CUSTOMER",
  PROVIDER: "PROVIDER",
  SUPER_ADMIN: "SUPER_ADMIN",
};

export const BusinessTypes = [
  {
    title: "Individual",
    value: "individual",
  },
  {
    title: "Company",
    value: "company",
  },
];

export const AvatarColorPalettes = [
  {
    color: "#EB5757",
    background: "#f8c4c4",
  },
  {
    color: "#9747FF",
    background: "#E0C8FF",
  },
  {
    color: "#219EBA",
    background: "#BCE2EA",
  },
  {
    color: "#F09E00",
    background: "#f8ddb0",
  },
  {
    color: "#c4bd07",
    background: "#f8ecb1",
  },
  {
    color: "#00F018",
    background: "#cef5c1",
  },
  {
    color: "#817c7c",
    background: "#e0d3fd",
  },
  {
    color: "#7B2525",
    background: "#fcd3ca",
  },
  {
    color: "#EB00F0",
    background: "#f2cff2",
  },
  {
    color: "#7800F0",
    background: "#ba95df",
  },
  {
    color: "#008BF0",
    background: "#cbe4f9",
  },
];

export const StripeAccountActivationStatus = {
  PENDING: "pending",
  REFUSED: "refused",
  ACCEPTED: "accepted",
  NEED_STRIPE_APPROVAL: "need_stripe_approval",
};
