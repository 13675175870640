import { Request } from "@/services/request";

export default {
  fetchCalendarEvents(
    page = 1,
    start_date = null,
    end_date = null,
    project = null
  ) {
    return new Request()
      .append(`api/v1/event/`)
      .method("get")
      .params({ page, start_date, end_date, project });
  },

  createCalendarEvent(data) {
    return new Request()
      .append("api/v1/event/")
      .method("post")
      .setData(data);
  },

  updateCalendarEvent(id, data) {
    return new Request()
      .append(`api/v1/event/${id}/`)
      .method("patch")
      .setData(data);
  },

  deleteCalendarEvent(id) {
    return new Request()
      .append(`api/v1/event/${id}/`)
      .method("delete")
  },

  syncGoogleCalendar(data) {
    return new Request()
      .append(`api/v1/calendar/google/`)
      .method("patch")
      .setData(data);
  },

  loginMicrosoft() {
    return new Request("https://login.microsoftonline.com/")
      .append(`consumers/oauth2/v2.0/authorize/`)
      .method("get")
      .params({
        client_id: process.env.VUE_APP_MS_CLIENT_ID,
        scope: "offline_access%20Calendars.ReadWrite",
        response_type: "code",
        state: "12345",
      });
  },

  syncMicrosoftCalendar(data) {
    return new Request()
      .append(`api/v1/calendar/microsoft/`)
      .method("patch")
      .setData(data);
  },
};
