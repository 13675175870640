import { Request } from "@/services/request";

export default {
  activateCall(data) {
    return new Request()
      .append("api/v1/calls/activate/")
      .method("patch")
      .setData(data);
  },

  fetchAllCalls({ page = 1 }) {
    return new Request().append("api/v1/calls/").params({ page }).method("get");
  },

  fetchMyCalls() {
    return new Request().append("api/v1/calls/my_calls/").method("get");
  },

  makeCall(data) {
    return new Request()
      .append("api/v1/calls/make_call/")
      .method("post")
      .setData(data);
  },
};
