<template>
  <div
    class="modal fade"
    :class="{ 'd-block': showModal, show: fadeModal }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">
            {{ title }}
          </h5>

          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          />
        </div>

        <div class="modal-body">
          <alerte-danger
            :head-error-message="errors.serverSideErrorMessage"
            @updateContent="errors.serverSideErrorMessage = $event"
          />
          <alerte-danger
            :head-error-message="errors.labelErrorMessage"
            @updateContent="errors.labelErrorMessage = $event"
          />

          <p class="text-dark">
            Are you sure you want to Delete {{ name }}
          </p>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-outline"
            @click="closeModal"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn-danger"
            :disabled="loading"
            @click="deleted"
          >
            <submission-spinner
              v-if="loading"
              :color="'#209516'"
            />
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorMessages from "@/utils/error-messages";
import api from "@/services/api";

import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "DeleteToDoTask",

  components: {
    SubmissionSpinner,
    AlerteDanger,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => ({
    showModal: false,
    fadeModal: false,
    id: null,
    name: null,
    loading: false,
    errors: {
      nameErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  methods: {

    openModal(item) {
      this.id = item.id;
      this.name = item.name;
      this.showModal = true;
      setTimeout(() => {
        this.fadeModal = true;
      }, 200);
    },

    closeModal() {
      this.fadeModal = false;
      setTimeout(() => {
        this.showModal = false;
      }, 200);
    },

    deleted() {
      if(this.loading) return;

      this.loading = true
      this.clearErrorsMessages();

      api.deleteToDoTask(this.id).then((_) => {
        this.closeModal()
        this.$emit('updateList')
      }).catch(error => {
        this.errorsHandler(error)
      }).then(() => {
        this.loading = false
      })
    },

    clearErrorsMessages() {
      this.errors.nameErrorMessage = null;
      this.errors.serverSideErrorMessage = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 400) {
          for (const error in err.response.data) {
            this.errors.nameErrorMessage = err.response.data[error][0];
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
