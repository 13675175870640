import { Request } from "@/services/request";

const baseUrl = "api/v1/provider";

export default {
  providerDashboard() {
    return new Request().append(`${baseUrl}/dashboard/`).method("get");
  },

  fetchAllProviders(data) {
    return new Request().append(`${baseUrl}/`).params(data).method("get");
  },

  fetchProvider(id) {
    return new Request().append(`${baseUrl}/${id}/`).method("get");
  },

  fetchProviderByUsername(username) {
    return new Request()
      .append(`${baseUrl}/provider_slug/?username=${username}`)
      .method("get");
  },

  makeProviderActive(id) {
    return new Request()
      .append(`${baseUrl}/${id}/make_active/`)
      .method("post")
      .setData({});
  },

  makeProviderInactive(id, data) {
    return new Request()
      .append(`${baseUrl}/${id}/make_inactive/`)
      .method("post")
      .setData(data);
  },

  sendInvitationProvider(data) {
    return new Request()
      .append(`api/v1/job_invitation/`)
      .method("post")
      .setData(data);
  },

  sendInvitationByMail(data) {
    return new Request()
      .append(`api/v1/job_invitation/invitation_by_mail/`)
      .method("post")
      .setData(data);
  },

  subcontractingInvitation(data) {
    return new Request()
      .append(`api/v1/subcontract/`)
      .method("post")
      .setData(data);
  },

  getInvitedSubcontractor(id) {
    return new Request()
      .append(`api/v1/subcontract/${id}/by_bid/`)
      .method("get");
  },

  verifyprovider(data) {
    return new Request()
      .append(`api/v1/job_invitation/verify_provider/`)
      .method("post")
      .setData(data);
  },

  fetchJobInProgress({
    page = 1,
    startDate = null,
    endDate = null,
    search = null,
    status = null,
    category = null,
    contractType = null,
    level = null,
    skills = null,
    budgetType = null,
    paymentFrequency = null,
    jobType = null,
    budgetFrom = null,
    budgetTo = null,
  }) {
    return new Request()
      .append(`api/v1/bid/job_ongoing/`)
      .method("get")
      .params({
        page,
        start_date: startDate,
        end_date: endDate,
        search,
        status,
        category,
        contract_type: contractType,
        level,
        skills,
        budget_type: budgetType,
        payment_frequency: paymentFrequency,
        job_type: jobType,
        budget_from: budgetFrom,
        budget_to: budgetTo,
      });
  },
  getProvider() {
    return new Request().append(`${baseUrl}/active_provider/`).method("get");
  },

  getInvitedProvider(id) {
    return new Request()
      .append(`api/v1/job_invitation/${id}/my_invitation/`)
      .method("get");
  },

  updateProvider(id, data) {
    return new Request()
      .append(`${baseUrl}/${id}/`)
      .method("patch")
      .setData(data);
  },

  updateUser(id, data) {
    return new Request()
      .contentType("multipart/form-data")
      .append(`api/v1/user/${id}/`)
      .method("patch")
      .setData(data);
  },

  providerDatabase(page, is_active = null, search = null, type = null) {
    return new Request()
      .append(`${baseUrl}/database/`)
      .params({ page, is_active, search, type })
      .method("get");
  },

  addProjectToFavorite({ id }) {
    return new Request()
      .append(`${baseUrl}/add_to_favorite/`)
      .method("post")
      .setData({ project: id });
  },

  removeProjectFromFavorite({ id }) {
    return new Request()
      .append(`${baseUrl}/remove_from_favorite/`)
      .method("post")
      .setData({ project: id });
  },

  providerApprove(id) {
    return new Request().append(`${baseUrl}/${id}/approve/`).method("post");
  },

  providerRefuse(id, reason) {
    return new Request()
      .append(`${baseUrl}/${id}/refuse/`)
      .method("post")
      .setData({ reason });
  },

  updateProviderStatus(id, status, refusalReason) {
    return new Request()
      .append(`${baseUrl}/${id}/change_status/`)
      .method("put")
      .setData({ status, refuse_reason: refusalReason });
  },

  getProviderBySearch(data) {
    return new Request().append(`api/v1/provider/`).method("get").params(data);
  },

  fetchAllSubcontract({ page = 1 }) {
    return new Request()
      .append(`api/v1/job_invitation/provider_invitation/`)
      .method("get")
      .params({
        page,
      });
  },
  fetchSubcontracting({ id, page = 1 }) {
    return new Request()
      .append(`api/v1/bid/${id}/sub_jobs/`)
      .method("get")
      .params({
        page,
      });
  },

  applySubcontract(data) {
    return new Request().append(`api/v1/apply/`).method("post").setData(data);
  },
};
