import VuexPersist from "vuex-persist";
import { createStore, createLogger } from "vuex";

import user from "@/store/modules/user";
import token from "@/store/modules/token";
import sDashState from "@/store/modules/sDashState";

const debug = process.env.NODE_ENV !== "production";

const vuexLocalPersist = new VuexPersist({
  key: "service-loop",
  storage: localStorage,
  modules: ["token", "user"],
});

export default createStore({
  modules: {
    user,
    token,
    sDashState,
  },
  strict: debug,
  plugins: debug
    ? [createLogger(), vuexLocalPersist.plugin]
    : [vuexLocalPersist.plugin],
});
