<template>
  <div
    class="item-bundle py-3"
    :class="{ active: service?.added }"
  >
    <div class="type row align-items-center mb-3">
      <div class="col-8">
        <h3 class="pricing-title">
          {{ service?.name }}
        </h3>
      </div>

      <div class="col">
        <span class="pricing-title">
          {{ formatPrice(service?.value) }}
        </span>
      </div>
    </div>

    <div class="description row align-items-center">
      <div class="col-xl-8">
        <p class="mb-3 mb-md-0">
          {{ service?.description }}
        </p>
      </div>

      <div class="col-xl-4 text-center">
        <button
          class="btn"
          :class=" (service?.added || includePlan) ? 'btn-secondary' : 'btn-primary bg-primary text-white'"
          :disabled="service?.added || includePlan"
          @click="addModule"
        >
          <template v-if="service?.added || includePlan">
            Module Added
          </template>
          <template v-else>
            Add Module
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PriceHelpers from "@/utils/price-format";

export default {
  name: "ServiceItem",

  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    includePlan() {
      for (const key in this.currentPlan) {
        if (this.currentPlan[key] === this.service?.id) {
          // console.log(key+" - "+this.currentPlan[key]+" === "+this.service?.id);
          return true;
          
        }
      }
      return false
    }
  },
  mounted() {
  },

  methods: {
    ...PriceHelpers,

    addModule() {
      this.$emit("onAddService")
    }
  }
};
</script>
