<template>
  <div
    class="col-md-6"
    :class="length === 3 ? 'col-lg-4' : 'col-lg-3'"
  >
    <router-link
      :to="{ name: route }"
      class="statistic-item"
    >
      <div class="card">
        <h3 :style="colorCard">
          <template v-if="isAmount">
            {{ formatPrice(count ?? 0) }}
          </template>
          <template v-else>
            {{ count }}
          </template>
        </h3>

        <p :style="colorCard">
          {{ title }}
        </p>

        <span class="linear-bar" />
      </div>
    </router-link>
  </div>
</template>

<script>
import PriceHelpers from "@/utils/price-format";

export default {
  name: "DashboardStatistic",

  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },

    count: {
      type: Number,
      required: true,
      default: 0,
    },

    route: {
      type: String,
      required: true,
    },

    length: {
      type: Number,
      required: false,
      default: 3,
    },

    color: {
      type: String,
      required: false,
      default: "",
    },

    isAmount: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed:{
    colorCard(){
      return {color:`${this.color}`}
    }
  },

  methods: {
    ...PriceHelpers,
  }
};
</script>
