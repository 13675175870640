<template>
  <div
    v-if="subcontract"
    class="job-content"
  >
    <div class="row">
      <div class="col-sm-6">
        <h3>
          <router-link
            :to="{
              name: 'ProviderSubcontractDetails',
              query: {
                idProposal: $route.params.proposalId,
                my_subcontract: true,
              },
              params: { id: subcontract.id },
            }"
            class="subtitle"
          >
            {{ subcontract.title }}
          </router-link>
        </h3>
        <p class="date">
          Posted at
          {{ formatDate(subcontract.created_at, "MMMM Do, YYYY") }}
        </p>
      </div>
      <div class="col text-sm-end">
        <router-link
          :to="{
            name: 'ProviderSubcontractDetails',
            query: {
              idProposal: $route.params.proposalId,
              my_subcontract: true,
              idJob: $route.params.id,
            },
            params: { id: subcontract.id },
          }"
          class="btn-primary me-3"
        >
          View
        </router-link>
        <img
          v-if="
            job?.status === jobStatus.ACTIVE &&
              subcontract.provider_bids === false
          "
          :src="icons.removeVectorIcon"
          alt="remove"
          @click.prevent="confirmClaim"
        >
      </div>
    </div>

    <div class="mt-3">
      <div
        v-if="subcontract.level"
        class="d-flex"
      >
        <p class="case">
          Level :
        </p>

        <p
          v-if="subcontract.level"
          class="number-case me-4"
        >
          {{ subcontract.level.name }}
        </p>
      </div>

      <div
        v-if="subcontract.budget_type === jobBudgetTypes.FIXED"
        class="d-flex"
      >
        <template
          v-if="subcontract.budget !== null && subcontract.budget !== undefined"
        >
          <p class="case">
            Budget :
          </p>

          <p class="number-case me-4">
            {{ formatPrice(subcontract.budget) }}
          </p>
        </template>
      </div>

      <template v-else>
        <div
          v-if="
            subcontract.budget_from !== null &&
              subcontract.budget_from !== undefined
          "
          class="d-flex"
        >
          <p class="case">
            Budget from :
          </p>

          <p class="number-case me-4">
            {{ formatPrice(subcontract.budget_from) }}
            <template
              v-if="
                subcontract.payment_frequency &&
                  formatJobPaymentFrequencies(subcontract.payment_frequency).short
              "
            >
              /{{
                formatJobPaymentFrequencies(subcontract.payment_frequency).short
              }}
            </template>
          </p>
        </div>

        <div
          v-if="
            subcontract.budget_to !== null &&
              subcontract.budget_to !== undefined
          "
          class="d-flex"
        >
          <p class="case">
            Budget to :
          </p>

          <p class="number-case me-4">
            {{ formatPrice(subcontract.budget_to) }}
            <template
              v-if="
                subcontract.payment_frequency &&
                  formatJobPaymentFrequencies(subcontract.payment_frequency).short
              "
            >
              /{{
                formatJobPaymentFrequencies(subcontract.payment_frequency).short
              }}
            </template>
          </p>
        </div>
      </template>

      <div
        v-if="subcontract.bids_end"
        class="d-flex me-4"
      >
        <p class="case">
          Bids ends :
        </p>

        <p class="number-case">
          {{ formatDate(subcontract.bids_end, "MMMM Do, YYYY") }}
        </p>
      </div>
    </div>

    <div v-if="subcontract.description">
      <div v-html="sanitizedDescription" />

      <router-link
        v-if="subcontract.description?.length > 154"
        :to="{
          name: 'ProviderSubcontractDetails',
          params: { id: subcontract.id },
        }"
        class="text-primary"
      >
        more
      </router-link>
    </div>
    <div class="mt-4 auto-flex">
      <span
        v-for="skill in subcontract.skills"
        :key="skill.id"
        class="type-job"
      >
        {{ skill.name }}
      </span>
    </div>
      
    <confirm-modal
      ref="confirmModal"
      ok-button-title="Delete"
      message="Are you sure you want to delete this job ?"
      @onConfirm="deleteJob"
    />
  </div>
</template>

<script>
import DOMPurify from "dompurify";
import JobHelper from "@/utils/job";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";
import removeVectorIcon from "@/assets/img/icons/poubell-red.svg";
import ConfirmModal from "@/components/ConfirmModal.vue";
import api from "@/services/api";
import { JobBudgetTypes, JobStatus } from "@/configs/constants/jobs";
import heartVectorIcon from "@/assets/img/icons/heart.svg";

export default {
  name: "SubcontractingItem",
  components: {
    ConfirmModal,
  },
  props: {
    subcontract: {
      type: Object,
      required: true,
      default: null,
    },
  },

  data: () => ({
    icons: {
      heartVectorIcon,
      removeVectorIcon,
    },
    loadingJob: false,
    job: null,
    errors: {
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    shortDescription() {
      if (this.subcontract.description?.length <= 154) {
        return this.subcontract.description;
      } else if (this.subcontract.description?.length > 154) {
        return `${this.subcontract.description.slice(0, 154)}...`;
      }
      return "";
    },

    sanitizedDescription() {
      return DOMPurify.sanitize(this.shortDescription);
    },

    jobBudgetTypes() {
      return JobBudgetTypes;
    },
    jobStatus() {
      return JobStatus;
    },
  },
  mounted() {
    this.fetchJob(this.$route.params.id);
  },
  methods: {
    ...JobHelper,
    ...DateHelpers,
    ...PriceHelpers,

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },
    confirmClaim() {
      this.$refs.confirmModal.openModal();
    },
    async deleteJob() {
      try {
        this.loadingJob = true;
        await api.deleteJob(this.subcontract.id);
        this.$emit("isDelete", this.subcontract.id);
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loadingJob = false;
      }
    },
    async fetchJob(id) {
      try {
        this.loading = true;
        const response = await api.fetchJob(id);
        let job = response.data;
        this.job = job;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },

    errorsHandler(err) {
      if (
        err.response &&
        err.response.status !== 400 &&
        err.response.status !== 404
      ) {
        this.errors.serverSideErrorMessage = err.response.message;
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>
