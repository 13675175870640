<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="my-3">
        Job Configs
      </h4>

      <div class="row">
        <div
          v-for="item in jobSettingsItems"
          :key="item.path"
          class="col-md-4"
        >
          <router-link
            :to="item.path"
            class="setting-item"
          >
            <div class="setting-item-content">
              <img
                :alt="item.title"
                :src="icons[item.icon]"
              >

              <h6>{{ item.title }}</h6>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JobSettingsItems } from "@/configs/job-settings-items";

import settingsSkillCategoryIcon from "@/assets/img/icons/skill-category.svg";
import settingsContractTypeIcon from "@/assets/img/icons/contract-type.svg";
import settingsLevelIcon from "@/assets/img/icons/level.svg";
import settingsJobLanguageIcon from "@/assets/img/icons/job-language.svg";
import settingsScreeningQuestionsIcon from "@/assets/img/icons/screening-questions.svg";
import settingsJobVisibilityIcon from "@/assets/img/icons/job-visibility.svg";
import settingsJobCategoryIcon from "@/assets/img/icons/job-category.svg";

export default {
  name: "SuperadminJobsSettingsPage",

  data() {
    return {
      icons: {
        settingsSkillCategoryIcon,
        settingsContractTypeIcon,
        settingsLevelIcon,
        settingsJobLanguageIcon,
        settingsScreeningQuestionsIcon,
        settingsJobVisibilityIcon,
        settingsJobCategoryIcon,
      },
    };
  },

  computed: {
    jobSettingsItems() {
      return JobSettingsItems;
    },
  },
};
</script>