<template>
  <div class="col-md-6 col-lg">
    <router-link
      :to="route"
      class="statistic-item"
      :class="color && color"
    >
      <div class="card">
        <h3 :style="colorCard">
          <template v-if="isAmount">
            {{ formatPrice(count ?? 0) }}
          </template>
          <template v-else>
            {{ count }}
          </template>
        </h3>

        <p :style="colorCard">
          {{ title }}
        </p>

        <span class="linear-bar" />
      </div>
    </router-link>
  </div>
</template>

<script>
import PriceHelpers from "@/utils/price-format";

export default {
  name: "DashboardStatistic",

  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },

    count: {
      type: Number,
      required: true,
      default: 0,
    },

    route: {
      type: Object,
      required: true,
    },
    
    color: {
      type: String,
      required: false,
      default: "",
    },

    formatNumber: {
      type: Boolean,
      required: false,
    },

    isAmount: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    colorCard() {
      return { color: `${this.color}` };
    },
  },

  methods: {
    ...PriceHelpers,
  }
};
</script>

<style scoped>
  .statistic-item .card{
    height: 110px;
    white-space: nowrap;
  }
</style>