import { AvatarColorPalettes } from "@/configs/constants/users";

export default {
  getAvatarColorsConfigs() {
    const min = 0;
    const max = AvatarColorPalettes.length - 1;
    const index = Math.floor(Math.random() * (max - min + 1)) + min;
    return AvatarColorPalettes[index];
  },
};
