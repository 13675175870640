<template>
  <div
    v-if="canApply"
    class="job-apply"
  >
    <section class="content col-lg-8 mx-auto box-form">
      <div
        v-if="job?.title"
        class="page-header"
      >
        <h3 class="d-block">
          Apply for {{ job.title }}
        </h3>
        <span
          class="label-link"
          @click="openDetail"
        >
          View Job Details
        </span>
      </div>

      <right-canvas
        v-if="job"
        ref="detailJodModal"
        :title="job?.title"
      >
        <section
          v-if="job"
          class="content job-detail"
        >
          <div class="page-content">
            <div>
              <p class="content-title">
                {{ job.title }}
              </p>

              <p class="content-subtitle pb-0">
                Posted at {{ formatDate(job.created_at, "MMMM Do, YYYY") }}

                <template v-if="job.category?.name">
                  in <span class="fw-bold">{{ job.category.name }}</span>
                </template>
              </p>
            </div>

            <div class="row pe-4 detail">
              <div
                class="col-md-8 py-4"
                :class="{
                  'col-md-8 description': showCustomerDetails,
                  'col-md-12': !showCustomerDetails,
                }"
              >
                <p
                  class="category-name"
                  v-html="sanitizedDescription"
                />
              </div>

              <div
                v-if="showCustomerDetails"
                class="col-md-4"
              >
                <p class="about">
                  About the client
                </p>
                <p>Payment method not verified</p>
                <p class="history">
                  History :
                </p>
                <ul>
                  <li>20 to 50 proposal</li>
                  <li>2 jobs posted</li>
                </ul>
              </div>
            </div>

            <div class="category-part">
              <div class="row">
                <div class="col-md-6 col-xl-4 infos">
                  <div
                    v-if="job.contract_type"
                    class="d-flex"
                  >
                    <p class="category-name">
                      <span class="category">Contract Type :</span>
                      {{ job.contract_type.name }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6 col-xl-4 infos">
                  <div
                    v-if="job.level"
                    class="d-flex"
                  >
                    <p class="category-name">
                      <span class="category">Level :</span>
                      {{ job.level.name }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6 col-xl-4 infos">
                  <div
                    v-if="job.job_language"
                    class="d-flex"
                  >
                    <p class="category-name">
                      <span class="category">Job Language :</span>
                      {{ job.job_language.name }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6 col-xl-4 infos">
                  <div
                    v-if="job.budget_type"
                    class="d-flex"
                  >
                    <p class="category-name">
                      <span class="category">Budget Type :</span>
                      {{ formatJobBudgetTypes(job.budget_type) }}
                    </p>
                  </div>
                </div>

                <template v-if="job.budget_type !== jobBudgetTypes.NOT_SURE">
                  <div class="col-md-6 col-xl-4 infos">
                    <div class="d-flex">
                      <p
                        v-if="job.payment_frequency"
                        class="category-name"
                      >
                        <span class="category">Payment Frequency :</span>
                        {{
                          formatJobPaymentFrequencies(job.payment_frequency)
                            .long
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    v-if="job.budget_type === jobBudgetTypes.FIXED"
                    class="col-md-6 col-xl-4 infos"
                  >
                    <div class="d-flex">
                      <p
                        v-if="job.budget !== null && job.budget !== undefined"
                        class="category-name"
                      >
                        <span class="category"> Budget : </span>
                        {{ formatPrice(job.budget) }}
                      </p>
                    </div>
                  </div>

                  <template v-else>
                    <div class="col-md-6 col-xl-4 infos">
                      <div
                        v-if="
                          job.budget_from !== null &&
                            job.budget_from !== undefined
                        "
                        class="d-flex"
                      >
                        <p class="category">
                          Budget :
                        </p>

                        <p class="category-name">
                          {{ formatPrice(job.budget_from) }}
                          <template
                            v-if="
                              job.payment_frequency &&
                                formatJobPaymentFrequencies(job.payment_frequency)
                                  .short
                            "
                          />
                        </p>
                        <p class="category">
                          &nbsp;to&nbsp;
                        </p>
                        <p
                          v-if="
                            job.budget_to !== null &&
                              job.budget_to !== undefined
                          "
                          class="category-name"
                        >
                          {{ formatPrice(job.budget_to) }}
                          <template
                            v-if="
                              job.payment_frequency &&
                                formatJobPaymentFrequencies(job.payment_frequency)
                                  .short
                            "
                          >
                            / {{
                              formatJobPaymentFrequencies(job.payment_frequency)
                                .short
                            }}
                          </template>
                        </p>
                      </div>
                    </div>
                  </template>
                </template>

                <div class="col-md-6 col-xl-4 infos">
                  <div
                    v-if="job.category"
                    class="d-flex"
                  >
                    <p class="category-name">
                      <span class="category"> Job Type : </span>
                      {{ formatJobType(job.job_type) }}
                    </p>
                  </div>
                </div>

                <div class="col-md-6 col-xl-4 infos">
                  <div
                    v-if="job.location_description"
                    class="d-flex"
                  >
                    <p class="category-name">
                      <span class="category"> Job Location : </span>
                      {{ job.location_description }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-xl-4 infos">
                  <div
                    v-if="job.start_date && job.end_date"
                    class="d-flex"
                  >
                    <p>
                      <span class="category"> Date : </span>

                      <template
                        v-if="
                          formatDate(job.start_date, 'MMMM') ===
                            formatDate(job.end_date, 'MMMM') &&
                            formatDate(job.start_date, 'YYYY') ===
                            formatDate(job.end_date, 'YYYY')
                        "
                      >
                        {{ formatDate(job.start_date, "MMMM") }}
                        {{ formatDate(job.start_date, "Do") }}
                        <span class="category"> to</span>

                        {{ formatDate(job.end_date, "Do") }}
                        {{ formatDate(job.end_date, "YYYY") }}
                      </template>

                      <template v-else>
                        {{ formatDate(job.start_date, "MMMM Do, YYYY") }}
                        <span class="category"> to</span>
                        {{ formatDate(job.end_date, "MMMM Do, YYYY") }}
                      </template>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="skills-part">
              <p class="skill-title">
                Skills :
              </p>

              <div class="auto-flex">
                <span
                  v-for="skill in job.skills"
                  :key="skill.id"
                  class="type-job"
                >
                  {{ skill.name }}</span>
              </div>
            </div>

            <div
              v-if="job.files?.length"
              class="skills-part pb-3"
            >
              <p class="skill-title">
                Photos and Documents
              </p>

              <div class="row">
                <div
                  v-for="(file, i) in job.files"
                  :key="i"
                  class="col-md-12 col-lg-6"
                  :class="{ 'mb-3': i < job.files.length - 1 }"
                >
                  <div class="document">
                    <a
                      :href="file.url"
                      target="_target"
                    >
                      <img
                        v-if="file.type === mediaTypesConstants?.IMAGE"
                        :src="icons.imageUploadedIcon"
                        alt="File Uploaded"
                        class="file-icon me-1"
                      >
                      <img
                        v-else
                        :src="icons.documentUploadedIcon"
                        alt="File Uploaded"
                        class="file-icon me-1"
                      >
                      {{ (mediaTypesConstants?.IMAGE) && getFileName(file.original_name) }}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="job.screening_question.length > 0"
              class="sreening-part"
            >
              <p class="screening-title">
                Screening Questions :
              </p>

              <div class="mt-4 mb-3">
                <div class="row">
                  <div class="col-md-12">
                    <p class="text-primary">
                      You will be asked to answer the following questions when
                      submitting a proposal:
                    </p>

                    <ol>
                      <li
                        v-for="question in job.screening_question"
                        :key="question.id"
                      >
                        <p>
                          {{ question.name }}
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </right-canvas>

      <loader-spinner v-if="loading" />

      <div
        v-else-if="errors.errorMessage"
        class="row mt-4"
      >
        <div class="col-md-12">
          <alerte-danger
            :head-error-message="errors.errorMessage"
            @updateContent="errors.errorMessage = $event"
          />
        </div>
      </div>

      <div
        v-else
        class="page-content"
      >
        <form
          v-if="job"
          @submit.prevent="applyJob"
        >
          <div class="row">
            <alerte-danger
              :head-error-message="errors.serverSideErrorMessage"
              @updateContent="errors.serverSideErrorMessage = $event"
            />

            <p class="title mt-3 mb-4">
              Proposal details
            </p>

            <div
              v-if="$route.params.properties == '[]'"
              class="col-lg-6 mb-3"
            >
              <label
                for="from"
                class="form-label"
              >
                Proposed Bid Amount <span class="text-danger">*</span>
              </label>

              <div class="d-flex align-items-center">
                <money3
                  v-model="applydata.amount"
                  prefix="$"
                  :class="{
                    'is-invalid':
                      (v$.applydata.amount.$invalid &&
                        v$.applydata.amount.$dirty) ||
                      errors.amountErrorMessage,
                  }"
                  @blur="v$.applydata.amount.$touch"
                />
                <span
                  v-if="
                    job?.budget_type !== jobBudgetTypes.NOT_SURE &&
                      job.payment_frequency &&
                      formatJobPaymentFrequencies(job?.payment_frequency).short
                  "
                  class="ms-2"
                >
                  /
                  {{
                    formatJobPaymentFrequencies(job?.payment_frequency).short
                  }}
                </span>
              </div>
              <template
                v-if="
                  (v$.applydata.amount.$invalid &&
                    v$.applydata.amount.$dirty) ||
                    errors.amountErrorMessage
                "
              >
                <small
                  v-if="v$.applydata.amount.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </small>

                <small
                  v-else-if="errors.amountErrorMessage"
                  class="invalid-feedback"
                >
                  {{ errors.amountErrorMessage }}
                </small>
              </template>
            </div>

            <div
              v-else
              class="col-12"
            >
              <div class="table-responsive mt-3">
                <table class="table">
                  <head-table :list="thead" />
                  <tbody>
                    <tr
                      v-for="(item, index) in applydata.items"
                      :key="index"
                    >
                      <td class="py-3 text-primary">
                        {{ item.company }}
                      </td>
                      <td>
                        {{ 
                          formatPrice(item.value)
                        }}
                      </td>
                      <td>
                        {{ 
                          formatPrice(item.values.reduce((prev, curr) => (prev + parseInt(curr.price)), 0))
                        }}
                      </td>
                      <td
                        v-for="(ite, i) in item.values"
                        :key="i"
                      >
                        <money3
                          v-model="ite.price"
                          prefix="$"
                          class="form-control p-3"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 mb-3">
                <label class="form-label">Work Start Date (optional)</label>

                <vue-date-picker
                  v-model="applydata.work_start_date"
                  placeholder="Choose a Start Date"
                  class="datepicker form-control"
                  auto-apply
                  :enable-time-picker="false"
                  :min-date="new Date()"
                  :max-date="applydata.work_end_date"
                  :format="'MM-dd-yyyy'"
                />

                <small
                  v-if="errors.workStartDateErrorMessage"
                  class="invalid-feedback"
                >
                  {{ errors.workStartDateErrorMessage }}
                </small>
              </div>

              <div class="col-lg-6 mb-3">
                <label
                  for="contract"
                  class="form-label"
                >Work End Date (optional)</label>

                <vue-date-picker
                  v-model="applydata.work_end_date"
                  placeholder="Choose an End Date"
                  class="datepicker form-control"
                  auto-apply
                  :enable-time-picker="false"
                  :min-date="applydata.work_start_date || new Date()"
                  :start-date="applydata.work_start_date || new Date()"
                  :format="'MM-dd-yyyy'"
                />

                <small
                  v-if="errors.workEndDateErrorMessage"
                  class="invalid-feedback"
                >
                  {{ errors.workEndDateErrorMessage }}
                </small>
              </div>

              <div class="col-lg-12 mb-3">
                <label
                  for="contract"
                  class="form-label"
                >
                  Cover Letter
                </label>

                <textarea
                  v-model="applydata.message"
                  placeholder="Write your answer here..."
                  class="m-0"
                  rows="4"
                  :class="{ 'form-control is-invalid' : errors.messageErrorMessage }"
                />
                <small
                  v-if="errors.messageErrorMessage"
                  class="invalid-feedback"
                >
                  {{ errors.messageErrorMessage }}
                </small>
              </div>

              <div class="col-lg-12 mb-3">
                <label
                  for="contract"
                  class="form-label"
                > Supporting Documents </label>
                <template v-if="myResumeList.length">
                  <select
                    v-model="applydata.cv"
                    class="form-select py-3 shadow-none"
                    :class="{ 'is-invalid': errors.cvErrorMessage }"
                  >
                    <option
                      v-for="cv in myResumeList"
                      :key="cv.id"
                      :value="cv.id"
                      :disabled="cv.id == null"
                      class="select-option"
                    >
                      {{ cv.original_name }}
                    </option>
                  </select>

                  <small
                    v-if="errors.cvErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.cvErrorMessage }}
                  </small>
                </template>

                <div class="upload-file-wrapper mt-3">
                  <input
                    ref="w9Document"
                    class="d-none shadow-none"
                    type="file"
                    accept="*"
                    @change="onDocumentChange"
                  >
                  <span v-if="uploadedDocument">
                    {{ uploadedDocument.filename }}
                  </span>
                  <span v-else>
                    Upload your document (CV, Insurance, COI, etc)
                  </span>

                  <button
                    type="button"
                    class="btn-upload"
                    @click="$refs.w9Document.click()"
                  >
                    <img
                      :src="icons.download"
                      alt="icon Upload"
                    >
                    Upload
                  </button>
                </div>
              </div>

              <div
                v-if="job?.screening_question?.length > 0"
                class="mb-3"
              >
                <p class="title mt-0">
                  Screening Questions
                </p>

                <template v-if="errors.responseErrorMessage">
                  <small
                    v-if="errors.responseErrorMessage"
                    class="invalid-feedback"
                  >
                    {{ errors.responseErrorMessage }}
                  </small>
                </template>

                <ol>
                  <div
                    v-for="question in job.screening_question"
                    :key="question.id"
                  >
                    <li
                      v-if="question.type == jobScreeningQuestionTypes.LONG"
                      class="text-primary mb-4"
                    >
                      <p>
                        {{ question.name }}
                      </p>
                      <textarea
                        required
                        placeholder="Write your answer here..."
                        class="mb-4 m-0"
                        @input="addResponse(question, '', $event)"
                      />
                    </li>
                    <li
                      v-if="question.type == jobScreeningQuestionTypes.MULTIPLE"
                    >
                      <p class="text-primary">
                        {{ question.name }}
                      </p>

                      <div class="check-list mb-4">
                        <div
                          v-for="(
                            responseQuestion, index
                          ) in question?.response"
                          :key="index"
                          class="form-check"
                        >
                          <input
                            :id="`question-${index}`"
                            class="form-check-input shadow-none"
                            type="checkbox"
                            @change="
                              addResponse(question, responseQuestion, '')
                            "
                          >
                          <label
                            class="form-check-label"
                            :for="`question-${index}`"
                          >
                            {{ responseQuestion.response }}
                          </label>
                        </div>
                      </div>
                    </li>
                    <li
                      v-if="question.type == jobScreeningQuestionTypes.SINGLE"
                    >
                      <p class="text-primary">
                        {{ question.name }}
                      </p>
                      <div class="check-list mb-4">
                        <div
                          v-for="(
                            responseQuestion, index
                          ) in question?.response"
                          :key="responseQuestion.id"
                          class="form-check"
                        >
                          <input
                            :id="`question-${index}`"
                            class="form-check-input shadow-none"
                            type="checkbox"
                            @input="addResponse(question, responseQuestion, '')"
                          >
                          <label
                            class="form-check-label"
                            :for="`question-${index}`"
                          >
                            {{ responseQuestion.response }}
                          </label>
                        </div>
                      </div>
                    </li>
                    <li
                      v-if="question.type == jobScreeningQuestionTypes.SHORT"
                      class="text-primary mb-4"
                    >
                      <p>{{ question.name }}</p>
                      <input
                        type="text"
                        required
                        class="form-control shadow-none"
                        placeholder="Write your answer here..."
                        @input="addResponse(question, '', $event)"
                      >
                    </li>
                  </div>
                </ol>
              </div>
              <div class="btn-block mt-4">
                <div class="col-6">
                  <router-link
                    :to="{ name: 'ProviderJobDetails', params: { id: job.id } }"
                    class="btn-outline"
                  >
                    Back
                  </router-link>
                </div>

                <div class="col-6 text-end">
                  <button
                    :disabled="v$.applydata.$invalid || submitting"
                    class="btn-save btn-primary"
                  >
                    <submission-spinner v-if="submitting" />
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
    <confirm-modal
      v-if="modalConfigs.showConfirmModal"
      ref="confirmModal"
      :ok-button-title="modalConfigs.confirmTitle"
      :close-button-title="'Cancel'"
      :message="modalConfigs.confirmMessage"
      @onConfirm="$router.push({name: 'ProviderBuyBids'})"
    />
  </div>
</template>

<script>
import DOMPurify from "dompurify";

import { toast } from "vue3-toastify";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import api from "@/services/api";
import errorMessages from "@/utils/error-messages";

import { Money3Component } from "v-money3";

import validationHelpers from "@/utils/validation";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import download from "@/assets/img/icons/downloads.svg";
import calendarVectorIcon from "@/assets/img/icons/calendar-icon.svg";
import eyeIcon from "@/assets/img/icons/eye.svg";

import {
  JobStatus,
  JobBudgetTypes,
  JobScreeningQuestionTypes,
} from "@/configs/constants/jobs";
import FileUtils from "@/utils/file";
import { ProviderStatus } from "@/configs/constants/users";
import { MediaTypesConstants } from "@/configs/constants";

import JobHelper from "@/utils/job";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import RightCanvas from "@/components/RightCanvas.vue";
import HeadTable from '@/components/HeadTable.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderJobApply",

  components: {
    money3: Money3Component,
    SubmissionSpinner,
    LoaderSpinner,
    RightCanvas,
    HeadTable,
    ConfirmModal,
    AlerteDanger,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    applydata: {
      amount: 0,
      work_start_date: null,
      work_end_date: null,
      cv: null,
      file: null,
      message: null,
      sqresponse: [],
      items: [],
    },
    properties: [],
    thead: ["Name", "Budget", "Overall"],
    multipleQuestion: [],
    uploadedDocument: null,
    uploadingW9DocumentFile: false,
    showCustomerDetails: false,
    job: null,
    loading: false,
    submitting: false,
    icons: {
      download,
      calendarVectorIcon,
      eyeIcon,
    },
    errors: {
      amountErrorMessage: null,
      workStartDateErrorMessage: null,
      workEndDateErrorMessage: null,
      cvErrorMessage: null,
      responseErrorMessage: null,
      serverSideErrorMessage: null,
      errorMessage: null,
      messageErrorMessage: null,
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
  }),

  validations() {
    return {
      applydata: {
        amount: {
          required,
        },
      },
    };
  },

  computed: {
    currentProvider() {
      return this.$store.getters["user/user"];
    },

    jobScreeningQuestionTypes() {
      return JobScreeningQuestionTypes;
    },

    myResumeList() {
      let items = [];
      if (this.currentProvider?.cv != null) {
        items = [
          { id: null, original_name: "Select your cv" },
          ...this.currentProvider.cv?.map((x) => x.user_cv),
        ];
      }
      return items;
    },

    jobBudgetTypes() {
      return JobBudgetTypes;
    },

    canApply() {
      return [ProviderStatus.OK, ProviderStatus.ACTIVE].includes(
        this.currentProvider?.status
      );
    },

    sanitizedDescription() {
      return DOMPurify.sanitize(this.job?.description ?? "");
    },

    mediaTypesConstants() {
      return MediaTypesConstants;
    }
  },

  mounted() {
    if (!this.canApply) {
      this.$router.back();
      return;
    }
    this.fetchJob(this.$route.params.id);
    this.applydata.job = this.$route.params.id;
  },

  methods: {
    ...FileUtils,
    ...JobHelper,
    ...DateHelpers,
    ...validationHelpers,
    ...PriceHelpers,

    
    openDetail() {
      this.$refs.detailJodModal.openCanvas();
    },
    confirmClaim() {
      this.modalConfigs.confirmTitle = "Buy Bids";
      this.modalConfigs.confirmMessage = `You've reached the bid limit Per Month`;
      this.modalConfigs.showConfirmModal = true;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    addResponse(quiz, response, event) {
      const questionIndex = this.applydata.sqresponse.findIndex(
        (elmt) => elmt.question === quiz.id
      );
      if (questionIndex !== -1) {
        if (
          quiz.type === JobScreeningQuestionTypes.SHORT ||
          quiz.type === JobScreeningQuestionTypes.LONG
        ) {
          this.applydata.sqresponse[questionIndex].response =
            event.target.value;
          this.job.screening_question[questionIndex].isAnswered = true;
        } else {
          const index = this.applydata.sqresponse[
            questionIndex
          ].response_selected.findIndex((responseId) => {
            return responseId == response.id;
          });
          if (index !== -1) {
            this.applydata.sqresponse[questionIndex].response_selected.splice(
              index,
              1
            );
            this.job.screening_question[questionIndex].isAnswered = false;
          } else {
            this.applydata.sqresponse[questionIndex].response_selected.push(
              response.id
            );
            this.job.screening_question[questionIndex].isAnswered = true;
          }
        }
      } else {
        switch (quiz.type) {
          case JobScreeningQuestionTypes.SHORT:
          case JobScreeningQuestionTypes.LONG:
            this.applydata.sqresponse.push({
              question: quiz.id,
              response: event.target.value,
            });
            const simpleIndex = this.job.screening_question.findIndex(
              (elmt) => elmt.id == quiz.id
            );
            if (simpleIndex !== -1)
              this.job.screening_question[simpleIndex].isAnswered = true;
            break;

          case JobScreeningQuestionTypes.SINGLE:
          case JobScreeningQuestionTypes.MULTIPLE:
            this.applydata.sqresponse.push({
              question: quiz.id,
              response_selected: [response.id],
            });
            const index = this.job.screening_question.findIndex(
              (elmt) => elmt.id == quiz.id
            );
            if (index !== -1)
              this.job.screening_question[index].isAnswered = true;
            break;
        }
      }
    },

    onDocumentChange(event) {
      const uploadedFile = event.target.files[0];
      this.uploadedDocument = {
        filename: uploadedFile.name,
      };
      this.applydata.file = uploadedFile;
    },

    async fetchJob(id) {
      try {
        this.loading = true;
        const response = await api.fetchJob(id);
        const job = response.data;
        if (job.owner_provider?.user?.id === this.currentProvider.id) {
          this.errors.errorMessage = "You cannot apply to a job that you own";
          return;
        }
        if (job.status !== JobStatus.ACTIVE) {
          this.errors.errorMessage = errorMessages.PROVIDER_CANNOT_APPLY;
          return;
        }

        this.job = job;
        const properties = JSON.parse(this.$route.params.properties)
        if (properties.length > 0) {
          this.thead = [...this.thead , ...job.customer_propertie_associate[0].items.map(el=> (el.name))];
          this.applydata.items = job.customer_propertie_associate
          .filter(el => properties.includes(el.customer_propertie_associate.id))
          .map(el1=> ({
            property: el1.customer_propertie_associate.id,
            company: el1.customer_propertie_associate.company,
            location_description: el1.customer_propertie_associate.location_description,
            value: el1.value,
            values: el1.items.map(el2=> ({
              item: el2.id,
              price: 0
            }))
          }));
          console.log(this.applydata.items);
          this.job.screening_question = this.job.screening_question.map(
            (question) => ({
              ...question,
              isAnswered: false,
            })
          );
        }
        
      } catch (error) {
        
        this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async applyJob() {
      const isgood = this.job.screening_question.some(
        (elmt) => elmt.isAnswered == false
      );
      if (isgood) {
        this.errors.responseErrorMessage =
          errorMessages.AN_ERROR_HAS_SCRENNING_QUESTION;
        this.submitting = false;
      } else {
        try {
          this.submitting = true;
          this.errors.responseErrorMessage = null;

          if (this.applydata.work_start_date) {
            this.applydata.work_start_date = this.formatDate(
              this.applydata.work_start_date,
              "YYYY-MM-DD"
            );
          }
          if (this.applydata.work_end_date) {
            this.applydata.work_end_date = this.formatDate(
              this.applydata.work_end_date,
              "YYYY-MM-DD"
            );
          }

          /**
           * @description
           * Fisrt updload the file if its defined
           */
          let file = undefined;
          if (this.applydata.file) {
            const response = await api.uploadFile({
              file: this.applydata.file,
            });
            file = response.data.file_id;
          }

          /**
           * @description
           * Then apply to the job
           */
          await api.applyJob({ ...this.applydata, file });

          this.clearform();
          toast.success(`Successfully submitted your job application.`);

          if (this.$route.query.is_subcontract) {
            this.$router.push({
              name: "ProviderSubcontractDetails",
              params: {
                id: this.$route.params.id,
              },
              query: {
                idProposal: this.$route.query.proposalId,
                idJob: $route.query.idJob,
                my_subcontract: true,
              },
            });
          } else {
            this.$router.push({
              name: "ProviderJobDetails",
              params: {
                id: this.$route.params.id,
              },
            });
          }
        } catch (error) {
          this.submitting = false;
          this.errorsHandler(error);
        } finally {
          this.submitting = false;
        }
      }
      this.submitting = false;
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    clearform() {
      this.applydata.amount = null;
      this.applydata.cv = null;
      this.applydata.message = null;
      this.applydata.work_end_date = null;
      this.applydata.work_start_date = null;
    },

    errorsHandler(err) {
      if (err.response) {
        if (err.response.status === 401) {
          /**
           * TODO
           * Hnadle logout
           */
          this.errors.serverSideErrorMessage =
            errorMessages.INVALID_CREDENTIALS;
        } else if (err.response.status === 404) {
          this.errors.errorMessage = errorMessages.JOB_NOT_FOUND;
        } else if (err.response.status === 400) {
          if (Array.isArray(err.response.data)) {
            for (const error in err.response.data) {
              if (error == "bid_error") {
                this.confirmClaim()
              } else {
                this.errors[error + "ErrorMessage"] = err.response.data[error][0];
              }
              
            }
          } else {
            this.errors.serverSideErrorMessage = err.response.data.error;
          }
        } else {
          this.errors.serverSideErrorMessage =
            errorMessages.AN_ERROR_HAS_OCCURED;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.job-apply h3 {
  color: #209516;
  font-weight: 500;
  font-size: 1.625rem;
  width: 100%;
}
.job-apply .page-content .title {
  font-weight: 600;
  font-size: 1.5rem;
  color: #222a36;
  margin-top: 1.3125rem;
}
 .btn-save {
  padding: 0.6rem 0.85rem;
  border-radius: 6px;
  font-weight: 500;
  font-size: 1rem;
  height: 100%;

}
 .btn-back {
  padding: 0.6rem 0.85rem;
  background: #e7e7e7;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 1rem;
  color: #222a36;
  display: inline-block;
}

.label-upload {
  cursor: pointer;
  color: #209516;
  padding: 0.6rem 1.25rem;
  font-weight: 500;
  font-size: 1rem;
  background: #ffffff;
  border: 1.2px solid #209516;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}
#upload {
  opacity: 1;
  position: absolute;
  z-index: -1;
  left: 7%;
  top: 30%;
  border: none !important;
}

.label-link{
  font-size: 1rem;
  font-style: italic;
  color:#b1b1b1;
  font-weight: 400;
  display:block;
  cursor: pointer;
}
.label-link:hover{
  text-decoration:underline;
  color: #209516;
}
.uploads {
  padding: 0.6rem;
  margin-top: 0.6rem;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  background: #ffffff;
  border: 1px solid #e5e8eb;
  border-radius: 4px;
  position: relative;
}

.category-part {
  border-bottom: none;
}

.job-detail .content-title {
  margin: 0;
}

.btn-block{
  display: flex;
  flex-wrap: wrap;
}
.section.content .page-content {
  padding: 0;
}
</style>
