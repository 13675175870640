<template>
  <div
    v-if="job.title != undefined"
    class="col-md-12"
  >
    <!-- Content Header (Page header) -->
    <section class="col-md-12 ">
      <h3>
        <router-link
          :to="{ name: 'SuperAdminJobs' }"
        >
          Jobs
        </router-link>
        <span class="fw-lighter"> / {{ job.title }}</span>
      </h3>
    </section>

    <!-- Start content -->
    <div class="col-md-12 row   p-5">
      <div
        class="col-md-12 row mt-3 border-bottom border-secondary border-opacity-25 px-0"
      >
        <div class="col-md-6">
          <h4 class="text-primary">
            {{ job.title }}
            <span 
              v-if="job.owner_provider"
              class="p-1 bg-danger border border-light rounded-circle d-inline-block"
            />
          </h4>

          <p>{{ formatDate(job.created_at) }}</p>
        </div>

        <div class="col-md-6 text-end">
          <button
            class="btn d-block d-sm-inline-block ms-sm-3 mt-3 mt-sm-0 px-3 py-2"
            :class="
              job.status == 'deactivate'
                ? 'btn-outline-danger'
                : job.status == 'draft'
                  ? 'btn-outline-warning'
                  : job.status == 'waiting'
                    ? 'btn-outline-secondary'
                    : job.status == 'completed'
                      ? 'btn-primary'
                      : 'btn-outline-primary'
            "
          >
            <span>
              {{ statusToUI(job.status) }}
            </span>
          </button>
          <option-action
            :status="job.status"
            :options="JobActionsList"
            @update:options="confirmClaim($event)"
          />
        </div>

        <p
          class="col-md-12 my-4"
          v-html="sanitizedDescription"
        />
      </div>

      <div
        class="col-lg-12 row mt-3 border-bottom border-secondary border-opacity-25 px-0"
      >
        <div class="col-lg-4 py-2">
          <p class="me-3  d-sm-inline-block text-wrap">
            Contract Type :
          </p>

          <p
            v-if="job.contract_type"
            class="d-sm-inline-block text-wrap text-capitalize"
          >
            {{ job.contract_type.name }}
          </p>
        </div>

        <div class="col-lg-4 py-2">
          <p class="me-3  d-sm-inline-block text-wrap">
            Level :
          </p>

          <p
            v-if="job.level"
            class="d-sm-inline-block text-wrap text-capitalize"
          >
            {{ job.level.name }}
          </p>
        </div>

        <div class="col-lg-4 py-2">
          <p class="me-3  d-sm-inline-block text-wrap">
            Job Language :
          </p>

          <p
            v-if="job.job_language"
            class="d-sm-inline-block text-wrap text-capitalize"
          >
            {{ job.job_language.name }}
          </p>
        </div>

        <div class="col-lg-4 py-2">
          <p class="me-3  d-sm-inline-block text-wrap">
            Payment Frequency :
          </p>
          <p class="d-sm-inline-block text-wrap text-capitalize">
            {{ job.payment_frequency }}
          </p>
        </div>

        <div class="col-lg-4 py-2">
          <p class="me-3  d-sm-inline-block text-wrap">
            Budget Type :
          </p>
          <p class="d-sm-inline-block text-wrap text-capitalize">
            {{ formatJobBudgetTypes(job.budget_type) }}
          </p>
        </div>

        <div
          v-if="job.budget_type == jobBudgetTypes.RANGE"
          class="col-lg-4 py-2"
        >
          <p class="me-3  d-sm-inline-block text-wrap">
            Budget from :
          </p>

          <p
            v-if="job.budget_from !== null && job.budget_from !== undefined"
            class="d-sm-inline-block text-wrap text-capitalize"
          >
            {{ formatPrice(job.budget_from) }}
          </p>

          <p class="me-3  d-sm-inline-block text-wrap ms-3">
            Budget to :
          </p>

          <p
            v-if="job.budget_to !== null && job.budget_to !== undefined"
            class="d-sm-inline-block text-wrap text-capitalize"
          >
            {{ formatPrice(job.budget_to) }}
          </p>
        </div>

        <div
          v-else
          class="col-lg-4 py-2"
        >
          <p class="me-3  d-sm-inline-block text-wrap">
            Budget :
          </p>
          <p
            v-if="job.budget !== null && job.budget !== undefined"
            class="d-sm-inline-block text-wrap text-capitalize"
          >
            {{ formatPrice(job.budget) }}
          </p>
        </div>

        <div class="col-lg-4 py-2">
          <p class="me-3  d-sm-inline-block text-wrap">
            Job Type :
          </p>
          <p class="d-sm-inline-block text-wrap text-capitalize">
            {{ formatJobType(job.job_type) }}
          </p>
        </div>

        <div class="col-lg-4 py-2">
          <p class="me-3  d-sm-inline-block text-wrap">
            Job Location :
          </p>
          <p class="d-sm-inline-block text-wrap text-capitalize">
            {{ job.location_description }}
          </p>
        </div>

        <div
          v-if="job.start_date"
          class="col-lg-4 py-2"
        >
          <p class="me-3  d-sm-inline-block text-wrap">
            Start Date :
          </p>
          <p class="d-sm-inline-block text-wrap text-capitalize">
            {{ formatDate(job.start_date, "MMMM Do, YYYY") }}
          </p>
        </div>

        <div
          v-if="job.end_date"
          class="col-lg-4 py-2"
        >
          <p class="me-3  d-sm-inline-block text-wrap">
            End Date :
          </p>
          <p class="d-sm-inline-block text-wrap text-capitalize">
            {{ formatDate(job.end_date, "MMMM Do, YYYY") }}
          </p>
        </div>
      </div>

      <div
        class="col-md-12 mt-3 border-bottom border-secondary border-opacity-25 px-0"
      >
        <p class="col-md-12 me-3 ">
          Skills :
        </p>

        <div class="col-md-12 mb-3">
          <div
            v-for="(item, index) in job.skills"
            :key="index"
            class="d-sm-inline-block rounded m-2 px-3 py-2 bg-light text-primary"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="col-md-12 my-5">
        <p class="col-md-12  fs-5">
          Screening Questions :
        </p>

        <p class="col-md-12 row me-3 text-primary ">
          <span class="col-md-9">You will be asked to answer the following questions when submitting
            a proposal:
          </span>
          <span class="col-md-2 text-center">Answer type</span>
        </p>

        <div class="col-md-12 mb-3 list-number">
          <p
            v-for="(item, index) in job.screening_question"
            :key="index"
            class="item-number row ms-3"
          >
            <span class="col-md-9 text-capitalize">
              {{ item.name }}
            </span>
            <span class="col-md-2 text-capitalize">
              {{ item.type }}
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End content -->
      
    <confirm-modal
      ref="confirmModal"
      :ok-button-title="confirmationOkBtnText"
      :message="confirmationMessage"
      @onConfirm="updateStatus"
    />
  </div>
</template>

<script>
import DOMPurify from "dompurify";

import ConfirmModal from "@/components/ConfirmModal.vue";
import OptionAction from"@/components/OptionAction.vue";

import api from "@/services/api";
import statusToUI from "@/utils/statusFormate";
import { DateHelpers } from "@/utils/date-helpers";
import {
  JobStatusList,
  JobActionsList,
  JobBudgetTypes,
} from "@/configs/constants/jobs";

import JobHelper from "@/utils/job";
import PriceHelpers from "@/utils/price-format";

export default {
  name: "SuperadminJobDetailsPage",

  components: {
    ConfirmModal,
    OptionAction,
  },

  data() {
    return {
      JobActionsList,
      JobStatusList,
      job: {},
      loading: true,
      updatingStatus: false,
      confirmationOkBtnText: null,
      confirmationMessage: null,
      status: null,
    };
  },

  computed: {
    sanitizedDescription() {
      return DOMPurify.sanitize(this.job?.description ?? "");
    },

    jobBudgetTypes() {
      return JobBudgetTypes;
    },
  },

  async mounted() {
    await this.fetchjob();
  },

  methods: {
    ...statusToUI,
    ...DateHelpers,
    ...JobHelper,
    ...PriceHelpers,

    async fetchjob() {
      let id = this.$route.params.id;
      this.loading = true;

      try {
        let response = await api.fetchJob(id);
        this.job = response.data;
      } catch (_) {
        /**
         * TODO
         * Handle errors
         */
        // this.errorHandler(e)
      } finally {
        this.loading = false;
      }
    },

    confirmClaim(status) {
      this.status = status;

      this.confirmationMessage = `Are you sure you want to make ${this.statusToUI(
        status
      )} ${this.job.title} account ?`;
      this.confirmationOkBtnText = "Confirm";

      this.$refs.confirmModal.openModal();
    },

    updateStatus() {
      if (this.loading) return;

      this.loading = true;

      api
        .makeJob(this.job.id, { status: this.status })
        .then((response) => {
          this.fetchjob();
          this.confirmationOkBtnText = null;
          this.confirmationMessage = null;
          this.selected = null;
          this.status = null;
          this.$refs.confirmModal.closeModal();
        })
        .catch((_) => {
          /**
           * TODO
           * Handle errors
           */
          // this.errorsHandler(error)
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
