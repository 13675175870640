import {
  JobTypes,
  JobStatus,
  JobBudgetTypes,
  JobProposalStatus,
  JobPaymentFrequencies,
  ScreeningQuestionResponseTypes,
} from "@/configs/constants/jobs";

export default {
  formatJobStatus(status) {
    let formatedStatus = "";

    switch (status) {
      case JobStatus.COMPLETED:
        formatedStatus = "Completed";
        break;

      case JobStatus.ACTIVE:
        formatedStatus = "Active";
        break;

      case JobStatus.WAITING:
        formatedStatus = "Pending";
        break;

      case JobStatus.DRAFT:
        formatedStatus = "Draft";
        break;

      case JobStatus.DEACTIVATE:
        formatedStatus = "Deactivated";
        break;

      case JobStatus.ONGOING:
        formatedStatus = "In Progress";
        break;
    }
    return formatedStatus;
  },

  getJobStatusStyle(status) {
    let styles = {
      color: null,
      class: null,
    };

    switch (status) {
      case JobStatus.COMPLETED:
        styles.color = "#209516";
        styles.class = "job-status-completed";
        break;

      case JobStatus.ACTIVE:
        styles.color = "#209516";
        styles.class = "job-status-active";
        break;

      case JobStatus.WAITING:
        styles.color = "#737C84";
        styles.class = "job-status-pending";
        break;

      case JobStatus.DRAFT:
        styles.color = "#ECBD01";
        styles.class = "job-status-draft";
        break;

      case JobStatus.DEACTIVATE:
        styles.color = "#e0230b";
        styles.class = "job-status-deactivated";
        break;

      case JobStatus.ONGOING:
        styles.color = "#209516";
        styles.class = "job-status-active";
        break;
    }
    return styles;
  },

  formatJobBudgetTypes(type) {
    let formatedType = null;

    switch (type) {
      case JobBudgetTypes.RANGE:
        formatedType = "Range";
        break;

      case JobBudgetTypes.FIXED:
        formatedType = "Fixed";
        break;

      case JobBudgetTypes.NOT_SURE:
        formatedType = "Not Sure";
        break;
    }
    return formatedType;
  },

  formatScreeningQuestionResponseTypes(type) {
    let formatedType = null;

    switch (type) {
      case ScreeningQuestionResponseTypes.TEXT:
        formatedType = "Long Answer";
        break;

      case ScreeningQuestionResponseTypes.SELECT_OPTION:
        formatedType = "Multiple Choice";
        break;
    }
    return formatedType;
  },

  formatJobType(type) {
    let formatedType = null;

    switch (type) {
      case JobTypes.ON_SITE:
        formatedType = "On Site";
        break;

      case JobTypes.REMOTE:
        formatedType = "Remote";
        break;

      case JobTypes.MULTISITE:
        formatedType = "Multi-site";
        break;
    }
    return formatedType;
  },

  formatJobPaymentFrequencies(frequency) {
    let formatedFrequency = {
      short: null,
      long: null,
    };

    switch (frequency) {
      case JobPaymentFrequencies.HOURLY:
        formatedFrequency.long = "Hourly";
        formatedFrequency.short = "hour";
        break;

      case JobPaymentFrequencies.FIXED:
        formatedFrequency.long = "Fixed";
        break;

      case JobPaymentFrequencies.MONTHLY:
        formatedFrequency.long = "Monthly";
        formatedFrequency.short = "month";
        break;

      case JobPaymentFrequencies.WEEKLY:
        formatedFrequency.long = "Weekly";
        formatedFrequency.short = "week";
        break;

      case JobPaymentFrequencies.ANNUAL:
        formatedFrequency.long = "Annual";
        formatedFrequency.short = "year";
        break;
    }
    return formatedFrequency;
  },

  formatProposalStatus(status) {
    let formatedStatus = "";

    switch (status) {
      case JobProposalStatus.SENDED:
        formatedStatus = "Pending Proposal";
        break;

      case JobProposalStatus.ACCEPTED:
        formatedStatus = "Chosen Proposal";
        break;

      case JobProposalStatus.REJECTED:
        formatedStatus = "Refused Proposal";
        break;
    }
    return formatedStatus;
  },

  getProposalStatusStyle(status) {
    let styles = {
      color: null,
      class: null,
    };

    switch (status) {
      case JobProposalStatus.SENDED:
        styles.color = "#209516";
        styles.class = "proposal-status-pending";
        break;

      case JobProposalStatus.ACCEPTED:
        styles.color = "#209516";
        styles.class = "proposal-status-accepted";
        break;

      case JobProposalStatus.REJECTED:
        styles.color = "#737C84";
        styles.class = "proposal-status-rejected";
        break;
    }
    return styles;
  },
};
