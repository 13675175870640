<template>
  <div class="row">
    <div
      v-if="errors.notFoundErrorMessage"
      class="col-md-8 offset-md-2 mt-7"
    >
      <alerte-danger
        :head-error-message="errors.notFoundErrorMessage"
        @updateContent="errors.notFoundErrorMessage = $event"
      />
    </div>

    <template v-else>
      <div
        class="mb-3 mb-xl-0"
        :class="{ 'col-xl-8': hasProposals && job?.customer_propertie_associate.length == 0, 'col-xl-12': !hasProposals || (hasProposals && job?.customer_propertie_associate.length != 0) }"
      >
        <div class="job-detail bg-white">
          <loader-spinner v-if="fetchingJob" />

          <template v-else>
            <template v-if="job">
              <div class="page-header">
                <div class="text-end mb-3">
                  <template v-if="job.status === jobStatus.ONGOING">
                    <router-link
                      class="btn-primary btn-next me-3"
                      :to="{ name: 'CustomerRating' }"
                    >
                      Mark as completed
                    </router-link>

                    <button
                      class="btn-danger btn-next me-3"
                      @click.prevent="confirmClaim(actions.CANCEL)"
                    >
                      <submission-spinner
                        v-if="
                          modalConfigs.currentAction === actions.CANCEL &&
                            updatingJob
                        "
                      />
                      Cancel job
                    </button>
                  </template>

                  <template v-if="job.status === jobStatus.ACTIVE">
                    <button
                      v-if="job.awarded"
                      class="btn-primary btn-next me-3"
                      @click.prevent="confirmClaim(actions.START)"
                    >
                      <submission-spinner
                        v-if="
                          modalConfigs.currentAction === actions.START &&
                            updatingJob
                        "
                      />
                      Start job
                    </button>

                    <button
                      class="btn-secondary"
                      :class="{ 'me-3': job.status !== jobStatus.DRAFT }"
                      @click.prevent="openInviteModal"
                    >
                      Invite Providers
                    </button>
                  </template>

                  <router-link
                    v-if="job.status !== jobStatus.DRAFT"
                    :to="{
                      name: 'CustomerToDoList',
                      params: { id: $route.params.id },
                    }"
                    class="btn-secondary btn-next me-3 todo-list"
                  >
                    To do list
                  </router-link>

                  <router-link
                    v-if="hasProposals && job?.customer_propertie_associate.length != 0"
                    :to="{
                      name: 'CustomerJobSommary',
                      params: { id: $route.params.id },
                    }"
                    class="btn-secondary btn-next me-3"
                  >
                    Proposals Summary
                  </router-link>
                </div>

                <div
                  v-if="false"
                  class="d-md-flex title-part justify-content-between mb-3"
                >
                  <div class="d-flex">
                    <router-link
                      :to="{
                        name: 'CustomerJobs',
                        query: { ...$route.query, status: job.status },
                      }"
                      class="title me-1 pb-3"
                    >
                      {{ formatJobStatus(job.status) }} Jobs
                    </router-link>
                    <p
                      v-if="job.title"
                      class="title-content"
                    >
                      / {{ job.title }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="page-content">
                <div class="row mt-4">
                  <div class="col-md-8">
                    <alerte-danger
                      :head-error-message="errors.serverSideErrorMessage"
                      @updateContent="errors.serverSideErrorMessage = $event"
                    />
                  </div>
                </div>

                <div class="box-form">
                  <div class="row position-relative">
                    <div class="col">
                      <div class="d-flex align-items-center">
                        <p
                          v-if="job.title"
                          class="content-title"
                        >
                          {{ job.title }}
                          <span
                            v-if="
                              [jobStatus.ACTIVE, jobStatus.ONGOING].includes(
                                job.status
                              )
                            "
                            class="active"
                          />
                        </p>
                      </div>

                      <p class="content-subtitle pb-0 m-0">
                        Posted at
                        {{ formatDate(job.created_at, "MMMM Do, YYYY") }}

                        <template v-if="job.category?.name">
                          in
                          <span class="fw-bold">{{ job.category.name }} </span>
                        </template>
                      </p>
                    </div>

                    <div
                      class="col-md-5 btn-active-part ps-0 justify-content-xl-end"
                    >
                      <app-job-status :status="job.status" />

                      <div
                        v-if="
                          canEditJob ||
                            canSaveAsDraft ||
                            canDeleteJob || canDuplicateJob ||
                            job.status === jobStatus.DRAFT
                        "
                      >
                        <div class="dropdown drop nav-link">
                          <a
                            class="dropdown-toggle item"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              :src="icons.dotVectorIcon"
                              alt="Menu Icon"
                            >
                          </a>

                          <ul class="dropdown-menu">
                            <li>
                              <router-link
                                v-if="canEditJob"
                                :to="{
                                  name: 'CustomerJobEdit',
                                  params: { id: job.id },
                                }"
                                class="dropdown-item border-bottom"
                              >
                                Edit
                              </router-link>
                            </li>

                            <li
                              v-if="canSaveAsDraft"
                              class="border-bottom"
                            >
                              <a
                                class="dropdown-item"
                                @click.prevent="confirmClaim(actions.DRAFT)"
                              >Save as Draft</a>
                            </li>
                            <li
                              v-if="canDuplicateJob"
                              class="border-bottom"
                            >
                              <a
                                class="dropdown-item"
                                @click.prevent="confirmClaim(actions.DUPLICATE)"
                              >Duplicate</a>
                            </li>

                            <li
                              v-if="job.status === jobStatus.DRAFT"
                              class="border-bottom"
                            >
                              <a
                                class="dropdown-item"
                                @click.prevent="confirmClaim(actions.REPUBLISH)"
                              >Republish</a>
                            </li>

                            <li
                              v-if="canDeleteJob"
                              class="dropdown-item"
                              @click.prevent="confirmClaim(actions.DELETE)"
                            >
                              Delete
                            </li>
                          </ul>
                        </div>

                        <div class="dropdown nav-links d-none m-drop">
                          <a
                            class="dropdown-toggle item"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              :src="icons.dotVectorIcon"
                              alt="Menu Icon"
                            >
                          </a>

                          <ul class="dropdown-menu">
                            <li v-if="canEditJob">
                              <router-link
                                :to="{
                                  name: 'CustomerJobEdit',
                                  params: { id: job.id },
                                }"
                                class="dropdown-item border-bottom"
                              >
                                Edit
                              </router-link>
                            </li>

                            <li
                              v-if="job.status !== jobStatus.DRAFT"
                              class="border-bottom"
                            >
                              <router-link
                                :to="{
                                  name: 'CustomerToDoList',
                                  params: { id: $route.params.id },
                                }"
                                class="dropdown-item"
                              >
                                To do list
                              </router-link>
                            </li>

                            <li
                              v-if="canSaveAsDraft"
                              class="border-bottom"
                            >
                              <a
                                class="dropdown-item"
                                @click.prevent="confirmClaim(actions.DRAFT)"
                              >Save as Draft</a>
                            </li>

                            <li
                              v-if="job.status === jobStatus.DRAFT"
                              class="border-bottom"
                            >
                              <a
                                class="dropdown-item"
                                @click.prevent="confirmClaim(actions.REPUBLISH)"
                              >Republish</a>
                            </li>

                            <li
                              v-if="canDeleteJob"
                              class="dropdown-item"
                              @click.prevent="confirmClaim(actions.DELETE)"
                            >
                              Delete
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p
                    v-if="job.description"
                    class="text"
                    v-html="sanitizedDescription"
                  />

                  <div class="category-part">
                    <div class="row mt-4">
                      <div class="col-xl-6">
                        <div
                          v-if="job.contract_type"
                          class="d-flex"
                        >
                          <p class="category">
                            Contract Type :
                          </p>

                          <p class="category-name">
                            {{ job.contract_type.name }}
                          </p>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <div
                          v-if="job.level"
                          class="d-flex"
                        >
                          <p class="category">
                            Level :
                          </p>

                          <p class="category-name">
                            {{ job.level.name }}
                          </p>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <div
                          v-if="job.job_language"
                          class="d-flex"
                        >
                          <p class="category">
                            Job Language :
                          </p>

                          <p class="category-name">
                            {{ job.job_language.name }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-xl-6">
                        <div
                          v-if="job.budget_type"
                          class="d-flex"
                        >
                          <p class="category">
                            Budget Type :
                          </p>

                          <p class="category-name">
                            {{ formatJobBudgetTypes(job.budget_type) }}
                          </p>
                        </div>
                      </div>

                      <template
                        v-if="job.budget_type !== jobBudgetTypes.NOT_SURE"
                      >
                        <div class="col-xl-6">
                          <div class="d-flex">
                            <p class="category">
                              Payment Frequency :
                            </p>

                            <p
                              v-if="job.payment_frequency"
                              class="category-name"
                            >
                              {{
                                formatJobPaymentFrequencies(
                                  job.payment_frequency
                                ).long
                              }}
                            </p>
                          </div>
                        </div>

                        <div
                          v-if="job.budget_type === jobBudgetTypes.FIXED"
                          class="col-xl-6"
                        >
                          <div class="d-flex">
                            <p class="category">
                              Budget :
                            </p>

                            <p
                              v-if="
                                job.budget !== null && job.budget !== undefined
                              "
                              class="category-name"
                            >
                              {{ formatPrice(job.budget) }}
                            </p>
                          </div>
                        </div>

                        <template v-else>
                          <div class="col-xl-6">
                            <div
                              v-if="
                                job.budget_from !== null &&
                                  job.budget_from !== undefined
                              "
                              class="d-flex"
                            >
                              <p class="category">
                                Budget :
                              </p>

                              <p class="category-name">
                                {{ formatPrice(job.budget_from) }}
                                <template
                                  v-if="
                                    job.payment_frequency &&
                                      formatJobPaymentFrequencies(
                                        job.payment_frequency
                                      ).short
                                  "
                                />
                              </p>
                              <p class="category">
                                &nbsp;to&nbsp;
                              </p>
                              <p
                                v-if="
                                  job.budget_to !== null &&
                                    job.budget_to !== undefined
                                "
                                class="category-name"
                              >
                                {{ formatPrice(job.budget_to) }}
                                <template
                                  v-if="
                                    job.payment_frequency &&
                                      formatJobPaymentFrequencies(
                                        job.payment_frequency
                                      ).short
                                  "
                                >
                                  /
                                  {{
                                    formatJobPaymentFrequencies(
                                      job.payment_frequency
                                    ).short
                                  }}
                                </template>
                              </p>
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>

                    <div class="row mt-4">
                      <div class="col-xl-6">
                        <div
                          v-if="job.category"
                          class="d-flex"
                        >
                          <p class="category">
                            Job Type :
                          </p>

                          <p class="category-name">
                            {{ formatJobType(job.job_type) }}
                          </p>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <div
                          v-if="job.location_description"
                          class="d-flex"
                        >
                          <p class="category">
                            Job Location :
                          </p>

                          <p class="category-name">
                            {{ job.location_description }}
                          </p>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <div
                          v-if="job.start_date && job.end_date"
                          class="d-flex"
                        >
                          <p>
                            <span class="category"> Date :</span>

                            <template
                              v-if="
                                formatDate(job.start_date, 'MMMM') ===
                                  formatDate(job.end_date, 'MMMM') &&
                                  formatDate(job.start_date, 'YYYY') ===
                                  formatDate(job.end_date, 'YYYY')
                              "
                            >
                              {{ formatDate(job.start_date, "MMMM") }}
                              {{ formatDate(job.start_date, "Do") }} to
                              {{ formatDate(job.end_date, "Do") }}
                              {{ formatDate(job.end_date, "YYYY") }}
                            </template>

                            <template v-else>
                              {{ formatDate(job.start_date, "MMMM Do, YYYY") }}
                              to
                              {{ formatDate(job.end_date, "MMMM Do, YYYY") }}
                            </template>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="job.customer_propertie_associate.length !=0"
                    class="skills-part"
                  >
                    <p class="skill-title mb-3">
                      Properties :
                    </p>
                    <div class="table-responsive mt-3">
                      <table class="table">
                        <head-table :list="thead" />
                        <tbody>
                          <tr
                            v-for="(item, index) in job.customer_propertie_associate"
                            :key="index"
                          >
                            <td>
                              {{ item.customer_propertie_associate.company }}
                            </td>
                            <td class="text-wrap">
                              {{ item.customer_propertie_associate.location_description }}
                            </td>
                            <td>
                              {{ item.customer_propertie_associate.zip }}
                            </td>

                            <td>
                              {{ item.customer_propertie_associate.city }}
                            </td>
                            <td>
                              {{ item.customer_propertie_associate.state }}
                            </td>
                            <td>
                              {{ formatPrice(item.value) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    v-if="job.customer_propertie_associate[0]"
                    class="skills-part"
                  >
                    <p class="skill-title">
                      Items for Pricing :
                    </p>

                    <div class="auto-flex">
                      <span
                        v-for="item in job.customer_propertie_associate[0].items"
                        :key="item.id"
                        class="type-job"
                      >
                        {{ item.name }}</span>
                    </div>
                  </div>

                  <div class="skills-part">
                    <p class="skill-title">
                      Skills :
                    </p>

                    <div class="auto-flex">
                      <span
                        v-for="skill in job.skills"
                        :key="skill.id"
                        class="type-job"
                      >
                        {{ skill.name }}</span>
                    </div>
                  </div>

                  <div
                    v-if="job.files?.length"
                    class="skills-part pb-3"
                  >
                    <p class="skill-title">
                      Photos and Documents
                    </p>

                    <div class="row">
                      <div
                        v-for="(file, i) in job.files"
                        :key="i"
                        class="col-md-12 col-lg-6"
                        :class="{ 'mb-3': i < job.files.length - 1 }"
                      >
                        <div class="document">
                          <a
                            :href="file.url"
                            target="_target"
                          >
                            <img
                              v-if="file.type === mediaTypesConstants.IMAGE"
                              :src="icons.imageUploadedIcon"
                              alt="File Uploaded"
                              class="file-icon me-1"
                            >
                            <img
                              v-else
                              :src="icons.documentUploadedIcon"
                              alt="File Uploaded"
                              class="file-icon me-1"
                            >
                            {{ getFileName(file.original_name) }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="job.screening_question.length > 0"
                    class="skills-part sreening-part"
                  >
                    <p class="screening-title">
                      Screening Questions :
                    </p>

                    <div class="mt-4 mb-3">
                      <div class="row">
                        <div class="col-md-12">
                          <p class="text-primary">
                            You will be asked to answer the following questions
                            when submitting a proposal:
                          </p>

                          <ol>
                            <li
                              v-for="question in job.screening_question"
                              :key="question.id"
                            >
                              <p>
                                {{ question.name }}
                              </p>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <confirm-modal
                v-if="modalConfigs.showConfirmModal"
                ref="confirmModal"
                :ok-button-title="modalConfigs.confirmTitle"
                :close-button-title="'No'"
                :message="modalConfigs.confirmMessage"
                @onConfirm="onModalConfirm"
              />
              <invite-modal
                ref="inviteModal"
                :job-id="$route.params.id"
              />
            </template>
          </template>
        </div>
      </div>

      <div
        v-if="hasProposals && job?.customer_propertie_associate.length == 0"
        class="col-xl-4"
      >
        <div class="border-proposal">
          <div class="d-flex justify-content-between align-items-center">
            <div
              id="proposals"
              class="screening-title mb-3"
            >
              Proposals ({{ zeroPad(listData.length) }}):
            </div>

            <div
              v-if="false"
              class="dropdown"
            >
              <div
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  :src="icons.proposalIcon"
                  alt=""
                >
                <span class="fw-proposal"> All proposals</span>
              </div>

              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item border-bottom">All proposal</a>
                </li>
                <li>
                  <a class="dropdown-item border-bottom">Favorites</a>
                </li>
                <li>
                  <a class="dropdown-item border-bottom">Answered</a>
                </li>
                <li>
                  <a class="dropdown-item border-bottom">Unanswered</a>
                </li>
                <li>
                  <a class="dropdown-item">Refused</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="proposals">
            <proposal-item
              v-for="proposal in listData"
              :key="proposal.id"
              :proposal="proposal"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import { nextTick } from "vue";
import DOMPurify from "dompurify";

import api from "@/services/api";
import ListMixin from "@/mixins/list";
import errorMessages from "@/utils/error-messages";

import JobHelper from "@/utils/job";
import FileUtils from "@/utils/file";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import { JobBudgetTypes, JobStatus } from "@/configs/constants/jobs";
import { MediaTypesConstants } from "@/configs/constants";

import InviteModal from "@/components/InviteModal.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import ProposalItem from "@/components/customer/jobs/ProposalItem.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import AppJobStatus from "@/components/customer/jobs/JobStatus.vue";

import dotVectorIcon from "@/assets/img/icons/tree-dot.svg";
import proposalIcon from "@/assets/img/icons/proposalIcon.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";
import duplicate from "@/assets/img/icons/heroicons_document-duplicate-20-solid.svg";
import HeadTable from '@/components/HeadTable.vue';
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "CustomerJobDetailsPage",

  components: {
    AppJobStatus,
    ConfirmModal,
    ProposalItem,
    InviteModal,
    LoaderSpinner,
    SubmissionSpinner,
    HeadTable,
    AlerteDanger,
  },

  mixins: [ListMixin(api.fetchJobBids, false)],

  data: () => ({
    icons: {
      dotVectorIcon,
      proposalIcon,
      imageUploadedIcon,
      documentUploadedIcon,
      duplicate
    },
    fetchingJob: false,
    updatingJob: false,
    deletingJob: false,
    fetchingJobBids: false,
    job: null,
    thead: ["Name", "Address", "Zip", "City", "State", "Budget"],
    actions: {
      DRAFT: "save-job-as-draft",
      DELETE: "delete-job",
      DUPLICATE: "duplicate-job",
      REPUBLISH: "republish-job",
      START: "start-job",
      COMPLETED: "completed-job",
      CANCEL: "cancel-job",
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: null,
      confirmMessage: null,
      showConfirmModal: false,
    },
    errors: {
      notFoundErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    jobBudgetTypes() {
      return JobBudgetTypes;
    },

    jobStatus() {
      return JobStatus;
    },

    sanitizedDescription() {
      return DOMPurify.sanitize(this.job?.description ?? "");
    },

    currentUser() {
      return this.$store.getters["user/user"];
    },

    canDeleteJob() {
      if (!this.job) return false;

      return (
        [JobStatus.DRAFT, JobStatus.ACTIVE].includes(this.job.status) &&
        !this.job.awarded &&
        this.currentUser?.id === this.job.owner?.user?.id
      );
    },

    canEditJob() {
      return [JobStatus.DRAFT, JobStatus.ACTIVE].includes(this.job.status);
    },
    canDuplicateJob() {
      return this.currentUser?.id === this.job.owner?.user?.id;
    },
    canSaveAsDraft() {
      return this.job.status === JobStatus.ACTIVE && !this.listData?.length;
    },

    mediaTypesConstants() {
      return MediaTypesConstants;
    },

    hasProposals() {
      return !this.fetchingJobBids && this.listData?.length;
    },

    currentCustomer() {
      return this.$store.getters["user/user"];
    },
  },

  watch: {
    listData() {
      this.fetchingJobBids = false;
    },
  },

  mounted() {
      this.fetchJob();
      this.fetchJobBids();
  },

  methods: {
    ...FileUtils,
    ...JobHelper,
    ...DateHelpers,
    ...PriceHelpers,

    zeroPad(num) {
      return (num < 10 ? "0" : "") + num.toString();
    },

    confirmClaim(action) {
      switch (action) {
        case this.actions.DRAFT:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to save this job as a draft ?";
          break;

        case this.actions.REPUBLISH:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to republish this job ?";
          break;

        case this.actions.START:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to start this job ?";
          break;

        case this.actions.COMPLETED:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to terminate this job ?";
          break;

        case this.actions.CANCEL:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to cancel this job ?";
          break;

        case this.actions.DELETE:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to delete this job ?";
          break;
        case this.actions.DUPLICATE:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to duplicate this job ?";
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.modalConfigs.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    openInviteModal() {
      this.$refs.inviteModal.openModal();
    },

    onModalConfirm() {
      switch (this.modalConfigs.currentAction) {
        case this.actions.DRAFT:
          this.updateJobStatus(
            JobStatus.DRAFT,
            "Job successfully Save as Draft."
          );
          break;

        case this.actions.REPUBLISH:
          this.updateJobStatus(
            JobStatus.ACTIVE,
            "Job successfully republished."
          );
          break;

        case this.actions.START:
          this.updateJobStatus(JobStatus.ONGOING, "Job successfully started.");
          break;

        case this.actions.COMPLETED:
          this.updateJobStatus(
            JobStatus.COMPLETED,
            "Job successfully terminated."
          );
          break;

        case this.actions.CANCEL:
          this.updateJobStatus(
            JobStatus.DEACTIVATE,
            "Job successfully canceled."
          );
          break;

        case this.actions.DELETE:
          this.deleteJob();
          break;
        case this.actions.DUPLICATE:
          this.duplicateJob();
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.modalConfigs.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    async duplicateJob() {
      try {
        this.loadingJob = true;
        await api.duplicateJob(this.$route.params.id);

        toast.success(`Job successfully duplicate.`);
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loadingJob = false;
      }
    },
    async fetchJob() {
      try {
        this.fetchingJob = true;
        const response = await api.fetchJob(this.$route.params.id);

        if (response.data.owner.user.id !== this.currentCustomer.id) {
          this.errors.notFoundErrorMessage =
            errorMessages.CANNOT_ACCESS_JOB_YOU_DONT_OWN;
          return;
        }

        this.job = response.data;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.fetchingJob = false;
      }
    },

    async deleteJob() {
      try {
        this.deletingJob = true;
        await api.deleteJob(this.$route.params.id);

        toast.success(`Job successfully deleted.`);
        this.$router.push({ name: "CustomerJobs" });
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.deletingJob = false;
      }
    },

    fetchJobBids() {
      this.fetchingJobBids = true;
      this.updateListFilters({
        id: this.$route.params.id,
      });
    },

    async updateJobStatus(status, successMessage) {
      try {
        this.updatingJob = true;
        await api.updateJob(
          this.$route.params.id,
          {
            status,
          },
          () => {
            toast.success(successMessage);
            this.job.status = status;
          }
        );
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.updatingJob = false;
      }
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            this.errors.notFoundErrorMessage = errorMessages.JOB_NOT_FOUND;
            break;

          default:
            this.errors.serverSideErrorMessage =
              errorMessages.AN_ERROR_HAS_OCCURED;
            break;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.border-proposal {
  box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 0.5rem;
  border-radius: 6px;
}
.dropdown-menu {
  background: #fbfbfb;
}
.dropdown.dropdown-item {
  border-bottom-color: #e5e8eb;
}
@media (max-width: 1200px) {
  .nav-link {
    position: absolute;
    top: 10%;
    right: 0;
  }
  .nav-links {
    position: absolute;
    top: 10%;
    right: 0;
  }
  .m-drop {
    display: block !important;
  }
  .drop {
    display: none !important;
  }
  .todo-list {
    display: none !important;
  }
  .active {
    display: block !important;
  }
  .job-status {
    display: none;
  }
  .content-title{
    display: inline-flex;
    align-items: center;
  }
  .content-title .active {
    width: 8px;
    height: 8px;
    margin-left: 0.5rem;
    border-radius: 1000px;
    background: #219512;
  }
}
</style>
