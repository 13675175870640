export default {
  statusToUI(status) {
    switch (status) {
      case 'need_complete_profile':
        return 'Incomplete Profile'

      case 'need_approval':
        return 'Awaiting for Approval'

      case 'need_stripe_approval':
        return 'Need Stripe Approval'

      case 'waiting':
        return 'Pending'

      case 'pending':
      return 'Pending'

      case 'overpaid':
      return 'Overpaid'

      case 'partial':
      return 'Partial'

      case 'refused':
        return 'Unauthorized'
        
      case 'deactivate':
        return 'Disabled'

      case 'draft':
        return 'Draft'

      case false:
        return 'Disabled'

      case 'ok':
        return 'Active'

      case 'completed':
        return 'Completed'

      case 'active':
        return 'Active'

      case true:
        return 'Active'

      case 'False':
        return 'Disabled'

      case 'True':
        return 'Active'
      
      case 'unsent':
        return 'Unsent'

      case 'INACTIVE':
        return 'Disabled'

      case 'ACTIVE':
        return 'Active'

      default:
        return status.charAt(0).toUpperCase() + status.slice(1).replaceAll('_', ' ')
    }
  },
};
