import jwt_decode from "jwt-decode";

import store from "@/store";
import { Request } from "@/services/request";
import { UserScopes } from "@/configs/constants/users";
import errorMessages from "@/utils/error-messages";

const extractAndStoreToken = (token, callback) => {
  return new Promise(async (resolve, reject) => {
    try {
      const decodedToken = jwt_decode(token);

      if (decodedToken.scope === UserScopes.SUPER_ADMIN) {
        const error = new Error(errorMessages.NOT_AUTHORIZED_TO_AUTHENTICATE);
        error.status = 403;
        throw error;
      }

      store.dispatch("token/setToken", {
        ...decodedToken,
        accessToken: token,
      });
      await services.fetchCurrentUser();
      if (callback) {
        callback({
          scope: decodedToken.scope,
          status: decodedToken.status,
        });
      }
      resolve();
    } catch (error) {
      store.dispatch("token/logout");
      reject(error);
    }
  });
};

const services = {
  async login(credentials, callback) {
    return new Promise((resolve, reject) => {
      new Request()
        .append("api/v1/users/login/")
        .method("post")
        .setData({
          ...credentials,
        })
        .then(async (response) => {
          resolve(await extractAndStoreToken(response.data.access, callback));
        })
        .catch((error) => {
          if (error.status === 403) {
            let response = {
              message: error.message,
              status: error.status,
            };
            error.response = response;
          }
          reject(error);
        });
    });
  },

  async loginSuperadmin(credentials) {
    let body = {
      ...credentials,
    };

    return new Promise((resolve, reject) => {
      new Request()
        .append("api/v1/superadminlogin/")
        .method("post")
        .setData(body)
        .then(async (response) => {
          var decodedToken = jwt_decode(response.data.access);

          if (decodedToken.scope !== UserScopes.SUPER_ADMIN) {
            const error = new Error(
              errorMessages.NOT_AUTHORIZED_TO_AUTHENTICATE
            );
            error.status = 403;
            throw error;
          }

          store.dispatch("token/setToken", {
            ...decodedToken,
            accessToken: response.data.access,
          });
          resolve();
        })
        .catch((error) => {
          if (error.status === 403) {
            let response = {
              message: error.message,
              status: error.status,
            };
            error.response = response;
          }
          reject(error);
        });
    });
  },

  register(data, callback) {
    return new Promise((resolve, reject) => {
      new Request()
        .append("api/v1/users/register/")
        .method("post")
        .setData(data)
        .then(async (response) => {
          resolve(
            await extractAndStoreToken(response.data.data.token, callback)
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchCurrentUser() {
    return new Promise((resolve, reject) => {
      new Request()
        .append(`api/v1/users/user_details/`)
        .method("get")
        .then(({ data }) => {
          store.dispatch("user/saveUser", data.data.user);
          resolve(data.data.user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changePassword(currentPassword, newPassword) {
    return new Request()
      .append(`api/v1/users/change_password/`)
      .method("put")
      .setData({
        old_password: currentPassword,
        new_password: newPassword,
      });
  },

  forgotPassword(data) {
    return new Request()
      .append(`api/v1/users/password_reset/`)
      .method("post")
      .setData(data);
  },

  resetPassword(fomdata) {
    return new Request()
      .append(`api/v1/users/new_password_confirm/`)
      .method("post")
      .setData(fomdata);
  },
  
  getUserByResetToken(token) {
    return new Request()
      .append(`api/v1/users/password_reset_confirm/`)
      .method("post")
      .setData({
        token,
      });
  },
  
  intiateEmailVerificationProcess(data) {
    return new Request()
      .append(`api/v1/user/send_verify_email/`)
      .method("post")
      .setData(data);
  },

  checkUserTokenFOrEmailVerification(token) {
    return new Request()
      .append(`api/v1/user/verify_user_token/`)
      .method("post")
      .setData({
        token,
      });
  },
  
  verifyEmailAddress(data) {
    return new Request()
      .append(`api/v1/user/verify_email/`)
      .method("post")
      .setData(data);
  },

  deleteAccount(data) {
    return new Request()
      .append(`api/v1/user/deletemyaccount/`)
      .method("delete")
      .setData({ ...data });
  },

  refreshToken(refreshToken, scopes) {
    let body = {
      ...OauthContants,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      scope: scopes.join(" "),
    };

    return new Request()
      .contentType("application/x-www-form-urlencoded")
      .append("api/oauth/token/")
      .method("post")
      .setData(body);
  },

  profile_type(data, callback) {
    return new Request()
      .append("api/v1/user/profile")
      .method("put")
      .setData(data)
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
      });
  },

  recoveryPassword(email, token) {
    return new Request()
      .append(`api/v1/user/lost_password/`)
      .method("post")
      .setData({ email, g_token: token });
  },

  registerOnLead(email) {
    return new Request()
      .append(`api/v1/lead/`)
      .method("post")
      .setData({ email });
  },

  switchAccount(data, callback) {
    return new Promise((resolve, reject) => {
     new Request()
      .append("api/v1/user/switch_account/")
      .method("post")
      .setData(data)
      .then(async (response) => {
        if (callback) {
          // console.log(response.data);
          resolve(await extractAndStoreToken(response.data.data.access_token, callback));
        }
      })
      .catch((error) => {
        reject(error);
      });
    })
  },
  loginAsProvider(data, callback) {
    return new Promise((resolve, reject) => {
     new Request()
      .append(`api/v1/provider/${data}/sign_in_as/`)
      .method("post")
      .then(async (response) => {
        if (callback) {
          // console.log(response.data);
          resolve(await extractAndStoreToken(response.data.token.access_token, callback));
        }
      })
      .catch((error) => {
        reject(error);
      });
    })
  },
  loginAsCustomer(data, callback) {
    return new Promise((resolve, reject) => {
     new Request()
     .append(`api/v1/customer/${data}/sign_in_as/`)
      .method("post")
      .then(async (response) => {
        if (callback) {
          // console.log(response.data);
          resolve(await extractAndStoreToken(response.data.token.access_token, callback));
        }
      })
      .catch((error) => {
        reject(error);
      });
    })
  }
};

export default services;
