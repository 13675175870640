<template>
  <div>
    <loader-spinner v-if="loading" />

    <div
      v-else-if="errors.notFoundErrorMessage"
      class="row mt-4"
    >
      <div class="col-lg-8 offset-md-2">
        <alerte-danger
          :head-error-message="errors.notFoundErrorMessage"
          @updateContent="errors.notFoundErrorMessage = $event"
        />
      </div>
    </div>

    <template v-else>
      <section
        v-if="job"
        class="content job-detail"
      >
        <div class="header">
          <div class="d-flex title-part mb-0">
            <router-link
              :to="{ name: 'ProviderJobs' }"
              class="title me-1"
            >
              Jobs
            </router-link>

            <p
              v-if="job.title"
              class="title-content"
            >
              / {{ job.title }}
            </p>
          </div>

          <div class="d-flex justify-content-end mb-2">
            <button
              v-if="job?.owner_provider?.user?.id !== currentProvider?.id"
              class="btn btn-secondary mx-3"
              :class="{ added: isJobFavorite }"
              :disabled="addingToFavorites || removingFromFavorites"
              @click="handleToggleFavorite"
            >
              <submission-spinner
                v-if="addingToFavorites || removingFromFavorites"
                :color="'#209516'"
              />
              <img
                v-else
                :src="icons.heartVectorIcon"
                alt="Favorite Icon"
              >
            </button>

            <div class="position-proposal-icon">
              <template v-if="job.provider_bids">
                <router-link
                  v-if="getProposalStatusStyle(job.provider_bids_status).class"
                  :to="{
                    name: 'ProviderProposalDetails',
                    params: {
                      id: job.id,
                      proposalId: job.provider_bids_id,
                    },
                  }"
                  class="proposal-status me-3"
                  :class="
                    getProposalStatusStyle(job.provider_bids_status).class
                  "
                >
                  <div
                    v-if="
                      job.provider_bids_status === jobProposalStatus.ACCEPTED
                    "
                    class="circle me-2"
                  />
                  <span class="mb-0">{{
                    formatProposalStatus(job.provider_bids_status)
                  }}</span>
                </router-link>
              </template>
            </div>
            <div
              v-if="job.provider_bids_status === jobProposalStatus.ACCEPTED"
              class="btn-group btn-group-dropdown"
            >
              <button
                type="button"
                class="btn dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  :src="icons.actionsIcon"
                  alt="Actions Icon"
                  class="icon-white"
                >
                Actions
              </button>
              <ul class="dropdown-menu">
                <li
                  v-for="option in actionOptions"
                  :key="option.value"
                  @click="handleActionSelection(option.value)"
                >
                  <a class="dropdown-item cursor-pointer">{{ option.label }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="page-content">
          <div>
            <p class="content-title">
              {{ job.title }}
            </p>

            <p class="content-subtitle pb-0">
              Posted at {{ formatDate(job.created_at, "MMMM Do, YYYY") }}

              <template v-if="job.category?.name">
                in <span class="fw-bold">{{ job.category.name }}</span>
              </template>
            </p>
          </div>

          <div class="row pe-4 detail">
            <div
              class="col-md-8 py-4"
              :class="{
                'col-md-8 description': showCustomerDetails,
                'col-md-12': !showCustomerDetails,
              }"
            >
              <p
                class="category-name"
                v-html="sanitizedDescription"
              />
            </div>

            <div
              v-if="showCustomerDetails"
              class="col-md-4"
            >
              <p class="about">
                About the client
              </p>
              <p>Payment method not verified</p>
              <p class="history">
                History :
              </p>
              <ul>
                <li>20 to 50 proposal</li>
                <li>2 jobs posted</li>
              </ul>
            </div>
          </div>

          <div class="category-part">
            <div class="row">
              <div class="col-md-6 col-lg-4 infos">
                <div
                  v-if="job.contract_type"
                  class="d-flex"
                >
                  <p class="category-name">
                    <span class="category">Contract Type :</span>
                    {{ job.contract_type.name }}
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 infos">
                <div
                  v-if="job.level"
                  class="d-flex"
                >
                  <p class="category-name">
                    <span class="category">Level :</span>
                    {{ job.level.name }}
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 infos">
                <div
                  v-if="job.job_language"
                  class="d-flex"
                >
                  <p class="category-name">
                    <span class="category">Job Language :</span>
                    {{ job.job_language.name }}
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 infos">
                <div
                  v-if="job.budget_type"
                  class="d-flex"
                >
                  <p class="category-name">
                    <span class="category">Budget Type :</span>
                    {{ formatJobBudgetTypes(job.budget_type) }}
                  </p>
                </div>
              </div>

              <template v-if="job.budget_type !== jobBudgetTypes.NOT_SURE">
                <div class="col-md-6 col-lg-4 infos">
                  <div class="d-flex">
                    <p
                      v-if="job.payment_frequency"
                      class="category-name"
                    >
                      <span class="category">Payment Frequency :</span>
                      {{
                        formatJobPaymentFrequencies(job.payment_frequency).long
                      }}
                    </p>
                  </div>
                </div>

                <div
                  v-if="job.budget_type === jobBudgetTypes.FIXED"
                  class="col-md-6 col-lg-4 infos"
                >
                  <div class="d-flex">
                    <p
                      v-if="job.budget !== null && job.budget !== undefined"
                      class="category-name"
                    >
                      <span class="category"> Budget : </span>
                      {{ formatPrice(job.budget) }}
                    </p>
                  </div>
                </div>

                <template v-else>
                  <div class="col-md-6 col-lg-4 infos">
                    <div
                      v-if="
                        job.budget_from !== null &&
                          job.budget_from !== undefined
                      "
                      class="d-flex"
                    >
                      <p class="category">
                        Budget :
                      </p>

                      <p class="category-name">
                        {{ formatPrice(job.budget_from) }}
                        <template
                          v-if="
                            job.payment_frequency &&
                              formatJobPaymentFrequencies(job.payment_frequency)
                                .short
                          "
                        />
                      </p>
                      <p class="category">
                        &nbsp;to&nbsp;
                      </p>
                      <p
                        v-if="
                          job.budget_to !== null && job.budget_to !== undefined
                        "
                        class="category-name"
                      >
                        {{ formatPrice(job.budget_to) }}
                        <template
                          v-if="
                            job.payment_frequency &&
                              formatJobPaymentFrequencies(job.payment_frequency)
                                .short
                          "
                        >
                          / {{
                            formatJobPaymentFrequencies(job.payment_frequency)
                              .short
                          }}
                        </template>
                      </p>
                    </div>
                  </div>
                </template>
              </template>

              <div class="col-md-6 col-lg-4 infos">
                <div
                  v-if="job.category"
                  class="d-flex"
                >
                  <p class="category-name">
                    <span class="category"> Job Type : </span>
                    {{ formatJobType(job.job_type) }}
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 infos">
                <div
                  v-if="job.location_description"
                  class="d-flex"
                >
                  <p class="category-name">
                    <span class="category"> Job Location : </span>
                    {{ job.location_description }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 infos">
                <div
                  v-if="job.start_date && job.end_date"
                  class="d-flex"
                >
                  <p>
                    <span class="category"> Date : </span>

                    <template
                      v-if="
                        formatDate(job.start_date, 'MMMM') ===
                          formatDate(job.end_date, 'MMMM') &&
                          formatDate(job.start_date, 'YYYY') ===
                          formatDate(job.end_date, 'YYYY')
                      "
                    >
                      {{ formatDate(job.start_date, "MMMM") }}
                      {{ formatDate(job.start_date, "Do") }}  <span class="category"> to</span>

                      {{ formatDate(job.end_date, "Do") }}
                      {{ formatDate(job.end_date, "YYYY") }}
                    </template>

                    <template v-else>
                      {{ formatDate(job.start_date, "MMMM Do, YYYY") }} <span class="category"> to</span>
                      {{ formatDate(job.end_date, "MMMM Do, YYYY") }}
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="job.customer_propertie_associate.length !=0"
            class="skills-part"
          >
            <p class="skill-title mb-3">
              Properties :
            </p>
            <div class="table-responsive mt-3">
              <table class="table">
                <head-table 
                  :list="job.provider_bids_status === jobProposalStatus.ACCEPTED ? thead : ['checkbox', ...thead]"
                  :checked="customer_propertie_associate.length == job.customer_propertie_associate.map(el => el.customer_propertie_associate.id).length"
                  @change="customer_propertie_associate = job.customer_propertie_associate.map(el => el.customer_propertie_associate.id)"
                />
                <tbody>
                  <tr
                    v-for="(item, index) in (job.customer_propertie_blocked.length != 0 ? job.customer_propertie_associate.filter(el1 => job.customer_propertie_blocked.find(el2 => el1.id !== el2.property.id)) : job.customer_propertie_associate)"
                    :key="index"
                  >
                    <td v-if="job.provider_bids_status !== jobProposalStatus.ACCEPTED">
                      <input
                        type="checkbox"
                        class="d-inline mx-2"
                        :checked="customer_propertie_associate?.find(el => el == item.customer_propertie_associate.id)"
                        @input="checkProperty(item.customer_propertie_associate.id)"
                      >
                    </td>
                    <td>
                      {{ item.customer_propertie_associate.company }}
                    </td>
                    <td class="text-wrap">
                      {{ item.customer_propertie_associate.location_description }}
                    </td>
                    <td>
                      {{ item.customer_propertie_associate.zip }}
                    </td>

                    <td>
                      {{ item.customer_propertie_associate.city }}
                    </td>
                    <td>
                      {{ item.customer_propertie_associate.state }}
                    </td>
                    <td>
                      {{ formatPrice(item.value) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div 
            v-if="job.customer_propertie_associate[0]"
            class="skills-part"
          >
            <p class="skill-title">
              Items for Pricing :
            </p>

            <div class="auto-flex">
              <span
                v-for="item in job.customer_propertie_associate[0].items"
                :key="item.id"
                class="type-job"
              >
                {{ item.name }}</span>
            </div>
          </div>

          <div class="skills-part">
            <p class="skill-title">
              Skills :
            </p>

            <div class="auto-flex">
              <span
                v-for="skill in job.skills"
                :key="skill.id"
                class="type-job"
              >
                {{ skill.name }}</span>
            </div>
          </div>

          <div
            v-if="job.files?.length"
            class="skills-part pb-3"
          >
            <p class="skill-title">
              Photos and Documents
            </p>

            <div class="row">
              <div
                v-for="(file, i) in job.files"
                :key="i"
                class="col-md-12 col-lg-6"
                :class="{ 'mb-3': i < job.files.length - 1 }"
              >
                <div class="document">
                  <a
                    :href="file.url"
                    target="_target"
                  >
                    <img
                      v-if="file.type === mediaTypesConstants.IMAGE"
                      :src="icons.imageUploadedIcon"
                      alt="File Uploaded"
                      class="file-icon me-1"
                    >
                    <img
                      v-else
                      :src="icons.documentUploadedIcon"
                      alt="File Uploaded"
                      class="file-icon me-1"
                    >
                    {{ getFileName(file.original_name) }}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="job.screening_question.length > 0"
            class="sreening-part"
          >
            <p class="screening-title">
              Screening Questions :
            </p>

            <div class="mt-4 mb-3">
              <div class="row">
                <div class="col-md-12">
                  <p class="text-primary">
                    You will be asked to answer the following questions when
                    submitting a proposal:
                  </p>

                  <ol>
                    <li
                      v-for="question in job.screening_question"
                      :key="question.id"
                    >
                      <p>
                        {{ question.name }}
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <confirm-modal
        v-if="modalConfigs.showConfirmModal"
        ref="confirmModal"
        :ok-button-title="modalConfigs.confirmTitle"
        :message="modalConfigs.confirmMessage"
        @onConfirm="onModalConfirm"
      />
    </template>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import { nextTick } from "vue";
import DOMPurify from "dompurify";

import actionsIcon from "@/assets/img/icons/actions.svg";
import heartVectorIcon from "@/assets/img/icons/heart.svg";
import imageUploadedIcon from "@/assets/img/icons/image_uploaded.svg";
import documentUploadedIcon from "@/assets/img/icons/document_uploaded.svg";

import api from "@/services/api";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

import JobHelper from "@/utils/job";
import FileUtils from "@/utils/file";
import PriceHelpers from "@/utils/price-format";
import { DateHelpers } from "@/utils/date-helpers";
import errorMessages from "@/utils/error-messages";
import { GlobalEvents, MediaTypesConstants } from "@/configs/constants";

import {
  JobProposalStatus,
  JobBudgetTypes,
  JobStatus,
} from "@/configs/constants/jobs";
import { ProviderStatus } from "@/configs/constants/users";

import ConfirmModal from "@/components/ConfirmModal.vue";
import SubmissionSpinner from "@/components/SubmissionSpinner.vue";
import HeadTable from '@/components/HeadTable.vue';
import AlerteDanger from '@/components/AlerteDanger.vue';

export default {
  name: "ProviderJobDetailsPage",

  components: {
    ConfirmModal,
    SubmissionSpinner,
    LoaderSpinner,
    HeadTable,
    AlerteDanger,
  },

  data: () => ({
    icons: {
      heartVectorIcon,
      imageUploadedIcon,
      documentUploadedIcon,
      actionsIcon,
    },
    loading: false,
    sendingRequest: false,
    thead: ["Name", "Address", "Zip", "City", "State", "Price"],
    job: null,
    customer_propertie_associate: [],
    actions: {
      ADD_TO_FAVORITES: "add-to-favorites",
      REQUEST_TO_START_JOB: "request-to-start-job",
      REMOVE_FROM_FAVORITES: "remove-from-favorites",
      TO_DO_LIST: 'to-do-list',
      APPLY_MULTI_SITE: 'Apply-multi-site',
      APPLY: 'Apply',
      REQUEST_TO_COMPLETE_JOB: "request-to-complete-job"
    },
    modalConfigs: {
      confirmTitle: null,
      currentAction: "request-to-start-job",
      confirmMessage: null,
      showConfirmModal: false,
    },
    addingToFavorites: false,
    removingFromFavorites: false,
    showCustomerDetails: false,
    isJobFavorite: false,
    errors: {
      notFoundErrorMessage: null,
      serverSideErrorMessage: null,
    },
  }),

  computed: {
    actionOptions() {
      let items = [];
      if (this.job.provider_bids_status === this.jobProposalStatus.ACCEPTED) {
        if (this.job.status == this.jobStatus.ACTIVE && !this.job.start_request) {
          items.push({
            label: "Request to start the job",
            value: this.actions.REQUEST_TO_START_JOB,
          });
        } else if (this.job.status !== this.jobStatus.COMPLETED && !this.job?.complete_request){
          items.push({
            label: "Request to complete the job",
            value: this.actions.REQUEST_TO_COMPLETE_JOB,
          });
        }
        items.push({
          label: "To do list",
          value: this.actions.TO_DO_LIST,
        });
      }
      if (!this.job.provider_bids ) {
        if (this.job.job_type === 'multi_site' && this.customer_propertie_associate.length == 0) {
          items.push({
            label: "Apply",
            value: this.actions.APPLY_MULTI_SITE,
          });
        } else if (this.job.status === this.jobStatus.ACTIVE && this.canApply) {
          items.push({
            label: "Apply",
            value: this.actions.APPLY,
          });
        }
      }
      

      return items;
    },
    jobProposalStatus() {
      return JobProposalStatus;
    },

    jobBudgetTypes() {
      return JobBudgetTypes;
    },

    jobStatus() {
      return JobStatus;
    },

    currentProvider() {
      return this.$store.getters["user/user"];
    },

    sanitizedDescription() {
      return DOMPurify.sanitize(this.job?.description ?? "");
    },

    canApply() {
      return (
        [ProviderStatus.OK, ProviderStatus.ACTIVE].includes(
          this.currentProvider?.status
        ) && this.job.owner_provider?.user?.id !== this.currentProvider.id
      );
    },

    mediaTypesConstants() {
      return MediaTypesConstants;
    },
    currentPlan() {
      return this.$store.getters["user/currentPlan"];
    },
    currentPlanServices() {
      return this.$store.getters["user/currentPlanServices"];
    },
  },

  mounted() {
    if ((this.includeService('marketplace') && this.includePlan('can_access_marketplace'))) {
      this.$router.push({
        name: 'ProviderJobs', 
      });
    } else {
      this.fetchJob();
      // console.log(this.currentProvider);
    }
  },

  created() {
    this.$emitter.on(
      GlobalEvents.job.PROPOSAL_RESPONSE,
      ({ status, proposalId }) => {
        if (this.job?.provider_bids_id === proposalId) {
          this.job.provider_bids_status = status
        }
      }
    );
  },

  methods: {
    ...JobHelper,
    ...FileUtils,
    ...DateHelpers,
    ...PriceHelpers,

    includeService(code) {
      let service = this.currentPlanServices?.find(el=> el.code == code);
      if (service == undefined) {
        return true;
      } else {
        return false
      }
    },
    includePlan(key) {
      if (this.currentPlan && this.currentPlan[key] == null) {
        return true;
      } else {
        return false
      }
    },
    checkProperty(id){
      if (this.customer_propertie_associate?.find(el => el == id) == undefined) {
        this.customer_propertie_associate?.push(id)
      } else {
        this.customer_propertie_associate =  this.customer_propertie_associate.filter(el => el != id)
      }
      // console.log(this.datasend.customer_propertie_associate);
    },

    gotoToDoList() {
      this.$router.push({
        name: "ProviderToDoList",
        params: { id: this.$route.params.id },
      });
    },

    async fetchJob() {
      try {
        this.loading = true;
        const response = await api.fetchJob(this.$route.params.id);
        this.job = response.data;
        // console.log(this.job)
        this.isJobFavorite = this.job?.provider_favorite;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.loading = false;
      }
    },

    handleActionSelection(action) {
      switch (action) {
        case this.actions.REQUEST_TO_START_JOB:
          this.confirmClaim(this.actions.REQUEST_TO_START_JOB)
          break;
        case this.actions.REQUEST_TO_COMPLETE_JOB:
          this.confirmClaim(this.actions.REQUEST_TO_COMPLETE_JOB)
          break;

        case this.actions.TO_DO_LIST:
          this.gotoToDoList();
          break;

        case this.actions.APPLY_MULTI_SITE:
          this.confirmClaim();
          break;

        case this.actions.APPLY:
          this.$router.push({
            name: 'ProviderJobApply', 
            params: { 
              id: this.job.id, 
              properties: JSON.stringify(this.customer_propertie_associate)}
          });
          break;
      }
    },

    handleToggleFavorite() {
      if (this.isJobFavorite) {
        this.confirmClaim(this.actions.REMOVE_FROM_FAVORITES);
      } else {
        this.confirmClaim(this.actions.ADD_TO_FAVORITES);
      }
    },

    confirmClaim(action) {
      switch (action) {
        case this.actions.REQUEST_TO_START_JOB:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to send request to start the job ?";
          break;
        case this.actions.REQUEST_TO_COMPLETE_JOB:
          this.modalConfigs.confirmTitle = "Yes";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to send request to complete the job ?";
          break;

        case this.actions.ADD_TO_FAVORITES:
          this.modalConfigs.confirmTitle = "Add";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to add this job to your favorites ?";
          break;

        case this.actions.REMOVE_FROM_FAVORITES:
          this.modalConfigs.confirmTitle = "Remove";
          this.modalConfigs.confirmMessage =
            "Are you sure you want to remove this job from your favorites ?";
          break;

        default:
          this.modalConfigs.confirmTitle = "Ok";
          this.modalConfigs.confirmMessage =
          "You must check at least one property";
          break;
      }
      this.modalConfigs.showConfirmModal = true;
      this.currentAction = action;
      nextTick(() => {
        this.$refs.confirmModal.openModal();
      });
    },

    onModalConfirm() {
      switch (this.currentAction) {
        case this.actions.ADD_TO_FAVORITES:
          this.addJobToFavorites();
          break;

        case this.actions.REMOVE_FROM_FAVORITES:
          this.removeJobFromFavorites();
          break;

        case this.actions.REQUEST_TO_START_JOB:
          this.requestStartJob();
          break;
        case this.actions.REQUEST_TO_COMPLETE_JOB:
          this.requestCompleteJob();
          break;

        default:
          break;
      }
      this.modalConfigs.confirmTitle = null;
      this.modalConfigs.confirmMessage = null;
      this.currentAction = null;
      this.modalConfigs.showConfirmModal = false;
    },

    async requestStartJob() {
      try {
        this.sendingRequest = true;
        await api.requestStartJob(this.$route.params.id,
          () => {
            this.job.start_request = true;
            toast.success("The request to start the job has been successfully sent.");
          }
        );
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.sendingRequest = false;
      }
    },
     async requestCompleteJob() {
      try {
        this.sendingRequest = true;
        await api.requestCompleteJob(this.$route.params.id,
          () => {
            this.job.complete_request = true;
            toast.success("The request to complete the job has been successfully sent.");
          }
        );
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.sendingRequest = false;
      }
    },

    async addJobToFavorites() {
      try {
        this.addingToFavorites = true;
        this.clearErrorsMessages();

        await api.addJobToFavorites({
          job: this.job.id,
        });
        this.isJobFavorite = true;
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.addingToFavorites = false;
      }
    },

    async removeJobFromFavorites() {
      try {
        this.removingFromFavorites = true;
        this.clearErrorsMessages();

        await api.removeJobFromFavorites({
          job: this.job.id,
        });
        this.isJobFavorite = false;
        this.$emitter.emit(
          GlobalEvents.JOB_REMOVED_FROM_FAVORITES,
          this.job.id
        );
      } catch (error) {
        this.errorsHandler(error);
      } finally {
        this.removingFromFavorites = false;
      }
    },

    clearErrorsMessages() {
      for (const key in this.errors) {
        if (Object.hasOwnProperty.call(this.errors, key)) {
          this.errors[key] = null;
        }
      }
    },

    errorsHandler(err) {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            this.errors.notFoundErrorMessage = errorMessages.JOB_NOT_FOUND;
            break;

          default:
            this.errors.serverSideErrorMessage =
              errorMessages.AN_ERROR_HAS_OCCURED;
            break;
        }
      } else {
        this.errors.serverSideErrorMessage = errorMessages.AN_ERROR_HAS_OCCURED;
      }
    },
  },
};
</script>

<style scoped>
.description {
  border-right: 1px solid #e5e8eb;
}
.about {
  font-weight: 600;
  font-size: 1.125rem;
  color: #209516;
}
.history {
  font-weight: 600;
  font-size: 1rem;
}
.detail {
  border-bottom: 1px solid #e5e8eb;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #e5e8eb;
}
.infos {
  margin: 1rem 0;
}
</style>
