import mitt from "mitt";
import { createApp } from "vue";

import vClickOutside from "click-outside-vue3";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueDatePicker from "@vuepic/vue-datepicker";
import FullCalendar from "@fullcalendar/vue3";
import VueTelInput from 'vue-tel-input';
import money from "v-money3";

import App from "./App.vue";
import router from "@/router";
import store from "@/store";

import bootstrap from "bootstrap/dist/js/bootstrap.bundle.js";

import "@vuepic/vue-datepicker/dist/main.css";
import "vue-tel-input/vue-tel-input.css";
import "vue3-toastify/dist/index.css";

const app = createApp(App);
app.use(store);
app.use(router);

/**
 * @description
 * Register Plugins here
 */
app.use(money);
app.use(bootstrap);
app.use(vClickOutside);

/**
 * @description
 * Register Global Components here
 */
app.use(CKEditor);
app.component("VueDatePicker", VueDatePicker);
app.component("FullCalendar", FullCalendar);
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_G_KEY,
    libraries: "places",
  },
});
app.use(VueTelInput, {
  autoFormat: true,
  validCharactersOnly: true,
  defaultCountry: 'us',
  mode: 'international',
});

/**
 * @description
 * Register Global properties here
 */
const emitter = mitt();
app.config.globalProperties.$emitter = emitter;

app.mount("#app");
